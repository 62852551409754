import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'checkout',
      component: () =>
        dynamicImport(
          import('@insurance/modules/layout/pages/InsurancePage.vue'),
        ),
      children: [
        {
          path: 'insurance-renewal/:id',
          name: ROUTES.CHECKOUT_INSURANCE.PAYMENT,
          component: () =>
            dynamicImport(
              import('@insurance/modules/renewal/pages/RenewalPaymentPage.vue'),
            ),
          meta: {
            auth: {
              required: true,
            },
          },
        },
      ],
    },
  ]
}
