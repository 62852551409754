import {
  COUNTRIES,
  CURRENCIES,
  MARKETPLACES,
  MARKET_STATUS,
  TRUSTMARKS,
} from '@config/constants'
import { ROUTES } from '@router'

const fromYoutube = ({ id, params = '' }) =>
  `https://www.youtube.com/embed/${id}?rel=0&amp;showinfo=0${params}`

const APP_CONFIG = {
  [COUNTRIES.FR]: {
    buyback: {
      enabled: true,
      swapAvailable: true,
      hasRib: true,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360010648979-Comment-sauvegarder-et-supp%5B%E2%80%A6%5D-il-aux-donn%C3%A9es-de-mon-appareil-apr%C3%A8s-sa-revente-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360012127479-Comment-d%C3%A9connecter-mon-compte-iCloud-Google-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/fr-fr/articles/360010965060',
        },
      },
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fr-fr',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/presse.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'the-impact-of-e-waste',
        category: 'events',
      },
    },
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'X194F6470570B4368A91A3C7230E9014C',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'V-_hrGnuBoM' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.AT]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'de-at',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/presse.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'events',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XE714EB9CDE3DA7C8DD4DCFF5B0CE6B9E',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: true,
    vatIncluded: true,
    video404: fromYoutube({ id: 'viGnI3gZMqU' }),
    warranty: {
      duration: 36,
      displayInYear: false,
    },
  },
  [COUNTRIES.BE]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fr-be',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/presse.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XB9BE628A37B8B2E1477584BD94039251',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: true,
    vatIncluded: false,
    video404: fromYoutube({ id: 'V-_hrGnuBoM' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.DE]: {
    buyback: {
      enabled: true,
      swapAvailable: true,
      hasRib: true,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360010648979-Was-passiert-mit-den-Daten-auf-meinem-Ger%C3%A4t-nachdem-ich-es-verkauft-habe-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360012127479-Wie-melde-ich-mich-von-meinem-iCloud-Konto-ab-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360012028260-Wie-melde-ich-mich-von-meinem-Google-Konto-ab-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/de/articles/360010965060',
        },
      },
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'de-de',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/presse.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'the-impact-of-e-waste',
        category: 'news',
      },
    },
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'X0D2D87BCBFAE89812C9338C968A4BD77',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: true,
    vatIncluded: true,
    video404: fromYoutube({ id: 'haECT-SerHk' }),
    warranty: {
      duration: 24,
      displayInYear: false,
    },
  },
  [COUNTRIES.ES]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'es-es',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/prensa.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: true,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.CONFIANZA_ONLINE,
    trustmarkConfig: {
      id: 'X416F4F5FD9E8CB7EC326CBA57589BE5B',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: true,
    vatIncluded: true,
    video404: fromYoutube({ id: 'A8qezmx3r1s' }),
    warranty: {
      displayInYear: true,
      duration: 24,
    },
  },
  [COUNTRIES.FI]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'fi-fi',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    // TODO: create a dedicated page in pastrami. This is still a badoom view.
    press: '/press.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'content',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '5eb28e4f90998f0001ecc108',
      href: 'https://fi.trustpilot.com/review/backmarket.fi',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: '0d_xT4GMYZo', params: '&start=332&end=374' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.GB]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    coolingOffDays: 30,
    cgvModalLegals: false,
    countryCode: 'en-gb',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.GBP,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/press.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'refurbished-smartphones',
        category: 'iphone',
      },
    },
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      businessUnitId: '5e2b02758b37ba0001e1d426',
      href: 'https://uk.trustpilot.com/review/backmarket.co.uk',
      templateId: '53aa8807dec7e10d38f59f32',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.GR]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'el-gr',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/press.html',
    serviceFeeGuideRoute: {},
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      businessUnitId: '60634b8d27f4190001f3896e',
      href: 'https://gr.trustpilot.com/review/backmarket.gr',
      templateId: '53aa8807dec7e10d38f59f32',
    },
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'qo4E0qGypAk' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.IE]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'en-ie',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/press.html',
    serviceFeeGuideRoute: {},
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634b8927f4190001f3896b',
      href: 'https://ie.trustpilot.com/review/backmarket.ie',
    },
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.IT]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'it-it',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/stampa.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '5c6301badc82bd00015430aa',
      href: 'https://it.trustpilot.com/review/backmarket.it',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
  [COUNTRIES.NL]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'nl-nl',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/presse.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.TRUSTED_SHOPS,
    trustmarkConfig: {
      id: 'XD3D9E2C9264223766FEDD3DB22395FA0',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: true,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: '8gqpAEsC_xE' }),
    warranty: {
      duration: 36,
      displayInYear: false,
    },
  },
  [COUNTRIES.PT]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'pt-pt',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    press: '/imprensa.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.TRUSTPILOT,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60377c9970c83a000172de3d',
      href: 'https://pt.trustpilot.com/review/backmarket.pt ',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'tHza_jWceDk' }),
    warranty: {
      displayInYear: true,
      duration: 24,
    },
  },
  [COUNTRIES.SE]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'sv-se',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.SEK,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: false,
    press: '',
    serviceFeeGuideRoute: {},
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: true,
    showInsuranceCatchupModal: false,
    // TODO set to trustpilot when enough reviews
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634bad27f4190001f38973',
      href: 'https://fr.trustpilot.com/review/backmarket.se',
    },
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: true,
    video404: fromYoutube({ id: 'v8_7yPocGPg' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.SK]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: true,
    coolingOffDays: 30,
    countryCode: 'sk-sk',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.EUR,
    marketplace: MARKETPLACES.EU,
    mobileAppAvailable: true,
    // eslint-disable-next-line line-comment-position
    press: '', // TODO
    serviceFeeGuideRoute: {},
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: true,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {
      templateId: '53aa8807dec7e10d38f59f32',
      businessUnitId: '60634bb1e143b400018548c0',
      href: 'https://fr.trustpilot.com/review/backmarket.sk',
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: true,
    // eslint-disable-next-line line-comment-position
    video404: fromYoutube({ id: 'd73MEkygsiY' }),
    warranty: {
      displayInYear: false,
      duration: 12,
    },
  },
  [COUNTRIES.US]: {
    buyback: {
      enabled: true,
      swapAvailable: false,
      hasRib: false,
      faq: {
        backup: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360010648979-What-happens-to-the-data-on-my-device-after-I-trade-it-in-',
        },
        icloud: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360034277353-How-do-I-disconnect-or-remove-my-iCloud-account-',
        },
        google: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360010895460-How-do-I-disconnect-or-remove-my-Google-account-',
        },
        counterOffer: {
          type: 'external',
          href: 'https://help.backmarket.com/hc/en-us/articles/360010965060',
        },
      },
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'en-us',
    countryStatus: MARKET_STATUS.OPEN,
    currency: CURRENCIES.USD,
    marketplace: MARKETPLACES.NA,
    mobileAppAvailable: true,
    press: '/press.html',
    serviceFeeGuideRoute: {
      name: ROUTES.BUYING_GUIDE.SELF,
      params: {
        slug: 'back-market-quality',
        category: 'news',
        id: 741,
      },
    },
    ewasteGuideRoute: {},
    showCarrier: true,
    showIncludedVat: false,
    showInsuranceCatchupModal: true,
    trustmark: TRUSTMARKS.GOOGLE_REVIEWS,
    trustmarkConfig: {
      merchantId: 118622995,
    },
    // TODO [PI-1678] Improve trustmarks config when specs available
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: fromYoutube({ id: 'Al7J6cSaSPw' }),
    warranty: {
      duration: 12,
      displayInYear: true,
    },
  },
  [COUNTRIES.JP]: {
    buyback: {
      enabled: false,
      swapAvailable: false,
      hasRib: false,
      faq: {},
    },
    cgvModalLegals: false,
    coolingOffDays: 30,
    countryCode: 'ja-jp',
    countryStatus: MARKET_STATUS.CLOSED,
    currency: CURRENCIES.JPY,
    marketplace: MARKETPLACES.AP,
    mobileAppAvailable: false,
    press: '/',
    serviceFeeGuideRoute: {},
    ewasteGuideRoute: {},
    showCarrier: false,
    showIncludedVat: false,
    showInsuranceCatchupModal: false,
    trustmark: TRUSTMARKS.NONE,
    trustmarkConfig: {},
    useTrustedShops: false,
    trustmarkGuarantee: false,
    vatIncluded: false,
    video404: '',
    warranty: {
      duration: 12,
      displayInYear: false,
    },
  },
}

export default APP_CONFIG
