import Vue from 'vue'

import { dangerouslyGetRuntimeConfig } from '@config/variables'

import { FLAG_TYPES } from './constants'
import { getDefaultConfigValues } from './methods'

const { FF_ABTEST_CART_NO_REDIRECT, FF_ABTEST_CART_STICKY_CHECKOUT_BUTTON } =
  dangerouslyGetRuntimeConfig()

/**
 * Config object for abtests
 * Read more: https://backmarket.atlassian.net/wiki/x/WIAecg
 * @typedef {Object} AbTest
 * @property {string} type
 * @property {string} defaultValue
 * @property {boolean} active
 */
export const flagsConfig = {
  autofocusNameOnCard: {
    type: FLAG_TYPES.BOOLEAN,
    defaultValue: false,
    active: true,
  },
  cartCheckoutStickyButton: {
    type: FLAG_TYPES.STRING,
    defaultValue: 'checkoutButtonMoving',
    active: FF_ABTEST_CART_STICKY_CHECKOUT_BUTTON,
  },
  cartNoRedirect: {
    type: FLAG_TYPES.STRING,
    defaultValue: 'cartRedirect',
    active: FF_ABTEST_CART_NO_REDIRECT,
  },
  cartModalCrossSell: {
    type: FLAG_TYPES.STRING,
    defaultValue: 'noVariation',
    active: true,
  },
  paymentFormNumberKeyboardAndNameLabel: {
    type: FLAG_TYPES.BOOLEAN,
    defaultValue: false,
    active: true,
  },
  'experiment.ppCousinsConversionScore': {
    type: FLAG_TYPES.STRING,
    defaultValue: 'noVariation',
    active: true,
  },
  'experiment.numberCardsDisplayed': {
    type: FLAG_TYPES.STRING,
    defaultValue: 'noVariation',
    active: true,
  },
}

export const flags = getDefaultConfigValues(flagsConfig)

// This function will refresh the flags dictionary defined right above. To make
// sure the flags are properly up-to-date, it needs to be called whenever the
// CloudBees configuration changes (both server-side and client-side).
export function refreshFlags(config) {
  Object.entries(config).forEach(([key, flag]) => {
    // Boolean flags values are accessible through the `isEnabled` function.
    // Read more: https://docs.cloudbees.com/docs/cloudbees-feature-management-api/latest/api-reference/javascript-ssr-api#_isenabled
    if (typeof flag.isEnabled === 'function') {
      Vue.set(flags, key, flag.isEnabled())

      return
    }

    // Strings and number flags values are accessible though `getValue`.
    // Read more: https://docs.cloudbees.com/docs/cloudbees-feature-management-api/latest/api-reference/javascript-ssr-api#_getvaluecontextstring
    if (typeof flag.getValue === 'function') {
      Vue.set(flags, key, flag.getValue())

      return
    }

    // We do not want to swallow the exception, but rather be explicit that
    // something is not working as expected (or not taken into account).
    Vue.set(flags, key, null)
  })

  return flags
}
