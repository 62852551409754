import { fetchCart } from '@http/endpoints'
import { PRODUCT_DEAL_TYPE } from '@tracking'

import { mapFromCart } from '../modules/common'

import address from './address'
import cart from './cart'
import discount from './discount'
import loader from './loader'
import payment from './payment'
import swap from './swap'
import userInformation from './userInformation'

/**
 * Namespaced Checkout Vuex Store
 * Read more: https://github.com/BackMarket/front/wiki/%5BRFC%5D-Checkout-Vuex-store
 */
export default {
  namespaced: true,

  modules: {
    address,
    cart,
    discount,
    loader,
    payment,
    swap,
    userInformation,
  },

  /**
   * @typedef {Object} State
   * @property {boolean} isLoaded
   * @returns {State}
   */
  state: () => ({
    isLoaded: false,
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {boolean} isLoaded
     */
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded
    },
  },

  getters: {
    /**
     * @param {State} state
     */
    isLoaded: (state) => state.isLoaded,

    /**
     * @param {Object} state
     * @param {Object} getters
     * @returns {Price}
     */
    priceAfterDiscount: (state, getters) =>
      getters['discount/isApplied']
        ? getters['discount/deductedPrice']
        : getters['cart/price'],

    /**
     * @param {Object} state
     * @param {Object} getters
     * @returns {boolean} Returns true if the current checkout state is shippable.
     * For regular shipping:
     * - shipping address is valid (see address/isShippingAddressComplete)
     * - cart items are shippable for the current shipping address country (see
     *   cart/isShippableForCountry)
     * For collection point shipping:
     * - customer details are valid (see address/isCollectionPointAddressComplete)
     * - note: no check is performed for the selected collection point, that is
     *         a separate check, which is used for a different redirection (see
     *         cart/isSelectedCollectionPointMissing).
     */
    isShippable: (state, getters) => {
      const isShippable = getters['address/hasCollectionPoint']
        ? getters['address/isCollectionPointAddressComplete']
        : getters['address/isShippingAddressComplete'] &&
          getters['cart/isShippableForCountry'](
            getters['address/shipping'].country,
          )

      return isShippable && getters['address/isBillingAddressComplete']
    },

    /**
     * @returns {import('modules/payment/modules/methods/config/methods').VirtualCardContext}
     */
    virtualCardContext: (state, getters, rootState, rootGetters) => ({
      email: rootGetters['user/email'],
      shippingAddress: getters['address/shipping'],
      billingAddress: getters['address/billing'],
      availableItems: getters['cart/availableItems'],
      tax: getters['cart/tax'],
      totalShippingPrice: getters['cart/totalShippingPrice'],
      priceAfterDiscount: getters.priceAfterDiscount,
      isDiscountApplied: getters['discount/isApplied'],
      discountToken: getters['discount/token'],
      discountDeduction: getters['discount/deduction'],
    }),

    /**
     * @param {Object} state
     * @param {Object} getters
     * @returns {Object}
     */
    trackingData: (state, getters) => ({
      products: getters['cart/items'].map((item) => ({
        ...item.productTrackingData,
        dealType: PRODUCT_DEAL_TYPE.NORMAL,
        splitPayment: getters['payment/advertisedMethodsId'],
      })),
      swap: getters['swap/hasOffer'],
    }),
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<void, Object>} context
     * @param {Object} [payload]
     * @return {Promise<void>}
     */
    async fetch({ getters, commit, dispatch }) {
      // 1. Fetch cart & addresses
      const { payload } = await dispatch(
        'http/request',
        { request: fetchCart },
        { root: true },
      )
      // TODO [PI-2154] Migrate to Checkout API
      const sanitizedPayload = mapFromCart(payload)
      await Promise.all([
        dispatch('cart/set', sanitizedPayload),
        dispatch('address/set', sanitizedPayload),
        dispatch('payment/set', sanitizedPayload),
        dispatch('swap/set', sanitizedPayload),
        dispatch('userInformation/set', sanitizedPayload),
      ])

      // 2. Fetch payment methods
      await dispatch('payment/fetchMethods', {
        cartItemsIds: getters['cart/itemsIds'],
        cartPrice: getters['cart/price'],
      })

      // 3. Update discount store
      await dispatch('discount/setBasePrice', getters['cart/price'])

      commit('setIsLoaded', true)
    },

    /**
     * @param {Object} context
     * @param {Object} payload
     * @param {Object} payload.paymentMethod
     * @param {Object} payload.paymentMethodGroup
     */
    async setPaymentMethod(
      { dispatch },
      { paymentMethod, paymentMethodGroup },
    ) {
      await dispatch('payment/setSelectedMethod', { paymentMethod })
      await dispatch('discount/setPaymentMethod', {
        paymentMethod,
        paymentMethodGroup,
      })
    },

    /**
     * @param {import('vuex').ActionContext<void, Object>} context
     * @param {Object} payload
     * @returns {{
     *   paymentId: string,
     *   redirection: Promise<import('@payment').PaymentRedirection>
     * }}
     */
    makePayment({ getters, dispatch }, payload) {
      return dispatch('payment/make', {
        ...payload,
        virtualCardContext: getters.virtualCardContext,
      })
    },
  },
}
