export const AFFIRM_CHECKOUT_VENDOR_NAME = 'Back Market'

export const AFFIRM_ERROR_REASONS = {
  CANCEL: 'canceled',
}

/**
 * Values to override on virtual card provided by Affirm. We need to do this in
 * order to generate a virtual card on dev/preprod that is compatible with Adyen
 * sandbox.
 */
export const AFFIRM_SANDBOX_VIRTUAL_CARD_OVERRIDES = {
  month: '03',
  year: '2030',
  securityCode: '737',
}
