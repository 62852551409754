import getConfig from './getConfig'
import breadCrumb from './modules/breadCrumb'
import categoryList from './modules/categoryList'
import page from './modules/page'

export default {
  namespaced: true,
  modules: {
    categoryList,
    breadCrumb,
    page,
  },
  actions: {
    fetch: async ({ dispatch }, { routeInfo }) => {
      dispatch('page/reset')
      const { rateModule, queryParams, pathParams } = getConfig(routeInfo)
      await Promise.all([
        dispatch('reviews/list/fetch', { routeInfo }, { root: true }),
        dispatch('reviews/summary/fetch', { routeInfo }, { root: true }),
        dispatch('breadCrumb/fetch', { routeInfo }),
        dispatch(
          `rates/${rateModule}/fetch`,
          { ...queryParams, ...pathParams },
          { root: true },
        ),
      ])
    },
    reset: ({ dispatch }) => {
      dispatch('breadCrumb/reset')
      dispatch('categoryList/reset')
      dispatch('page/reset')
      dispatch('reviews/list/reset', { root: true })
      dispatch('reviews/summary/reset', { root: true })
    },
  },
}
