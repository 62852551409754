export const FORM_TYPES = {
  INSURANCE_ONLY: 'INSURANCE_ONLY_FORM',
  NONE: 'NONE',
  SWAP_AND_INSURANCE: 'SWAP_AND_INSURANCE_FORM',
  SWAP_ONLY: 'SWAP_ONLY_FORM',
}

export default {
  namespaced: true,

  state: () => ({
    birthdate: '',
    formType: FORM_TYPES.NONE,
    missingData: false,
    nationality: '',
  }),

  mutations: {
    setUserInformation(
      state,
      { birthdate, formType, missingData, nationality } = {},
    ) {
      state.birthdate = birthdate || ''
      state.formType = formType
      state.missingData = missingData
      state.nationality = nationality || ''
    },
  },

  getters: {
    isNationalityRequired: (state) =>
      state.formType === FORM_TYPES.SWAP_AND_INSURANCE ||
      state.formType === FORM_TYPES.SWAP_ONLY,

    isFormRequired: (state) => state.formType !== FORM_TYPES.NONE,

    isMissingData: (state) => state.missingData,

    userInformation: (state) => state,
  },

  actions: {
    async set({ commit }, { userInformation }) {
      commit('setUserInformation', userInformation)
    },
  },
}
