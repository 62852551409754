import isEmpty from 'lodash/isEmpty'

import { COMPANY_NAME } from '@config/constants'

import {
  SCHEMA_BEST_RATING,
  SCHEMA_ITEM_CONDITION,
  SCHEMA_SEARCH_NAME,
  SCHEMA_WORST_RATING,
} from './constants'

/**
 * Builds the product schema object.
 * https://developers.google.com/search/docs/advanced/structured-data/product
 * This object is used in a JSON-LD script that is injected in the page head
 *
 * @typedef Price
 * @property {Number} averageRate
 * @property {Number} reviewsCount
 *
 *  @typedef Rate
 * @property {Number} value
 * @property {string} currency
 *
 * @param {string} title
 * @param {string} description
 * @param {string} image
 * @param {Rate} rate
 * @param {string} [itemCondition=RefurbishedCondition]
 * @param {Price} [price]
 * @param {string} [brand]
 *
 * @return {SchemaObject} Product schema object
 */
export const buildProductSchema = ({
  title,
  description,
  image,
  rate = {},
  itemCondition = SCHEMA_ITEM_CONDITION.REFURBISHED,
  price = {},
  brand,
}) => {
  const baseInfo = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: title,
    description,
    image,
  }

  const brandInfo = brand
    ? {
        brand: {
          '@type': 'Thing',
          name: brand,
        },
      }
    : {}

  const priceInfo = !isEmpty(price)
    ? {
        priceCurrency: price.currency,
        price: price.amount,
      }
    : {}

  const offersInfo = {
    offers: {
      '@type': 'Offer',
      availability: 'http://schema.org/InStock',
      itemCondition,
      ...priceInfo,
      seller: {
        '@type': 'Organization',
        name: COMPANY_NAME,
      },
    },
  }

  const aggregateRatingInfo = !isEmpty(rate)
    ? {
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: rate.averageRate,
          reviewCount: rate.reviewsCount,
          bestRating: SCHEMA_BEST_RATING,
          worstRating: SCHEMA_WORST_RATING,
        },
      }
    : {}

  return {
    ...baseInfo,
    ...brandInfo,
    ...offersInfo,
    ...aggregateRatingInfo,
  }
}

/**
 * Builds the Sitelinks searchbox schema object.
 * https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
 * This object is used in a JSON-LD script that is injected in the homepage head
 *
 * @param {string} homeUrl
 * @param {string} searchUrl
 *
 * @return {SchemaObject} Searchbox schema object
 */
export const buildSearchBoxSchema = ({ homeUrl, searchUrl }) => ({
  '@context': 'https://schema.org/',
  '@type': 'WebSite',
  name: COMPANY_NAME,
  url: homeUrl,
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: `${searchUrl}?q={${SCHEMA_SEARCH_NAME}}&ga_search={${SCHEMA_SEARCH_NAME}}`,
      'query-input': `required name=${SCHEMA_SEARCH_NAME}`,
    },
  ],
})

/**
 * Builds the schema object for Faq.
 * https://developers.google.com/search/docs/advanced/structured-data/faqpage
 * This object is used in a JSON-LD script that is injected in the page head
 *
 * @typedef Question
 * @property {string} question
 * @property {string} answer
 *
 * @param {Question[]} questions
 *
 * @return {SchemaObject} Faq schema object
 */
export const buildFaqSchema = (questions = []) => {
  const entityitems = questions.map((item) => {
    const { question, answer } = item

    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer,
      },
    }
  })

  return {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: entityitems,
  }
}
