import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'student-discount',
      name: ROUTES.STUDENT_DISCOUNT,
      component: () => dynamicImport(import('./pages/StudentDiscount.vue')),
    },
  ]
}
