import isEmpty from 'lodash/isEmpty'

export default ({ app }, inject) => {
  inject('static', (path) => {
    if (process.isDev && (isEmpty(path) || !path.startsWith('/'))) {
      throw new Error(
        `[$static plugin] Path should start with a slash. (${path})`,
      )
    }

    return app.$config.STATICS_PATH + path
  })
}
