import conversations from './modules/conversations'
import discussion from './modules/discussion'
import information from './modules/information'
import invoiceRequests from './modules/invoiceRequests'
import order from './modules/order'
import productReturns from './modules/productReturns'

export default {
  namespaced: true,
  modules: {
    conversations,
    discussion,
    information,
    invoiceRequests,
    order,
    productReturns,
  },
}
