import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return {
    requiresSellerAuthentication: [],
    other: [
      {
        name: ROUTES.BO_MERCHANT.REGISTER,
        path: 'subscribe',
        component: () => dynamicImport(import('./pages/register')),
        meta: {
          notSeller: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.HOME },
          },
        },
      },
      {
        name: ROUTES.BO_MERCHANT.LOGIN,
        path: 'login',
        component: () => dynamicImport(import('./pages/login')),
        meta: {
          notSeller: {
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.HOME },
          },
        },
      },
    ],
  }
}
