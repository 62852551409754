import { illustrationPlugin } from '@backmarket/design-system'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueSmoothScroll from 'vue-smooth-scroll'

import { dangerouslyGetRuntimeConfig } from '@config/variables'

const { IMAGE_OPTIMIZATION_PROVIDER } = dangerouslyGetRuntimeConfig()

/*
 * Beware that adding a component below will make it loaded and
 * available everywhere, this will then make the whole application
 * heavier. This might not be what you want.
 * Consider loading your compopent only in your view instead.
 */

Vue.use(VueSmoothScroll)
Vue.use(VueCookies)

let provider

switch (IMAGE_OPTIMIZATION_PROVIDER) {
  case 'local': {
    const {
      provider: eleventy,
      // eslint-disable-next-line global-require
    } = require('../../modules/static/images/eleventy-provider')

    provider = eleventy
    break
  }
  case 'cloudflare': {
    const {
      provider: cloudflareProvider,
      // eslint-disable-next-line global-require
    } = require('../../modules/static/images/cloudflare-provider')

    provider = cloudflareProvider
    break
  }

  default:
    // Do not generate optimized path if no provider is set.
    provider = (path) => path
}

Vue.use(illustrationPlugin, {
  provider,
})
