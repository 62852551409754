export const createConfig = (FLAG_TYPES, roxInstance, config) => {
  const flagValues = {
    [FLAG_TYPES.BOOLEAN]: (value) => new roxInstance.Flag(value),
    [FLAG_TYPES.STRING]: (value) => new roxInstance.RoxString(value),
    [FLAG_TYPES.NUMBER]: (value) => new roxInstance.RoxNumber(value),
  }

  return Object.keys(config).reduce((acc, key) => {
    if (!config[key].active) return acc

    const { type, defaultValue } = config[key]

    return {
      ...acc,
      [key]: flagValues[type](defaultValue),
    }
  }, {})
}

export const getDefaultConfigValues = (config) => {
  return Object.keys(config).reduce((acc, key) => {
    if (!config[key].active) return acc

    const { defaultValue } = config[key]

    return {
      ...acc,
      [key]: defaultValue,
    }
  }, {})
}
