import {
  PAYMENT_GROUPS,
  PAYMENT_METHODS,
  paymentGroupIcon,
  paymentMethodIcon,
} from '../../methods'
import AffirmModal from '../components/AffirmModal.vue'
import KlarnaModal from '../components/KlarnaModal.vue'
import KlarnaSizeableIcon from '../components/KlarnaSizeableIcon.vue'
import OneyModal from '../components/OneyModal.vue'

import translations from './advertising.translations'
import { PAYMENT_ADVERTISING_TYPES } from './constants'

const oneyConfig = {
  [PAYMENT_ADVERTISING_TYPES.CART]: {
    available: translations.installmentPaymentAvailable,
    unavailable: translations.installmentPaymentUnavailable,
    modal: OneyModal,
  },
  [PAYMENT_ADVERTISING_TYPES.LANDING]: {
    label: translations.payInInstallments,
    icon: paymentMethodIcon(PAYMENT_METHODS.CARD),
    modal: OneyModal,
  },
  [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
    label: translations.payInInstallments,
    description: translations.asLowAs,
    subDescription: translations.splitPayment,
    modal: OneyModal,
  },
}

/**
 * @type {Object<string, {
 *   cart?: PaymentAdvertisingCartConfig,
 *   landing?: PaymentAdvertisingLandingConfig,
 *   product?: PaymentAdvertisingProductConfig,
 *   search?: PaymentAdvertisingSearchConfig,
 * }>}
 *
 * @typedef {Object} PaymentAdvertisingCartConfig
 * @property {Translation} available
 * @property {Translation} unavailable
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingLandingConfig
 * @property {Translation} label
 * @property {string} icon
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingProductConfig
 * @property {Translation} label
 * @property {import('vue').default | null} modal
 *
 * @typedef {Object} PaymentAdvertisingSearchConfig
 * @property {Translation} heading
 * @property {Translation} body
 * @property {string} label
 * @property {{ component: import('vue').default, props: Object }} largeIcon
 * @property {string} smallIcon
 * @property {import('vue').default | null} modal
 *
 * @typedef {{ id: string, defaultMessage: string }} Translation
 *
 * Note: order matters as sometimes we will advertise only the fist one we find
 * here.
 */
export const PAYMENT_METHODS_ADVERTISING = {
  [PAYMENT_METHODS.AFFIRM]: {
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: AffirmModal,
    },
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.payInInstallments,
      icon: paymentMethodIcon(PAYMENT_METHODS.CARD),
      modal: AffirmModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.payInInstallments,
      description: null,
      subDescription: null,
      modal: AffirmModal,
    },
  },
  [PAYMENT_METHODS.KLARNA_PAY_LATER]: {
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.payLaterOrInInstallments,
      icon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.SEARCH]: {
      heading: translations.klarnaSimplyPay,
      body: translations.klarnaPaySafely,
      smallIcon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      largeIcon: {
        component: KlarnaSizeableIcon,
        props: { groupId: PAYMENT_GROUPS.KLARNA_PAY_LATER },
      },
      modal: KlarnaModal,
    },
  },
  [PAYMENT_METHODS.KLARNA_SLICE_IT]: {
    [PAYMENT_ADVERTISING_TYPES.CART]: {
      available: translations.installmentPaymentAvailable,
      unavailable: translations.installmentPaymentUnavailable,
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.LANDING]: {
      label: translations.payInInstallments,
      icon: paymentGroupIcon(PAYMENT_GROUPS.KLARNA_PAY_LATER),
      modal: KlarnaModal,
    },
    [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
      label: translations.payInInstallments,
      description: null,
      subDescription: null,
      modal: KlarnaModal,
    },
  },
  [PAYMENT_METHODS.ONEY3X]: oneyConfig,
  [PAYMENT_METHODS.ONEY4X]: oneyConfig,
  [PAYMENT_METHODS.ONEY6X]: oneyConfig,
  [PAYMENT_METHODS.ONEY10X]: oneyConfig,
  [PAYMENT_METHODS.ONEY12X]: oneyConfig,
}
