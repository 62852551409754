import {
  checkUserFromEmail,
  fetchUserData,
  isPasswordTokenValid,
  login,
  lostPassword,
  register,
  resetPassword,
  setClientSourcingAddress,
} from '@http/endpoints'

/**
 * @typedef State
 * @property {String} [firstName]
 * @property {String} [lastName]
 * @property {String} [email]
 * @property {String} [emailHashed]
 * @property {Object} [shippingAddress]
 * @property {Object} [billingAddress]
 * @property {Object} [sourcingAddress]
 * @property {Object} isShadowing
 * @property {Boolean} isShadowing.client
 * @property {Boolean} isShadowing.merchant
 * @property {Boolean} isStaff
 * @property {Number} clientId
 * @property {Number} merchantId
 * @property {Number} cartItemsCount
 * @property {Object} lifetimeRevenue
 * @property {Date} lastOrderDate
 * @property {Number} numberOfOrders
 */

export default {
  namespaced: true,

  /**
   * Current module state.
   *
   * @return {Object}
   */
  state() {
    return {
      firstName: null,
      lastName: null,
      email: '',
      emailHashed: '',
      phone: {},
      shippingAddress: {},
      billingAddress: {},
      sourcingAddress: {},
      isShadowing: {
        client: false,
        merchant: false,
      },
      isStaff: false,
      clientId: null,
      merchantId: null,
      cartItemsCount: 0,
      lastOrderDate: null,
      numberOfOrders: 0,
      lifetimeRevenue: { amount: 0, currency: null },
    }
  },

  getters: {
    /**
     * Return the authenticated user first name.
     *
     * @param {Object} state
     * @returns {String}
     */
    firstName: (state) => state.firstName,

    /**
     * Return the authenticated user last name.
     *
     * @param {Object} state
     * @returns {String}
     */
    lastName: (state) => state.lastName,

    /**
     * Return the authenticated user email.
     *
     * @param {Object} state
     * @returns {String}
     */
    email: (state) => state.email,

    /**
     * Return the authenticated user email hashed for tracking purposes.
     *
     * @param {Object} state
     * @returns {String}
     */
    emailHashed: (state) => state.emailHashed,

    /**
     * Return the authenticated user phone.
     *
     * @param {Object} state
     * @returns {String}
     */
    phone: (state) => state.phone,

    /**
     * Return whether the user is a fake client or not.
     *
     * A "fake" client is when someone is shadowing another user. For example,
     * customer care champions are often using user account on their behalf.
     *
     * @param {State} state
     * @return {Boolean}
     */
    isShadowingClient: (state) => state.isShadowing.client,

    /**
     * Return whether the user is a fake merchant or not.
     *
     * A "fake" merchant is when someone is shadowing another merchant user. For
     * example, developers are often using merchant account on their behalf to
     * test some features.
     *
     * @param {State} state
     * @return {Boolean}
     */
    isShadowingMerchant: (state) => state.isShadowing.merchant,

    /**
     * Return whether the user an admin, or not.
     *
     * @param {State} state
     * @return {Boolean}
     */
    isStaff: (state) => state.isStaff,

    /**
     * Return the authenticated user client id.
     *
     * @param {State} state
     * @return {Number}
     */
    clientId: (state) => state.clientId,

    /**
     * Return the authenticated user client id.
     *
     * @param {State} state
     * @return {Number}
     */
    merchantId: (state) => state.merchantId,

    /**
     * Returns a boolean: if the user is a merchant.
     *
     * @param {State} state
     * @return {Boolean}
     */
    isMerchant: (state) => !!state.merchantId,

    /**
     * Returns the amount of items in the cart.
     *
     * @param {State} state
     * @return {Number}
     */
    cartItemsCount: (state) => state.cartItemsCount,

    lastOrderDate: (state) =>
      state.lastOrderDate ? new Date(state.lastOrderDate) : null,
    numberOfOrders: (state) => state.numberOfOrders,
    lifetimeRevenue: (state) => state.lifetimeRevenue,
  },

  mutations: {
    /**
     * Store the given first name.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {String} payload.name
     */
    setName(state, { name }) {
      state.firstName = name
    },

    /**
     * Store the given last name.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {String} payload.lastName
     */
    setLastName(state, { lastName }) {
      state.lastName = lastName
    },

    /**
     * Store the given email.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {String} payload.email
     */
    setEmail(state, { email }) {
      state.email = email
    },

    /**
     * Store the given hashed email for tracking purposes.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {String} payload.emailHashed
     */
    setEmailHashed(state, { emailHashed }) {
      state.emailHashed = emailHashed
    },

    /**
     * Store the given phone number and dial code.
     *
     * @param {Object} state
     * @param {Object} payload
     * @param {String} payload.dial
     * @param {String} payload.phone
     */
    setPhone(state, { dial, phone }) {
      state.phone = dial && phone ? { dial, phone } : {}
    },

    /**
     * Store the admin status.
     *
     * @param {State} state
     * @param {Object} payload
     * @param {Boolean} payload.isStaff
     */
    setIsStaff(state, { isStaff }) {
      state.isStaff = isStaff
    },

    /**
     * Store the user shadowing status.
     *
     * @param {State} state
     * @param {Object} payload
     * @param {Boolean} payload.client
     * @param {Boolean} payload.merchant
     */
    setIsShadowing(state, { client = false, merchant = false }) {
      state.isShadowing = { client, merchant }
    },

    /**
     * Store the user client id.
     *
     * @param {State} state
     * @param {Object} payload
     * @param {Number} payload.clientId
     */
    setClientId(state, { clientId }) {
      state.clientId = clientId
    },

    /**
     * Store the user merchant id.
     *
     * @param {State} state
     * @param {Object} payload
     * @param {Number} payload.merchantId
     */
    setMerchantId(state, { merchantId }) {
      state.merchantId = merchantId
    },

    /**
     * Store the amount of items in the cart.
     *
     * @param {State} state
     * @param {Object} payload
     * @param {Number} payload.cartItemsCount
     */
    setCartItemsCount(state, { cartItemsCount }) {
      state.cartItemsCount = cartItemsCount
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {Object} payload.lifetimeRevenue
     */
    setLifetimeRevenue(state, { lifetimeRevenue }) {
      state.lifetimeRevenue = lifetimeRevenue
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {Object} payload.lastOrderDate
     */
    setLastOrderDate(state, { lastOrderDate }) {
      state.lastOrderDate = lastOrderDate
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {Object} payload.numberOfOrders
     */
    setNumberOfOrders(state, { numberOfOrders }) {
      state.numberOfOrders = numberOfOrders
    },
  },

  actions: {
    setUser(
      { commit },
      { username, dial, phone, firstName, lastName, emailHashed },
    ) {
      commit('setName', { name: firstName })
      commit('setLastName', { lastName })
      commit('setEmail', { email: username })
      commit('setEmailHashed', { emailHashed })
      commit('setPhone', { dial, phone })
    },
    /**
     * Fetch the current authenticated user if any.
     *
     * @param {Object} context
     * @param {Function} context.commit
     * @param {Function} context.dispatch
     * @return {Promise}
     */
    async fetch({ commit, dispatch }) {
      const { payload } = await dispatch(
        'http/request',
        { request: fetchUserData },
        { root: true },
      )

      commit('setName', { name: payload.first_name })
      commit('setLastName', { lastName: payload.last_name })
      commit('setEmail', { email: payload.username })
      commit('setEmailHashed', { emailHashed: payload.email_hashed })
      commit('setPhone', {
        dial: payload.country_dial_in_code,
        phone: payload.phone,
      })
      commit('setIsShadowing', {
        client: payload.is_staff_shadowing_client,
        merchant: payload.is_staff_shadowing_merchant,
      })
      commit('setIsStaff', { isStaff: payload.is_staff })
      commit('setClientId', { clientId: payload.client_id })
      commit('setMerchantId', { merchantId: payload.merchant_id })
      commit('setCartItemsCount', {
        cartItemsCount: payload.items_in_cart_count,
      })
      commit('setLifetimeRevenue', {
        lifetimeRevenue: payload.lifetime_revenue,
      })
      commit('setNumberOfOrders', {
        numberOfOrders: payload.number_of_orders,
      })
      commit('setLastOrderDate', {
        lastOrderDate: payload.last_order_date,
      })

      commit('auth/setCsrf', { csrf: payload.csrf }, { root: true })
      commit('auth/setId', { id: payload.id }, { root: true })
      if (payload.encrypted_user_id) {
        commit(
          'auth/setEncryptedId',
          { encryptedId: payload.encrypted_user_id },
          { root: true },
        )
      }
    },

    async getUser({ dispatch, commit }, { email }) {
      commit('setEmail', { email })

      await dispatch(
        'http/request',
        {
          request: checkUserFromEmail,
          body: { email },
          encode: true,
        },
        { root: true },
      )
    },

    async signUp(
      { dispatch, commit },
      {
        email,
        password,
        firstName,
        lastName,
        dial,
        phone,
        newsletter,
        gts,
        source,
        confirmationEmail,
      },
    ) {
      const body = {
        email: email.trim(),
        password,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        country_dial_in_code: dial,
        phone,
        newsletter,
        gts,
        source,
        confirmationEmail: JSON.stringify(confirmationEmail),
      }

      const { payload } = await dispatch(
        'http/request',
        { request: register, body, encode: true },
        { root: true },
      )

      const { confirmationEmailActivated } = payload

      commit(
        'auth/setConfirmationEmailActivated',
        {
          confirmationEmailActivated,
        },
        { root: true },
      )

      if (confirmationEmailActivated) {
        commit('setEmail', { email })
      } else {
        await dispatch('fetch')
      }
    },

    async signIn({ dispatch, commit }, { username, password }) {
      const body = {
        username,
        password,
      }

      const { payload } = await dispatch(
        'http/request',
        { request: login, body, encode: true },
        { root: true },
      )

      commit('auth/setCsrf', { csrf: payload.csrf }, { root: true })
      commit('auth/setId', { id: payload.client.user.id }, { root: true })

      await dispatch('fetch')
    },

    setClientSourcingAddress(
      { dispatch },
      {
        firstName,
        lastName,
        gender,
        birthdate,
        street,
        street2,
        city,
        country,
        postalCode,
        phone,
        nationality,
      },
    ) {
      return dispatch(
        'http/request',
        {
          request: setClientSourcingAddress,
          body: {
            gender: gender && gender.toString(),
            firstName,
            lastName,
            birthdate,
            street,
            street2,
            city,
            postalCode,
            country,
            phone,
            nationality,
          },
        },
        { root: true },
      )
    },

    updateCartItemsCount({ commit }, cartItemsCount) {
      commit('setCartItemsCount', { cartItemsCount })
    },

    /**
     * Send email to the given address to reset the password
     *
     * @param {String} email
     */
    async lostPassword({ dispatch, commit }, { email }) {
      const body = {
        login: email,
      }

      await dispatch(
        'http/request',
        { request: lostPassword, body, encode: true },
        { root: true },
      )
      commit('setEmail', { email })
    },

    /**
     * Save new password to API
     *
     * @param {String} newPassword
     * @param {String} newPasswordConfirmation
     * @param {String} token
     * @param {String} userId
     */
    async resetPassword(
      { dispatch },
      { newPassword, newPasswordConfirmation, token, userId },
    ) {
      const body = {
        token,
        new_password: newPassword,
        new_password_confirmation: newPasswordConfirmation,
        userId,
      }

      await dispatch(
        'http/request',
        {
          request: resetPassword,
          body,
          pathParams: { userId },
          encode: true,
        },
        { root: true },
      )
    },

    async isPasswordTokenValid({ dispatch }, { userId, token }) {
      await dispatch(
        'http/request',
        {
          request: isPasswordTokenValid,
          pathParams: { userId, token },
        },
        { root: true },
      )
    },
  },
}
