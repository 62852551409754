import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => [
  {
    name: ROUTES.HELP_CENTER.HOME,
    path: 'help',
    component: () => dynamicImport(import('./Home.vue')),
    meta: {
      auth: {
        required: false,
      },
    },
  },
]
