import { CURRENCIES } from '@config/constants'
import { getPlugin as getTranslationPlugin } from '@i18n/utils'

import translations from './checkout.translations'

const AFFIRM_CURRENCIES_POWERS = {
  [CURRENCIES.EUR]: 2,
  [CURRENCIES.USD]: 2,
  [CURRENCIES.GBP]: 2,
}

/**
 * @param {string} email
 * @param {LegacyAddress} address
 * @returns {Object} An [Affirm checkout object]
 *
 * [Affirm checkout object]: https://docs.affirm.com/affirm-developers/docs/create-a-checkout-object
 */
export const affirmCheckoutAddress = (email, address) => ({
  name: {
    first: address.firstName,
    last: address.lastName,
  },
  address: {
    line1: address.street,
    line2: address.street2,
    city: address.city,
    state: address.stateOrProvince,
    zipcode: address.postalCode,
    country: address.country,
  },
  phone_number: [address.countryDialInCode, address.phone]
    .filter(Boolean)
    .join(' '),
  email,
})

/**
 * @param {Price} price
 * @returns {number} An integer number
 */
export function affirmCheckoutPrice({ currency, amount }) {
  if (!Object.keys(AFFIRM_CURRENCIES_POWERS).includes(currency)) {
    throw new Error(`Unsupported currency: ${currency}`)
  }

  return Math.round(
    10 ** AFFIRM_CURRENCIES_POWERS[currency] * parseFloat(amount),
  )
}

/**
 * @param {import('modules/checkout').CartItem} item
 * @returns {Object} An item of an [Affirm checkout object]
 */
export const affirmCheckoutItem = (item) => ({
  display_name: item.title,
  unit_price: affirmCheckoutPrice(item.unitPrice),
  qty: item.quantity,
  item_image_url: item.image,
  item_url: item.url,
  sku: item.sku,
})

/**
 * @param {Price} price
 * @param {string} token
 * @returns {Object} An item of an [Affirm checkout object]
 */
export const affirmCheckoutDiscounts = (price, token) => {
  const $t = getTranslationPlugin()

  return {
    [token]: {
      discount_amount: affirmCheckoutPrice(price),
      discount_display_name: $t(translations.discount, {
        amount: $t.price(price),
      }),
    },
  }
}
