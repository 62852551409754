
import {
  RevIllustration,
  RevLink,
  RevModal,
  RevPicker,
} from '@backmarket/design-system'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import FormattedMessage from '@i18n/components/FormattedMessage'

import {
  PaymentMethodGroup,
  paymentGroupIcon,
  paymentGroupLabel,
} from '../../methods'

import InstallmentSimulation from './InstallmentSimulation.vue'
import translations from './InstallmentSimulationModal.translations'

export default {
  components: {
    RevLink,
    FormattedMessage,
    RevIllustration,
    InstallmentSimulation,
    RevModal,
    RevPicker,
  },

  props: {
    name: {
      type: String,
      default: 'InstallmentSimulationModal',
    },

    paymentGroupId: {
      type: String,
      required: true,
    },

    paymentMethods: {
      type: Array,
      default: null,
    },

    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedMethodIndex: -1,
    }
  },

  computed: {
    translations: () => translations,

    ...mapGetters({
      country: 'config/country',
      countryPaymentMethods: 'countryConfig/paymentMethods',
    }),

    groupLabel() {
      return paymentGroupLabel(this.paymentGroupId)
    },

    groupIcon() {
      return paymentGroupIcon(this.paymentGroupId)
    },

    steps() {
      return [
        {
          title: translations.step1Title,
          description: translations.step1Description,
          withIcon: true,
        },
        {
          title: translations.step2Title,
          description: translations.step2Description,
          withIcon: true,
        },
        {
          title: translations.step3Title,
          description: translations.step3Description,
          withIcon: false,
        },
      ]
    },

    paymentGroup() {
      const methods = this.paymentMethods || this.countryPaymentMethods

      return (
        PaymentMethodGroup.fromAvailableMethods(methods).find(
          (group) => group.id === this.paymentGroupId,
        ) || new PaymentMethodGroup({}, [])
      )
    },

    hasManyMethods() {
      return this.paymentGroup.methods.length > 1
    },

    selectedMethod() {
      return this.paymentGroup.methods[this.selectedMethodIndex]
    },

    footerValues() {
      return get(this.paymentGroup, `config.legalNotices[${this.country}]`)
    },

    hasFooterValues() {
      return !isEmpty(this.footerValues)
    },
  },

  methods: {
    getLabel(method) {
      return this.$t(method.label, {
        installmentCount: method.installmentCount,
      })
    },

    isSelected(index) {
      return index === this.selectedMethodIndex
    },

    handleMethodPickerSelect(index) {
      this.selectedMethodIndex = index
    },

    handleOpen() {
      this.selectedMethodIndex = 0
    },
  },
}
