export const DEFAULT_ADDRESS_STATE = {
  firstName: '',
  lastName: '',
  company: '',
  street: '',
  street2: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  country: '',
  customerIdNumber: '',
  countryDialInCode: '',
  phone: '',
  birthdate: '',
  nationality: '',
  alerts: [],
}
