import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  // these routes are on the /dashboard/ so they need to be wrapped under the locale in the URL.
  // separated from ./routes.js to have them wrapped under the locale at once.
  return [
    // Customer Request - Diagnostic.
    {
      path: 'dashboard/sav/diagnostic/:orderlineId',
      name: ROUTES.DIAGNO,
      meta: { rollout: false },
    },
    // Customer request - SAV Discussion.
    {
      path: 'dashboard/customer-request/:customerRequestId',
      name: ROUTES.SAV_CONVERSATION,
      component: () =>
        dynamicImport(import('@sav-discussion/ConnectedDiscussion')),
      meta: {
        auth: {
          required: true,
        },
      },
    },
  ]
}
