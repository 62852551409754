import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const Compare = () => dynamicImport(import('./pages/Compare.vue'))

export default () => {
  return [
    {
      name: ROUTES.COMPARISON.HOME,
      path: 'compare',
      component: Compare,
    },
    {
      name: ROUTES.COMPARISON.PRODUCTS,
      path: 'compare/:productA/:productB',
      component: Compare,
    },
  ]
}
