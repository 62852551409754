import { PAYMENT_METHODS } from '../../methods'

export const PROCESSOUT_ALTERNATE_GATEWAYS = {
  PAYPAL_REST: 'paypal-rest',
  SANDBOX: 'sandbox',
  ADYEN_AFFIRM: 'adyenaffirm',
}

export const PROCESSOUT_ALTERNATE_PAYMENT_METHODS = {
  [PAYMENT_METHODS.AFFIRM]: PROCESSOUT_ALTERNATE_GATEWAYS.ADYEN_AFFIRM,
  [PAYMENT_METHODS.PAYPAL]: PROCESSOUT_ALTERNATE_GATEWAYS.PAYPAL_REST,
  [PAYMENT_METHODS.PROCESSOUT_SANDBOX]: PROCESSOUT_ALTERNATE_GATEWAYS.SANDBOX,
}
