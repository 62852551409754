import { BREAKPOINTS } from '@config/constants'
import { isNode } from '@core/helpers'
import { ROUTES } from '@router'

const PAGE_TYPES = {
  HOME: 'home',
  LANDING: 'landing',
  PRODUCT: 'product',
  CMS_DEFAULT_LAYOUT: 'cms',
  PAYMENT_RESULT: 'payment_result',
  // TODO: Remove/Update as part of ACQ-153
  BUYING_GUIDE: 'buying_guide',
  SELLER_LISTINGS: 'seller_listings',
  SELLER_DASHBOARD: 'seller_dashboard',
  SELLER_INVOICES: 'seller_invoices',
  SELLER_ORDERS: 'seller_orders',
}

export const TRACKING_PROVIDERS = {
  NOODLE: 'NOODLE',
  GA: 'GA',
  AMPLITUDE: 'AMPLITUDE',
  BRAZE: 'BRAZE',
  LOGGER: 'LOGGER',
}

export const METRICS_WEB_PERFORMANCE = {
  CLS: 'cls',
  FCP: 'fcp',
  FID: 'fid',
  LCP: 'lcp',
  TTFB: 'ttfb',
  INP: 'inp',
}

export const PRODUCT_SOURCE_TYPE = {
  HIGHLIGHTS: 'highlights',
  HERO: 'hero',
  RESULTS: 'results',
  HOME_RECOS: 'home page featured products',
  LP_HERO: 'landing page hero product',
  LP_RECOS: 'landing page featured products',
  PP_RECOS_1: 'product page cousins 1',
  PP_RECOS_2: 'product page cousins 2',
  CROSS_SELL: 'cross sell',
  CROSS_SELL_MODAL: 'cross sell modal',
}

export const PAGE_TYPE_BY_ROUTE_NAME = {
  [ROUTES.HOME]: PAGE_TYPES.HOME,
  [ROUTES.LANDING.MERCHANT]: PAGE_TYPES.LANDING,
  [ROUTES.LANDING.SEARCH]: PAGE_TYPES.LANDING,
  [ROUTES.LANDING.PRODUCT]: PAGE_TYPES.LANDING,
  [ROUTES.PRODUCT.HOME]: PAGE_TYPES.PRODUCT,
  [ROUTES.PRODUCT.MERCHANT]: PAGE_TYPES.PRODUCT,
  [ROUTES.CHECKOUT.PAYMENT_RESULT]: PAGE_TYPES.PAYMENT_RESULT,
  [ROUTES.CMS.BRAND_STORE]: PAGE_TYPES.CMS_DEFAULT_LAYOUT,
  [ROUTES.CMS.EVENT]: PAGE_TYPES.CMS_DEFAULT_LAYOUT,
  [ROUTES.BUYING_GUIDE.SELF]: PAGE_TYPES.BUYING_GUIDE,
  [ROUTES.BO_MERCHANT.LISTINGS.ACTIVE]: PAGE_TYPES.SELLER_LISTINGS,
  [ROUTES.BO_MERCHANT.HOME]: PAGE_TYPES.SELLER_DASHBOARD,
  [ROUTES.BO_MERCHANT.INVOICES.PENDING]: PAGE_TYPES.SELLER_INVOICES,
  [ROUTES.BO_MERCHANT.ORDERS.NEW]: PAGE_TYPES.SELLER_ORDERS,
}

export const EVENT_TYPE = {
  CLICK: 'click',
  CONVERSION: 'conversion',
}

const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

export const getDevice = () => {
  // only works on client side, so we are mobile first on server side
  if (isNode()) {
    return DEVICES.MOBILE
  }
  const matchMedia = (media) => window.matchMedia(media).matches
  const breakpoint = BREAKPOINTS.length - BREAKPOINTS.findIndex(matchMedia) - 1

  return breakpoint < 2 ? DEVICES.MOBILE : DEVICES.DESKTOP
}

export const getConnection = () => {
  if (isNode()) {
    return 'unknown'
  }

  return window.navigator?.connection?.effectiveType || 'unknown'
}
