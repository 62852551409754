import backmarket from './modules/backmarket'
import landing from './modules/landing'
import merchant from './modules/merchant'
import product from './modules/product'

export default {
  namespaced: true,
  modules: {
    backmarket,
    product,
    merchant,
    landing,
  },
}
