import { fromPairs } from '@core/helpers'
import { experiments } from '@experiments/config'

export default {
  namespaced: true,
  state() {
    return {
      experiments: {},
    }
  },
  getters: {
    getExperiments: (state) => {
      const pairs = Object.entries(state.experiments).map(([key, value]) => {
        return [key.replace('experiment.', ''), value]
      })

      return fromPairs(pairs)
    },
    isDefaultSegment: (state) => {
      const configuredExperimentsCount = Object.keys(experiments).length
      const storedExperimentsCount = Object.keys(state.experiments).length

      if (configuredExperimentsCount !== storedExperimentsCount) {
        return false
      }

      // Using forEach is more complex since we want the function to return.
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(state.experiments)) {
        if (experiments[key] !== value) {
          return false
        }
      }

      return true
    },
    // This is probably not what you are looking for. If you want to consume
    // experiments from a Vue component or another Vuex store module, you have
    // to use the getExperiments getter instead.
    getOriginalExperiments: (state) => state.experiments,
  },
  mutations: {
    setExperiments(state, payload) {
      state.experiments = payload
    },
  },
  actions: {
    load({ commit }, payload = {}) {
      commit('setExperiments', { ...experiments, ...payload })
    },
  },
}
