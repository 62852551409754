import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import {
  deleteMessageCustomerRequestDiscussion,
  fetchDiscussionCustomerRequestDiscussion,
  postMessageCustomerRequestDiscussion,
  rateMessageCustomerRequestDiscussion,
  shareAttachmentsWithSeller,
} from '@http/endpoints'
import { SAV_ROLES } from '@sav-common/constants'
import {
  getApiPathFromRole,
  isAdmin,
  isBackcareAgent,
} from '@sav-common/helpers'

export function indexMessages(claims = []) {
  const newClaims = []
  let messagesIndex

  if (!isEmpty(claims)) {
    claims.forEach((claim) => {
      const newClaim = { ...claim }
      newClaims.push(newClaim)

      newClaim.indexedMessages = newClaim.indexedMessages || {}
      if (isEmpty(newClaim.messages)) {
        return
      }

      messagesIndex = newClaim.messages.reduce((indexedMessages, message) => {
        if (!message || !message.messageId) {
          return indexedMessages
        }

        newClaim.indexedMessages[message.messageId] = message

        return { ...indexedMessages, [message.messageId]: message }
      }, messagesIndex)
    })
  }

  return { claims: newClaims, messages: messagesIndex }
}

export default {
  namespaced: true,

  state() {
    return {
      customerRequest: {},
    }
  },
  getters: {
    claims(state) {
      return get(state.customerRequest, 'claims', [])
    },
    messages(state) {
      return get(state.customerRequest, 'messages', {})
    },
    message(state, getters) {
      return (messageId) => {
        const { messages } = getters
        if (isEmpty(messages)) {
          return {}
        }

        return messages[messageId]
      }
    },
  },
  mutations: {
    updateAttachmentSharedWithSellerStatus(
      state,
      { messageId, sharedAttachmentsIds },
    ) {
      const { messages } = state.customerRequest
      const message = messages[messageId]

      message.attachments = message.attachments.map((attachment) => ({
        ...attachment,
        sharedWithSeller:
          sharedAttachmentsIds.includes(attachment.attachmentId) ||
          attachment.sharedWithSeller,
      }))
    },
    addMessage(state, { message }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { claims, messages } = state.customerRequest
      if (isEmpty(claims)) {
        return
      }

      const lastClaim = claims[claims.length - 1]
      lastClaim.messages = lastClaim.messages || []
      lastClaim.indexedMessages = lastClaim.indexedMessages || {}

      lastClaim.messages.push(message)
      Vue.set(lastClaim.indexedMessages, message.messageId, message)
      Vue.set(messages, message.messageId, message)
    },
    clear(state) {
      state.customerRequest = {}
    },
    deleteMessage(state, { messageId }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { claims, messages } = state.customerRequest
      if (isEmpty(claims)) {
        return
      }

      delete messages[messageId]
      for (let i = 0; i < claims.length; i += 1) {
        if (messageId in claims[i].indexedMessages) {
          delete claims[i].indexedMessages[messageId]
          claims[i].messages = claims[i].messages.filter(
            (message) => message.messageId !== messageId,
          )

          return
        }
      }
    },
    set(state, { claims }) {
      state.customerRequest = indexMessages(claims)
    },
    updateRates(state, { messageId, rates = {} }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      const { messages } = state.customerRequest
      if (isEmpty(messages)) {
        return
      }

      const message = messages[messageId]
      if (isEmpty(messages) || !message.rateable) {
        return
      }

      Vue.set(message, 'rates', {
        ...message.rates,
        ...rates,
      })
    },
  },
  actions: {
    async shareAttachmentWithSeller(
      { commit, state, dispatch },
      { messageId, customerRequestId },
    ) {
      const { messages } = state.customerRequest
      const message = messages[messageId]

      const attachmentsIds = message.attachments
        .filter((attachment) => attachment.sharedWithSeller === false)
        .map((attachment) => attachment.attachmentId)

      await dispatch(
        'http/request',
        {
          request: shareAttachmentsWithSeller,
          pathParams: {
            customerRequestId,
          },
          body: { attachmentsIds },
        },
        { root: true },
      )

      commit('updateAttachmentSharedWithSellerStatus', {
        messageId,
        sharedAttachmentsIds: attachmentsIds,
      })
    },
    addMessage({ commit }, { message }) {
      commit('addMessage', { message })
    },
    clear({ commit }) {
      commit('clear')
    },
    async fetch(
      { commit, dispatch },
      { role = SAV_ROLES.client, customerRequestId },
    ) {
      const { payload: claims } = await dispatch(
        'http/request',
        {
          request: fetchDiscussionCustomerRequestDiscussion,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )

      commit('set', { claims })
    },
    async deleteMessage({ commit, dispatch }, { messageId }) {
      await dispatch(
        'http/request',
        {
          request: deleteMessageCustomerRequestDiscussion,
          pathParams: { messageId },
        },
        { root: true },
      )

      commit('deleteMessage', { messageId })
    },
    async post(
      { commit, dispatch },
      {
        role = SAV_ROLES.client,
        customerRequestId,
        kind,
        informative = false,
        message: content,
        attachments = [],
      },
    ) {
      // TODO [FRONT-586] Use toFormData Axios helper
      const body = new FormData()
      body.append('kind', kind)
      body.append('message', content)
      attachments.forEach((attachment) =>
        body.append('attachments', attachment),
      )
      body.append(
        'isInformative',
        isBackcareAgent(role) || isAdmin(role) ? informative : null,
      )

      const { payload: message } = await dispatch(
        'http/request',
        {
          request: postMessageCustomerRequestDiscussion,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
          body,
        },
        { root: true },
      )

      commit('addMessage', { message })
    },
    async rate({ commit, getters, dispatch }, { messageId, rates }) {
      const { rates: previousRates } = getters.message(messageId)

      commit('updateRates', { messageId, rates })

      try {
        await dispatch(
          'http/request',
          {
            request: rateMessageCustomerRequestDiscussion,
            pathParams: { messageId },
            body: rates,
          },
          { root: true },
        )
      } catch (error) {
        commit('updateRates', {
          messageId,
          rates: previousRates,
        })

        throw error
      }
    },
  },
}
