import auth from '@auth/store/auth'
import user from '@auth/store/user'
import swap from '@buyback/modules/Swap'
import { checkoutStore as checkout } from '@checkout'
import { store as toast } from '@core/components/Toast'
import customerRequestResolutionFlow from '@diagnosis/store/store'
import experiments from '@experiments/store/experiments'
import http from '@http/store'
import { store as insurance } from '@insurance'
import { ratesStore as rates, store as reviews } from '@internal-reviews'
import reviewform from '@internal-reviews/components/Form/store'
import { store as reviewpage } from '@internal-reviews/components/Page'
import { landing, search } from '@landing/store'
import gdpr from '@legal/store'
import { store as myOrders } from '@my-orders'
import payment from '@payment/store'
import product from '@product/store'
import { store as customerRequestDiscussion } from '@sav-discussion'
import { store as customerRequestPlatform } from '@sav-platform'
import seller from '@seller/store'
import listings from '@seller/sub-modules/onboarded/pages/listings/store'
import sourcing from '@seller/sub-modules/onboarded/pages/sourcing/store'
import pricingTool from '@seller/sub-modules/onboarded/shared/PricingTool/store'
import braze from '@tracking/braze/store'

import config from './modules/config'
import countryConfig from './modules/countryConfig'
import layout from './modules/layout'

export const modules = {
  experiments,
  auth,
  braze,
  checkout,
  config,
  countryConfig,
  customerRequestResolutionFlow,
  customerRequestDiscussion,
  customerRequestPlatform,
  gdpr,
  http,
  insurance,
  landing,
  layout,
  listings,
  seller,
  myOrders,
  pricingTool,
  payment,
  product,
  rates,
  reviewform,
  reviewpage,
  reviews,
  sourcing,
  swap,
  toast,
  user,
  // We register the same module twice because we need the same features in different places. Instead of managing every
  // possible context from the Vuex module itself, we create a context-less module, and use it several times. #SOLID
  landingSearch: search,
  searchBar: search,
}
