import isEmpty from 'lodash/isEmpty'

import { isFunction, isNumber } from '@core/helpers'
import { CancelToken } from '@http/api'
import {
  getSourcingMerchantInfos,
  getSourcingOrders,
  getSourcingPending,
  getSourcingReceived,
} from '@http/endpoints'

export default {
  namespaced: true,

  state: () => ({
    filters: {},
    hasFilters: false,
    hasOrders: false,
    loading: false,
    nextPageNumber: null,
    orders: [],
    count: 0,
    markets: [],
    cancelToken: null,
  }),

  getters: {
    filters: (state) => state.filters,
    cancelToken: (state) => state.cancelToken || {},
    hasCancelToken: (state, getters) => isFunction(getters.cancelToken.cancel),
    hasFilters: (state) => !isEmpty(state.filters),
    hasOrders: (state) => !isEmpty(state.orders),
    loading: (state) => state.loading,
    nextPageNumber: (state) => state.nextPageNumber,
    orders: (state) => state.orders,
    count: (state) => state.count,
    markets: (state) => state.markets,
  },

  mutations: {
    setCancelToken(state, cancelToken) {
      state.cancelToken = cancelToken
    },

    setFilters(state, filters) {
      state.filters = filters
    },

    setLoading(state, loading) {
      state.loading = loading
    },

    setNextPageNumber(state, { next }) {
      state.nextPageNumber = isEmpty(next)
        ? null
        : parseInt(next.match(/page=(\d+)/)[1], 10)
    },

    setOrders(state, { results }) {
      state.orders = results
    },

    setCount(state, { count }) {
      state.count = count
    },

    setMarkets(state, { markets }) {
      state.markets = markets
    },

    setNextPageOrders(state, { results }) {
      state.orders = [...state.orders, ...results]
    },

    resetStore(state) {
      state.filters = {}
      state.hasFilters = false
      state.hasOrders = false
      state.loading = false
      state.nextPageNumber = null
      state.orders = []
      state.count = 0
    },
  },

  actions: {
    cancelToken({ getters, commit }) {
      getters.cancelToken.cancel()

      // Prevent serializing Axios cancel token
      // Read more: https://backmarket.atlassian.net/browse/FRONT-496
      // TODO Refactor to not store Axios cancel token in the data store
      commit('setCancelToken', {})
    },

    reset({ commit }) {
      commit('resetStore')
    },

    async fetchOrders(
      { commit, dispatch, getters },
      { request, queryParams, loadNextPage },
    ) {
      if (getters.hasCancelToken) {
        getters.cancelToken()
      }

      const cancelToken = CancelToken.source()
      commit('setCancelToken', cancelToken)
      commit('setLoading', true)

      try {
        const { payload } = await dispatch(
          'http/request',
          {
            request,
            queryParams: {
              ...queryParams,
              ...(loadNextPage &&
                isNumber(getters.nextPageNumber) && {
                  page: getters.nextPageNumber,
                }),
            },
            cancelToken,
          },
          { root: true },
        )

        commit('setFilters', queryParams)
        if (!isEmpty(payload)) {
          commit('setNextPageNumber', payload)
          commit('setCount', payload)
          if (loadNextPage) {
            commit('setNextPageOrders', payload)
          } else {
            commit('setOrders', payload)
          }
        }
      } finally {
        commit('setLoading', false)

        // Prevent serializing Axios cancel token
        // Read more: https://backmarket.atlassian.net/browse/FRONT-496
        // TODO Refactor to not store Axios cancel token in the data store
        commit('setCancelToken', {})
      }
    },

    async fetchSourcingOrders(
      { dispatch, getters },
      { queryParams, loadNextPage },
    ) {
      if (isEmpty(getters.markets)) {
        dispatch('fetchSourcingMerchantInfos')
      }

      await dispatch('fetchOrders', {
        request: getSourcingOrders,
        queryParams,
        loadNextPage,
      })
    },

    async fetchSourcingReceivedOrders(
      { dispatch },
      { queryParams, loadNextPage },
    ) {
      await dispatch('fetchOrders', {
        request: getSourcingReceived,
        queryParams,
        loadNextPage,
      })
    },

    async fetchSourcingPendingOrders(
      { dispatch },
      { queryParams, loadNextPage },
    ) {
      await dispatch('fetchOrders', {
        request: getSourcingPending,
        queryParams,
        loadNextPage,
      })
    },

    async fetchSourcingMerchantInfos({ commit, dispatch }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: getSourcingMerchantInfos,
        },
        { root: true },
      )

      if (!isEmpty(payload)) {
        commit('setMarkets', payload)
      }
    },
  },
}
