export default {
  headline: {
    id: 'footer_headline',
    defaultMessage:
      'Hello, we are Back Market, the leading marketplace dedicated to refurbished devices. Our mission? To make resurrected devices mainstream. Yes it sounds churchy, but there you have it.',
  },
  categoryAbout: {
    id: 'footer_category_about',
    defaultMessage: 'About',
  },
  categoryHelp: {
    id: 'footer_category_help',
    defaultMessage: 'Help',
  },
  categoryLaw: {
    id: 'footer_category_law',
    defaultMessage: 'Law',
  },
  categoryHello: {
    id: 'footer_category_hello',
    defaultMessage: 'Hello',
  },
  aboutUs: {
    id: 'footer_about_us',
    defaultMessage: 'Who we are?',
  },
  productComparison: {
    id: 'footer_product_comparison',
    defaultMessage: 'Phone comparison',
  },
  buyback: {
    id: 'footer_buyback',
    defaultMessage: 'Buy back',
  },
  buybackB2B: {
    id: 'footer_buyback_b2b',
    defaultMessage: 'We take over your company fleet',
  },
  buyingGuides: {
    id: 'footer_buying_guides',
    defaultMessage: 'Buying Guides',
  },
  studentGoodDeals: {
    id: 'footer_students_good_deals',
    defaultMessage: 'Student Offer',
  },
  militaryProgram: {
    id: 'footer_military_program',
    defaultMessage: 'Military program',
  },
  hire: {
    id: 'footer_hire',
    defaultMessage: "We're hiring!",
  },
  blog: {
    id: 'footer_blog',
    defaultMessage: 'Blog',
  },
  press: {
    id: 'footer_press',
    defaultMessage: 'Press',
  },
  backmarketWarranty: {
    id: 'footer_backmarket_warranty',
    defaultMessage: 'BackMarket Warranty',
  },
  becomeRefurbisher: {
    id: 'footer_become_refurbisher',
    defaultMessage: 'Sellers - register to sell',
  },
  boMerchant: {
    id: 'footer_bo_merchant',
    defaultMessage: 'Seller Portal',
  },
  payment: {
    id: 'footer_payment',
    defaultMessage: 'Payments 100% secured',
  },
  shipping: {
    id: 'footer_shipping',
    defaultMessage: 'Shipping',
  },
  help: {
    id: 'footer_faq',
    defaultMessage: 'Help',
  },
  accessibilityStatement: {
    id: 'footer_accessibility_statement',
    defaultMessage: 'Accessibility Statement',
  },
  terms: {
    id: 'footer_terms',
    defaultMessage: 'Terms of service',
  },
  termsBuyback: {
    id: 'footer_terms_buyback',
    defaultMessage: 'Terms of buyback',
  },
  termsSale: {
    id: 'footer_terms_sale',
    defaultMessage: 'General terms and conditions of sale',
  },
  dataProtection: {
    id: 'footer_data_protection',
    defaultMessage: 'Data protection',
  },
  cookies: {
    id: 'footer_cookie_policy',
    defaultMessage: 'Cookies',
  },
  infosRefurbisher: {
    id: 'footer_infos_refurbisher',
    defaultMessage: 'Refurbishers info',
  },
  infosConsumer: {
    id: 'footer_infos_consumer',
    defaultMessage: 'Consumer info',
  },
  legal: {
    id: 'footer_legal',
    defaultMessage: 'User Terms of Use Agreement',
  },
  paymentMethods: {
    id: 'footer_payment_methods',
    defaultMessage: 'Payments 100% secured',
  },
  trustpilot: {
    id: 'footer_trust_pilot',
    defaultMessage: 'Trustpilot',
  },
  trustedShops: {
    id: 'footer_trusted_shops',
    defaultMessage: 'Trusted Shops',
  },
  glassdoor: {
    id: 'footer_glassdoor',
    defaultMessage: 'Glassdoor',
  },
  welcomeToTheJungle: {
    id: 'footer_welcome_to_the_jungle',
    defaultMessage: 'Welcome to the Jungle',
  },
  medium: {
    id: 'footer_medium',
    defaultMessage: 'Medium',
  },
  celebrity: {
    id: 'footer_celebrity',
    defaultMessage: 'The Rock',
  },
  flagsScreenReaderIntro: {
    id: 'footer_flags_screen_reader_intro',
    defaultMessage:
      'Back Market is available in several countries. Take your pick.',
  },
  flagAlternativeText: {
    id: 'footer_flag_alternative_text',
    defaultMessage: 'Visit Back Market website in {country}',
  },
}
