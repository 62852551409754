
import { RevLink } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import translations from './Header.translations'

export default {
  components: {
    RevLink,
  },

  computed: {
    ...mapGetters({
      isNavigationOpened: 'isNavigationOpened',
    }),
    translations: () => translations,
  },
}
