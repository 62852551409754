
import { RevButtonBase } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import { COMPANY_NAME, COUNTRIES } from '@config/constants'
import AppStoreButtons from '@core/components/AppStoreButtons'
import Flag from '@core/components/Flag'
import { getMarketBaseURLForCountry } from '@core/helpers/countries'
import { ROUTES } from '@router'
import { getLinks } from '@router/links'

import translations from './Footer.translations'
import FooterList from './FooterList.vue'
import { getFooterConfigFor, getSocialNetWorksFor } from './footerConfig'

export default {
  components: {
    RevButtonBase,
    AppStoreButtons,
    FooterList,
    Flag,
  },

  data() {
    return {
      footerFlags: [],
    }
  },

  async fetch() {
    const link = { name: ROUTES.HOME }
    const baseUrl = this.$store.getters['config/baseURL']
    const footerFlags = await getLinks({ baseUrl, link })

    this.footerFlags = [
      ...footerFlags.map((flag) => ({
        ...flag,
        isCurrentCountryPage: this.country === flag.country ? 'page' : false,
      })),
      {
        country: COUNTRIES.JP,
        href: getMarketBaseURLForCountry({
          hostname: baseUrl,
          country: COUNTRIES.JP,
        }).baseURL,
      },
    ]
  },

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    COMPANY_NAME: () => COMPANY_NAME,
    translations: () => translations,
    year: () => new Date().getFullYear(),
    footerConfig() {
      return getFooterConfigFor(this.country)
    },
    socials() {
      return getSocialNetWorksFor(this.country)
    },
    links() {
      return this.footerConfig.categories.map(({ title, ...rest }) => ({
        title: this.$t(title),
        ...rest,
      }))
    },
  },
}
