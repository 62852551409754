import {
  fetchBackmarketReviewsBreadcrumb,
  fetchBackmarketReviewsList,
  fetchBackmarketReviewsSummary,
  fetchLandingReviewsBreadcrumb,
  fetchLandingReviewsList,
  fetchLandingReviewsSummary,
  fetchMerchantReviewsBreadcrumb,
  fetchMerchantReviewsList,
  fetchMerchantReviewsSummary,
  fetchReviewsBreadcrumbByProduct,
  fetchReviewsListByProductLegacy,
  fetchReviewsSummaryByProduct,
} from '@http/endpoints'

import {
  LANDING_REVIEWS,
  MERCHANT_REVIEWS,
  PRODUCT_REVIEWS,
} from '../constants'

const getConfig = ({ landingId, productId, sellerId, name, limit }) => {
  let queryParams

  switch (name) {
    case MERCHANT_REVIEWS:
      queryParams = {
        id: sellerId,
      }

      return {
        rateModule: 'merchant',
        endpoints: {
          list: fetchMerchantReviewsList,
          summary: fetchMerchantReviewsSummary,
          breadcrumb: fetchMerchantReviewsBreadcrumb,
        },
        queryParams,
      }

    case LANDING_REVIEWS: {
      queryParams = {
        pk: landingId,
      }

      if (limit) {
        queryParams.limit = limit
      }

      return {
        rateModule: 'landing',
        endpoints: {
          list: fetchLandingReviewsList,
          summary: fetchLandingReviewsSummary,
          breadcrumb: fetchLandingReviewsBreadcrumb,
        },
        queryParams,
      }
    }

    case PRODUCT_REVIEWS:
      return {
        rateModule: 'product',
        endpoints: {
          list: fetchReviewsListByProductLegacy,
          summary: fetchReviewsSummaryByProduct,
          breadcrumb: fetchReviewsBreadcrumbByProduct,
        },
        pathParams: { productId },
      }

    default:
      return {
        rateModule: 'backmarket',
        endpoints: {
          list: fetchBackmarketReviewsList,
          summary: fetchBackmarketReviewsSummary,
          breadcrumb: fetchBackmarketReviewsBreadcrumb,
        },
      }
  }
}

export default getConfig
