import amplitude from 'amplitude-js'

import { isBrowser } from '@core/helpers'

import { SERVER_ZONE, USER_GROUP_NAME, USER_GROUP_VALUES } from './constants'

// BM is under the "growth plan" of Amplitude
// Documentation: https://amplitude.github.io/Amplitude-JavaScript/

let isActive = false

const isAmplitudeActive = () => isActive && isBrowser()

const getInstance = () => amplitude.getInstance()

export const sendEvent = (eventName, payload) => {
  if (isAmplitudeActive()) {
    getInstance().logEvent(eventName, payload)
  }
}

export const setUserProperties = (userProperties) => {
  if (isAmplitudeActive()) {
    getInstance().setUserProperties(userProperties)
  }
}

export const setUserId = (userId) => {
  if (isAmplitudeActive()) {
    getInstance().setUserId(userId)
  }
}

export const setOptOut = (optOut) => {
  if (isAmplitudeActive()) {
    getInstance().setOptOut(optOut)

    // When clicking on the cookie modal or on the cookies page
    // Amplitude needs to be activated if the optOut is false
    if (!optOut) {
      getInstance().enableTracking()
    }
  }
}

export const initAmplitude = ({
  apiKey,
  version,
  sessionId,
  kill = false,
  deferInitialization = false,
}) => {
  isActive = !kill

  if (isAmplitudeActive()) {
    // serverZone is fixed to EU in order to keep the data out of the US.
    // https://github.com/amplitude/Amplitude-JavaScript/blob/0618a901ab0209a44d05eacd82d1a6cbabf6a945/src/server-zone.js#L9-L12
    getInstance().init(apiKey, null, {
      disableCookies: false,
      batchEvents: true,
      eventUploadPeriodMillis: 5000,
      serverZone: SERVER_ZONE,
      serverZoneBasedApi: true,
      deferInitialization,
      // Marketing
      includeFbclid: true,
      includeGclid: true,
      includeUtm: true,
      onExitPage: () => {
        // this function is invoked when page is unloaded
        // switches to beacon and sends all unsent events immediately
        getInstance().sendEvents()
      },
    })

    // The session ID is mapped on the Amplitude side. The method setSessionId() is not recommended by our
    // account manager, and suggested to retrieve the session ID from the context tracker instead,
    // to then map it on the Amplitude dashboard directly.

    getInstance().setVersionName(version)

    setUserProperties({
      [USER_GROUP_NAME]: USER_GROUP_VALUES.NOT_LOGGED,
      session_id: sessionId,
    })
  }
}
