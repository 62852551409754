import { decamelizeKeys } from 'humps'

import { APP_NAME } from '@config/constants'
import logger from '@logger'

import { PRODUCT_SOURCE_TYPE } from '../constants'

import { sendEvent, setOptOut, setUserId, setUserProperties } from './amplitude'
import { USER_GROUP_NAME, USER_GROUP_VALUES } from './constants'
import * as DEFINITIONS from './definitions'
import {
  flagModel,
  globalRatingModel,
  productModel,
  productModelCollection,
  promotionModel,
  reviewModel,
} from './models'

// Checking if there is a race condition on the page view & context event
let contextLoaded = false
export const context = ({ flags = {}, abtest = {}, ...ctx }) => {
  contextLoaded = true
  setUserProperties({
    flags: flagModel(flags, ctx.country),
    abtest: flagModel(abtest, ctx.country),
    ...decamelizeKeys(ctx),
  })
}

export const pageView = (to, from) => {
  if (!contextLoaded) {
    logger.error(
      new Error('[RACE CONDITION] The context should be initialized first'),
    )
  }

  const event = {
    platform: APP_NAME,
    current_page: {
      page_type: to.pageType,
      params: to.params,
    },
  }

  // Navigating on the SPA
  if (from.pageType) {
    event.referrer = {
      page_type: from.pageType,
      params: from.params,
    }
    // First load
  } else {
    event.referrer = {
      url: document.referrer,
    }
  }

  sendEvent(DEFINITIONS.PAGE_VIEW, event)
}

export const resize = ({ breakpointName }) => {
  setUserProperties({ breakpoint: breakpointName })
}

export const gdpr = ({ analytics }) => {
  setOptOut(!analytics)
}

export const userContext = ({
  userId,
  merchantId,
  isStaff,
  lastOrderDate,
  numberOfOrders,
  lifetimeRevenue,
}) => {
  let groupValue = USER_GROUP_VALUES.LOGGED_CUSTOMER
  if (isStaff) {
    groupValue = USER_GROUP_VALUES.LOGGED_STAFF
  }
  if (merchantId !== null) {
    groupValue = USER_GROUP_VALUES.LOGGED_MERCHANT
  }

  setUserId(userId)

  setUserProperties(
    decamelizeKeys({
      lastOrderDate,
      numberOfOrders,
      lifetimeRevenue,
      [USER_GROUP_NAME]: groupValue,
    }),
  )
}

export const paymentSuccess = ({ payment, products }) => {
  let productRevenue = 0
  let quantity = 0

  products.forEach((product) => {
    productRevenue += Number(product.price) * Number(product.quantity)
    quantity += Number(product.quantity)

    sendEvent(
      DEFINITIONS.PURCHASE_PRODUCT,
      decamelizeKeys({
        order_id: payment.orderId,
        ...productModel(product),
      }),
    )
  })

  sendEvent(DEFINITIONS.PURCHASE_ORDER, {
    shipping_revenue: payment.shipping,
    swap_eligible: payment.swap,
    sponsorship: payment.code_parrain,
    commission: payment.commission,
    order_id: payment.orderId,
    product_revenue: productRevenue,
    product_quantity: quantity,
    payment_method: payment.paymentMethod,
    // Calculation of the product prices only (no fees etc.)
    $revenue: payment.revenue,
  })
}

export const submit = ({ id, name, payload }) => {
  sendEvent(DEFINITIONS.FORM_SUBMIT, {
    zone: id,
    name,
    payload,
  })
}

export const click = (ctx) => {
  sendEvent(DEFINITIONS.CLICK, decamelizeKeys(ctx))
}

export const productImpression = (product) => {
  const productInfos = productModel(product)
  // Due to heavy volumetry on this sourceType, and this is not planed for analysis yet,
  // Let's disable this source for now.
  if (productInfos.product_list === PRODUCT_SOURCE_TYPE.RESULTS) {
    return
  }

  sendEvent(DEFINITIONS.PRODUCT_IMPRESSION, productInfos)
}

export const productImpressionBatched = (p) => {
  sendEvent(DEFINITIONS.PRODUCT_IMPRESSION, {
    products: productModelCollection(p),
  })
}

export const reviewImpression = ({ pageType, ...ctx }) => {
  const productInfo = productModel(ctx)
  const reviewInfo = reviewModel(ctx)
  const payload = { ...productInfo, ...reviewInfo, page_type: pageType }

  sendEvent(DEFINITIONS.REVIEW_IMPRESSION, payload)
}

export const reviewBlockImpression = ({ pageType, ...ctx }) => {
  const productInfo = productModel(ctx)
  const ratingInfo = globalRatingModel(ctx)
  const payload = { ...productInfo, ...ratingInfo, page_type: pageType }

  sendEvent(DEFINITIONS.REVIEW_BLOCK_IMPRESSION, payload)
}

export const addToCart = ({ product }) => {
  sendEvent(DEFINITIONS.ADD_TO_CART, productModel(product))
}

export const productClick = ({ product }) => {
  const productInfos = productModel(product)

  sendEvent(DEFINITIONS.PRODUCT_CLICK, productInfos)
}

export const product = ({ product: p }) => {
  sendEvent(DEFINITIONS.PRODUCT, productModel(p))
}

export const funnel = ({ products, routeName, swap } = {}) => {
  sendEvent(DEFINITIONS.CHECKOUT_PAGE_VIEW, {
    page_type: routeName,
    products: productModelCollection(products),
    swap,
  })
}

// Care – ActionBar
export const refundActionClickedInActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_REFUND_ACTION, payload)
}
export const tryAgainToFetchActionsFromActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_TRY_AGAIN_TO_FETCH_ACTIONS, payload)
}
export const refundFromActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_REFUND_STEP_SUBMIT_REFUND, payload)
}
export const goBackFromActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_BACK, payload)
}
export const exitFromActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_EXIT, payload)
}
export const openClaimActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_OPEN_CLAIM, payload)
}
export const closeClaimActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_CLOSE_CLAIM, payload)
}
export const sendMessageActionClickedInActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_SEND_MESSAGE_ACTION, payload)
}

export const returnLabelGenerationClickedInActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_RETURN_LABEL_GENERATION_ACTION, payload)
}

export const returnLabelGenerationFromActionBar = (payload) => {
  sendEvent(DEFINITIONS.ACTION_BAR_RETURN_LABEL_GENERATION_STEP_SUBMIT, payload)
}

/* LEGACY EVENTS FORMAT BELOW */
// TODO: Remove as part of GNL-2044
export const clickCarousel = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_CLICK, promotionModel(promotion))
}

export const modal = ({ name, status }) => {
  if (status === 'opened') {
    sendEvent(DEFINITIONS.MODAL, { name })
  }
}

export const landingBannerClick = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_CLICK, promotionModel(promotion))
}

export const landingBannerImpression = (promotion) => {
  sendEvent(DEFINITIONS.PROMOTION_IMPRESSION, promotionModel(promotion))
}
