import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      name: ROUTES.LANDING.SEARCH,
      path: 'search',
      component: () => dynamicImport(import('./pages/SearchLanding')),
    },
    {
      name: ROUTES.LANDING.MERCHANT,
      // TODO change params names https://backmarket.atlassian.net/browse/URL-465
      path: 's/:merchantName/:uuid',
      component: () => dynamicImport(import('./pages/SellerLanding')),
    },
    {
      name: ROUTES.LANDING.FILTERS,
      path: 'l/filters',
      component: () => dynamicImport(import('./pages/Filters')),
    },
    {
      name: ROUTES.LANDING.FILTERS_DETAILS,
      path: 'l/filters/:filter',
      component: () => dynamicImport(import('./pages/SubFilters')),
    },
    {
      name: ROUTES.LANDING.FILTERS_DETAILS_PRICE,
      path: 'l/filters/price',
      component: () => dynamicImport(import('./pages/PriceFilter')),
    },
    {
      name: ROUTES.LANDING.SORT,
      path: 'l/sort',
      component: () => dynamicImport(import('./pages/Sort')),
    },
    {
      name: ROUTES.LANDING.PRODUCT,
      // TODO change params names https://backmarket.atlassian.net/browse/URL-466
      path: 'l/:slugV2(.*?)/:uuid',
      component: () => dynamicImport(import('./pages/ProductLanding')),
    },
  ]
}
