import { removeEmptyValuesInObject } from '@core/helpers'

export const reviewModel = ({ reviewPosition, reviewScore }) => {
  return removeEmptyValuesInObject({
    review_position_impression: reviewPosition,
    review_score_impression: reviewScore,
  })
}

export const globalRatingModel = ({ averageRate, reviewsCount }) => {
  return removeEmptyValuesInObject({
    average_rate: averageRate,
    reviews_total_count: reviewsCount,
  })
}
