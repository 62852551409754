
import { mapGetters } from 'vuex'

export const BADGE_ID = 'MyCustomTrustbadge'
export const DEFAULT_WIDGET_CONFIG = {
  customBadgeHeight: '90',
  customBadgeWidth: '180',
  disableResponsive: 'true',
  trustCardTrigger: 'click',
  trustcardDirection: 'topLeft',
  variant: 'custom_reviews',
  yOffset: '5',
}

export default {
  name: 'TrustedShops',
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadScript: false,
    }
  },
  computed: {
    ...mapGetters({
      trustmarkConfig: 'config/trustmarkConfig',
    }),
    badgeId: () => BADGE_ID,
  },
  watch: {
    isMobile(mobile) {
      // _tsConfig is the name of TrustedShops configuration, and can't be changed.
      // eslint-disable-next-line no-underscore-dangle
      window._tsConfig.disableTrustbadge = mobile ? 'true' : 'false'

      if (!mobile && window.trustbadge === undefined) {
        this.loadScript = true
      }
    },
  },
  async mounted() {
    // _tsConfig is the name of TrustedShops configuration, and can't be changed.
    // eslint-disable-next-line no-underscore-dangle
    window._tsConfig = {
      ...DEFAULT_WIDGET_CONFIG,
      customElementId: this.badgeId,
      disableTrustbadge: this.isMobile ? 'true' : 'false',
    }

    if (!this.isMobile && window.trustbadge === undefined) {
      this.loadScript = true
    }

    // Await for all refs to be available.
    await this.$nextTick()

    if (window.trustbadge) {
      window.trustbadge.reInitialize()
    }
  },
}
