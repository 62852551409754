import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals'

import { dangerouslyGetRuntimeFeatureFlags } from '@config/variables'
import { initBreakpointObserver } from '@core/breakpointsObserver'
import { fromEntries } from '@core/helpers'
import { getCookies } from '@core/helpers/cookies'
import { getFlags } from '@feature-flags'
import { getUserCrmId } from '@http/endpoints'
import {
  trackContext,
  trackPageView,
  trackRumView,
  trackWebPerformance,
} from '@tracking/events'

import { initAmplitude } from './amplitude/amplitude'
import { initAppsflyer } from './appsflyer/appsflyer'
import { DO_NOT_LOAD_ROUTES_COLLECTION as APPSFLYER_BLOCKLISTED_ROUTES } from './appsflyer/constants'
import { canLoadAppsflyer } from './appsflyer/helpers'
import {
  importBrazeSdk,
  initializeSdk,
  registerBrazeServiceWorker,
} from './braze/braze'
import { DO_NOT_LOAD_ROUTES_COLLECTION as BRAZE_BLOCKLISTED_ROUTES } from './braze/constants'
import {
  METRICS_WEB_PERFORMANCE,
  PAGE_TYPE_BY_ROUTE_NAME,
  getConnection,
  getDevice,
} from './constants'
import {
  refreshSessionIdCookie,
  refreshVisitorIdCookie,
} from './correlation-ids'
import { trackDesignSystem } from './design-system'
import { isNotOnBlocklistedRoute } from './helpers'
// Can't import it via the index, it would create a circular dependency
import { initNoodle } from './noodle/init'

function startMonitoringWebVitals({ store, route }) {
  const webPerformanceDetails = {
    pageType: PAGE_TYPE_BY_ROUTE_NAME[route.name],
    connection: getConnection(),
    deviceType: getDevice(),
    // We do not rely on the `event.meta.country` property here because our
    // lab monitoring system (from the `web-performance-tracking` repository)
    // starts a single client for all different countries, meaning that the
    // `event.meta.country` property is always the same, for all events.
    // So, we need to pass the `country` as an `event.data` property, and
    // we configured the Noodle to consume the `web-performance.value` event
    // that way. So, we also must follow that constraint here.
    country: store.getters['config/country'],
  }

  const composeTrackWebPerformance = (data) =>
    trackWebPerformance({ ...webPerformanceDetails, ...data })

  onCLS(({ value }) => {
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.CLS,
      value,
    })
  })

  onFCP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.FCP,
      value,
    }),
  )

  onFID(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.FID,
      value,
    }),
  )

  onLCP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.LCP,
      value,
    }),
  )

  onTTFB(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.TTFB,
      value,
    }),
  )

  onINP(({ value }) =>
    composeTrackWebPerformance({
      metric: METRICS_WEB_PERFORMANCE.INP,
      value,
    }),
  )
}

export default ({ app, store, $config, route }) => {
  const userCookies = getCookies()
  const country = store.getters['config/country']
  const cookies = fromEntries(userCookies)

  const visitorId = refreshVisitorIdCookie(cookies)
  const sessionId = refreshSessionIdCookie(cookies)

  store.commit('config/setConfig', { visitorId, sessionId })

  if (!$config.KILL_NOODLE) {
    initNoodle(
      {
        visitorId,
        sessionId,
        country,
        locale: store.getters['config/locale'],
        merchantId: store.getters['config/merchantId'],
        userId: store.getters['config/clientId'],
      },
      { url: $config.NOODLE_URL, env: $config.ENVIRONMENT },
    )
  }

  const gdpr = store.getters['gdpr/cookies/all']
  initAmplitude({
    kill: $config.KILL_AMPLITUDE || !$config.FF_AMPLITUDE.includes(country),
    apiKey: $config.AMPLITUDE_API_KEY,
    version: process.env.IMAGE_RELEASE,
    deferInitialization: !gdpr.analytics,
    sessionId,
  })

  const canLoadBraze =
    $config.FF_BRAZE_ENABLED_BY_COUNTRY.includes(country) &&
    !$config.KILL_BRAZE &&
    isNotOnBlocklistedRoute({
      blocklistedRoutesCollections: BRAZE_BLOCKLISTED_ROUTES,
      currentRoute: route.name,
    })

  if (canLoadBraze) {
    const setAvailable = () => store.commit('braze/setBrazeAvailable')
    const fetchUserCrmId = () =>
      store.dispatch('http/request', { request: getUserCrmId })

    initializeSdk({
      apiKey: $config.BRAZE_API_KEY,
      safariWebsitePushId: $config.BRAZE_SAFARI_PUSH_ID,
      optIn: gdpr.userExperience,
      getBrazeSdk: importBrazeSdk,
      setAvailable,
      getUserCrmId: fetchUserCrmId,
    })

    registerBrazeServiceWorker()
  }

  if (
    canLoadAppsflyer({
      country,
      $config,
      store,
      blocklistedRoutesCollections: APPSFLYER_BLOCKLISTED_ROUTES,
      currentRoute: route.name,
    })
  ) {
    initAppsflyer({ bannersApiKey: $config.APPSFLYER_BANNERS_API_KEY })
  }

  // Initialize GTM
  const dataLayerContext = store.getters['config/dataLayerContext']
  const paymentMethodsAvailable =
    store.getters['countryConfig/paymentMethodsIds']
  const featureFlags = dangerouslyGetRuntimeFeatureFlags()
  const abtest = {
    ...store.getters['experiments/getOriginalExperiments'],
    ...getFlags(),
  }
  const isDefaultSegment = store.getters['experiments/isDefaultSegment']

  // Calls breakpoints observer to track initial user breakpoint
  initBreakpointObserver(store)

  // Push the generic event
  trackContext({
    ...dataLayerContext,
    gdpr,
    visitorId,
    sessionId,
    release: process.env.IMAGE_RELEASE,
    paymentMethodsAvailable,
    isDefaultSegment,
    abtest,
    flags: featureFlags,
  })

  trackDesignSystem()

  app.router.afterEach((to, from) => {
    // At the init, the path is equal (/ & /) while the name is different (pageName & null)
    // We check the path in order to support the pageView event when going on another link of the same pageName,
    // like going from a product A to a product B (would be the same pageName)
    if (to.name !== from.name || to.path !== from.path) {
      trackPageView(
        { ...to, pageType: to.name },
        { ...from, pageType: from.name },
      )
    }
  })

  // Make sure to track only configured pages to avoid null values on graphs side.
  if (route.name in PAGE_TYPE_BY_ROUTE_NAME) {
    startMonitoringWebVitals({ store, route })

    trackRumView({
      pageType: PAGE_TYPE_BY_ROUTE_NAME[route.name],
      connection: getConnection(),
      deviceType: getDevice(),
      // We do not rely on the `event.meta.country` property here because our
      // lab monitoring system (from the `web-performance-tracking` repository)
      // starts a single client for all different countries, meaning that the
      // `event.meta.country` property is always the same, for all events.
      // So, we need to pass the `country` as an `event.data` property, and
      // we configured the Noodle to consume the `web-performance.value` event
      // that way. So, we also must follow that constraint here.
      country,
    })
  }
}
