
import { RevList, RevListItem } from '@backmarket/design-system'

import translations from './Listbox.translations'

export default {
  components: {
    RevList,
    RevListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedIndex: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    translations: () => translations,
  },
  watch: {
    selectedIndex(newIndex) {
      const item =
        newIndex === -1
          ? this.$refs.listbox.querySelector('[role = "option"]')
          : this.$refs.listbox.querySelector('[aria-selected = "true"]')
      if (item) {
        item.scrollIntoView({ block: 'center' })
      }
    },
  },
  methods: {
    clickHandler(index) {
      this.$emit('select', index)
    },
  },
}
