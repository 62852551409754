import { fetchPaymentMethods } from '@http/endpoints'

export default {
  namespaced: true,

  state: () => ({
    methods: [],
  }),

  mutations: {
    setMethods(state, methods) {
      state.methods = methods
    },
  },

  getters: {
    methods: (state) => state.methods,
  },

  actions: {
    async fetchMethods({ commit, dispatch, rootGetters }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchPaymentMethods,
          queryParams: {
            country_code: rootGetters['config/country'],
            // listings: '',
            // bag_price: 300,
          },
        },
        { root: true },
      )

      commit('setMethods', payload.results)
    },
  },
}
