import { archiveCustomerRequestPlatformProductReturn } from '@http/endpoints'
import { isReturns } from '@sav-common/helpers'

import { PRODUCT_RETURN_STATES, SAV_PLATFORM_ROLES } from '../../../constants'

export default {
  namespaced: true,

  state() {
    return {}
  },
  actions: {
    async archive(
      { dispatch },
      {
        context,
        customerRequestId,
        productReturnId,
        role = SAV_PLATFORM_ROLES.merchant,
      },
    ) {
      await dispatch(
        'http/request',
        {
          request: archiveCustomerRequestPlatformProductReturn,
          pathParams: { productReturnId },
        },
        {
          root: true,
        },
      )

      // Now that the product return of the customer request is archived, we can
      // update the allowed actions for this customer request.
      dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )

      // Also, we update the status of the product return.
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnBackDelivered,
          },
        },
        { root: true },
      )

      // If we are in the product return context, we can remove the product
      // return from the list as it's not a pending product return anymore.
      if (isReturns(context)) {
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }
    },
  },
}
