import getConfig from '../../../components/Page/store/getConfig'

const getDefaultState = () => ({
  summary: {
    statsInfo: [],
  },
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get: (state) => state.summary,
  },
  mutations: {
    set: (state, summary) => {
      state.summary = summary
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    fetch: async ({ commit, dispatch }, { routeInfo }) => {
      const { pathParams, queryParams, endpoints } = getConfig(routeInfo)

      const request = {
        request: endpoints.summary,
        pathParams,
        queryParams,
      }
      const { payload } = await dispatch('http/request', request, {
        root: true,
      })

      commit('set', payload)
    },
    set: ({ commit }, summary) => commit('set', summary),
    reset: ({ commit }) => commit('reset'),
  },
}
