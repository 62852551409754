import { PAYMENT_METHOD_CONFIGS } from '../config/methods'

import { paymentGroupConfig, sortedPaymentGroups } from './methods'

export default class PaymentMethodGroup {
  /**
   * @param {{ id: string} & import('../config/methods').PaymentGroupConfig} groupConfig
   * @param {import('@payment').PaymentMethod[]} availableMethods
   */
  constructor(
    {
      id,
      label,
      external = true,
      messages = [],
      providerName = null,
      errorMessages = {},
      legalNotices,
    },
    availableMethods = [],
  ) {
    this.config = {
      id,
      label,
      external,
      messages,
      providerName,
      errorMessages,
      legalNotices,
    }
    this.availableMethods = availableMethods
  }

  get id() {
    return this.config.id
  }

  get label() {
    return this.config.label
  }

  get messages() {
    return this.config.messages
  }

  get providerName() {
    return this.config.providerName
  }

  get external() {
    return this.config.external
  }

  get networks() {
    return Array.from(
      this.availableMethods.reduce(
        (set, { networks }) => new Set([...set, ...networks]),
        new Set(),
      ),
    )
  }

  /**
   * @typedef {import('@payment').PaymentMethod[] & import('../config/methods').PaymentMethodConfig} PaymentMethodWithConfig
   * @returns {PaymentMethodWithConfig[]}
   */
  get methods() {
    return this.availableMethods.map((method) => ({
      // Add a default label as fallback
      label: {
        id: `(no label for "${method.bmCode}" payment method)`,
        defaultMessage: method.bmCode,
      },

      ...PAYMENT_METHOD_CONFIGS[method.bmCode],
      ...method,
    }))
  }

  get isDisabled() {
    return this.availableMethods.every((method) => !method.enabled)
  }

  get errors() {
    return Array.from(
      this.availableMethods.reduce(
        (set, { errors }) => new Set([...set, ...errors]),
        new Set(),
      ),
    )
  }

  get errorMessages() {
    return this.errors
      .map((error) => this.config.errorMessages[error])
      .filter(Boolean)
  }

  /**
   * @param {import('@payment').PaymentMethod[]} availableMethods
   */
  static fromAvailableMethods(availableMethods) {
    const methodsPerGroupId = availableMethods.reduce(
      (acc, method) => ({
        ...acc,
        [method.group]: [...(acc[method.group] || []), method],
      }),
      {},
    )

    return sortedPaymentGroups(
      Object.entries(methodsPerGroupId).map(
        ([id, methods]) => new this({ id, ...paymentGroupConfig(id) }, methods),
      ),
    )
  }
}
