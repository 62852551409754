import {
  getBackPricerStatus,
  getListings,
  updateListing,
} from '@http/endpoints'

import { updateMarketsListingLink } from '../utils'

export default {
  namespaced: true,
  state: () => ({
    isBackPricerEnabled: false,
    listings: [],
    count: 0,
  }),
  getters: {
    listing: (state) => (listingId) => {
      return state.listings.find((listing) => listing.id === listingId)
    },
    listings: (state) => state.listings,
    count: (state) => state.count,
    hasBackPricerEnabled: (state) => state.isBackPricerEnabled,
  },
  mutations: {
    setCount(state, count) {
      state.count = count
    },
    setListing(state, { listingId, listing }) {
      const listingIndex = state.listings.findIndex(
        (originalListing) => originalListing.id === listingId,
      )

      if (listingIndex === -1) {
        return
      }

      state.listings.splice(listingIndex, 1, listing)
    },
    setListings(state, listings) {
      state.listings = listings
    },
    setBackPricerStatus(state, backPricerStatus) {
      state.isBackPricerEnabled = backPricerStatus
    },
  },
  actions: {
    async getListings({ rootGetters, commit, dispatch }, queryParams) {
      const {
        payload: { count, results: listings },
      } = await dispatch(
        'http/request',
        {
          request: getListings,
          queryParams,
        },
        { root: true },
      )

      // we need to correctly format the URL of the listing page using getLink helper
      const updateMarketsListingsLinksPromises = listings.map((listing) => {
        return updateMarketsListingLink({
          listing,
          baseUrl: rootGetters['config/baseURL'],
        })
      })

      const updatedListings = await Promise.all(
        updateMarketsListingsLinksPromises,
      )

      commit('setListings', updatedListings)
      commit('setCount', count)
    },
    async updateListing(
      { commit, dispatch, rootGetters },
      { listingId, body },
    ) {
      const { payload: listing } = await dispatch(
        'http/request',
        {
          request: updateListing,
          pathParams: { listingId },
          body,
        },
        { root: true },
      )

      // we need to correctly format the URL of the listing page using getLink helper
      const updatedListing = await updateMarketsListingLink({
        listing,
        baseUrl: rootGetters['config/baseURL'],
      })

      commit('setListing', { listingId, listing: updatedListing })
    },
    async setBackPricerStatus(
      { commit, dispatch },
      { isFeatureFlagActivated },
    ) {
      const {
        payload: { backpricerEnabled },
      } = await dispatch(
        'http/request',
        { request: getBackPricerStatus },
        { root: true },
      )

      commit('setBackPricerStatus', isFeatureFlagActivated && backpricerEnabled)
    },
  },
}
