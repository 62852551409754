import isEmpty from 'lodash/isEmpty'

import { ROUTES } from '@router'

export const BACKMARKET_ID = '1458344336'

// The smart banner is displayed on the following routes
export const WHITELISTED_ROUTE = [
  ROUTES.HOME,
  ROUTES.PRODUCT.HOME,
  ROUTES.LANDING.PRODUCT,
]

export const APPSFLYER_MEDIA_SOURCE = 'backmarket'
export const APPSFLYER_CAMPAIGN = 'smartbanner'

export const trackingContext = ({ $route, $store }) => {
  const { name, params = {} } = $route
  if (ROUTES.PRODUCT.HOME === name) return params.id
  if (ROUTES.LANDING.PRODUCT === name) return $store.landingId

  return ''
}

export const trackingLink = ({
  appsflyerBaseUrl,
  country,
  deepLink = '',
  route = 'unknown',
  context = '',
}) => {
  if (isEmpty(country) || isEmpty(appsflyerBaseUrl)) {
    return undefined
  }

  const lowercaseCountry = country.toLowerCase()
  const pathParams = []

  pathParams.push(`pid=${APPSFLYER_MEDIA_SOURCE}`)
  pathParams.push(`c=${APPSFLYER_CAMPAIGN}`)
  pathParams.push(`af_ad=${lowercaseCountry}`)
  pathParams.push(`af_adset=${route}`)
  if (!isEmpty(deepLink)) pathParams.push(`deep_link_value=${deepLink}`)
  if (!isEmpty(context)) pathParams.push(`af_adset_id=${context}`)

  return `${appsflyerBaseUrl}?${pathParams.join('&')}`
}

export const iosSmartBannerMetadata = ({ deepLink }) => {
  const appArgument = !isEmpty(deepLink) ? `,app-argument=${deepLink}` : ''

  return {
    name: 'apple-itunes-app',
    content: `app-id=${BACKMARKET_ID}${appArgument}`,
  }
}

export const metadataWithSmartBanner = ({ deepLink = '', metadata = {} }) => {
  const meta = metadata.meta || []

  return {
    ...metadata,
    meta: [
      ...meta,
      iosSmartBannerMetadata({
        deepLink,
      }),
    ],
  }
}
