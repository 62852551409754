import { PROCESSOUT_ERRORS, PROCESSOUT_SDK_ERRORS } from '../config/errors'

export default {
  errorTitle: {
    id: 'funnel_payment_page_payment_error_title',
    defaultMessage: 'Payment has failed',
  },
  errorDescription: {
    id: 'funnel_payment_page_payment_error_message',
    defaultMessage:
      "We're sorry, but your payment didn't work. Here is the error message that our secure payment system sent us: {reason}.",
  },
  [PROCESSOUT_ERRORS.OWN.LOAD_FAILURE]: {
    id: 'processout_own_load_failure',
    defaultMessage:
      'An error occurred while connecting to our payment provider. Please make sure your Internet connection is working, and try again.',
  },
  [PROCESSOUT_ERRORS.OWN.UNKNOWN_SDK_ERROR]: {
    id: 'processout_own_unknown_sdk_error',
    defaultMessage: '{message}',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CUSTOMER_POPUP_BLOCKED]]: {
    id: 'processout_sdk_error_customer_popup_blocked',
    defaultMessage:
      'Please allow pop-ups, in order to continue with your payment flow.',
  },

  // Name
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_NAME]]: {
    id: 'processout_sdk_error_card_invalid_name',
    defaultMessage: 'This card holder seems to be invalid',
  },

  // Number
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_MISSING_NUMBER]]: {
    id: 'processout_sdk_error_missing_number',
    defaultMessage: 'Card number is required',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_NUMBER]]: {
    id: 'processout_sdk_error_card_invalid_number',
    defaultMessage: 'This card number is invalid',
  },

  // Date
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_MISSING_EXPIRY]]: {
    id: 'processout_sdk_error_card_missing_expiry',
    defaultMessage: 'Date is required',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_EXPIRY_DATE]]: {
    id: 'processout_sdk_error_card_invalid_expiry_date',
    defaultMessage: 'This date is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_DATE]]: {
    id: 'processout_sdk_error_card_invalid_expiry_date',
    defaultMessage: 'This date is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_EXPIRY_MONTH]]: {
    id: 'processout_sdk_error_card_invalid_expiry_month',
    defaultMessage: 'Expiry month is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_MONTH]]: {
    id: 'processout_sdk_error_card_invalid_expiry_month',
    defaultMessage: 'Expiry month is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_EXPIRY_YEAR]]: {
    id: 'processout_sdk_error_card_invalid_expiry_year',
    defaultMessage: 'Expiry year is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_YEAR]]: {
    id: 'processout_sdk_error_card_invalid_expiry_year',
    defaultMessage: 'Expiry year is invalid',
  },

  // Security code
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_MISSING_CVC]]: {
    id: 'processout_sdk_error_card_missing_cvc',
    defaultMessage: 'CVV is missing',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_FAILED_CVC]]: {
    id: 'processout_sdk_error_card_failed_cvc',
    defaultMessage: 'This CVV is invalid',
  },
  [PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CARD_INVALID_CVC]]: {
    id: 'processout_sdk_error_card_invalid_cvc',
    defaultMessage: 'This CVV is invalid',
  },
}
