import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  const repairTrackingRoute = [
    {
      path: 'dashboard/repair/:repairId',
      name: ROUTES.REPAIR.TRACKING,
      component: () =>
        dynamicImport(
          import('@repair/pages/repairTracking/RepairTracking.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
  ]

  return repairTrackingRoute
}
