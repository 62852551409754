import { camelizeKeys } from 'humps'
import isEmpty from 'lodash/isEmpty'

import { COUNTRIES } from '@config/constants'
import { sortByAlphabetical } from '@core/helpers'
import {
  getCountryCodeFromLocale,
  getCurrencyFromCountryCode,
} from '@core/helpers/countries'

export const mapFromLandingDetails = (payload) => ({
  slug: payload.slug,
  // Product specific
  id: String(payload.id),
  urls: payload.urls,
  links: payload.links,
  link: payload.link,
  hero: payload.hero,
  tagline: payload.tagline,
  seo: {
    title: payload.title_seo,
    text: payload.text_seo,
  },
  electronicWaste: !isEmpty(payload.electronic_waste)
    ? {
        weight: payload.electronic_waste.weight,
        chips: payload.electronic_waste.chips_number,
      }
    : {},
  buyingGuide: payload.buying_guide,
  subtitle: payload.displayed_title,
  title: payload.displayed_title_plural,
  displayHeroAndRates: payload.display_hero_and_rates,
  marketingContent: payload.marketing_content,
  type: payload.landing_type,
  listView: payload.list_view,
  listViewStructure: payload.list_view_structure,
  // Merchant specific
  address: payload.address,
  company: payload.company,
  deltaAged: payload.delta_aged,
  orderlinesLen: payload.orderlines_len,
  legalName: payload.merchant_legal_name,
  shopCoverPicture: payload.shop_cover_picture,
  description: payload.description,
  vatNumber: payload.vat_number,
  siretNumber: payload.siret_number,
  isShownMerchantInfo: Boolean(payload.display_merchant_infos),
  isVanishingDeals: payload.isVanishingDeals,
})

/* Payment */

export const DISCOUNT_TYPES = {
  NOT_VALID: 0,
  WITHOUT_REFERRAL: 1,
  WITH_REFERRAL: 3,
  REFERRAL_BASE: 5,
}

/**
 * TODO: Migrate to Price model
 * @typedef Discount
 * @property {string} id
 * @property {number} deductionMax
 * @property {number} deduction
 * @property {number} deductedPrice
 * @property {number} referralBase
 *
 * @param {Object} payload
 * @returns {Discount}
 */
const mapFromValidDiscount = (payload) => {
  const amount = Number(payload.amount)

  return {
    id: payload.promotion_code_pk,
    deductionMax: amount,
    deduction: amount,
    deductedPrice: Number(payload.new_price),
    referralBase: DISCOUNT_TYPES.REFERRAL_BASE,
  }
}

/**
 * @param {Object} payload
 * @typedef {{ isValid: true } & Discount} ValidDiscount
 * @typedef {{ isValid: false, errorMessage: string }} InvalidDiscount
 * @returns {ValidDiscount|InvalidDiscount}
 * @throws {Error} If discount type returned by API is unknown.
 */
export const mapFromDiscount = (payload) => {
  const { valid: type, ...data } = payload

  switch (type) {
    case DISCOUNT_TYPES.WITH_REFERRAL:
    case DISCOUNT_TYPES.WITHOUT_REFERRAL:
      return {
        isValid: true,
        ...mapFromValidDiscount(data),
      }

    case DISCOUNT_TYPES.NOT_VALID:
      return {
        isValid: false,
        errorMessage: data.message,
      }

    default:
      throw new Error(`Unknown discount type: ${type}`)
  }
}

/* Profile */

export const mapFromNewsletterPreferences = (preferences) =>
  preferences.map((data) => ({
    userPreferenceId: data.email_preference,
    newsletterId: data.marketing_newsletter_id,
    name: data.marketing_newsletter_name,
    optin: data.optin,
  }))

/* Customer Request (SAV) CMS Templates */

export const mapFromBuyingGuides = (payload) => {
  return {
    ...payload,
    countryCode: getCountryCodeFromLocale(payload.country_code),
  }
}

export const mapFromBuyingGuidesHub = (payload) => {
  return {
    ...payload,
    countryCode: getCountryCodeFromLocale(payload.country_code),
  }
}

/* Reviews */
export const mapReviewsListFromApi = ({ results = [], ...data }) => ({
  ...data,
  results: results.map((review) => {
    return {
      reviewId: review.reviewOrderLineId,
      reviewInfo: {
        rate: Number(review.rate),
        comment: review.comment,
        dateReview: review.dateReview,
      },
      characteristics: review.reviews || [],
      userInfo: {
        firstName: review.firstName,
        lastName: review.lastName,
        nbClientOrders: review.nbClientOrders,
      },
      productInfo: {
        imageUrl: review.imageUrl,
        productUrl: review.productUrl,
        merchantName: review.merchantName,
        productTitle: review.productTitle,
        modelTitle: review.modelTitle || review.product?.title || '',
        link: review.product?.link || {},
      },
    }
  }),
})

export const mapReviewsSummaryFromApi = (summary = {}) => ({
  statsInfo: summary.rate_distribution
    ? summary.rate_distribution.map((stat) => ({
        percentage: stat.percentage,
        rateRange: stat.rate_range,
      }))
    : [],
})

function getFormattedMerchantReviews(merchantReviews = {}) {
  if (isEmpty(merchantReviews)) {
    return null
  }

  return Object.entries(merchantReviews).reduce(
    (formattedMerchantReviews, [key, value = {}]) => ({
      ...formattedMerchantReviews,
      [key]: {
        averageRate: value.average_rate,
        merchantSlug: value.merchant_slug,
        reviewsCount: value.reviews_count,
      },
    }),
    {},
  )
}

export const mapProductRateFromApi = (data = {}) => ({
  description: data.description || null,
  merchantsReviews: getFormattedMerchantReviews(data.merchants_reviews),
})

export const mapFromKybProfile = (data) => ({
  legalName: data.pseudo || '',
  displayName: data.company || '',
  email: data.business_mail || '',
  phone: data.address__phone || '',
  address: data.address__street || '',
  city: data.address__city || '',
  zipCode: data.address__postal_code || '',
  state: data.address__state_or_province || '',
  country: data.address__country || '',
  ein: data.vat_number || '',
})

export const mapFromKybBank = (data) => ({
  accountNumber: data.merchant__wallet__account_number || '',
  routingNumber: data.merchant__wallet__branch_code || '',
  owner: data.owner_name || '',
  currencyCode: data.currency || '',
  countryCode: data.country_code || COUNTRIES.US,
  // We do not handle the file received from the API. This will cause the user to
  // upload its file again if they ever want to submit the form one more time.
  // This is far from ideal, but it's simpler for now.
  statement: '',
})

export const mapFromKybOwner = (data) => ({
  firstName: data.address__first_name || '',
  lastName: data.address__last_name || '',
  birthdate: data.address__birthdate || '',
  address: data.address__street || '',
  city: data.address__city || '',
  zipCode: data.address__postal_code || '',
  gender: data.address__gender !== null ? data.address__gender.toString() : '',
  state: data.address__state_or_province || '',
  country: data.address__country || '',
  email: data.address__email || '',
  ssn: data.merchant__social_security_number
    ? data.merchant__social_security_number.slice(-4)
    : '',
})

export const mapFromAllCategories = ({ results: categories }) =>
  camelizeKeys(categories)
    .filter(({ categoryName }) => !isEmpty(categoryName))
    .sort((a, b) => {
      if (a.categoryName < b.categoryName) return -1
      if (a.categoryName > b.categoryName) return 1

      return 0
    })

// TODO remove when CMS API is available
export const mapFromProductBanner = (data) => ({
  currency: data.listing.currency,
  image: data.image,
  originalPrice: data.price_original,
  price: data.listing.price,
  cta: { link: data.link },
  title: data.model_name,
})

export const mapProductReviews = (data) => ({
  ...data,
  rate: Number(data.rate),
})

export const mapFromInternationalOnboarding = (payload) => {
  const finalPayload = { ...payload }

  if (!isEmpty(payload.metaData)) {
    // @TO-DO: should be remove after https://backmarket.atlassian.net/browse/GM-4630
    if (payload.metaData.shippers) {
      finalPayload.metaData.carriers = payload.metaData.shippers.map(
        (shipper) => {
          return {
            id: shipper.id,
            name: shipper.shipperDisplayName,
            backship: shipper.isBackship,
            collectionPoint: shipper.collectionPoint,
            restrictedToMarkets: shipper.restrictedToMarkets,
          }
        },
      )
    }
  }

  return finalPayload
}

// TODO: clean the mapFromGetListings once the API endpoint applies the changes (MCC-2650)
const GRADES = ['shiny', 'gold', 'silver', 'bronze', 'stallone']

const transformListing = ({ markets, state, quantity, ...listing }) => {
  const marketsWithCountryCodes = Object.entries(markets).map(
    ([countryCode, market]) => {
      return {
        ...market,
        countryCode,
        // FIXME: GM-5112 - Move currency fallack logic into page or store.
        currency: getCurrencyFromCountryCode(countryCode),
      }
    },
  )

  // FIXME: GM-5112 - Move sort logic into page or store.
  const marketsWithCountryCodesOrdered = sortByAlphabetical(
    marketsWithCountryCodes,
    'countryCode',
  )

  return {
    ...listing,
    // FIXME: GM-5112 - Move sort logic into page or store.
    markets: marketsWithCountryCodesOrdered,
    // FIXME: GM-5113 - Legacy "grades" (Int), use aesthericGrade.code instead
    state: GRADES[state],
    // FIXME: GM-5112 - Use "quantity" instead of "stock" top avoid renaming
    stock: quantity,
  }
}

export const mapFromUpdateListing = (payload) => transformListing(payload)

export const mapFromGetListings = (payload) => ({
  ...payload,
  results: payload.results.map(transformListing),
})

export const mapFromBalanceAjustements = (payload) => ({
  ...payload,
  results: payload.results.map((result) => ({
    price: { amount: result.amount, currency: result.currency },
    action: result.action,
    seller: { id: result.merchant_id, company: result.merchant_company },
    mention: result.mention,
    initiatorName: result.initiator_name,
    date: result.date,
  })),
})

export const mapFromPicsouConfig = (payload) => {
  const actionOptions = {}
  payload.options_action.forEach((actionOption) => {
    actionOptions[actionOption.name] = actionOption.value
  })

  const mentionOptions = {}
  payload.options_mention.forEach((mentionOption) => {
    mentionOptions[mentionOption.name] = mentionOption.value
  })

  return {
    actionOptions,
    mentionOptions,
    currencyOptions: payload.options_currencies,
    defaultCurrency: payload.default_currency,
  }
}
