import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => [
  {
    // TODO [ACQ-265] `slug` param needed during Buying Guides migration to Contentful.
    path: 'peach/:category/:pageName',
    component: () => dynamicImport(import('./Article.vue')),
    name: ROUTES.CMS.ARTICLE,
  },
]
