export const TYPES = {
  FUNCTIONAL: 'functional',
  ADVERTISING: 'advertising',
  ANALYTICS: 'analytics',
  USER_EXPERIENCE: 'userExperience',
}

export const COOKIES = {
  [TYPES.ADVERTISING]: 'BM_Advertising',
  [TYPES.ANALYTICS]: 'BM_Analytics',
  [TYPES.USER_EXPERIENCE]: 'BM_User_Experience',
}

export const DEFAULT_STATE = {
  [TYPES.ADVERTISING]: false,
  [TYPES.ANALYTICS]: false,
  [TYPES.FUNCTIONAL]: true,
  [TYPES.USER_EXPERIENCE]: false,
}

export const MISSING_COOKIE_ERROR = new Error(
  'a GDPR session cookie is missing',
)
