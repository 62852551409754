export const API = 'bmApiCall'
export const CLICK = 'bmClick'
export const CONTEXT = 'bmContext'
export const ERROR = 'bmError'
export const FORM_SUBMIT = 'bmSubmit'
export const GDPR = 'bmGDPR'
export const PAGE_VIEW = 'bmPageView'
export const RESIZE = 'bmResize'
export const USER_CONTEXT = 'bmUserContext'
export const MODAL = 'bmModal'
export const TOAST = 'bmToast'
export const CAROUSEL_IMPRESSION = 'bmSlideImpression'

export const ADD_TO_CART = 'addToCart'
export const EARLYBIRD_RECOS = 'ebReco'
export const FUNNEL = 'funnel'
export const LANDING = 'landing_page'
export const PAYMENT_FAIL = 'bmPaymentFail'
export const PAYMENT_SUCCESS = 'purchase'
export const PAYMENT_SUCCESS_TMP = 'purchaseTmp'
export const PRODUCT = 'product'
export const PROMOTION_CLICK = 'bmPromotionClick'
export const PROMOTION_IMPRESSION = 'bmPromotionImp'
export const REMOVE_FROM_CART = 'removeFromCart'

export const PRODUCT_IMPRESSIONS = 'bmProductImpressions'
export const PRODUCT_IMPRESSION = 'bmProductImpression'
export const PRODUCT_CLICK = 'bmProductClick'

export const BUYBACK_CONFIRMATION = 'bmBuybackConfirmation'
export const BUYBACK_NO_OFFER = 'bmBuybackNoOffer'

export const PROMPT_NOTIFICATION_IMPRESSION = 'promptNotificationImpression'

// Legacy events
export const SWAP = 'swap'
export const RESET_PASSWORD = 'reset_pwd'
export const RESET_PASSWORD_SUCCESS = 'reset_pwd_success'
export const SIGN_IN = 'sign_in'
export const SIGN_UP = 'sign_up'
export const SWAP_MODAL = 'swap_modal'
