import isEmpty from 'lodash/isEmpty'

import { PROCESSOUT_ALTERNATE_PAYMENT_METHODS } from '../config/alternate-payment-methods'
import { PROCESSOUT_ERRORS, PROCESSOUT_ERROR_ORIGINS } from '../config/errors'

import { processOutError } from './errors'

export function findAlternatePaymentMethod(
  alternatePaymentMethods,
  paymentMethod,
) {
  if (
    !Object.keys(PROCESSOUT_ALTERNATE_PAYMENT_METHODS).includes(
      paymentMethod.bmCode,
    )
  ) {
    throw processOutError(
      PROCESSOUT_ERRORS.OWN.UNSUPPORTED_PAYMENT_METHOD,
      new Error(
        `ProcessOut support is not implemented for "${paymentMethod.bmCode}"`,
      ),
      {
        origin: PROCESSOUT_ERROR_ORIGINS.FIND_ALTERNATE_PAYMENT_METHODS,
        paymentMethod,
        supported: Object.keys(PROCESSOUT_ALTERNATE_PAYMENT_METHODS),
      },
    )
  }

  const gatewayName = PROCESSOUT_ALTERNATE_PAYMENT_METHODS[paymentMethod.bmCode]
  const alternatePaymentMethod = alternatePaymentMethods.find(
    (config) => config.gateway.name === gatewayName,
  )

  if (isEmpty(alternatePaymentMethod)) {
    throw processOutError(
      PROCESSOUT_ERRORS.OWN.GATEWAY_NOT_FOUND,
      new Error(
        `Could not find ProcessOut gateway for "${paymentMethod.bmCode}"`,
      ),
      {
        origin: PROCESSOUT_ERROR_ORIGINS.FIND_ALTERNATE_PAYMENT_METHODS,
        paymentMethod,
        expectedGatewayName: gatewayName,
        actualGatewayNames: alternatePaymentMethods.map(
          (config) => config.gateway.name,
        ),
        alternatePaymentMethods,
      },
    )
  }

  return alternatePaymentMethod
}
