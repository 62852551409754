import { fetchCustomerRequestProductReturn } from '@http/endpoints'
import { SAV_ROLES } from '@sav-common/constants'
import { getApiPathFromRole } from '@sav-common/helpers'

export default {
  namespaced: true,

  state() {
    return {
      productReturn: {},
    }
  },
  getters: {
    productReturn(state) {
      return state.productReturn
    },
  },
  mutations: {
    set(state, { productReturn }) {
      state.productReturn = productReturn
    },
  },
  actions: {
    async fetch(
      { commit, dispatch },
      { role = SAV_ROLES.client, customerRequestId },
    ) {
      const { payload: productReturn } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestProductReturn,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )

      commit('set', { productReturn })
    },
  },
}
