import { SizeCategory } from '@config/constants/breakpoints'
import { getScreenSizeCategory } from '@core/helpers/dom'
import { isNotOnBlocklistedRoute } from '@tracking/helpers'

export function canLoadAppsflyer({
  country,
  $config,
  store,
  blocklistedRoutesCollections,
  currentRoute,
}) {
  // TODO [LIF-464] temporary solution as the screen size does not necessarily equal the device type.
  const screenSizeCategory = getScreenSizeCategory()
  const isMobileDevice = [SizeCategory.small, SizeCategory.medium].includes(
    screenSizeCategory,
  )

  const isMobileAppAvailable = store.getters['config/isMobileAppAvailable']
  const isEnabledInCurrentCountry =
    $config.FF_APPSFLYER_ENABLED_BY_COUNTRY?.includes(country)
  const isKilled = $config.KILL_APPSFLYER

  const isGrantedOnCurrentRoute = isNotOnBlocklistedRoute({
    blocklistedRoutesCollections,
    currentRoute,
  })

  return (
    isMobileDevice &&
    isMobileAppAvailable &&
    isEnabledInCurrentCountry &&
    isGrantedOnCurrentRoute &&
    !isKilled
  )
}
