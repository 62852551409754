import isEmpty from 'lodash/isEmpty'

import {
  paymentAuthorize,
  paymentConfirmCard,
  paymentConfirmHPP,
} from '@http/endpoints'
import { ROUTES } from '@router'

import { adyenEncrypt } from './modules/adyen'
import {
  PAYMENT_SERVICE_PROVIDERS,
  PaymentRedirection,
  paymentMethodVirtualCard,
} from './modules/methods'
import processout from './modules/processout/store'
import { ravelinDeviceId } from './modules/security'

/**
 * @typedef {Object} CreatePaymentPayload
 * @property {string} paymentId
 * @property {'GET'|'POST'} redirectMethod
 * @property {string?} redirectUrl
 * @property {Record<string,string>?} redirectData
 * @property {string?} gatewayReference
 */

/**
 * @typedef {Object} MakePaymentPayload
 * @property {() => Promise<CreatePaymentPayload & Object>} create
 * @property {import('.').PaymentMethod} paymentMethod
 * @property {import('.').PaymentConfig} paymentConfig
 * @property {boolean} requiresPayment False when no payment is required (ex: 100% discount)
 * @property {import('./modules/methods/config/methods').VirtualCardContext} virtualCardContext
 * @property {import('.').PaymentData} [paymentData] Un-encrypted payment data
 */

export default {
  namespaced: true,

  modules: {
    processout,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<PaymentRedirection>}
     */
    async make({ dispatch }, payload) {
      if (!payload.requiresPayment) {
        return dispatch('create', payload)
      }

      switch (payload.paymentMethod.pspCode) {
        case PAYMENT_SERVICE_PROVIDERS.ADYEN_MARKETPAY:
        case PAYMENT_SERVICE_PROVIDERS.ADYEN_PAYIN_PAYOUT:
          return dispatch('makeAdyenPayment', payload)

        case PAYMENT_SERVICE_PROVIDERS.PAYPAL:
          // For PayPal (as Service Provider), we just need to create a payment
          // and redirect.
          return dispatch('create', payload)

        case PAYMENT_SERVICE_PROVIDERS.PROCESSOUT:
          return dispatch('makeProcessOutPayment', payload)

        default:
          throw new Error(
            `Unsupported payment service provider: ${payload.paymentMethod.pspCode}`,
          )
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<{
     *  paymentId: string,
     *  gatewayReference?: string,
     *  redirection: PaymentRedirection?
     * }>} Payment id, redirection if available, and ProcessOut invoice id if available
     */
    async create(context, payload) {
      const {
        paymentId,
        gatewayReference,
        redirectData,
        redirectLink,
        redirectMethod,
      } = await payload.create({
        payment_method: { reference: payload.paymentMethod.reference },
        deviceId: await ravelinDeviceId(this.$config),
        signifyd_fingerprint: payload.paymentConfig.signifydFingerprint,
      })

      return {
        paymentId,
        gatewayReference,
        ...(isEmpty(redirectLink)
          ? {}
          : {
              redirection: new PaymentRedirection(
                redirectMethod,
                redirectLink,
                redirectData,
              ),
            }),
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<PaymentRedirection>}
     */
    async makeProcessOutCardPayment({ dispatch }, payload) {
      const token = await dispatch('processout/tokenize', payload.paymentData)

      const { paymentId, gatewayReference } = await dispatch('create', payload)

      try {
        await dispatch('processout/makeCardPayment', {
          invoiceId: gatewayReference,
          token,
          paymentMethod: payload.paymentMethod,
        })
      } catch (error) {
        // Do not treat error, as we will redirect to payment result page in
        // that case. These error may happen based on user input (ex: using a
        // blocked card). The payment result page will display a translated
        // error message to the user, that will be generated on the back-end
        // side.
      }

      // Call payment confirm endpoint regardless of whether it's a success or
      // failure.
      try {
        await dispatch(
          'http/request',
          { request: paymentConfirmCard, pathParams: { paymentId } },
          { root: true },
        )
      } catch (error) {
        // Do not treat error, as we will redirect to payment result page in
        // that case. Errors on `paymentConfirmCard` endpoint must be monitored
        // though, as they are not expected.
      }

      return paymentId
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<PaymentRedirection>}
     */
    async makeProcessOutHostedPayment({ dispatch }, payload) {
      const { paymentId, gatewayReference } = await dispatch('create', payload)

      const alternatePaymentMethod = await dispatch(
        'processout/getAlternatePaymentMethodConfig',
        {
          invoiceId: gatewayReference,
          paymentMethod: payload.paymentMethod,
        },
      )

      const gatewayToken = await dispatch(
        'processout/handleAlternatePaymentMethodInvoiceAction',
        { alternatePaymentMethod },
      )

      // Call payment confirm endpoint regardless of whether it's a success or
      // failure.
      try {
        await dispatch(
          'http/request',
          {
            request: paymentConfirmHPP,
            pathParams: { paymentId },
            body: { gatewayToken },
          },
          { root: true },
        )
      } catch (error) {
        // Do not treat error, as we will redirect to payment result page in
        // that case. Errors on `paymentConfirmHPP` endpoint must be monitored
        // though, as they are not expected.
      }

      return paymentId
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<{ paymentId: string, redirection: PaymentRedirection }>}
     */
    async makeProcessOutPayment({ dispatch }, payload) {
      const paymentId = payload.paymentMethod.hppEnabled
        ? await dispatch('makeProcessOutHostedPayment', payload)
        : await dispatch('makeProcessOutCardPayment', payload)

      const redirection = new PaymentRedirection('GET', {
        name: ROUTES.CHECKOUT.PAYMENT_RESULT,
        type: 'internal',
        params: { paymentId },
      })

      return { paymentId, redirection }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {MakePaymentPayload} payload
     * @returns {Promise<{ paymentId: string, redirection: PaymentRedirection }>}
     */
    async makeAdyenPayment({ dispatch }, payload) {
      const { paymentId, redirection } = await dispatch('create', payload)

      // If we already have a redirection, proceed (ex: PayPal, Adyen + HPP)
      if (redirection instanceof PaymentRedirection) {
        return { paymentId, redirection }
      }

      const virtualCard = paymentMethodVirtualCard(payload.paymentMethod.bmCode)
      const paymentData = virtualCard
        ? await virtualCard(paymentId, payload.virtualCardContext, this.$config)
        : payload.paymentData

      return {
        paymentId,
        redirection: await dispatch('authorizeAdyen', {
          paymentId,
          paymentData,
          paymentConfig: payload.paymentConfig,
        }),
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string} payload.paymentId
     * @param {import('@payment').PaymentConfig} payload.paymentConfig
     * @param {import('@payment').AdyenPaymentData} payload.paymentData
     * @return {Promise<PaymentRedirection>}
     */
    async authorizeAdyen(
      { dispatch },
      { paymentId, paymentConfig, paymentData },
    ) {
      // TODO [FRONT-586] Use toFormData Axios helper
      const data = new FormData()
      data.append(
        'encryptedData',
        await adyenEncrypt(
          paymentData,
          paymentConfig.adyenEncryption.key,
          paymentConfig.adyenEncryption.time,
        ),
      )
      if (paymentData.vendorReference) {
        data.append('checkout_vendor_reference', paymentData.vendorReference)
      }

      const { payload } = await dispatch(
        'http/request',
        {
          request: paymentAuthorize,
          pathParams: { paymentId },
          body: data,
        },
        { root: true },
      )

      // Internal link can be either payment KO, or payment OK without 3D Secure
      const {
        issuer_link: externalLink,
        link: internalLink,
        term_url: TermUrl,
        pa_req: PaReq,
        md: MD,
      } = payload

      if (isEmpty(internalLink) && isEmpty(externalLink)) {
        throw new Error(
          'Could not determine redirection after payment authorization',
        )
      }

      return isEmpty(internalLink)
        ? new PaymentRedirection('POST', externalLink, { TermUrl, PaReq, MD })
        : new PaymentRedirection('GET', internalLink)
    },
  },
}
