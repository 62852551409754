import { fetchCategoryReviews } from '@http/endpoints'

import { LANDING_REVIEWS } from '../../../constants'
import getConfig from '../../getConfig'

const getDefaultState = () => ({
  list: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get: (state) => state.list,
  },
  mutations: {
    set: (state, list) => {
      state.list = list
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    fetch: async ({ commit, dispatch }, { routeInfo }) => {
      if (routeInfo.name === LANDING_REVIEWS) {
        return
      }

      const { queryParams } = getConfig(routeInfo)

      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchCategoryReviews,
          queryParams,
        },
        { root: true },
      )

      commit('set', payload)
    },
    set: ({ commit }, list) => commit('set', list),
    reset: ({ commit }) => commit('reset'),
  },
}
