export default {
  iconAltText: {
    id: 'header_user_submenu_icon_alt_text',
    defaultMessage: 'User sub menu',
  },
  myAccount: {
    id: 'header_user_submenu_my_account',
    defaultMessage: 'My account',
  },
  myOrders: {
    id: 'header_user_submenu_my_orders',
    defaultMessage: 'My orders',
  },
  mySales: {
    id: 'header_user_submenu_my_sales',
    defaultMessage: 'My sales',
  },
  logOut: {
    id: 'header_user_submenu_log_out',
    defaultMessage: 'Log out',
  },
}
