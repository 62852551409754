import isEmpty from 'lodash/isEmpty'

import { MARKETPLACES } from '@config/constants'
import { isBoolean, isNumber } from '@core/helpers'

import {
  ALLOWED_FILTERS,
  DEFAULT_FILTERS,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from './constants'

const SERIALIZATION_SEPARATOR = ','

export const roleValidator = (role) =>
  !isEmpty(role) && Object.values(SAV_PLATFORM_ROLES).includes(role)

export function getNonDefaultFilters(filters = {}, { context } = {}) {
  if (isEmpty(filters)) {
    return {}
  }

  return Object.entries(filters).reduce((filteredFilters, [name, value]) => {
    if (
      !ALLOWED_FILTERS[context].includes(name) ||
      DEFAULT_FILTERS[context][name] === value
    ) {
      return filteredFilters
    }

    return {
      ...filteredFilters,
      [name]: value,
    }
  }, {})
}

export function getNonEmptyFilters(filters = {}) {
  if (isEmpty(filters)) {
    return {}
  }

  return Object.entries(filters).reduce(
    (nonEmptyFilters, [filterName, filterValue]) => {
      if (
        !isBoolean(filterValue) &&
        !isNumber(filterValue) &&
        isEmpty(filterValue)
      ) {
        return nonEmptyFilters
      }

      return {
        ...nonEmptyFilters,
        [filterName]: filterValue,
      }
    },
    {},
  )
}

// Transform array to a string with array values separated by comma
export function serializeArray(array) {
  return array.join(SERIALIZATION_SEPARATOR)
}

// Transform string with values separated by comma to array of values
export function deserializeArray(string) {
  return string.split(SERIALIZATION_SEPARATOR)
}

// Return a dictionnary of serialized filter values from a dictionnary of filters values
export function getSerializedFilters(filters) {
  const serializedFilters = Object.keys(filters).reduce((query, filterName) => {
    const filterValue = filters[filterName]

    if (Array.isArray(filters[filterName])) {
      return { ...query, [filterName]: serializeArray(filterValue) }
    }

    return { ...query, [filterName]: filterValue }
  }, {})

  return serializedFilters
}

export function contains(commaSeparatedList, item) {
  if (!commaSeparatedList) {
    return false
  }

  const array = commaSeparatedList.toString().replace(/\s+/g, '').split(',')
  const entry = item?.toString()

  return array.includes(entry)
}

export function isWFREnabled(config, marketplace, sellerId) {
  const isMarketplaceEnabled = contains(
    config.FF_WFR_ENABLED_MARKETPLACES,
    marketplace,
  )

  if (!isMarketplaceEnabled) {
    return false
  }

  const FF_BY_MARKETPLACE = {
    [MARKETPLACES.EU]: config.FF_WFR_EXCLUDED_SELLERS_IDS_EU,
    [MARKETPLACES.NA]: config.FF_WFR_EXCLUDED_SELLERS_IDS_NA,
  }

  const isSellerExcluded = contains(FF_BY_MARKETPLACE[marketplace], sellerId)

  return !isSellerExcluded
}

export function getContextFromRouteParams(routeParams) {
  return routeParams?.context || SAV_PLATFORM_CONTEXTS.standaloneConversation
}
