// List of tags types and their associated meta tag
export const META_TAGS = {
  description: ['description', 'og:description', 'twitter:description'],
  title: ['og:title', 'twitter:title'],
  image: ['og:image', 'twitter:image'],
  url: ['og:url', 'twitter:url'],
}

// Meta tags added on every pages
export const BASE_META = [
  {
    hid: 'og:type',
    property: 'og:type',
    content: 'website',
  },
  {
    hid: 'twitter:site',
    name: 'twitter:site',
    content: '@back_market',
  },
  {
    hid: 'twitter:card',
    name: 'twitter:card',
    content: 'summary_large_image',
  },
]

export const DEFAULT_META_IMAGE = '/img/relovethepreloved.png'

export const SCHEMA_LOGO = '/img/logo-bm-schema.png'

export const SCHEMA_SEARCH_NAME = 'q'

export const SCHEMA_PRODUCT_IMAGE = '/img/relovethepreloved.png'

export const SCHEMA_BEST_RATING = 5

export const SCHEMA_WORST_RATING = 1

export const SCHEMA_ITEM_CONDITION = {
  REFURBISHED: 'http://schema.org/RefurbishedCondition',
  NEW: 'http://schema.org/NewCondition',
}
