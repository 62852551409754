import deepmerge from 'deepmerge'

import logger from '@logger'
import { ROUTES } from '@router'

import { PAYMENT_VIRTUAL_CARD_ERRORS } from '../../../config/constants'
import {
  AFFIRM_CHECKOUT_VENDOR_NAME,
  AFFIRM_ERROR_REASONS,
  AFFIRM_SANDBOX_VIRTUAL_CARD_OVERRIDES,
} from '../config'

import {
  affirmCheckoutAddress,
  affirmCheckoutDiscounts,
  affirmCheckoutItem,
  affirmCheckoutPrice,
} from './checkout'
import { getAffirmSdk } from './sdk'

/**
 * @param {string} type Any PAYMENT_VIRTUAL_CARD_ERRORS
 * @param {Error} error
 * @param {Object} [data]
 * @returns {Error & { type: string }}
 */
const typedError = (type, error, data) =>
  Object.assign(new Error(error.message), {
    stack: error.stack,
    type,
    data,
  })

/**
 * @param {Object} [successPayload]
 * @param {string} [successPayload.id]
 * @param {string} [successPayload.checkout_token]
 * @param {string} [successPayload.callback_id]
 * @param {string} [successPayload.created]
 * @param {string} [successPayload.cardholder_name]
 * @param {string} [successPayload.number]
 * @param {string} [successPayload.cvv] 4-digits, ex: "0920"
 * @param {string} [successPayload.expiration]
 * @param {Object} $config
 * @return {import('@payment').AdyenVirtualCardData}
 */
function virtualCardData(
  {
    id: vendorReference = '',
    cardholder_name: name = '',
    number = '',
    cvv: securityCode = '',
    expiration = '',
  } = {},
  $config = {},
) {
  const virtualCard = {
    name,
    number,
    securityCode,
    month: expiration.slice(0, 2),
    year: `20${expiration.slice(-2)}`,
  }
  const overrides = $config.AFFIRM_SANDBOX_ENABLED
    ? AFFIRM_SANDBOX_VIRTUAL_CARD_OVERRIDES
    : {}

  return {
    ...deepmerge(virtualCard, overrides),
    vendorReference,
  }
}

/**
 * @param {Object} [errorPayload]
 * @param {string} [errorPayload.checkout_token]
 * @param {string} [errorPayload.reason]
 * @return {Error}
 */
const virtualCardError = ({
  checkout_token: checkoutToken = '',
  reason = '',
} = {}) =>
  reason === AFFIRM_ERROR_REASONS.CANCEL
    ? typedError(
        PAYMENT_VIRTUAL_CARD_ERRORS.CANCEL,
        new Error(`Affirm error: ${reason}`),
        {
          checkoutToken,
          isCancel: true,
          redirectionLink: null,
        },
      )
    : typedError(
        PAYMENT_VIRTUAL_CARD_ERRORS.ERROR,
        new Error(`Affirm error: ${reason}`),
        {
          checkoutToken,
          isCancel: false,
          redirectionLink: {
            type: 'internal',
            name: ROUTES.CHECKOUT.PAYMENT_RESULT,
          },
        },
      )

/**
 * @type {import('../../../config/methods').VirtualCardGetter}
 */
export async function affirmVirtualCard(
  paymentId,
  {
    email,
    shippingAddress,
    billingAddress,
    availableItems,
    tax,
    totalShippingPrice,
    priceAfterDiscount,
    isDiscountApplied,
    discountToken,
    discountDeduction,
  },
  $config = {},
) {
  try {
    // 1. Load SDK
    const affirm = await getAffirmSdk($config)

    // 2. Create checkout object
    // Read more: https://docs.affirm.com/affirm-developers/docs/create-a-checkout-object
    const checkoutParams = {
      merchant: {
        name: AFFIRM_CHECKOUT_VENDOR_NAME,
        use_vcn: true,
      },
      shipping: affirmCheckoutAddress(email, shippingAddress),
      billing: affirmCheckoutAddress(email, billingAddress),
      items: availableItems.map(affirmCheckoutItem),
      shipping_amount: affirmCheckoutPrice(totalShippingPrice),
      tax_amount: affirmCheckoutPrice(tax),
      total: affirmCheckoutPrice(priceAfterDiscount),
      discounts: isDiscountApplied
        ? affirmCheckoutDiscounts(discountDeduction, discountToken)
        : {},
      order_id: paymentId,
    }
    const checkoutData = affirm.checkout(checkoutParams)

    // 3. Create a virtual card
    // Read more: https://docs.affirm.com/affirm-developers/docs/vcn-web
    return await new Promise((resolve, reject) => {
      affirm.ui.ready(() => {
        affirm.ui.error.on('close', (data) => {
          reject(
            typedError(
              PAYMENT_VIRTUAL_CARD_ERRORS.INVALID,
              new Error('Please check your contact information for accuracy.'),
              {
                isCancel: false,
                redirectionLink: null,
                ...data,
              },
            ),
          )
        })

        affirm.checkout.open_vcn({
          checkout_data: checkoutData,
          success: (successPayload) => {
            resolve(virtualCardData(successPayload, $config))
          },
          error: (errorPayload) => {
            reject(virtualCardError(errorPayload))
          },
        })
      })
    })
  } catch (error) {
    if (Object.values(PAYMENT_VIRTUAL_CARD_ERRORS).includes(error.type)) {
      throw error
    }

    logger.error(error)
    throw typedError(PAYMENT_VIRTUAL_CARD_ERRORS.FAILURE, error, {
      isCancel: false,
      redirectionLink: {
        type: 'internal',
        name: ROUTES.CHECKOUT.PAYMENT_RESULT,
      },
    })
  }
}
