import { SAV_PLATFORM_CONTEXTS } from '@sav-platform/constants'

import { SAV_ROLES } from './constants'

export function getApiPathFromRole(role = SAV_ROLES.merchant) {
  switch (role) {
    case SAV_ROLES.admin:
      return 'staff'

    case SAV_ROLES.backcare:
      return 'backcare_agent'

    case SAV_ROLES.client:
      return 'customer'

    case SAV_ROLES.merchant:
    default:
      return role
  }
}

export function isAdmin(role) {
  return role === SAV_ROLES.admin
}

export function isBackcare(context) {
  return context === SAV_PLATFORM_CONTEXTS.backcare
}

export function isBackcareAgent(role) {
  return role === SAV_ROLES.backcare
}

export function isMerchant(role) {
  return role === SAV_ROLES.merchant
}

export function isPending(context) {
  return context === SAV_PLATFORM_CONTEXTS.pending
}

export function isReturns(context) {
  return context === SAV_PLATFORM_CONTEXTS.returns
}

export function isStandaloneConversation(context) {
  return context === SAV_PLATFORM_CONTEXTS.standaloneConversation
}
