
import { RevContainer } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import BlockRenderer from '@cms/components/BlockRenderer.vue'
import { Container } from '@cms/components/private/Container'
import {
  DESCRIPTION_TAGS,
  IMAGE_TAGS,
  TITLE_TAGS,
  createMetaForGroupedTags,
  createSocialMediaTagsFor,
} from '@cms/helpers/meta'
import { valueBuilder } from '@cms/helpers/tracking'
import { requestCMSPageConfigFromRoute } from '@cms/service'
import { COMPANY_NAME } from '@config/constants'
import SkinnyBanner from '@core/components/SkinnyBanner'
import { prepareJsonLdScript } from '@core/helpers'
import {
  buildSearchBoxSchema,
  generateAlternateLinks,
} from '@core/helpers/head'
import { navSeoFetch } from '@http/endpoints'
import { getSocialNetWorksFor } from '@navigation/components/Footer/footerConfig'
import { ROUTES } from '@router'
import { trackClick, trackProductClick } from '@tracking/events'

import { fullWidthComponents } from './Home.constants'
import HomeNavigation from './legacy/components/HomeNavigation.vue'

export default {
  components: {
    BlockRenderer,
    RevContainer,
    Container,
    HomeNavigation,
    SkinnyBanner,
  },

  data() {
    return {
      alternateLinks: [],
      navigationSEO: [],
      cmsHomeConfig: {},
    }
  },

  async fetch() {
    try {
      const promises = [
        this.$store.dispatch('http/request', { request: navSeoFetch }),
        this.$store.dispatch(
          'http/request',
          requestCMSPageConfigFromRoute({ route: this.$route }),
        ),
      ]

      const [
        { payload: navigationSEO = [] } = {},
        { payload: cmsHomeConfig = {} } = {},
      ] = await Promise.all(promises)

      const baseUrl = this.$store.getters['config/baseURL']
      const countries = cmsHomeConfig.meta?.alternateLinks?.map(
        ({ country: linkCountry }) => linkCountry,
      )
      const alternateLinks = await generateAlternateLinks({
        countries,
        link: this.$route,
        baseUrl,
      })

      this.alternateLinks = alternateLinks
      this.navigationSEO = navigationSEO
      this.cmsHomeConfig = cmsHomeConfig
    } catch (err) {
      this.$nuxt.error({
        statusCode: err.status_code,
        message: err.message,
      })
    }
  },

  head() {
    const {
      country,
      baseURL: hostname,
      cmsHomeConfig,
      $route: { params },
      $navigation,
    } = this

    // some fallbacks are provided for possible undefined data (which is allowed on preprod environment)
    const title = cmsHomeConfig.titleSeo
    const cmsMeta = cmsHomeConfig.meta ?? {}
    const imageUrl = cmsMeta?.image?.src

    const textAndImageMeta = createMetaForGroupedTags([
      {
        tags: DESCRIPTION_TAGS,
        content: cmsMeta.description,
      },
      {
        tags: TITLE_TAGS,
        content: title,
      },
      {
        tags: IMAGE_TAGS,
        content: imageUrl,
      },
    ])

    const countrySocialMedia = getSocialNetWorksFor(country)
    const socialMediaMeta = createSocialMediaTagsFor(countrySocialMedia)

    const additionalLinks = [
      {
        rel: 'canonical',
        href: `${hostname}/${params.locale}`,
        itemprop: 'url',
      },
      {
        href: imageUrl,
        itemprop: 'logo',
      },
    ]

    const searchPath = $navigation.resolve({
      name: ROUTES.LANDING.SEARCH,
    }).href

    const schema = buildSearchBoxSchema({
      homeUrl: `${hostname}/${params.locale}`,
      searchUrl: `${hostname}${searchPath}`,
    })

    const homeSchemaScript = prepareJsonLdScript({ schema })

    return {
      title,
      titleTemplate: `%s | ${COMPANY_NAME}`,
      htmlAttrs: {
        itemscope: 'itemscope',
        itemtype: 'http://schema.org/Corporation',
      },
      meta: [
        ...textAndImageMeta,
        ...socialMediaMeta,
        {
          itemprop: 'name',
          content: COMPANY_NAME,
        },
      ],
      link: [...this.alternateLinks, ...additionalLinks],
      script: homeSchemaScript,
      __dangerouslyDisableSanitizers: ['script'],
    }
  },

  computed: {
    ...mapGetters({
      isSkinnyBannerAvailable: 'countryConfig/isSkinnyBannerAvailable',
      country: 'config/country',
      baseURL: 'config/baseURL',
      warrantyDuration: 'config/warrantyDuration',
      coolingOffDays: 'config/coolingOffDays',
    }),
    hasSkinnyBanner() {
      return this.isSkinnyBannerAvailable('home')
    },
  },

  methods: {
    componentReference(block) {
      // "block.props?.identifier" corresponds to a legacy component still in use
      return block.props?.identifier || block.type
    },

    isFullWidth(blockType) {
      return fullWidthComponents.includes(blockType)
    },

    getBlockPosition(index) {
      return index + 1
    },

    sendTracking({ type, title }) {
      const { name, params } = this.$route

      trackClick({
        zone: valueBuilder([name, params.pageName, params.pageCategory]),
        name: title,
        value: type,
      })
    },

    sendTrackingProductClickData(trackingData) {
      if (!isEmpty(trackingData)) {
        trackProductClick(trackingData)
      }
    },
  },
}
