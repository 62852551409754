import best from '../components/Best/store'

import list from './modules/list'
import summary from './modules/summary'

export default {
  namespaced: true,
  modules: {
    best,
    summary,
    list,
  },
}
