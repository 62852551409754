export const PAYMENT_SERVICE_PROVIDERS = {
  ADYEN_MARKETPAY: 'adyen_marketpay',
  ADYEN_PAYIN_PAYOUT: 'adyen',
  PAYPAL: 'paypal',
  PROCESSOUT: 'processout',
}

export const PAYMENT_GROUPS = {
  AFFIRM: 'affirm',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  ONEY: 'oney',
  PAYPAL: 'paypal',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
}

export const PAYMENT_METHODS = {
  AFFIRM: 'affirm',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  ONEY10X: 'oney10x',
  ONEY12X: 'oney12x',
  ONEY3X: 'oney3x',
  ONEY4X: 'oney4x',
  ONEY6X: 'oney6x',
  PAYPAL: 'paypal',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
}

export const PAYMENT_NETWORKS = {
  AFFIRM: 'affirm',
  AMERICAN_EXPRESS: 'amex',
  APPLE_PAY: 'apple_pay',
  BANCONTACT: 'bancontact',
  CARTES_BANCAIRES: 'cb',
  DISCOVER: 'discover',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  KLARNA: 'klarna',
  MASTERCARD: 'mastercard',
  ONEY: 'oney',
  PAYPAL: 'paypal',
  POSTEPAY: 'postepay',
  PROCESSOUT: 'processout',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
  VISA: 'visa',
}

export const PAYMENT_METHOD_ERRORS = {
  DISABLED: 'not_activated',
  COUNTRY_DISABLED: 'country_not_activated',
  ONLY_MERCHANT_INCOMPATIBLE: 'merchant_not_valid',
  ONE_MERCHANT_INCOMPATIBLE: 'one_merchant_is_not_valid',
  MANY_MERCHANTS_INCOMPATIBLE: 'incompatible_merchants',
  PRICE_INCOMPATIBLE: 'price_not_eligible',
  INSURANCE_INCOMPATIBLE: 'insurance_subscription',
}

export const PAYMENT_VIRTUAL_CARD_ERRORS = {
  CANCEL: 'VIRTUAL_CARD_CANCEL',
  INVALID: 'VIRTUAL_CARD_INVALID',
  ERROR: 'VIRTUAL_CARD_ERROR',
  FAILURE: 'VIRTUAL_CARD_FAILURE',
}

export const PAYMENT_ICON_PATHS = {
  GROUPS: '/img/payment/groups',
  METHODS: '/img/payment/methods',
  NETWORKS: '/img/payment/networks',
}

export const PAYMENT_INTEGRATION_TYPES = {
  ADYEN_CHECKOUT_API: 'adyen_checkout_api',
  HPP: 'hpp',
  NATIVE: 'native',
}
