import { COOKIES, DEFAULT_STATE, TYPES } from '@config/constants'
import { isUndefined } from '@core/helpers'
import { setCookie } from '@core/helpers/cookies'
import { saveCookiesSettings } from '@http/endpoints'
import retry from '@http/retry'
import { trackGDPR } from '@tracking/events'

import config from './config'

export default {
  namespaced: true,
  state() {
    return {
      ...DEFAULT_STATE,
      isOpen: false,
    }
  },
  getters: {
    all: ({ isOpen, ...state }) => state,
    isOpen: (state) => state.isOpen,
  },
  mutations: {
    set(state, { key, value }) {
      state[key] = value
    },
  },
  actions: {
    closeModal({ commit }) {
      commit('set', { key: 'isOpen', value: false })
    },
    load({ commit }, { legalCookies }) {
      legalCookies.forEach(({ key, value }) => {
        commit('set', { key, value })
      })

      const legalCookiesNames = legalCookies.map(({ key }) => key)
      const isOpen = Object.keys(COOKIES).some(
        (cookieName) => !legalCookiesNames.includes(cookieName),
      )
      commit('set', { key: 'isOpen', value: isOpen })
    },

    setSessionCookies({ commit }, choices) {
      Object.entries(COOKIES).forEach(([key, cookie]) => {
        if (!isUndefined(choices[key])) {
          const value = choices[key]
          commit('set', {
            key,
            value,
          })

          setCookie(cookie, value, config)
        }
      })
    },

    async save(
      { state = DEFAULT_STATE, dispatch, rootGetters },
      settings = {},
    ) {
      try {
        const identifier = rootGetters['config/visitorId']

        const choices = Object.keys(COOKIES).reduce(
          (acc, key) => ({
            ...acc,
            [key]: isUndefined(settings[key]) ? state[key] : settings[key],
          }),
          { [TYPES.FUNCTIONAL]: true },
        )

        await retry(() =>
          dispatch(
            'http/request',
            {
              request: saveCookiesSettings,
              body: {
                identifier,
                choices,
              },
            },
            {
              root: true,
            },
          ),
        )

        trackGDPR(choices)

        dispatch('setSessionCookies', choices)
      } catch {
        throw new Error('Retry failed')
      }
    },
  },
}
