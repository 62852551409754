export default {
  mileShort: {
    id: 'formatting_unit_mile_short',
    defaultMessage: '{distance} mi',
  },
  footShort: {
    id: 'formatting_unit_foot_short',
    defaultMessage: '{distance} ft',
  },
  meterShort: {
    id: 'formatting_unit_meter_short',
    defaultMessage: '{distance} m',
  },
  kilometerShort: {
    id: 'formatting_unit_kilometer_short',
    defaultMessage: '{distance} km',
  },
}
