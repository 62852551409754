import Rox from 'rox-browser'

import { flagsConfig, refreshFlags } from './config'
import { FLAG_TYPES } from './constants'
import { createConfig } from './methods'

export const ABTEST_GLOBAL_VARIABLE = 'abtest'

const resolveExperiments = async ({ $config, store }) => {
  const config = createConfig(FLAG_TYPES, Rox, flagsConfig)

  Rox.register(config)
  Rox.setCustomStringProperty('country', store.getters['config/country'])

  await Rox.setup($config.CLOUDBEES_KEY, {
    platform: 'Browser',
    analytics: { enable: false },
  })

  return refreshFlags(config)
}

export default async ({ $config, store }) => {
  if (!$config.FF_CLOUDBEES_ENABLED) return

  await resolveExperiments({ $config, store })
}
