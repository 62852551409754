export const PAGE_VIEW = 'page_viewed'
export const CLICK = 'clicked'
export const PRODUCT_IMPRESSION = 'product_impression'
export const REVIEW_IMPRESSION = 'review_impression'
export const REVIEW_BLOCK_IMPRESSION = 'review_block_impression'
export const PURCHASE_PRODUCT = 'purchase_product'
export const PURCHASE_ORDER = 'purchase_order'
export const ADD_TO_CART = 'add_to_cart'
export const PRODUCT_CLICK = 'product_clicked'
export const FORM_SUBMIT = 'info_submitted'
export const PROMOTION_CLICK = 'promotion_clicked'
export const PROMOTION_IMPRESSION = 'promotion_impression'
export const MODAL = 'modal_viewed'
export const PRODUCT = 'product'
export const CHECKOUT_PAGE_VIEW = 'checkout_page_viewed'

// Care – Action Bar
export const ACTION_BAR_REFUND_ACTION = 'action_bar_refund_action'
export const ACTION_BAR_REFUND_STEP_SUBMIT_REFUND =
  'action_bar_refund_step_submit_refund'
export const ACTION_BAR_TRY_AGAIN_TO_FETCH_ACTIONS =
  'action_bar_try_again_to_fetch_actions'
export const ACTION_BAR_BACK = 'action_bar_back'
export const ACTION_BAR_EXIT = 'action_bar_exit'
export const ACTION_BAR_RETURN_LABEL_GENERATION_ACTION =
  'action_bar_return_label_generation_action'
export const ACTION_BAR_RETURN_LABEL_GENERATION_STEP_SUBMIT =
  'action_bar_return_label_generation_step_submit'
export const ACTION_BAR_OPEN_CLAIM = 'action_bar_open_claim'
export const ACTION_BAR_CLOSE_CLAIM = 'action_bar_close_claim'
export const ACTION_BAR_SEND_MESSAGE_ACTION = 'action_bar_send_message_action'
