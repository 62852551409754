import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    // Client Dashboard.
    {
      path: 'dashboard',
      name: ROUTES.DASHBOARD.SELF,
      component: () => dynamicImport(import('./Dashboard.vue')),
      redirect: (to) => ({ ...to, name: ROUTES.DASHBOARD.MY_ORDERS.SELF }),
      children: [
        {
          path: 'orders',
          name: ROUTES.DASHBOARD.MY_ORDERS.SELF,
          component: () => dynamicImport(import('@my-orders/Orders')),
        },
        {
          path: 'sales',
          name: ROUTES.DASHBOARD.MY_SALES,
          component: () => dynamicImport(import('@buyback/pages/MySales')),
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/cancel',
          name: ROUTES.DASHBOARD.MY_ORDERS.CANCEL,
          component: () =>
            dynamicImport(import('@my-orders/components/CancelOrder')),
          props: true,
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/withdraw',
          name: ROUTES.DASHBOARD.MY_ORDERS.WITHDRAW.ORDER,
          component: () =>
            dynamicImport(
              import(
                '@my-orders/components/Withdraw/ConnectedOrderWithdraw.vue'
              ),
            ),
          props: true,
        },
        {
          path: 'orders/:orderId/orderlines/:orderlineId/insurances/withdraw',
          name: ROUTES.DASHBOARD.MY_ORDERS.WITHDRAW.INSURANCES,
          component: () =>
            dynamicImport(
              import(
                '@my-orders/components/Withdraw/ConnectedInsuranceWithdraw.vue'
              ),
            ),
          props: true,
        },
        {
          path: 'profile',
          name: ROUTES.DASHBOARD.KYC.PROFILE,
          component: () =>
            dynamicImport(import('@kyc/Profile/ConnectedProfile.vue')),
        },
        {
          path: 'refer-friend',
          name: ROUTES.DASHBOARD.KYC.REFER_FRIEND,
          component: () =>
            dynamicImport(import('@kyc/ReferFriend/Landing.vue')),
        },
        {
          path: 'others',
          name: ROUTES.DASHBOARD.KYC.OTHER,
          meta: { rollout: false },
        },
      ],
      meta: {
        auth: { required: true },
      },
    },
    {
      name: ROUTES.REFERRAL_PROGRAM_SHARING_ROUTE,
      path: 'refer-friend-welcome',
      meta: { rollout: false },
    },
  ]
}
