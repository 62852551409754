import { COUNTRIES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const buybackRoutes = [
  {
    name: ROUTES.BUYBACK.DEVICE_FORM,
    path: 'buyback-funnel/device/:slug/:id',
    component: () => dynamicImport(import('@buyback/pages/StepDevice')),
  },
  {
    name: ROUTES.BUYBACK.OFFER,
    path: 'buyback-funnel/device/:slug/:id/offer/:offerId',
    component: () => dynamicImport(import('@buyback/pages/OfferStep')),
    props: true,
  },
  {
    name: ROUTES.BUYBACK.NO_OFFER,
    path: 'buyback-funnel/device/:slug/:id/no-offer',
    component: () => dynamicImport(import('@buyback/pages/NoOffer')),
  },
  {
    name: ROUTES.BUYBACK.BANK,
    path: 'buyback-funnel/device/:slug/:id/offer/:offerId/bank',
    component: () => dynamicImport(import('@buyback/pages/StepBank')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    name: ROUTES.BUYBACK.ADDRESS,
    path: 'buyback-funnel/device/:slug/:id/offer/:offerId/address',
    component: () => dynamicImport(import('@buyback/pages/StepAddress')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    name: ROUTES.BUYBACK.SHIPPING,
    path: 'buyback-funnel/device/:slug/:id/offer/:offerId/shipping',
    component: () => dynamicImport(import('@buyback/pages/StepShipping')),
    meta: {
      auth: {
        required: true,
      },
    },
  },
  {
    name: ROUTES.BUYBACK.CONFIRMATION,
    path: 'buyback-funnel/confirmation/:id',
    component: () => dynamicImport(import('@buyback/pages/StepConfirmation')),
  },
]

export default ({ country }) => {
  const shouldAddBuybackRoutes = [
    COUNTRIES.DE,
    COUNTRIES.FR,
    COUNTRIES.US,
  ].includes(country)

  return [
    {
      path: 'dashboard/sourcing/orders/:id/',
      name: ROUTES.DASHBOARD.SOURCING_TRACK_SALE,
      meta: { rollout: false },
    },
    ...insertIf(shouldAddBuybackRoutes, buybackRoutes),
  ]
}
