
import {
  IconAvatar,
  IconPackage,
  IconPower,
  IconSwap,
  RevAvatar,
  RevLink,
} from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import Logout from '@auth/components/Logout'
import CatIconAvatar from '@campaigns/cat/avatar/CatIconAvatar.vue'
import ClickOutside from '@core/directives/ClickOutside'
import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
} from '@navigation/constant'
import { ROUTES } from '@router'
import { trackClick } from '@tracking/events'

import translations from './UserIconAndSubMenu.translations'

const AVATAR_SIZE_MEDIUM = 'medium'
const AVATAR_SIZE_SMALL = 'small'
const AVATAR_COLOR = 'purple'

export default {
  components: {
    RevLink,
    RevAvatar,
    Logout,
    IconAvatar,
    CatIconAvatar,
    IconPackage,
    IconPower,
    IconSwap,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      isVisible: false,
    }
  },

  computed: {
    translations: () => translations,
    ROUTES: () => ROUTES,
    ...mapGetters({
      isAuthenticated: 'auth/status',
      firstName: 'user/firstName',
      breakpoint: 'breakpoint',
      isBuyBackEnabled: 'config/isBuyBackEnabled',
      country: 'config/country',
    }),
    HEADER_TRACKING_NAME: () => HEADER_TRACKING_NAME,
    HEADER_TRACKING_ZONE: () => HEADER_TRACKING_ZONE,
    iconAvatarLink() {
      return this.isAuthenticated ? null : { name: ROUTES.AUTH.REGISTER }
    },
    trackingName() {
      return this.isAuthenticated
        ? this.HEADER_TRACKING_NAME.DASHBOARD
        : this.HEADER_TRACKING_NAME.REGISTER
    },
    firstLetterOfName() {
      if (this.firstName) {
        return this.firstName.charAt(0)
      }

      return ''
    },
    avatarColor() {
      return AVATAR_COLOR
    },
    avatarSize() {
      return this.breakpoint > 1 ? AVATAR_SIZE_MEDIUM : AVATAR_SIZE_SMALL
    },
    isCatCampaignEnabled() {
      return this.$config.FF_CAT_CAMPAIGN_BY_COUNTRY?.includes(this.country)
    },
  },

  methods: {
    toggleSubMenu() {
      if (this.isAuthenticated) {
        this.isVisible = !this.isVisible
      }
    },
    closeSubMenu() {
      this.isVisible = false
    },
    handleClick(name) {
      this.closeSubMenu()
      trackClick({
        zone: this.HEADER_TRACKING_ZONE,
        name,
      })
    },
  },
}
