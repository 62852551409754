const getDefaultState = () => ({
  error: null,
})

const getErrorContent = ({ err, error, mail, id }) => {
  if (err.status_code !== 403 && err.status_code !== 401) {
    error({
      statusCode: err.status_code,
      message: err.message,
    })

    return null
  }

  return {
    statusCode: err.status_code,
    mail,
    id: Number(id),
  }
}

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    get: (state) => state.error,
  },
  mutations: {
    set: (state, content) => {
      state.error = getErrorContent(content)
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    set: ({ commit }, options) => commit('set', options),
    reset: ({ commit }) => commit('reset'),
  },
}
