
import { RevSkeleton } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import { formatDateToYYYYMMDD } from '@core/helpers/date'
import { fetchInstallmentSimulation } from '@http/endpoints'

import { paymentMethodInstallmentCount } from '../../methods'

import translations from './InstallmentSimulation.translations'

const DATE_FORMAT = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
}

export default {
  components: {
    RevSkeleton,
  },

  props: {
    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },

    /**
     * @typedef {import('@payment').PaymentMethod}
     */
    paymentMethod: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    simulations: {},
  }),

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),

    translations: () => translations,

    hasInstallments() {
      return this.installmentCount > 0
    },

    installmentCount() {
      return isEmpty(this.paymentMethod)
        ? 0
        : paymentMethodInstallmentCount(this.paymentMethod.bmCode)
    },

    isLoading() {
      return isEmpty(this.simulation)
    },

    lines() {
      if (this.isLoading) {
        return Array.from({ length: this.installmentCount }).map((_, i) => i)
      }

      return [
        {
          date: this.$t.date(new Date(), DATE_FORMAT),
          translation: this.$t(translations.downAmount, {
            amount: this.$t.price(this.simulation.downPrice),
          }),
        },
        ...this.simulation.installments.map((installment, index) => ({
          date: this.$t.date(new Date(installment.date), DATE_FORMAT),
          translation: this.$t(translations.wording, {
            amount: this.$t.price(installment.price),
            number: index + 1,
          }),
        })),
      ]
    },

    requiresPayment() {
      return parseFloat(this.basePrice.amount) > 0
    },

    simulationKey() {
      return `${this.basePrice.amount}/${this.installmentCount}`
    },

    simulation() {
      const simulation = this.simulations[this.simulationKey]
      const amountToPrice = (amount) => ({
        amount: String(amount),
        currency: this.basePrice.currency,
      })

      return isEmpty(simulation)
        ? simulation
        : {
            ...simulation,
            costPrice: amountToPrice(simulation.cost),
            downPrice: amountToPrice(simulation.downAmount),
            totalPrice: amountToPrice(simulation.totalAmount),
            installments: (simulation.installments || []).map(
              (installment) => ({
                ...installment,
                date: formatDateToYYYYMMDD(installment.collectionDate),
                price: amountToPrice(installment.amount),
              }),
            ),
          }
    },

    shouldDisplayFallback() {
      return !this.shouldDisplayInstallments && !isEmpty(this.$slots.fallback)
    },

    shouldDisplayInstallments() {
      return this.hasInstallments && this.requiresPayment
    },

    shouldDisplayWrapper() {
      return this.shouldDisplayInstallments || this.shouldDisplayFallback
    },
  },

  watch: {
    simulationKey: {
      immediate: true,
      async handler() {
        this.fetchSimulation()
      },
    },
  },

  methods: {
    async fetchSimulation() {
      if (
        this.hasInstallments &&
        !Object.keys(this.simulations).includes(this.simulationKey)
      ) {
        this.$set(this.simulations, this.simulationKey, null)

        const { payload } = await this.$store.dispatch('http/request', {
          request: fetchInstallmentSimulation,
          queryParams: {
            payment_method: this.paymentMethod.bmCode,
            amount: this.basePrice.amount,
            nb_installments: this.installmentCount,
            country_code: this.country,
          },
        })

        this.$set(this.simulations, this.simulationKey, payload)
      }
    },
  },
}
