import claims from './modules/claims/store'
import productReturn from './modules/productReturn/productReturn'
import resolutions from './modules/resolutions/store'

export default {
  namespaced: true,
  modules: {
    claims,
    productReturn,
    resolutions,
  },
}
