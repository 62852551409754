import { dangerouslyGetRuntimeConfig } from '@config/variables'
import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const { FF_DIAGNOSIS_V2 } = dangerouslyGetRuntimeConfig()

export default () => {
  const resolutionFlowRoute = [
    // Claim creation
    {
      path: 'dashboard/claim/new/:orderlineId/:tilesIds',
      name: ROUTES.DASHBOARD.RESOLUTION.SELF,
      component: () => dynamicImport(import('@diagnosis/ResolutionFlow')),
      meta: {
        auth: {
          required: true,
        },
      },
    },
    // Contact form confirmation (remote assistance), with the legacy `customerRequestId`
    {
      path: 'dashboard/claim/confirmation/:customerRequestId?',
      name: ROUTES.DASHBOARD.RESOLUTION.CONTACT_CONFIRMATION,
      component: () =>
        dynamicImport(
          import('@diagnosis/ResolutionFlow/ContactConfirmationPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
    // Contact form confirmation (remote assistance)
    {
      path: 'dashboard/resolution/confirmation/:resolutionId?',
      name: ROUTES.DASHBOARD.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
      component: () =>
        dynamicImport(
          import('@diagnosis/ResolutionFlow/ContactConfirmationPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
    // Return funnel confirmation, with the legacy `customerRequestId`
    {
      path: 'dashboard/claim/return/confirmation/:customerRequestId',
      name: ROUTES.DASHBOARD.RESOLUTION.RETURN_CONFIRMATION,
      component: () =>
        dynamicImport(
          import('@diagnosis/ResolutionFlow/ReturnConfirmationPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
    // Return funnel confirmation
    {
      path: 'dashboard/resolution/return/confirmation/:resolutionId',
      name: ROUTES.DASHBOARD.RESOLUTION.RESOLUTION_RETURN_CONFIRMATION,
      component: () =>
        dynamicImport(
          import('@diagnosis/ResolutionFlow/ReturnConfirmationPage.vue'),
        ),
      meta: {
        auth: {
          required: true,
        },
      },
    },
  ]

  if (FF_DIAGNOSIS_V2) {
    return [
      ...resolutionFlowRoute,
      {
        path: 'dashboard/diagnosis/new/:orderlineId/:customerIssues?',
        name: ROUTES.DASHBOARD.DIAGNOSIS.SELF,
        component: () =>
          dynamicImport(import('@diagnosis/DiagnosisFlow/DiagnosisFlow.vue')),
        meta: {
          auth: {
            required: true,
          },
        },
      },
    ]
  }

  return resolutionFlowRoute
}
