import { APP_NAME } from '@config/constants'

export const LOG_LEVELS = {
  ERROR: 0,
  INFO: 1,
  DEBUG: 2,
}

export const LOG_LEVELS_NAME = {
  INFO: 'info',
  ERROR: 'error',
  DEBUG: 'debug',
}

export const DD_SERVICE = process.env.DD_SERVICE_NAME || APP_NAME

export const DD_BROWSER_LOGS_CLIENT_TOKEN =
  'pubdc168fd6fb5f2bf245b09ff737b0112b'

export const DD_SITE = 'datadoghq.com'
