import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { parse } from 'query-string'

/**
 * Helper method to get the default export from an `import()` call.
 */
export const dynamicImport = (promise) => promise.then((m) => m.default || m)

/**
 * process.server & client are set by Nuxt and should be used before these methods
 * because it is removing the useless code from the build. (the content of a process.server condition
 * on a client build is simply removed)
 *
 * However, use these helpers when you need to detect the browser / node if:
 * - You want to detect it on runtime
 * - process.server/client is not initialized yet
 */
export const isBrowser = () =>
  typeof window !== 'undefined' && typeof window.document !== 'undefined'

export const isNode = () =>
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null

/**
 * Checks if `value` is undefined.
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isUndefined = (value) => typeof value === 'undefined'

/**
 * Checks if `value` is `true` or `false`.
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isBoolean = (value) => typeof value === 'boolean'

/**
 * Checks if `value` is a string
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isString = (value) => typeof value === 'string'

/**
 * Checks if `value` is an array.
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isArray = (value) => Array.isArray(value)

/**
 * Checks if `value` is a number.
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isNumber = (value) => {
  // While the below predicate correctly filters out most of the variables,
  // it still responds positively for strings, hence this pre-check.
  if (isString(value)) return false

  try {
    return Number.isNaN(parseFloat(value)) === false && Number.isFinite(value)
  } catch (_) {
    // The predicate above throws for symbols (and possibly some other values).
    return false
  }
}

/**
 * Checks if `value` is a function.
 *
 * @param {*} value
 * @return {Boolean}
 */
export const isFunction = (value) => {
  return typeof value === 'function'
}

/**
 * @param {number} value
 * @returns {boolean} Whether `value` is a positive integer
 */
export const isPositiveInteger = (value) => Number.isInteger(value) && value > 0

/**
 * Returns an object composed from key-value `pairs`.
 *
 * @param {[any, any][]} pairs
 * @return Object
 */
export const fromPairs = (pairs) => {
  return pairs.reduce((data, [key, value]) => ({ ...data, [key]: value }), {})
}

/**
 * Map over the `collection` values, and transform them using the `mapper` function.
 *
 * @param {Object} collection
 * @param {Function} mapper
 * @return {Object}
 */
export const mapValues = (collection, mapper) => {
  const pairs = Object.entries(collection).map(([key, value]) => [
    key,
    mapper(value, key, collection),
  ])

  return fromPairs(pairs)
}

/**
 * Checks if `predicate` returns truthy for any element of `collection`.
 *
 * @param {Object} collection
 * @param {Function} predicate
 * @return {Boolean}
 */
export const someValues = (collection, predicate) => {
  return Object.values(collection).some(predicate)
}

/**
 * Split `array` into multiple arrays of length `size`.
 *
 * @param {Array} array
 * @param {Number} size
 * @return {Array}
 */
export const chunk = (array, size) => {
  if (!array) return []

  const firstChunk = array.slice(0, size)
  if (!firstChunk.length) return array

  return [firstChunk, ...chunk(array.slice(size, array.length), size)]
}

export const SLEEP_RESPONSE = 'sleep'
/**
 * Wait until a given `number`.
 *
 * @param {Number} time
 * @return {Promise}
 */
export const sleep = (time) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(SLEEP_RESPONSE), time)
  })

/**
 * Format `size` to human readable size units (Gb, Mb, Kb…)
 *
 * @param {Number} size
 * @return {String}
 */
export const formatFileSize = (size) => {
  const GIGABYTES_QUOTIENT = 1000000000
  const MEGABYTES_QUOTIENT = 1000000
  const KILOBYTES_QUOTIENT = 1000

  const gigabytes = size / GIGABYTES_QUOTIENT
  if (gigabytes > 1) return `${gigabytes.toFixed(2)} GB`

  const megabytes = size / MEGABYTES_QUOTIENT
  if (megabytes > 1) return `${megabytes.toFixed(2)} MB`

  const kilobytes = size / KILOBYTES_QUOTIENT
  if (kilobytes > 1) return `${kilobytes.toFixed(2)} KB`

  return `${size} Bytes`
}

// Warning: Turn Maps and Set into Array
export const objClean = (obj) => JSON.parse(JSON.stringify(obj))

/**
 * Check if array is empty
 *
 * @param {Array} array
 * @return {Boolean}
 */

const isArrayEmpty = (array) => isArray(array) && !array.length

/**
 * Filter the properties of an object
 *
 * @param {Object} object the object to filter
 * @param {Function} filterFn the filter function
 * @return {Object} the filtered object according to the filter function passed in parameters
 */

export const filterObjectProperties = (object, filterFn) => {
  if (isEmpty(object)) {
    return {}
  }

  return Object.keys(object)
    .filter(filterFn)
    .reduce(
      (final, key) => ({
        ...final,
        [key]: object[key],
      }),
      {},
    )
}

/**
 * Remove empty values and empty arrays in object
 *
 * @param {Object} object
 * @return {Object}
 */
export const removeEmptyValuesInObject = (object) => {
  if (!object) {
    return object
  }

  return pickBy(object, (value) => {
    return (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !isArrayEmpty(value)
    )
  })
}

export const replaceUrlParameters = (endpoint, params = {}) => {
  return Object.entries(objClean(params))
    .reduce(
      (url, [key, value]) => url.replace(new RegExp(`:${key}[?]?`, 'g'), value),
      endpoint,
    )
    .replace(/:.+[?]?/g, '')
}

/**
 * Format raw size to return human readable one
 *
 * @param {Number} size
 * @param {string} byteUnit
 * @param {Number} divider Change this to 1000 if you want to use SI unit.
 * @return {String} human readable size
 */
export const humanReadableSize = (size, byteUnit, divider = 1024) => {
  let bytes = size

  if (Math.abs(bytes) < divider) {
    return `${bytes}${byteUnit}`
  }

  const units = [
    `k${byteUnit}`,
    `M${byteUnit}`,
    `G${byteUnit}`,
    `T${byteUnit}`,
    `P${byteUnit}`,
    `E${byteUnit}`,
    `Z${byteUnit}`,
    `Y${byteUnit}`,
  ]

  let unit = -1
  do {
    bytes /= divider
    unit += 1
  } while (Math.abs(bytes) >= divider && unit < units.length - 1)

  return `${bytes.toFixed(2)}${units[unit]}`
}

/**
 *
 *
 * @param {Boolean} condition The condition whether we return the element or not
 * @param {Object|Array} element The element to return if the condition is true.
 * @return {Object|Array} Returns the element or an empty value of the type provided ({} or [])
 */
export const insertIf = (condition, element) => {
  const defaultValue = Array.isArray(element) ? [] : {}

  return condition ? element : defaultValue
}

// Inspired by https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
/* eslint-disable no-bitwise */
export const hashObjects = (...objects) =>
  objects
    .map((o) => JSON.stringify(o))
    .join()
    .split('')
    .reduce((a, b) => {
      const hash = (a << 5) - a + b.charCodeAt(0)

      return hash & hash
    }, 0)
    .toString()
/* eslint-enable no-bitwise */

const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?\/?)$/
const isIPAddress = (ipaddress) => IP_ADDRESS_REGEX.test(ipaddress)

/**
 * Split hostname to get domain, subdomain, and extension
 * @param {String} hostname {protocol}://{subdomain}.{domain}.{extension}:{port}
 * @return {Object}
 */
export const splitHostname = (hostname) => {
  const hostnameWithoutProtocol = hostname.replace(/(^\w+:|^)\/\//, '')
  const hostnameWithoutPort = hostnameWithoutProtocol.replace(/:.*/, '')

  if (isIPAddress(hostnameWithoutPort)) {
    return { domain: hostname }
  }

  const regexParse = /([a-z-0-9]{2,63}).([a-z.]{2,6})$/
  const [, domain, extension] = regexParse.exec(hostnameWithoutPort)

  return {
    domain,
    extension,
    subdomain: hostname.replace(`${domain}.${extension}`, '').slice(0, -1),
  }
}

/**
 * Remove the full qualification of the next URL sent back by the backend.
 * The backend has to send the URL fully qualified, they can't do anything else.
 *
 * (https://imperatorz.slack.com/archives/GDVR92EJH/p1568279029011600?thread_ts=1568275096.001800&cid=GDVR92EJH)
 * (TLDR: "c'est bien un truc par défaut qu'on utilise qui vient de DRF
 * (https://www.django-rest-framework.org/api-guide/pagination/#pagenumberpagination)
 * et on l'utilise déjà partout où on fait de la pagination")
 *
 * So get rid of the "protocol://host" part to only keep the path to the endpoint.
 */
export const computeNextPagePath = (url) => {
  if (isEmpty(url)) {
    return null
  }

  return !url.includes('/bm/') ? url : `/bm/${url.split('/bm/')[1]}`
}

/**
 * Convert an floating amount to a cents amount.
 * This helpers is made to avoid some calculations errors due to JavaScript.
 * For example: 160.9 * 100 = 16090 but 160.8 * 100 = 16080.0000000002.
 * So instead, split the integer and the decimal part (by converting the amount
 * to string), multiply the integer part by 100 and the decimal part by 10 and
 * add them together.
 *
 * @param  {number} amount The amount to convert into cents.
 * @return {number} The amount converted to cents.
 *
 * @throws When the provided amount is not a number (integer or float).
 */
export const amountToCents = (amount) => {
  if (typeof amount !== 'number') {
    throw new Error(
      `You should provide a numeric amount (${amount}, ${typeof amount} given)!`,
    )
  }

  const strAmount = String(amount)
  const [integer = '0', decimal = '0'] = strAmount.split('.')

  const float = decimal.length < 2 ? `${decimal}0` : decimal.substr(0, 2)

  return (
    parseInt(integer, 10) * 100 +
    parseInt(float.charAt(0), 10) * 10 +
    parseInt(float.charAt(1), 10)
  )
}

export const centsToAmount = (cents) => {
  if (typeof cents !== 'number') {
    throw new Error(
      `You should provide a numeric cents amount (${cents}, ${typeof cents} given)!`,
    )
  }

  return cents / 100
}

export const fromEntries = (map = new Map()) => {
  if ('fromEntries' in Object) {
    return Object.fromEntries(map)
  }

  // Can't use logger here because of circular deps
  if (process.env.NODE_ENV !== 'test') {
    console.error('[fromEntries] Method not supported')
  }

  // careful, Maps can have non-String keys; object literals can't.
  return Array.from(map).reduce(
    (obj, [key, value]) => ({
      ...obj,
      [key]: value,
    }),
    {},
  )
}

/**
 * Split an object into two, based on a predicate.
 * Read more: https://github.com/lodash/lodash/issues/3172
 * @param {Object<any, any>} source
 * @param {(sourceElement: any) => boolean} predicate
 * @return {[Object<any, any>, Object<any, any>]}
 */
export function partitionBy(source, predicate) {
  return Object.entries(source).reduce(
    (result, [key, value]) => {
      // Reassigning a reducer accumulator is fine.
      // eslint-disable-next-line no-param-reassign
      result[predicate(value, key, source) ? 0 : 1][key] = value

      return result
    },
    [{}, {}],
  )
}

/**
 * Formats a string by replacing all space characters ( ) to hyphens (-)
 * @param {String} text The string to replace
 * @return {String} A new string with "-" instead of " "
 */
export const spacesToHyphens = (text) => {
  return text.replace(/\s+/g, '-')
}

/**
 * Formats a string by replacing all hyphens (-) to space characters ( )
 * @param {String} text The string to replace
 * @return {String} A new string with " " instead of "-"
 */
export const hyphensToSpaces = (text) => {
  return text.replace(/-/g, ' ')
}

/**
 * @param {Object[]} collection A collection of objects having a `property` property.
 * @param {string} property Property name. Uses `get` so dotted notation can be
 * used to access deeply nested objects.
 * @param {string[]} valuesOrder Ordered values of `collection[*][property]`
 * @returns {Object[]} A new array containing all elements from `collection`,
 * ordered by their `property` value, as defined in `valuesOrder` collection.
 * Items whose property has unknown value (not in `valuesOrder`) are preserved,
 * but returned at the end of the collection.
 */
export function sortByProperty(collection, property, valuesOrder) {
  function indexOf(item) {
    const value = get(item, property, undefined)
    const index = valuesOrder.indexOf(value)

    return index === -1 ? valuesOrder.length : index
  }

  return collection.sort((itemA, itemB) => indexOf(itemA) - indexOf(itemB))
}

/**
 *
 *
 * @param {Array} array of objects
 * @param {string} property (key) of object that we want to sort by alphabetical
 * @return {Array} Returns an array sorted by alphabetical on a specific property
 */
export const sortByAlphabetical = (array, property) =>
  array.sort((a, b) => {
    const aInLowerCase = a[property].toLowerCase()
    const bInLowerCase = b[property].toLowerCase()
    if (aInLowerCase < bInLowerCase) return -1
    if (aInLowerCase > bInLowerCase) return 1

    return 0
  })

export const parsedHash = (hash) => {
  return Object.entries(parse(hash)).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? value : [value],
    }),
    {},
  )
}

/**
 * Format the JSON schema.org data to create the JSON LD scripts to inject
 * @param {Object} jsonLds
 * @return {Array}
 */
export const prepareJsonLdScript = (jsonLds) => {
  const transformed = mapValues(jsonLds, (value) => ({
    innerHTML: JSON.stringify(value, null, 4),
    type: 'application/ld+json',
  }))

  return Object.values(transformed)
}

/**
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#Issue_with_plain_JSON.stringify_for_use_as_JavaScript
 * @param {string}
 * @return {string}
 */

export const friendlyJSONStringify = (string) => {
  return JSON.stringify(string)
    .replace(/\u2028/g, '\\u2028')
    .replace(/\u2029/g, '\\u2029')
}

/**
 * This should be refactored once a UNIT system is put in place.
 * @param {number} grams
 * @param {string} unit
 * @return {number}
 */
export const gramsToUnit = (grams, unit) => {
  const RATIO = 1000

  switch (unit) {
    case 'kg':
      return Math.round(grams / RATIO)
    case 'mt':
      return Math.round(grams / RATIO / RATIO)
    default:
      return grams
  }
}

const TOTAL_OF_MINUTES_IN_ONE_HOUR = 60
const NUMBER_OF_HOURS_IN_ONE_DAY = 24

export const minutesToHours = (minutes) =>
  Math.round(minutes / TOTAL_OF_MINUTES_IN_ONE_HOUR)

export const minutesToDaysOrHours = (minutes) => {
  const minutesConvertedIntoHour = minutesToHours(minutes)

  return minutesConvertedIntoHour < NUMBER_OF_HOURS_IN_ONE_DAY
    ? `H -${minutesConvertedIntoHour}`
    : `J -${Math.round(minutesConvertedIntoHour / NUMBER_OF_HOURS_IN_ONE_DAY)}`
}
