import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default ({ country, config }) => {
  const { FF_MILITARY_DISCOUNT_BY_COUNTRY } = config
  const isMilitaryDiscountEnabledForCurrentCountry =
    FF_MILITARY_DISCOUNT_BY_COUNTRY.includes(country)

  if (!isMilitaryDiscountEnabledForCurrentCountry) {
    return []
  }

  return [
    {
      path: 'military-discount',
      name: ROUTES.MILITARY_DISCOUNT,
      component: () => dynamicImport(import('./MilitaryDiscount.vue')),
    },
  ]
}
