import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { HEADER_CFWORKER_EXPERIMENTS } from '@http/headers'
import logger from '@logger'

const USER_REQUEST_KEY = 'user_request'

// We are mutating the request node object to register the core values of the application and use it in other middleware / plugins.
export const saveUserContextOnRequest = (req, context) => {
  // eslint-disable-next-line no-param-reassign
  req[USER_REQUEST_KEY] = {
    ...req[USER_REQUEST_KEY],
    ...context,
  }
}

export const hasUserContextOnRequest = (req) => {
  return !isEmpty(req[USER_REQUEST_KEY])
}

export const getUserContextFromRequest = (req, key) => {
  const context = get(req, USER_REQUEST_KEY, {})

  if (isEmpty(key)) {
    return context
  }

  return context[key]
}

/**
 * @param {import('express').Request} request
 */
export const getExperimentsFromRequest = (request) => {
  const header = get(request.headers, HEADER_CFWORKER_EXPERIMENTS, '{}')

  try {
    return JSON.parse(header)
  } catch (error) {
    const message = `Failed to parse ${HEADER_CFWORKER_EXPERIMENTS} header`
    logger.error(error, message, {
      value: header,
      http: {
        method: request.method,
        hostname: request.hostname,
        path: request.path,
        userAgent: request.headers['user-agent'],
      },
    })

    return {}
  }
}
