import isEmpty from 'lodash/isEmpty'

import { dangerouslyInjectScriptOnce } from '@core/helpers/dom'

import {
  PROCESSOUT_SDK_ALTERNATE_PAYMENT_METHODS_FILTER,
  PROCESSOUT_SDK_URL,
} from '../config/constants'
import { PROCESSOUT_ERRORS, PROCESSOUT_ERROR_ORIGINS } from '../config/errors'

import { processOutError } from './errors'

async function processOutInjectSdk() {
  try {
    await dangerouslyInjectScriptOnce(PROCESSOUT_SDK_URL)

    return window.ProcessOut
  } catch (sdkError) {
    throw processOutError(PROCESSOUT_ERRORS.OWN.LOAD_FAILURE, sdkError, {
      origin: PROCESSOUT_ERROR_ORIGINS.INJECT_SDK,
    })
  }
}

/**
 * @param {string} origin Indication of the origin of the issue, that could help
 * troubleshooting.
 * @param {() => Promise<any>} task Task to run
 */
async function handleSdkTask(origin, task) {
  try {
    return await task()
  } catch (sdkError) {
    const code = Object.keys(PROCESSOUT_ERRORS.SDK).includes(sdkError.code)
      ? PROCESSOUT_ERRORS.SDK[sdkError.code]
      : PROCESSOUT_ERRORS.OWN.UNKNOWN_SDK_ERROR

    throw processOutError(code, sdkError, { origin })
  }
}

/**
 * Promise-based ProcessOut client
 * @param {Object} publicKey Public ProcessOut API key
 */
export async function processOutClient(publicKey) {
  const { ProcessOut } = await processOutInjectSdk()

  try {
    if (isEmpty(publicKey)) {
      throw new Error('Missing ProcessOut public key')
    }

    const client = new ProcessOut(publicKey)

    return {
      /**
       * @type {string}
       */
      publicKey,

      /**
       * https://docs.processout.com/payments/processoutjs-reference/#setting-up
       * @param {HTMLElement} containerElement
       * @param {object} options
       * @returns {Promise<ProcessOut.Form>}
       */
      setupForm(containerElement, options) {
        return handleSdkTask(
          PROCESSOUT_ERROR_ORIGINS.CLIENT_SETUP_FORM,
          () =>
            new Promise((resolve, reject) => {
              client.setupForm(containerElement, options, resolve, reject)
            }),
        )
      },

      /**
       * https://docs.processout.com/payments/processoutjs-reference/#bind-the-form-and-tokenize-the-card
       * @param {ProcessOut.Form} form
       * @param {object} options
       * @param {object} [options.contact]
       * @param {string} [options.name]
       * @param {string} [options.preferred_scheme]
       * @returns {Promise<string>}
       */
      tokenize(form, options) {
        return handleSdkTask(
          PROCESSOUT_ERROR_ORIGINS.CLIENT_TOKENIZE,
          () =>
            new Promise((resolve, reject) => {
              client.tokenize(form, options, resolve, reject)
            }),
        )
      },

      /**
       * https://docs.processout.com/payments/payments-and-sca/#step-3-make-payment-on-the-invoice
       * @param {string} invoiceID
       * @param {string} token
       * @param {object} options
       * @returns {Promise<string>}
       */
      makeCardPayment(invoiceId, token, options) {
        return handleSdkTask(
          PROCESSOUT_ERROR_ORIGINS.CLIENT_MAKE_CARD_PAYMENT,
          () =>
            new Promise((resolve, reject) => {
              client.makeCardPayment(invoiceId, token, options, resolve, reject)
            }),
        )
      },

      /**
       * https://docs.processout.com/payments/alternative-payment-methods/#fetch-available-apms-and-redirect
       * @param {string} invoiceId
       * @param {string} filter
       * @returns {Promise<ProcessOut.GatewayConfiguration[]>}
       */
      fetchGatewayConfigurations(invoiceId, filter) {
        return handleSdkTask(
          PROCESSOUT_ERROR_ORIGINS.CLIENT_FETCH_GATEWAY_CONFIGURATION,
          () =>
            new Promise((resolve, reject) => {
              client.fetchGatewayConfigurations(
                { invoiceID: invoiceId, filter },
                resolve,
                reject,
              )
            }),
        )
      },

      /**
       * Same as fetchGatewayConfigurations, but return only alternate payment
       * methods (ie: all but card).
       * @param {string} invoiceId
       * @param {string} filter
       * @returns {Promise<ProcessOut.GatewayConfiguration[]>}
       */
      fetchAlternatePaymentMethods(invoiceId) {
        return this.fetchGatewayConfigurations(
          invoiceId,
          PROCESSOUT_SDK_ALTERNATE_PAYMENT_METHODS_FILTER,
        )
      },

      /**
       * https://docs.processout.com/payments/alternative-payment-methods/#fetch-available-apms-and-redirect
       * @param {ProcessOut.GatewayConfiguration} alternatePaymentMethod
       * @returns {Promise<string>} Resolves with a gateway token on success
       */
      handleAlternatePaymentMethodInvoiceAction(alternatePaymentMethod) {
        return handleSdkTask(
          PROCESSOUT_ERROR_ORIGINS.ALTERNATE_PAYMENT_METHOD_HANDLE_INVOICE_ACTION,
          () =>
            new Promise((resolve, reject) => {
              alternatePaymentMethod.handleInvoiceAction(resolve, reject)
            }),
        )
      },
    }
  } catch (rootError) {
    throw processOutError(
      PROCESSOUT_ERRORS.OWN.CLIENT_INIT_FAILURE,
      rootError,
      { origin: PROCESSOUT_ERROR_ORIGINS.CLIENT_INIT },
    )
  }
}
