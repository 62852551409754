
import { RevLink } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'

import translations from './HomeNavigation.translations'

export default {
  name: 'NavigationHome',
  components: {
    RevLink,
  },
  props: {
    navigationSeo: {
      type: Array,
      required: true,
    },
  },
  computed: {
    translations: () => translations,
    navigationWithUrl() {
      // Filter first menu and submenu to get and display only column with URL
      const hasUrl = (item) => !isEmpty(item.link)
      const navWithUrls = this.navigationSeo.filter(
        (item) => hasUrl(item) && !isEmpty(item.children.filter(hasUrl)),
      )

      return navWithUrls.map(({ id, link, titleDisplay, children = [] }) => {
        return {
          id,
          link,
          titleDisplay,
          hasChildren: !isEmpty(children),
          children: children.slice(0, 10).filter((child) => child.titleDisplay),
        }
      })
    },
    hasNavigation() {
      return !isEmpty(this.navigationSeo)
    },
  },
}
