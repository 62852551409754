import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const Reviews = () =>
  dynamicImport(import('@internal-reviews/components/Page/ConnectedPage.vue'))

export default () => {
  return [
    // Review form
    {
      name: ROUTES.REVIEWS.FORM,
      path: 'review/:id',
      component: () =>
        dynamicImport(
          import('@internal-reviews/components/Form/ConnectedForm.vue'),
        ),
    },
    // Review pages
    {
      name: ROUTES.REVIEWS.PAGES.LANDING,
      path: 'r/l/:landingSlug/:landingId',
      component: Reviews,
    },
    {
      name: ROUTES.REVIEWS.PAGES.MERCHANT,
      path: 'r/s/:sellerSlug/:sellerId',
      component: Reviews,
    },
    {
      name: ROUTES.REVIEWS.PAGES.PRODUCT,
      path: 'r/p/:productId',
      component: Reviews,
    },
    {
      name: ROUTES.REVIEWS.PAGES.ALL,
      path: 'r',
      component: Reviews,
    },
  ]
}
