
import { RevIllustration } from '@backmarket/design-system'

import { paymentGroupIcon } from '../../methods'

export default {
  components: {
    RevIllustration,
  },

  props: {
    groupId: {
      type: String,
      required: true,
    },
  },

  computed: {
    icon() {
      return paymentGroupIcon(this.groupId)
    },
  },
}
