import { deepValuesIn } from '@core/helpers/collections'

export function isNotOnBlocklistedRoute({
  blocklistedRoutesCollections,
  currentRoute,
}) {
  const allBlocklistedRoutes = blocklistedRoutesCollections
    .map(deepValuesIn)
    .flat()

  return !allBlocklistedRoutes.includes(currentRoute)
}
