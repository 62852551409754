import { MARKETPLACES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

// @TODO sort which routes we keep for the option page.
const getOptionsRoutes = (marketplace) => {
  const childrenRoutes = [
    ...insertIf(marketplace === MARKETPLACES.EU, [
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.MARKETS,
        path: 'markets',
        component: () =>
          dynamicImport(
            import('@seller/sub-modules/onboarded/pages/options/tabs/markets'),
          ),
      },
    ]),
    {
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.ADDRESSES,
      path: 'addresses',
      component: () =>
        dynamicImport(
          import('@seller/sub-modules/onboarded/pages/options/tabs/addresses'),
        ),
    },
    {
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.SHIPPING,
      path: 'shipping',
      component: () =>
        dynamicImport(
          import('@seller/sub-modules/onboarded/pages/options/tabs/shipping'),
        ),
    },
    {
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.PAYMENT,
      path: 'payment',
      component: () =>
        dynamicImport(
          import('@seller/sub-modules/onboarded/pages/options/tabs/payment'),
        ),
    },
    ...insertIf(marketplace === MARKETPLACES.EU, [
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.PRICING,
        path: 'pricing',
        component: () =>
          dynamicImport(
            import('@seller/sub-modules/onboarded/pages/options/tabs/pricing'),
          ),
      },
    ]),
    {
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.BACKPRICER,
      path: 'backpricer',
      component: () =>
        dynamicImport(
          import('@seller/sub-modules/onboarded/pages/options/tabs/backpricer'),
        ),
    },
    {
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.INTEGRATIONS,
      path: 'integrations',
      component: () =>
        dynamicImport(
          import(
            '@seller/sub-modules/onboarded/pages/options/tabs/integrations'
          ),
        ),
    },
  ]

  return [
    {
      path: 'options',
      component: () =>
        dynamicImport(import('@seller/sub-modules/onboarded/pages/options')),
      name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.HOME,
      redirect: { name: childrenRoutes[0].name },
      children: childrenRoutes,
    },
  ]
}

const pricingToolRoute = [
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.PRICING_TOOL,
    path: 'pricing-tool',
    component: () =>
      dynamicImport(import('@seller/sub-modules/onboarded/pages/pricing-tool')),
  },
]

const routes = [
  {
    path: 'listings',
    name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.ACTIVE,
    component: () =>
      dynamicImport(import('@seller/sub-modules/onboarded/pages/listings')),
    children: [
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.MISSING_INFOS,
        path: 'missing-infos',
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.IN_VALIDATION,
        path: 'in-validation',
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.ARCHIVED,
        path: 'archived',
      },
    ],
  },
  {
    path: 'listings/create/:productBmId',
    name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.CREATE.DETAILS,
    component: () =>
      dynamicImport(
        import('@seller/sub-modules/onboarded/pages/listing-creation'),
      ),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.FEEDBACK,
    path: 'feedback',
    component: () =>
      dynamicImport(import('@seller/sub-modules/onboarded/pages/feedback')),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.HOME,
    path: 'home',
    component: () => dynamicImport(import('./pages/home')),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.ROOT,
    path: 'profile',
    component: () =>
      dynamicImport(import('@seller/sub-modules/onboarded/pages/profile')),
    redirect: { name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.HOME },
    children: [
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.HOME,
        path: 'home',
        component: () =>
          dynamicImport(
            import('@seller/sub-modules/onboarded/pages/profile/tabs/home'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.COMPANY_INFO,
        path: 'company-info',
        component: () =>
          dynamicImport(
            import(
              '@seller/sub-modules/onboarded/pages/profile/tabs/company-info'
            ),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.LEGAL_DETAILS,
        path: 'legal-details',
        component: () =>
          dynamicImport(
            import(
              '@seller/sub-modules/onboarded/pages/profile/tabs/legal-details'
            ),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.CONTACT_DETAILS,
        path: 'contact-details',
        component: () =>
          dynamicImport(
            import(
              '@seller/sub-modules/onboarded/pages/profile/tabs/contact-details'
            ),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.ONBOARDING.PROFILE.USER_PERMISSIONS,
        path: 'user-permissions',
        component: () =>
          dynamicImport(
            import(
              '@seller/sub-modules/onboarded/pages/profile/tabs/user-permissions'
            ),
          ),
      },
    ],
  },
]

const hyperwalletRoutes = [
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.KYB,
    path: 'options/payment/kyb',
    component: () =>
      dynamicImport(
        import('@seller/sub-modules/onboarded/pages/hyperwallet/kyb'),
      ),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.TRANSFER_METHODS,
    path: 'options/payment/transfer-methods',
    component: () =>
      dynamicImport(
        import(
          '@seller/sub-modules/onboarded/pages/hyperwallet/transfer-methods'
        ),
      ),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.OPTIONS.TRANSFER_METHODS_CONFIRMATION,
    path: 'options/payment/transfer-methods/confirmation',
    component: () =>
      dynamicImport(
        import('@seller/sub-modules/onboarded/pages/hyperwallet/confirmation'),
      ),
  },
]

export default ({ marketplace }) => {
  return {
    requiresSellerAuthentication: [
      {
        path: 'onboarding',
        redirect: { name: ROUTES.BO_MERCHANT.ONBOARDING.HOME },
        meta: {
          seller: {
            isSellerOnboarding: true,
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.HOME },
          },
        },
        component: () => dynamicImport(import('./layouts/Default')),
        children: [
          ...routes,
          ...hyperwalletRoutes,
          ...getOptionsRoutes(marketplace),
          ...insertIf(marketplace === MARKETPLACES.EU, pricingToolRoute),
        ],
      },
    ],
    other: [],
  }
}
