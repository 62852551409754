import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'accessibility-statement',
      component: () => dynamicImport(import('./Statement.vue')),
      name: ROUTES.A11Y.STATEMENT,
    },
  ]
}
