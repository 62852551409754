import { ROUTES } from '@router'

export const PAGE_TYPE_BY_ROUTE_NAME = {
  [ROUTES.CMS.ARTICLE]: 'article',
  [ROUTES.BUYING_GUIDE.SELF]: 'article',
  [ROUTES.CMS.BRAND_STORE]: 'brand-store',
  [ROUTES.CMS.BRAND_STORE_CATEGORY]: 'brand-store',
  [ROUTES.CMS.EVENT]: 'event-page',
  [ROUTES.CMS.BUYBACK]: 'buyback',
  [ROUTES.CMS.BUYBACK_CATEGORY]: 'buyback',
  [ROUTES.CMS.BUSINESS]: 'business-page',
  [ROUTES.CMS.SELLER]: 'seller',
  [ROUTES.HOME]: 'core',
  [ROUTES.A11Y.STATEMENT]: 'core',
}

export const PAGE_NAME_BY_ROUTE_NAME = {
  [ROUTES.HOME]: 'home',
  [ROUTES.A11Y.STATEMENT]: 'accessibility-statement',
}
