const middleware = {}

middleware['auth'] = require('../app/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['countryConfig'] = require('../app/middleware/countryConfig.js')
middleware['countryConfig'] = middleware['countryConfig'].default || middleware['countryConfig']

middleware['guest'] = require('../app/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['layout'] = require('../app/middleware/layout.js')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['notSeller'] = require('../app/middleware/notSeller.js')
middleware['notSeller'] = middleware['notSeller'].default || middleware['notSeller']

middleware['seller'] = require('../app/middleware/seller.js')
middleware['seller'] = middleware['seller'].default || middleware['seller']

middleware['staff'] = require('../app/middleware/staff.js')
middleware['staff'] = middleware['staff'].default || middleware['staff']

export default middleware
