import { fetchMerchantRateReviews } from '@http/endpoints'

export default {
  namespaced: true,
  state() {
    return {
      rate: {
        averageRate: 0,
        reviewsCount: 0,
      },
    }
  },
  getters: {
    get: (state) => state.rate,
  },
  mutations: {
    set: (state, { averageRate, reviewsCount }) => {
      state.rate.averageRate = averageRate
      state.rate.reviewsCount = reviewsCount
    },
  },
  actions: {
    fetch: async ({ dispatch }, queryParams) => {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchMerchantRateReviews,
          queryParams,
        },
        { root: true },
      )

      dispatch('set', payload)
    },
    set: ({ commit }, data) => commit('set', data),
  },
}
