
import isEmpty from 'lodash/isEmpty'

import { COUNTRIES } from '@config/constants'
import IconFlagAT from '@core/icons/FlagAT.svg'
import IconFlagBE from '@core/icons/FlagBE.svg'
import IconFlagDE from '@core/icons/FlagDE.svg'
import IconFlagES from '@core/icons/FlagES.svg'
import IconFlagFI from '@core/icons/FlagFI.svg'
import IconFlagFR from '@core/icons/FlagFR.svg'
import IconFlagGB from '@core/icons/FlagGB.svg'
import IconFlagGR from '@core/icons/FlagGR.svg'
import IconFlagIE from '@core/icons/FlagIE.svg'
import IconFlagIT from '@core/icons/FlagIT.svg'
import IconFlagJP from '@core/icons/FlagJP.svg'
import IconFlagNL from '@core/icons/FlagNL.svg'
import IconFlagPT from '@core/icons/FlagPT.svg'
import IconFlagSE from '@core/icons/FlagSE.svg'
import IconFlagSK from '@core/icons/FlagSK.svg'
import IconFlagUS from '@core/icons/FlagUS.svg'
import { tw } from '@core/tailwind'

const flagsComponents = {
  [COUNTRIES.AT]: IconFlagAT,
  [COUNTRIES.DE]: IconFlagDE,
  [COUNTRIES.GB]: IconFlagGB,
  [COUNTRIES.GR]: IconFlagGR,
  [COUNTRIES.US]: IconFlagUS,
  [COUNTRIES.ES]: IconFlagES,
  [COUNTRIES.FI]: IconFlagFI,
  [COUNTRIES.BE]: IconFlagBE,
  [COUNTRIES.FR]: IconFlagFR,
  [COUNTRIES.IT]: IconFlagIT,
  [COUNTRIES.IE]: IconFlagIE,
  [COUNTRIES.NL]: IconFlagNL,
  [COUNTRIES.PT]: IconFlagPT,
  [COUNTRIES.SK]: IconFlagSK,
  [COUNTRIES.SE]: IconFlagSE,
  [COUNTRIES.JP]: IconFlagJP,
}

export const SIZES = { s: 's', m: 'm', l: 'l', xl: 'xl', xxl: 'xxl' }

export default {
  inheritAttrs: false,
  props: {
    countryCode: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: SIZES.m,
      validator: (size) => isEmpty(size) || Object.values(SIZES).includes(size),
    },
  },
  computed: {
    flagComponent() {
      return flagsComponents[this.countryCode]
    },
    sizeClass() {
      const sizeClasses = {
        [SIZES.s]: tw`h-2 w-3`,
        [SIZES.m]: tw`h-3 w-[1.8rem]`,
        [SIZES.l]: tw`h-4 w-6`,
        [SIZES.xl]: tw`h-5 w-[3rem]`,
        [SIZES.xxl]: tw`h-3 w-[1.8rem]`,
        default: tw`h-3 w-[1.8rem]`,
      }

      return sizeClasses[this.size] || sizeClasses.default
    },
  },
}
