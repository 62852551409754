import isEmpty from 'lodash/isEmpty'

import {
  getCategoryContentFromContentService,
  getContentFromContentService,
} from '@http/endpoints'

import {
  PAGE_NAME_BY_ROUTE_NAME,
  PAGE_TYPE_BY_ROUTE_NAME,
} from './content-service.config'

export const isSubPage = ({ routeName, pageCategory }) =>
  Object.keys(PAGE_TYPE_BY_ROUTE_NAME).includes(routeName) &&
  !isEmpty(pageCategory)

export const pageParamsFromRoute = ({ route }) => {
  const { pageName, pageCategory, slug } = route.params
  const routeName = route.name
  const pageType = PAGE_TYPE_BY_ROUTE_NAME[routeName]

  return {
    pageType,
    pageName: pageName || PAGE_NAME_BY_ROUTE_NAME[routeName] || slug,
    pageCategory,
  }
}

export const requestEndpointFromRoute = ({ route }) => {
  const routeName = route.name
  const { pageCategory } = route.params

  return isSubPage({ routeName, pageCategory })
    ? getCategoryContentFromContentService
    : getContentFromContentService
}

export const requestCMSPageConfigFromRoute = ({ route }) => ({
  request: requestEndpointFromRoute({ route }),
  pathParams: pageParamsFromRoute({ route }),
})

export const sanitizeRouteParams = ({ parentPageInfos, route }) => {
  if (isEmpty(parentPageInfos)) {
    return {}
  }

  const routeParams = Object.keys(route.params)

  return Object.keys(parentPageInfos).reduce((updatedParams, info) => {
    if (
      routeParams.includes(info) &&
      route.params[info] !== parentPageInfos[info]
    ) {
      return {
        ...updatedParams,
        [info]: parentPageInfos[info],
      }
    }

    return updatedParams
  }, {})
}
