import { fetchReviewsRateByProduct } from '@http/endpoints'

export default {
  namespaced: true,
  state() {
    return {
      reviewsCount: null,
      averageRate: null,
    }
  },
  getters: {
    get: (state) => ({
      reviewsCount: state.reviewsCount,
      averageRate: state.averageRate,
    }),
  },
  mutations: {
    set: (state, data) => {
      state.reviewsCount = data.reviewsCount
      state.averageRate = data.averageRate
    },
  },
  actions: {
    fetch: async ({ commit, dispatch }, { productId }) => {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchReviewsRateByProduct,
          pathParams: { productId },
        },
        { root: true },
      )

      commit('set', payload)
    },
    set: ({ commit }, data) => commit('set', data),
  },
}
