
import { RevIllustration, RevModal, RevPicker } from '@backmarket/design-system'

import { PAYMENT_GROUPS } from '../../methods'

import KlarnaLogo from './KlarnaLogo.svg'
import translations from './KlarnaModal.translations'

export default {
  name: 'KlarnaModal',

  components: {
    RevIllustration,
    KlarnaLogo,
    RevModal,
    RevPicker,
  },

  props: {
    name: {
      type: String,
      default: 'KlarnaModal',
    },
  },

  data() {
    return {
      options: [
        {
          title: translations.payLaterOption,
          testId: 'option-pay-later',
          groupId: PAYMENT_GROUPS.KLARNA_PAY_LATER,
          questions: [
            {
              question: translations.payLaterQuestion1,
              answer: translations.payLaterAnswer1,
            },
            {
              question: translations.payLaterQuestion2,
              answer: translations.payLaterAnswer2,
            },
          ],
        },
        {
          title: translations.sliceItOption,
          testId: 'option-slice-it',
          groupId: PAYMENT_GROUPS.KLARNA_SLICE_IT,
          questions: [
            {
              question: translations.sliceItQuestion1,
              answer: translations.sliceItAnswer1,
            },
            {
              question: translations.sliceItQuestion2,
              answer: translations.sliceItAnswer2,
            },
          ],
        },
      ],

      selectedOptionIndex: 0,
    }
  },

  computed: {
    selectedOption() {
      return this.options[this.selectedOptionIndex]
    },

    translations: () => translations,
  },

  methods: {
    isSelected(index) {
      return this.selectedOptionIndex === index
    },

    handleOptionClick(optionIndex) {
      this.selectedOptionIndex = optionIndex
    },
  },
}
