import translations from './fields.translations'

export const FIELD_DEFAULT_MAX_LENGTH = 254

export const FIELD_REPORTED_ERRORS = {
  INVALID: 'invalid',
  UNAUTHORIZED: 'unauthorized',
}

export const ERROR_TRANSLATIONS = {
  postalCode: {
    [FIELD_REPORTED_ERRORS.INVALID]: translations.postalCodeInvalid,
    [FIELD_REPORTED_ERRORS.UNAUTHORIZED]: translations.postalCodeUnauthorized,
  },
}
