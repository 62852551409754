import {
  closeCustomerRequestPlatformConversation,
  fetchCustomerRequestPlatformAllowedActions,
  fetchCustomerRequestPlatformRemainingHours,
  openCustomerRequestPlatformConversation,
} from '@http/endpoints'
import { getApiPathFromRole, isBackcare, isPending } from '@sav-common/helpers'

import { SAV_PLATFORM_ROLES, URGENCIES } from '../../../constants'

export function getDefaultAllowedActions() {
  return {
    refund: {},
    postMessageKinds: [],
  }
}

export default {
  namespaced: true,

  state() {
    return {
      allowedActions: getDefaultAllowedActions(),
    }
  },
  getters: {
    allowedActions(state) {
      return state.allowedActions
    },
  },
  mutations: {
    clearAllowedActions(state) {
      state.allowedActions = getDefaultAllowedActions()
    },
    setAllowedActions(state, { allowedActions }) {
      const defaultAllowedActions = getDefaultAllowedActions()

      state.allowedActions = {
        ...allowedActions,
        refund: allowedActions.refund || defaultAllowedActions.refund,
        postMessageKinds:
          allowedActions.postMessageKinds ||
          defaultAllowedActions.postMessageKinds,
      }
    },
  },
  actions: {
    clearAllowedActions({ commit }) {
      commit('clearAllowedActions')
    },
    async close(
      { dispatch },
      { customerRequestId, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      await dispatch(
        'http/request',
        {
          request: closeCustomerRequestPlatformConversation,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )
    },
    async fetchAllowedActions(
      { commit, dispatch },
      { customerRequestId, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const { payload: allowedActions } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformAllowedActions,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )

      commit('setAllowedActions', { allowedActions })
    },
    async fetchRemainingHours(
      { dispatch },
      { customerRequestId, update = true },
    ) {
      const { payload: remainingHours = null } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformRemainingHours,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      if (update) {
        dispatch(
          'customerRequestPlatform/conversations/update',
          {
            customerRequestId,
            updates: {
              remainingHours,
            },
          },
          { root: true },
        )
      }
    },
    async open(
      { dispatch },
      { customerRequestId, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      await dispatch(
        'http/request',
        {
          request: openCustomerRequestPlatformConversation,
          pathParams: {
            customerRequestId,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )
    },
    async post(
      { dispatch },
      {
        backcarePendingForMerchant = false,
        context,
        customerRequestId,
        role = SAV_PLATFORM_ROLES.merchant,
        ...messageParams
      },
    ) {
      await dispatch(
        'customerRequestDiscussion/discussion/post',
        {
          ...messageParams,
          customerRequestId,
          role,
        },
        { root: true },
      )

      if (isPending(context)) {
        if (backcarePendingForMerchant) {
          // If this is a BackCare conversation inside of the pending customer
          // requests of the merchant (i.e. BackCare agent is waiting for an
          // answer from the merchant), simply remove it from the list (as the
          // merchant answered, it is not waiting an action anymore), and
          // update the counts.
          // Note that we don't need to update anything in the conversation as
          // it will be unselected anyway.
          dispatch(
            'customerRequestPlatform/conversations/remove',
            { customerRequestId },
            { root: true },
          )
        } else {
          // If this is a normal conversation inside of the pending view, update
          // the remaining hours (as the merchant made an action).
          await dispatch(
            'customerRequestPlatform/discussion/fetchRemainingHours',
            { customerRequestId },
            { root: true },
          )

          // And also update the display of the conversation in the conversation
          // particularly the pending date and the urgency status).
          // The conversation is also moved to the bottom of the list as it
          // doesn't need any particular attention from the merchant anymore.
          dispatch(
            'customerRequestPlatform/conversations/update',
            {
              customerRequestId,
              updates: {
                pendingSince: new Date().toISOString(),
                urgency: URGENCIES.low,
              },
              params: {
                moveToBottom: true,
              },
            },
            { root: true },
          )
        }
      } else if (isBackcare(context)) {
        // In the BackCare context, we simply remove the "Waiting for action"
        // tag from the conversation in the list and move it to the bottom of
        // the list as it doesn't need any particular attention from the
        // merchant anymore.
        dispatch(
          'customerRequestPlatform/conversations/update',
          {
            customerRequestId,
            updates: {
              backcarePendingForMerchant: false,
            },
            params: {
              moveToBottom: true,
            },
          },
          { root: true },
        )
      }

      if (!backcarePendingForMerchant) {
        // Update the allowed actions to reflect the creation of a new message.
        await dispatch('fetchAllowedActions', { customerRequestId, role })
      }
    },
  },
}
