import { changeUser, getSdk, updateSdk } from './braze'
import { EVENT_PLATFORM } from './constants'
import * as DEFINITIONS from './definitions'
import { productModel } from './models/product'

export const gdpr = ({ userExperience: hasUserConsent }) => {
  if (!getSdk()) return

  updateSdk({ optIn: hasUserConsent })
}

export const userContext = async ({ clientId }) => {
  if (!getSdk() || !clientId) return

  changeUser()
}

export const product = ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.PRODUCT
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const addToCart = async ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.ADD_TO_CART
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const buybackDeviceSpecifications = async ({ product: p }) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.BUYBACK_DEVICE_SPECIFICATIONS
  const payload = {
    ...productModel(p),
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}

export const searchTerm = async (query) => {
  const sdk = getSdk()

  if (!sdk) return

  const eventName = DEFINITIONS.SEARCH_TERM
  const payload = {
    search_term: query,
    event_platform: EVENT_PLATFORM,
  }

  sdk.logCustomEvent(eventName, payload)
}
