import { PAYMENT_ADVERTISING_TYPES } from './constants'

// Affirm modal settings
// Read more: https://docs.affirm.com/affirm-developers/docs/html-reference

// Formatted for affrom price in cents
export const AFFIRM_MINIMAL_AMOUNT = 5000

export const AFFIRM_MODAL_CLASSES = {
  AS_LOW_AS: 'affirm-as-low-as',
  SITE: 'affirm-site-modal',
  PRODUCT: 'affirm-product-modal',
}

export const AFFIRM_MODAL_PAGE_TYPES = {
  HOMEPAGE: 'homepage',
  LANDING: 'landing',
  SEARCH: 'search',
  CATEGORY: 'category',
  PRODUCT: 'product',
  CART: 'cart',
  PAYMENT: 'payment',
  BANNER: 'banner',
}

export const AFFIRM_MODAL_SETTINGS = {
  [PAYMENT_ADVERTISING_TYPES.PRODUCT]: {
    class: AFFIRM_MODAL_CLASSES.PRODUCT,
    pageType: AFFIRM_MODAL_PAGE_TYPES.PRODUCT,
    requiresAmount: true,
  },
  [PAYMENT_ADVERTISING_TYPES.CART]: {
    class: AFFIRM_MODAL_CLASSES.PRODUCT,
    pageType: AFFIRM_MODAL_PAGE_TYPES.CART,
    requiresAmount: true,
  },
  [PAYMENT_ADVERTISING_TYPES.LANDING]: {
    class: AFFIRM_MODAL_CLASSES.SITE,
    pageType: AFFIRM_MODAL_PAGE_TYPES.CATEGORY,
    requiresAmount: false,
  },
  [PAYMENT_ADVERTISING_TYPES.SEARCH]: {
    class: AFFIRM_MODAL_CLASSES.SITE,
    pageType: AFFIRM_MODAL_PAGE_TYPES.CATEGORY,
    requiresAmount: false,
  },
}
