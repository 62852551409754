import { dangerouslyInjectScriptOnce } from '@core/helpers/dom'

export const AFFIRM_SDK_URL_SANDBOX =
  'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'
export const AFFIRM_SDK_URL_PRODUCTION =
  'https://cdn1.affirm.com/js/v2/affirm.js'

export async function getAffirmSdk({ AFFIRM_API_KEY, AFFIRM_SANDBOX_ENABLED }) {
  // eslint-disable-next-line no-underscore-dangle
  window._affirm_config = {
    public_api_key: AFFIRM_API_KEY,
  }

  // Using the production version of the SDK with a sandbox API key results in
  // a HTTP 400 response on checkout request, so we have to use a specific SDK
  // for each environment.
  //
  // Also, we need to load SDK on-the-fly to avoid the risk of using an
  // out-of-date version.
  // Read more: https://www.notion.so/48789d03a546423082829369a9e7d0d7
  await dangerouslyInjectScriptOnce(
    AFFIRM_SANDBOX_ENABLED ? AFFIRM_SDK_URL_SANDBOX : AFFIRM_SDK_URL_PRODUCTION,
  )

  return window.affirm
}
