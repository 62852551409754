import isEmpty from 'lodash/isEmpty'

import { computeNextPagePath } from '@core/helpers'
import API from '@http/api'
import {
  fetchCustomerRequestPlatformConversations,
  fetchCustomerRequestPlatformMerchantIssues,
  fetchCustomerRequestPlatformStaffIssues,
  fetchPendingProductReturnCustomerRequestPlatform,
} from '@http/endpoints'
import { CUSTOMER_REQUESTS_STATES } from '@sav-common/constants'
import {
  getApiPathFromRole,
  isBackcare,
  isMerchant,
  isPending,
  isReturns,
  isStandaloneConversation,
} from '@sav-common/helpers'

import {
  CONTEXTS_WITH_ISSUES_LIST,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from '../../../constants'
import { getNonEmptyFilters } from '../../../helpers'

export default {
  namespaced: true,

  state() {
    return {
      // TODO: [Clément] Replace this by a Map when Vue supports it.
      conversationsOrdered: [],
      conversations: {},
      count: null,
      // We need to save the context each time we fetch conversations.
      // This way, when the API call finished, we can check that we didn't
      // switch context in between and avoid flashing/overwriting content.
      // E.g., I open the SAV Platform on the 'pending' context. The call fires
      // and before the response arrives, the user changes to the 'returns'
      // context, we don't want the 'pending' results to display under the
      // 'returns' context, even for a few seconds (the time to receive the
      // response for the 'returns' context) because it can be troubling for the
      // user.
      lastContext: null,
      nextConversationsPagePath: null,
      issues: [],
      selected: null,
    }
  },
  getters: {
    count(state) {
      return state.count || 0
    },
    get(state) {
      return (customerRequestId) => state.conversations[customerRequestId] || {}
    },
    hasMoreConversations(state) {
      return !isEmpty(state.nextConversationsPagePath)
    },
    issues(state) {
      return state.issues
    },
    lastContext(state) {
      return state.lastContext
    },
    list(state) {
      return state.conversationsOrdered.map(
        (customerRequestId) => state.conversations[customerRequestId],
      )
    },
    nextConversationsPagePath(state) {
      return state.nextConversationsPagePath
    },
    selected(state) {
      return state.selected
    },
  },
  mutations: {
    addConversations(state, { nextPageUrl, conversations }) {
      state.nextConversationsPagePath = computeNextPagePath(nextPageUrl)

      conversations.forEach((conversation) => {
        const customerRequestId = parseInt(conversation.customerRequestId, 10)

        state.conversations[customerRequestId] = {
          ...conversation,
          id: customerRequestId,
        }

        const index = state.conversationsOrdered.indexOf(customerRequestId)
        if (index > -1) {
          state.conversationsOrdered.splice(index, 1)
        }
        state.conversationsOrdered.push(customerRequestId)
      })
    },
    close(state, { customerRequestId }) {
      const conversation = state.conversations[customerRequestId]
      if (isEmpty(conversation)) {
        return
      }

      conversation.state = CUSTOMER_REQUESTS_STATES.closed
    },
    emptyConversations(state) {
      state.conversationsOrdered = []
      state.conversations = {}
    },
    emptyNextConversationPagePath(state) {
      state.nextConversationsPagePath = null
    },
    increaseCount(state, { amount = 1 } = {}) {
      state.count += amount
    },
    open(state, { customerRequestId }) {
      const conversation = state.conversations[customerRequestId]
      if (isEmpty(conversation)) {
        return
      }

      conversation.state = CUSTOMER_REQUESTS_STATES.open
    },
    removeConversation(state, { customerRequestId }) {
      state.conversations[customerRequestId] = undefined

      const index = state.conversationsOrdered.indexOf(customerRequestId)
      if (index > -1) {
        state.conversationsOrdered.splice(index, 1)
      }

      state.count -= 1
    },
    resetCount(state) {
      state.count = null
    },
    resetLastContext(state) {
      state.lastContext = null
    },
    select(state, { customerRequestId }) {
      state.selected = customerRequestId
    },
    setConversations(state, { nextPageUrl = null, conversations = [] } = {}) {
      state.nextConversationsPagePath = computeNextPagePath(nextPageUrl)

      state.conversationsOrdered = []
      state.conversations = conversations.reduce(
        (previousConversations, conversation) => {
          const customerRequestId = parseInt(conversation.customerRequestId, 10)

          state.conversationsOrdered.push(customerRequestId)

          return {
            ...previousConversations,
            [customerRequestId]: { ...conversation, id: customerRequestId },
          }
        },
        {},
      )
    },
    setCount(state, { count }) {
      state.count = count
    },
    setIssues(state, { issues }) {
      state.issues = issues
    },
    setLastContext(state, { context }) {
      state.lastContext = context
    },
    unselect(state) {
      state.selected = null
    },
    update(
      state,
      { customerRequestId, updates, params = { moveToBottom: false } },
    ) {
      const conversation = state.conversations[customerRequestId]

      if (params.moveToBottom) {
        const index = state.conversationsOrdered.indexOf(customerRequestId)
        if (index > -1) {
          state.conversationsOrdered.splice(index, 1)
        }

        state.conversationsOrdered.push(customerRequestId)
      }

      state.conversations[customerRequestId] = {
        ...conversation,
        ...updates,
      }
    },
  },
  actions: {
    async close(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      // Make the close call to the API (through the concerned store).
      await dispatch(
        'customerRequestPlatform/discussion/close',
        { customerRequestId, role },
        { root: true },
      )

      if (isMerchant(role)) {
        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )

        if (
          !isStandaloneConversation(context) &&
          CONTEXTS_WITH_ISSUES_LIST.includes(context)
        ) {
          // Refresh the list of pending issues as it may have one less.
          dispatch('fetchIssues', { context, role })
        }
      }

      if (isPending(context)) {
        dispatch('remove', { customerRequestId })
      } else {
        if (!isStandaloneConversation(context)) {
          // Set the state of the conversation in the list as closed.
          commit('close', { customerRequestId })
        }

        // Update the allowed actions for the now closed customer request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchAllowedActions',
          { customerRequestId, role },
          { root: true },
        )
      }
    },

    async closed(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      if (isMerchant(role)) {
        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )

        if (
          !isStandaloneConversation(context) &&
          CONTEXTS_WITH_ISSUES_LIST.includes(context)
        ) {
          // Refresh the list of pending issues as it may have one less.
          dispatch('fetchIssues', { context, role })
        }
      }

      if (isPending(context)) {
        dispatch('remove', { customerRequestId })

        dispatch('unselect', {
          emptyMerchant: !isMerchant(role),
          hideToast: false,
        })
      } else if (!isStandaloneConversation(context)) {
        // Set the state of the conversation in the list as closed.
        commit('close', { customerRequestId })
      }
    },

    empty({ commit }) {
      commit('resetCount')
      commit('emptyNextConversationPagePath')
      commit('emptyConversations')
      commit('toast/hide', null, { root: true })
    },
    async fetchConversations(
      { commit, dispatch, getters },
      {
        context,
        emptyList = true,
        filters = {},
        role = SAV_PLATFORM_ROLES.merchant,
      },
    ) {
      commit('setLastContext', { context })

      if (emptyList) {
        dispatch('empty')
      }

      const { payload } = await dispatch(
        'http/request',
        {
          request: isReturns(context)
            ? fetchPendingProductReturnCustomerRequestPlatform
            : fetchCustomerRequestPlatformConversations,
          pathParams: {
            context: isMerchant(role) ? context : undefined,
            role: getApiPathFromRole(role),
          },
          queryParams: filters,
        },
        { root: true },
      )

      if (!isEmpty(getters.lastContext) && getters.lastContext !== context) {
        return
      }

      const { count, next: nextPageUrl, results = [] } = payload

      let conversations = results
      // Product returns must be a bit transformed to ressemble other conversations.
      if (isReturns(context)) {
        conversations = results.map(({ claim, ...productReturn }) => ({
          ...claim,
          productReturn,
        }))
      }

      commit('setConversations', { nextPageUrl, conversations })

      if (isMerchant(role)) {
        commit('setCount', { count })

        // Remove the update banner of the context we are loading the list of.
        dispatch(
          'customerRequestPlatform/information/acknowledge',
          { context },
          {
            root: true,
          },
        )
      }

      commit('resetLastContext')
    },
    async fetchNextConversations({ commit, getters, dispatch }, { context }) {
      if (!getters.hasMoreConversations) {
        return
      }

      const config = {
        request: API.get({
          name: 'fetchNextConversationsPage',
          path: getters.nextConversationsPagePath,
        }),
      }

      try {
        const { payload } = await dispatch('http/request', config, {
          root: true,
        })

        const { next: nextPageUrl, results = [] } = payload

        let conversations = results
        // Product returns must be a bit transformed to resemble other conversations.
        if (context === SAV_PLATFORM_CONTEXTS.returns) {
          conversations = results.map(({ claim, ...productReturn }) => ({
            ...claim,
            productReturn,
          }))
        }

        commit('addConversations', { nextPageUrl, conversations })
      } catch (error) {
        commit('emptyNextConversationPagePath')

        throw error
      }
    },
    async fetchIssues(
      { commit, dispatch },
      { context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const { payload: issues } = await dispatch(
        'http/request',
        {
          request: isMerchant(role)
            ? fetchCustomerRequestPlatformMerchantIssues
            : fetchCustomerRequestPlatformStaffIssues,
          pathParams: {
            context: isMerchant(role) ? context : undefined,
            role: getApiPathFromRole(role),
          },
        },
        { root: true },
      )

      commit('setIssues', { issues })
    },
    async open(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      // Make the close call to the API (through the concerned store).
      await dispatch(
        'customerRequestPlatform/discussion/open',
        { customerRequestId, role },
        { root: true },
      )

      if (isMerchant(role)) {
        // Update the local count only in "Backcare" context (the only context
        // with local count where you can open a closed conversation)
        if (isBackcare(context)) {
          commit('increaseCount')
        }

        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )
      }

      if (!isStandaloneConversation(context)) {
        if (CONTEXTS_WITH_ISSUES_LIST.includes(context) && isMerchant(role)) {
          // Refresh the list of pending issues as it may have one less.
          dispatch('fetchIssues', { context, role })
        }

        // Set the state of the conversation as opened.
        commit('open', { customerRequestId })
      }

      // Update the allowed actions for the now opened customer request.
      await dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )
    },

    async opened(
      { commit, dispatch },
      { customerRequestId, context, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      if (isMerchant(role)) {
        // Update the local count only in "Backcare" context (the only context
        // with local count where you can open a closed conversation)
        if (isBackcare(context)) {
          commit('increaseCount')
        }

        // Update the global counts (those displayed in the sidebar)
        dispatch(
          'customerRequestPlatform/information/fetchCounts',
          {},
          {
            root: true,
          },
        )
      }

      if (!isStandaloneConversation(context)) {
        if (CONTEXTS_WITH_ISSUES_LIST.includes(context) && isMerchant(role)) {
          // Refresh the list of pending issues as it may have one less.
          dispatch('fetchIssues', { context, role })
        }

        // Set the state of the conversation as opened.
        commit('open', { customerRequestId })
      }
    },

    remove({ commit, dispatch }, { customerRequestId }) {
      commit('removeConversation', { customerRequestId })

      // Update the global counts (those displayed in the sidebar)
      dispatch(
        'customerRequestPlatform/information/fetchCounts',
        {},
        {
          root: true,
        },
      )
    },
    async search(
      { dispatch },
      { context, filters = {}, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const nonEmptyFilters = getNonEmptyFilters(filters)

      const searchParams = {
        context,
        filters: nonEmptyFilters,
        role,
      }

      await dispatch('fetchConversations', searchParams)

      return searchParams
    },
    async select(
      { commit, dispatch },
      { customerRequestId, role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const promises = [
        dispatch(
          'customerRequestDiscussion/discussion/fetch',
          { customerRequestId, role },
          { root: true },
        ),
        dispatch(
          'customerRequestDiscussion/information/fetch',
          { customerRequestId },
          { root: true },
        ),
      ]

      if (!isMerchant(role)) {
        promises.push(
          dispatch(
            'customerRequestPlatform/information/fetchMerchantInformation',
            { customerRequestId },
            { root: true },
          ),
        )
      }

      await Promise.all(promises)

      // Wait for all the information being loaded before loading the allowed
      // actions.
      await dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )

      commit('select', { customerRequestId })
    },
    async unselect(
      { commit, dispatch },
      { emptyMerchant = false, hideToast = true } = {},
    ) {
      commit('unselect')

      dispatch('customerRequestDiscussion/information/clear', null, {
        root: true,
      })
      dispatch('customerRequestDiscussion/discussion/clear', null, {
        root: true,
      })
      dispatch('customerRequestPlatform/discussion/clearAllowedActions', null, {
        root: true,
      })

      if (emptyMerchant) {
        dispatch('customerRequestPlatform/information/clearMerchant', null, {
          root: true,
        })
      }

      if (hideToast) {
        dispatch('toast/hide', null, { root: true })
      }
    },
    update({ commit }, { customerRequestId, updates, params = {} }) {
      commit('update', { customerRequestId, updates, params })
    },
  },
}
