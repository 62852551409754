
import { IconSearch } from '@backmarket/design-system'

import translations from './Textbox.translations'

export default {
  components: {
    IconSearch,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      default: 'q',
    },
    inputId: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    translations: () => translations,
  },

  mounted() {
    this.hydrated()
  },

  methods: {
    blurHandler() {
      this.$emit('close-listbox')
    },

    resetHandler() {
      this.$emit('change', '')
    },

    submitHandler() {
      this.$emit('submit', this.value)
    },

    changeHandler({ target }) {
      this.$emit('change', target.value)
    },

    specialKeyHandler({ code }) {
      if (code === 'ArrowUp') {
        this.$emit('keydown', -1)
      } else if (code === 'ArrowDown') {
        this.$emit('keydown', 1)
      }
    },

    handleFocus() {
      this.$refs.input.focus()
      this.$emit('focus')
    },

    blur() {
      this.$refs.input.blur()
    },

    hydrated() {
      if (this.$refs.input === document.activeElement) {
        this.handleFocus()
      }
    },
  },
}
