import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import Router from 'vue-router'

import {
  getBcp47LocaleFrom,
  getDefaultLocaleFromCountry,
  isCountrySupported,
} from '@core/helpers/countries'

import { getCountry, getMarketplace, reloadForRoute } from './helpers'
import { getModulesRoutes } from './modulesRoutes'
import * as ROUTES from './names'

// https://stackoverflow.com/a/55544303/869162
// Don't install router globally for tests
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

export const createRouter = async (
  ssrContext,
  createDefaultRouter,
  routerOptions,
  config,
) => {
  const country = routerOptions?.country || getCountry(ssrContext)
  const marketplace = routerOptions?.marketplace || getMarketplace(ssrContext)
  const locale = getBcp47LocaleFrom(getDefaultLocaleFromCountry(country))

  if (!isCountrySupported(country)) {
    // The router being a Promise, we keep the same type
    return Promise.reject(new Error('Country not supported'))
  }

  const routes = getModulesRoutes({ country, marketplace, locale, config })

  const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (to.hash.includes('scroll=false')) {
        return false
      }
      if (
        (to.name === from.name && isEqual(to.params, from.params)) ||
        to.meta.scrollToSavedPosition === false
      ) {
        return false
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition || { x: 0, y: 0 })
        }, 150)
      })
    },
    routes,
  })

  // If a route is not rolled out, we refresh the website on the target URL, to go through Doorman.
  // Doorman would redirect to Badoom if this is necessary.
  router.beforeEach((to, from, next) => {
    if (
      !isEmpty(from.name) &&
      (reloadForRoute(to) || to.name === ROUTES.AUTH.REGISTER)
    ) {
      window.location.assign(to.fullPath)

      return next(false)
    }

    return next()
  })

  return router
}
