
import { mapGetters } from 'vuex'

import { COMPANY_NAME } from '@config/constants'
import { getDeepLinkByRoute } from '@core/helpers/deepLink'
import { BASE_META, DEFAULT_META_IMAGE, getMetas } from '@core/helpers/head'
import {
  WHITELISTED_ROUTE,
  metadataWithSmartBanner,
  trackingContext,
  trackingLink,
} from '@core/helpers/smartBanner'
import ExternalReviews from '@external-reviews'
import Footer from '@navigation/components/Footer'
import Header from '@navigation/components/Header.vue'

export default {
  name: 'DefaultLayout',
  components: {
    Footer,
    Header,
    ExternalReviews,
  },

  head() {
    if (this.isIOSSmartBannerDisplayed) {
      return this.metaWithSmartBanner
    }

    return this.defaultMetadata
  },
  computed: {
    ...mapGetters({
      locale: 'config/locale',
      baseURL: 'config/baseURL',
      isNavigationOpened: 'isNavigationOpened',
      isMobileAppAvailable: 'config/isMobileAppAvailable',
      country: 'config/country',
      landingId: 'landing/landingId',
    }),

    mainClass() {
      const styles = []

      if (this.isNavigationOpened) {
        styles.push(this.$style.overlay)
      }

      return styles
    },

    isIOSSmartBannerActivatedForRoute() {
      return WHITELISTED_ROUTE.includes(this.$route.name)
    },

    isIOSSmartBannerDisplayed() {
      return this.isMobileAppAvailable && this.isIOSSmartBannerActivatedForRoute
    },

    defaultMetadata() {
      return {
        titleTemplate: `%s | ${COMPANY_NAME}`,

        htmlAttrs: {
          lang: this.locale,
        },

        bodyAttrs: {
          class: this.isNavigationOpened ? 'deploy-nav' : '',
        },

        meta: [
          ...BASE_META,
          ...getMetas({
            image: this.$static(DEFAULT_META_IMAGE),
            url: `${this.baseURL}${this.$route.fullPath}`,
          }),
        ],
      }
    },

    metaWithSmartBanner() {
      const context = {
        $route: this.$route,
        $store: { landingId: this.landingId },
      }
      const deepLink = getDeepLinkByRoute(context)

      const deepLinkWithTracking = trackingLink({
        context: trackingContext(context),
        country: this.country,
        deepLink,
        appsflyerBaseUrl: this.$config.APPSFLYER_BASE_URL,
        route: this.$route.name,
      })

      return metadataWithSmartBanner({
        deepLink: deepLinkWithTracking,
        metadata: this.defaultMetadata,
      })
    },
  },
}
