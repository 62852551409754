// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2s_lHITtKHIdH7E6mYHM7_{position:fixed;top:0;left:0;right:0;transition:all .2s ease}._2s_lHITtKHIdH7E6mYHM7_._1GJEkvWPAYEF9sxKCIf7rX{transform:translateY(-100%)}._2kKM90WZphfvQqBWvHzqu0{-ms-overflow-style:none;scrollbar-width:none}._2kKM90WZphfvQqBWvHzqu0::-webkit-scrollbar{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animated": "_2s_lHITtKHIdH7E6mYHM7_",
	"hidden": "_1GJEkvWPAYEF9sxKCIf7rX",
	"hideScrollbar": "_2kKM90WZphfvQqBWvHzqu0"
};
module.exports = ___CSS_LOADER_EXPORT___;
