import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

import {
  ALLOWED_CONTEXTS,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
} from './Platform/constants'

export default () => {
  return [
    // Customer request - Merchant SAV Platform.
    {
      name: ROUTES.SAV_PLATFORM.HOME,
      path: '/bo_merchant/customer-request',
      component: () =>
        dynamicImport(import('@sav-platform/ConnectedSAVPlatform')),
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        merchant: {
          required: true,
        },
      },
      props: {
        role: SAV_PLATFORM_ROLES.merchant,
      },
      redirect: {
        name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS,
        params: {
          context: SAV_PLATFORM_CONTEXTS.pending,
        },
      },
      children: [
        {
          name: ROUTES.SAV_PLATFORM.INVOICE_REQUESTS,
          path: 'invoice-requests',
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedInvoiceRequests.vue'),
              ),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.RESPONSE_TEMPLATES,
          path: 'response-templates',
          components: {
            rightPanel: () =>
              dynamicImport(import('@sav-templates/ConnectedTemplates')),
          },
          props: {
            rightPanel: {
              isMerchant: true,
            },
          },
        },
        {
          name: ROUTES.SAV_PLATFORM.CUSTOMER_REQUESTS,
          path: `:context(${ALLOWED_CONTEXTS[SAV_PLATFORM_ROLES.merchant].join(
            '|',
          )})?/:customerRequestId([0-9]+)?/:claimId([0-9]+)?`,
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
      ],
    },
    // Customer request - Admin SAV Platform.
    {
      name: ROUTES.SAV_PLATFORM_ADMIN.HOME,
      path: '/bo_admin/customer-request',
      component: () =>
        dynamicImport(import('@sav-platform/ConnectedSAVPlatform')),
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        staff: {
          required: true,
        },
      },
      props: {
        role: SAV_PLATFORM_ROLES.admin,
      },
      redirect: {
        name: ROUTES.SAV_PLATFORM_ADMIN.CUSTOMER_REQUESTS,
      },
      children: [
        {
          name: ROUTES.SAV_PLATFORM_ADMIN.RESPONSE_TEMPLATES,
          path: 'response-templates',
          components: {
            rightPanel: () =>
              dynamicImport(import('@sav-templates/ConnectedTemplates')),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM_ADMIN.CUSTOMER_REQUESTS,
          path: `:context(${ALLOWED_CONTEXTS[SAV_PLATFORM_ROLES.admin].join(
            '|',
          )})?/:customerRequestId([0-9]+)?/:claimId([0-9]+)?`,
          meta: {
            scrollToSavedPosition: false,
          },
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
      ],
    },
    // Customer request - BackCare SAV Platform.
    {
      name: ROUTES.SAV_PLATFORM_BACKCARE.HOME,
      path: '/bo_admin/backcare/customer-request',
      component: () =>
        dynamicImport(import('@sav-platform/ConnectedSAVPlatform')),
      meta: {
        auth: {
          required: true,
          // TODO: Use route names instead
          redirection: ['/bo_merchant/'],
        },
        staff: {
          required: true,
        },
      },
      props: {
        role: SAV_PLATFORM_ROLES.backcare,
      },
      children: [
        {
          name: ROUTES.SAV_PLATFORM_BACKCARE.RESPONSE_TEMPLATES,
          path: 'response-templates',
          components: {
            rightPanel: () =>
              dynamicImport(import('@sav-templates/ConnectedTemplates')),
          },
        },
        {
          name: ROUTES.SAV_PLATFORM_BACKCARE.CUSTOMER_REQUESTS,
          path: `:context(${ALLOWED_CONTEXTS[SAV_PLATFORM_ROLES.backcare].join(
            '|',
          )})?/:customerRequestId([0-9]+)?/:claimId([0-9]+)?`,
          components: {
            rightPanel: () =>
              dynamicImport(
                import('@sav-platform/pages/ConnectedCustomerRequests.vue'),
              ),
          },
        },
      ],
    },
  ]
}
