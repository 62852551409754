import { trackUserContext } from '@tracking/events'

const executed = false

const fetchUser = async (store, callback) => {
  await store.dispatch('user/fetch')

  callback()
}

/**
 * Fetch the authenticated user information.
 * Must be called client-side only.
 */
export default function authenticate({ store }) {
  // Because we store stuff related to the session (user profile, cookies, ...),
  // we only want this util to be executed once in the lifetime of the app.
  if (executed) return

  const trackUserContextFn = () => {
    if (store.getters['auth/status']) {
      // If an AM impersonates a merchant account, the API sets the
      // is_staff property to false. However, we do want to make the
      // difference for the tracking.
      const isStaff = store.getters['user/isShadowingMerchant']
        ? true
        : store.getters['user/isStaff']

      trackUserContext({
        isStaff,
        userId: store.getters['auth/id'],
        clientId: store.getters['user/clientId'],
        emailHashed: store.getters['user/emailHashed'],
        merchantId: store.getters['user/merchantId'],
        lastOrderDate: store.getters['user/lastOrderDate'],
        numberOfOrders: store.getters['user/numberOfOrders'],
        lifetimeRevenue: store.getters['user/lifetimeRevenue'],
      })
    }
  }

  if (!store.getters['auth/fetched']) {
    // this should never be with an await else we ll have an issue on the first renderer
    fetchUser(store, trackUserContextFn)
  }
  trackUserContextFn()
}
