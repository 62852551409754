export default {
  namespaced: true,
  state() {
    return {
      listingsId: [],
      technicalSpecifications: {},
    }
  },

  getters: {
    listingsId: ({ listingsId }) => listingsId,
    getTechnicalSpecifications: ({ technicalSpecifications }) =>
      technicalSpecifications,
  },

  mutations: {
    setListingsId(state, listingsId) {
      state.listingsId = listingsId
    },
    setTechnicalSpecifications(state, technicalSpecifications) {
      state.technicalSpecifications = technicalSpecifications
    },
  },

  actions: {
    storeListingsId({ commit }, grades) {
      const listings = grades.map(({ id }) => id)
      commit('setListingsId', listings)
    },
    storeTechnicalSpecifications({ commit }, technicalSpeficications) {
      commit('setTechnicalSpecifications', technicalSpeficications)
    },
  },
}
