import isEmpty from 'lodash/isEmpty'

import { getDefaultLocaleFromCountry } from '@core/helpers/countries'
import { formatDateToYYYYMMDD } from '@core/helpers/date'
import { NEWSLETTERS_NAMES } from '@dashboard/know-your-customer/Profile/components/Preferences/PreferenceSelect/PreferenceSelect.constants'
import { ALL_CATEGORIES } from '@seller/sub-modules/onboarded/pages/international-onboarding/InternationalOnboarding.constant'

export const mapToAddress = (address) => {
  const trim = (value) => (value || '').trim().replace(/\s/g, ' ')

  return {
    first_name: trim(address.firstName) || null,
    last_name: trim(address.lastName) || null,
    company: trim(address.company) || null,
    street: trim(address.street) || null,
    street2: trim(address.street2) || null,
    city: trim(address.city) || null,
    state_or_province: trim(address.stateOrProvince) || null,
    postal_code: trim(address.postalCode) || null,
    country: trim(address.country) || null,
    country_dial_in_code: trim(address.countryDialInCode) || null,
    phone: trim(address.phone) || null,
    birthdate: formatDateToYYYYMMDD(trim(address.birthdate)) || null,
    nationality: trim(address.nationality) || null,
    customer_id_number: trim(address.customerIdNumber) || null,
  }
}

export const mapToClientAddress = ({ isShipping, isBilling, ...address }) => ({
  ...(isShipping
    ? { is_shipping: true, same_as_shipping: !!isBilling }
    : { is_shipping: false }),
  ...mapToAddress(address),
})

export const mapToNewsletterPreferences = (preferences) => {
  return preferences.map((data) => {
    // Temporary fix: Backend API is still waiting for specific values for the frequencies
    // Todo [LIF-426] Remove after Braze migration is done (see [LIF-418] for cleanup)
    const newsletterRules = {
      [NEWSLETTERS_NAMES.sletter]: { frequency: 0 },
      [NEWSLETTERS_NAMES.bestDeals]: { frequency: 30 },
    }
    const { frequency } = newsletterRules[data.name]

    return {
      email_preference: data.userPreferenceId,
      marketing_newsletter: data.newsletterId,
      optin: data.optin,
      frequency,
    }
  })
}

export const mapToKybProfile = (data) => ({
  pseudo: data.legalName,
  company: data.displayName,
  address__phone: data.phone,
  address__street: data.address,
  address__city: data.city,
  address__postal_code: data.zipCode,
  address__state_or_province: data.state,
  address__country: data.country,
  vat_number: data.ein,
  business_mail: data.email,
})

export const mapToKybBank = (data) => ({
  void_check: data.statement,
  merchant__wallet__account_number: data.accountNumber,
  merchant__wallet__branch_code: data.routingNumber,
  owner_name: data.owner,
  currency: data.currencyCode,
  country_code: data.countryCode,
})

export const mapToKybOwner = (data) => ({
  address__first_name: data.firstName,
  address__last_name: data.lastName,
  address__birthdate: data.birthdate,
  address__street: data.address,
  address__city: data.city,
  address__postal_code: data.zipCode,
  address__gender: parseInt(data.gender, 10),
  address__state_or_province: data.state,
  address__country: data.country,
  address__email: data.email,
  merchant__social_security_number: data.ssn,
})

export const mapToInternationalOnboarding = (payload) => {
  const finalPayload = { ...payload }

  if (!isEmpty(payload.markets)) {
    finalPayload.markets = payload.markets.map(getDefaultLocaleFromCountry)
  }

  if (!isEmpty(payload.shipping) && Array.isArray(payload.shipping)) {
    const shippingMethods = payload.shipping.map(
      ({ countryCode, ...method }) => ({
        ...method,
        // ! for the backend, a countryCode is a locale
        countryCode: getDefaultLocaleFromCountry(countryCode),
      }),
    )

    const categoryShipping = shippingMethods.filter(
      ({ category }) => category !== ALL_CATEGORIES,
    )

    const globalShipping = shippingMethods.filter(
      ({ category }) => category === ALL_CATEGORIES,
    )

    finalPayload.shipping = { globalShipping, categoryShipping }
  }

  if (!isEmpty(payload.customerCare)) {
    finalPayload.customerCare = payload.customerCare.map(
      ({ market, ...rest }) => ({
        ...rest,
        market: getDefaultLocaleFromCountry(market),
      }),
    )
  }

  if (!isEmpty(payload.payment)) {
    finalPayload.payment = payload.payment.map(({ market, ...rest }) => ({
      ...rest,
      market: getDefaultLocaleFromCountry(market),
    }))
  }

  if (!isEmpty(payload.pricingRules) && !isEmpty(payload.pricingRules.rules)) {
    finalPayload.pricingRules = {
      rules: payload.pricingRules.rules.map((rule) => ({
        ...rule,
        targetMarket: getDefaultLocaleFromCountry(rule.targetMarket),
      })),
    }
  }

  return finalPayload
}

export const mapToDiscount = (payload) => ({
  ...payload,
  country_code: getDefaultLocaleFromCountry(payload.country_code),
})

export const mapToOperationSubscribe = (payload) => {
  return {
    ...payload,
    // TODO: On the endpoint, use the header instead?
    country: getDefaultLocaleFromCountry(payload.country),
  }
}

export const mapToChangeReferenceMarket = (payload) => ({
  referenceMarket: getDefaultLocaleFromCountry(payload.referenceMarket),
})

export const mapToUpdatePayPalActiveMarkets = (payload) => ({
  markets: payload.markets.map(getDefaultLocaleFromCountry),
})

export const mapToCreateMerchantShippingMethod = (payload) => ({
  ...payload,
  market: getDefaultLocaleFromCountry(payload.market),
})

export const mapToCreateBalanceAdjustment = (payload) => ({
  merchant_id: payload.merchantId,
  action: payload.action,
  mention: payload.mention,
  designation: payload.designation,
  amount: payload.amount,
  currency: payload.currency,
  comments: payload.comments,
})
