
import { SCHEMA_SEARCH_NAME } from '@core/helpers/head'

import translations from './Combobox.translations'
import Listbox from './Listbox.vue'
import Textbox from './Textbox.vue'

export default {
  components: {
    Textbox,
    Listbox,
  },
  props: {
    popularSearches: {
      type: Array,
      default: () => [],
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    inputId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      selectedIndex: -1,
      isListboxOpen: false,
      inputValue: '',
    }
  },

  computed: {
    inputName: () => SCHEMA_SEARCH_NAME,
    selection() {
      return {
        query: this.inputValue,
        item: this.items[this.selectedIndex],
        index: this.selectedIndex,
      }
    },

    hasQuery() {
      return this.inputValue !== ''
    },

    title() {
      if (this.inputValue) {
        return ''
      }

      return this.$t(translations.popularSearches)
    },

    items() {
      if (this.inputValue) {
        return this.suggestions
      }

      return this.popularSearches
    },
  },

  beforeMount() {
    const input = document.querySelector(`#${this.inputId}`)
    if (input) {
      this.changeHandler(input.value)
    }
  },

  methods: {
    submitHandler() {
      this.submit()
    },

    changeHandler(value) {
      this.inputValue = value
      this.selectedIndex = -1
      this.$emit('change', this.inputValue)
    },

    selectIndex(index) {
      this.selectedIndex = index
      this.submit()
    },

    changeSelectedIndex(offset) {
      const { length } = this.items
      if (length !== 0) {
        this.selectedIndex = (length + this.selectedIndex + offset) % length
      }
    },

    closeListbox() {
      this.isListboxOpen = false
    },

    openListbox() {
      this.selectedIndex = -1
      this.isListboxOpen = true
    },

    submit() {
      this.$refs.textbox.blur()
      this.$emit('submit', this.selection)
    },
  },
}
