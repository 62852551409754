import isEmpty from 'lodash/isEmpty'

import { discountCheck } from '@http/endpoints'

import translations from './discount.translations'

/**
 * @typedef {import('@http/transformResponse/transformResponse').Discount} Discount
 */

/**
 * @typedef {Object} DiscountPaymentData
 * @property {string} id
 * @property {Price} deduction
 * @property {Price} totalDeduction
 */

/**
 * @type Discount
 */
const EMPTY_DISCOUNT = {
  id: '',
  deductionMax: 0,
  deduction: 0,
  deductedPrice: 0,
  referralBase: 0,
}

export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {Price} basePrice
   * @property {string} token
   * @property {Discount|null} discount
   * @property {string} errorMessage
   * @property {boolean} disabledStatus
   * @property {string} disabledMessage
   *
   * @returns {State}
   */
  state: () => ({
    basePrice: {
      currency: '',
      amount: '0.00',
    },
    token: '',
    discount: { ...EMPTY_DISCOUNT },
    errorMessage: '',
    disabledStatus: false,
    disabledMessage: '',
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {Price} basePrice
     */
    setBasePrice(state, basePrice) {
      state.basePrice = basePrice
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {boolean} payload.disabledStatus
     * @param {string} payload.disabledMessage
     */
    setDisabled(state, { disabledStatus, disabledMessage }) {
      state.disabledStatus = disabledStatus
      state.disabledMessage = disabledMessage
    },

    /**
     * @param {State} state
     * @param {string} token
     */
    setToken(state, token) {
      state.token = token
    },

    /**
     * @param {State} state
     * @param {import('@http/transformResponse/transformResponse').Discount} discount
     */
    setDiscount(state, discount) {
      state.discount = discount
      state.errorMessage = ''
    },

    /**
     * @param {State} state
     * @param {string} errorMessage
     */
    setError(state, errorMessage) {
      state.discount = { ...EMPTY_DISCOUNT }
      state.errorMessage = errorMessage
    },

    /**
     * @param {State} state
     */
    deleteDiscount(state) {
      state.discount = { ...EMPTY_DISCOUNT }
      state.errorMessage = ''
    },

    /**
     * @param {State} state
     * @param {Object} payload
     * @param {number} deduction
     * @param {number} deductedPrice
     */
    setDeduction(state, { deduction, deductedPrice }) {
      state.discount.deduction = deduction
      state.discount.deductedPrice = deductedPrice
    },
  },

  getters: {
    /**
     * @param {State} state
     * @returns {string}
     */
    token: (state) => state.token,

    /**
     * @param {State} state
     * @returns {string}
     */
    errorMessage: (state) =>
      state.disabledStatus ? state.disabledMessage : state.errorMessage,

    /**
     * @param {State} state
     * @returns {boolean}
     */
    isDisabled: (state) => state.disabledStatus,

    /**
     * @param {State} state
     * @returns {string}
     */
    id: (state) => state.discount.id,

    /**
     * @param {State} state
     * @returns {Price}
     */
    deduction: (state) => ({
      currency: state.basePrice.currency,
      amount: String(state.discount.deduction),
    }),

    /**
     * @param {State} state
     * @returns {Price}
     */
    deductedPrice: (state) => ({
      currency: state.basePrice.currency,
      amount: String(state.discount.deductedPrice),
    }),

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {boolean}
     */
    isApplied: (state, getters) => !isEmpty(getters.id) && !getters.isDisabled,

    /**
     * @param {State} state
     * @param {Object} getters
     * @returns {DiscountPaymentData|null}
     */
    paymentData: (state, getters) => {
      if (!getters.isApplied) {
        return null
      }

      return {
        id: getters.id,
        deduction: getters.deduction,
        totalDeduction: getters.deduction,
      }
    },
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Price} basePrice
     */
    setBasePrice({ state, commit }, basePrice) {
      if (
        state.basePrice.currency !== basePrice.currency ||
        state.basePrice.amount !== basePrice.amount
      ) {
        commit('deleteDiscount')
        commit('setBasePrice', basePrice)
      }
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {Object} payload.paymentMethod
     * @param {Object} payload.paymentMethodGroup
     */
    setPaymentMethod({ commit }, { paymentMethod, paymentMethodGroup }) {
      commit('setDisabled', {
        disabledStatus: !paymentMethod.promoCodeEnabled,
        disabledMessage: this.$t(translations.unavailableForPaymentProvider, {
          paymentProvider: paymentMethodGroup.providerName,
        }),
      })
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     */
    reset({ commit }) {
      commit('deleteDiscount')
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {string} token
     */
    saveToken({ commit }, token) {
      commit('deleteDiscount')
      commit('setToken', token)
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {string} token
     */
    async checkToken({ state, commit, dispatch, rootGetters }, token) {
      commit('deleteDiscount')
      commit('setToken', token)

      const { payload } = await dispatch(
        'http/request',
        {
          request: discountCheck,
          body: {
            country_code: rootGetters['config/country'],
            client_pk: rootGetters['auth/id'],
            bag_price: state.basePrice.amount,
            token,
          },
        },
        { root: true },
      )
      const { isValid, errorMessage, ...discount } = payload

      if (isValid) {
        commit('setDiscount', discount)
      } else {
        commit('setError', errorMessage)
      }
    },
  },
}
