import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

const Product = () => dynamicImport(import('./pages/product.vue'))

export default () => {
  const routePath = {
    [ROUTES.PRODUCT.MERCHANT]: 's/:merchantName/:merchantId/p/:uuid',
    [ROUTES.PRODUCT.HOME]: 'p/:slugV2/:uuid',
  }

  return [
    {
      name: ROUTES.PRODUCT.MERCHANT,
      path: routePath[ROUTES.PRODUCT.MERCHANT],
      component: Product,
    },
    {
      name: ROUTES.PRODUCT.HOME,
      path: routePath[ROUTES.PRODUCT.HOME],
      component: Product,
    },
  ]
}
