import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  fetchActionsCustomerRequestDiscussion,
  fetchDetailsCustomerRequestDiscussion,
  fetchInformationCustomerRequestDiscussion,
  fetchProductReturnsCustomerRequestPlatform,
  postCustomerRequestPlatformCustomerMessagesVisibility,
  sosCustomerRequestDiscussion,
} from '@http/endpoints'
import { getApiPathFromRole } from '@sav-common/helpers'

export default {
  namespaced: true,

  state() {
    return {
      actions: {},
      customerRequest: {},
    }
  },
  getters: {
    information(state) {
      return state.customerRequest
    },
    isAllowed(state) {
      return (action, defaultValue = false) =>
        get(state.actions, action, defaultValue)
    },
    customerMessagesVisibleToSeller(state) {
      return Boolean(state.customerRequest.customerMessagesVisibleToSeller)
    },
    isCustomerRequestBackCare(state) {
      return Boolean(state.customerRequest.backcare)
    },
    lastAttachmentSharedAt(state) {
      const lastAttachmentSharedAt =
        state.customerRequest?.lastAttachmentSharedAt

      if (lastAttachmentSharedAt === null) {
        return null
      }

      return new Date(lastAttachmentSharedAt)?.getTime()
    },
  },
  mutations: {
    clear(state) {
      state.actions = {}
      state.customerRequest = {}
    },
    closeDetails(state) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      state.customerRequest = {
        ...state.customerRequest,
        detailed: false,
      }
    },
    set(state, { information }) {
      state.customerRequest = information
    },
    setActions(state, { actions }) {
      state.actions = actions
    },
    setCustomerMessagesVisibility(state, { customerMessagesVisibleToSeller }) {
      state.customerRequest = {
        ...state.customerRequest,
        customerMessagesVisibleToSeller,
      }
    },
    setDetails(state, { details }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      state.customerRequest = {
        ...state.customerRequest,
        ...details,
        product: {
          ...state.customerRequest.product,
          ...details.product,
        },
        orderline: {
          ...state.customerRequest.orderline,
          ...details.orderline,
        },
        detailed: true,
      }
    },
    setProductReturns(state, { productReturns }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      state.customerRequest = {
        ...state.customerRequest,
        productReturns,
      }
    },
    update(state, { updates }) {
      if (isEmpty(state.customerRequest)) {
        return
      }

      state.customerRequest = {
        ...state.customerRequest,
        ...updates,
      }
    },
  },
  actions: {
    async changeCustomerMessagesVisibility(
      { commit, dispatch },
      { customerRequestId, role, customerMessagesVisibleToSeller },
    ) {
      await dispatch(
        'http/request',
        {
          request: postCustomerRequestPlatformCustomerMessagesVisibility,
          pathParams: { customerRequestId, role: getApiPathFromRole(role) },
          body: {
            customerMessagesVisibleToSeller,
          },
        },
        { root: true },
      )
      commit('setCustomerMessagesVisibility', {
        customerMessagesVisibleToSeller,
      })
    },
    clear({ commit }) {
      commit('clear')
    },
    closeDetails({ commit }) {
      commit('close-details')
    },
    async fetch({ commit, dispatch }, { customerRequestId }) {
      const { payload: information } = await dispatch(
        'http/request',
        {
          request: fetchInformationCustomerRequestDiscussion,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('set', { information })
    },
    async fetchActions({ commit, dispatch }, { customerRequestId }) {
      const { payload: actions } = await dispatch(
        'http/request',
        {
          request: fetchActionsCustomerRequestDiscussion,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('setActions', { actions })
    },
    async fetchDetails({ commit, dispatch }, { customerRequestId }) {
      const [{ payload: details }, { payload: productReturns }] =
        await Promise.all([
          dispatch(
            'http/request',
            {
              request: fetchDetailsCustomerRequestDiscussion,
              pathParams: { customerRequestId },
            },
            { root: true },
          ),
          dispatch(
            'http/request',
            {
              request: fetchProductReturnsCustomerRequestPlatform,
              pathParams: { customerRequestId },
            },
            { root: true },
          ),
        ])

      commit('setDetails', { details })
      commit('setProductReturns', { productReturns })
    },
    async sos({ dispatch }, { customerRequestId }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: sosCustomerRequestDiscussion,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      if (!isEmpty(payload.message)) {
        dispatch(
          'customerRequestDiscussion/discussion/addMessage',
          {
            message: payload.message,
          },
          { root: true },
        )
      }
    },
    update({ commit }, { customerRequestId, updates }) {
      commit('update', { customerRequestId, updates })
    },
  },
}
