export default {
  title: {
    id: 'installments_simulation_modal_title_new',
    defaultMessage: 'Pay in installments',
  },
  subtitle: {
    id: 'installments_simulation_modal_subtitle',
    defaultMessage: 'Pay in installments via',
  },
  step1Title: {
    id: 'installments_simulation_modal_can_i_pay_in_installments',
    defaultMessage: 'Can I pay in installments?',
  },
  step1Description: {
    id: 'installments_simulation_modal_can_i_pay_in_installments_description',
    defaultMessage:
      'Yes and no, it depends on the merchants. To ensure that you can pay in installments, rely on the logo of our partner Oney:',
  },
  step2Title: {
    id: 'installments_simulation_modal_first_title',
    defaultMessage: 'Simple operation',
  },
  step2Description: {
    id: 'installments_simulation_modal_first_description',
    defaultMessage:
      'Validate your basket then choose the Payment option 3x or 4x by Credit Card.',
  },
  step3Title: {
    id: 'installments_simulation_modal_second_title',
    defaultMessage: "It's your turn!",
  },
  step3Description: {
    id: 'installments_simulation_modal_second_description',
    defaultMessage:
      'Complete the requested information (without providing any documents). Then get an immediate response from Oney.',
  },
}
