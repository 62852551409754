import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { isNumber } from '@core/helpers'
import {
  fetchBackRepairFactories,
  fetchCustomerRequestPlatformCarriers,
  fetchCustomerRequestPlatformCounts,
  fetchCustomerRequestPlatformMerchant,
  fetchCustomerRequestPlatformMerchantInformation,
  fetchCustomerRequestPlatformTemplates,
} from '@http/endpoints'
import { getApiPathFromRole } from '@sav-common/helpers'

import {
  COUNTS_PROPERTIES,
  COUNTS_UPDATE_STATUS,
  SAV_PLATFORM_ROLES,
} from '../../../constants'

export default {
  namespaced: true,

  state() {
    return {
      backRepairFactories: [],
      carriers: [],
      counts: {},
      countsStatus: {},
      dragging: false,
      merchant: {},
      templates: {},
    }
  },
  getters: {
    backRepairFactories(state) {
      return state.backRepairFactories
    },
    carriers(state) {
      return state.carriers
    },
    counts(state) {
      return state.counts
    },
    dragging(state) {
      return state.dragging
    },
    merchant(state) {
      return state.merchant
    },
    merchantBackcareCountries(state) {
      return state.merchant.backcare
    },
    merchantHasBackcare(state) {
      return Object.values(state.merchant.backcare || {}).some((hasBackare) =>
        Boolean(hasBackare),
      )
    },
    merchantHasBackship(state) {
      return Boolean(state.merchant.backship)
    },
    merchantIsBackshipEligible(state) {
      return state.merchant.backship
        ? false
        : Boolean(state.merchant.backshipEligible)
    },
    merchantLocales(state) {
      return Object.keys(state.merchant.locales || {})
        .filter((locale) => state.merchant.locales[locale])
        .map((locale) => (locale === 'en-eu' ? 'en-us' : locale))
    },
    merchantName(state) {
      return state.merchant.name
    },
    returnAddresses(state) {
      return state.merchant.returnAddresses
    },
    templates(state, _, __, rootGetters) {
      const locale = get(
        rootGetters['customerRequestDiscussion/information/information'],
        'orderline.order.locale',
        '',
      )

      return get(state.templates, locale, [])
    },
    countUpdateStatus(state) {
      return (context) =>
        state.countsStatus[context] || COUNTS_UPDATE_STATUS.noUpdate
    },
  },
  mutations: {
    acknowledge(state, { context }) {
      state.countsStatus[context] = COUNTS_UPDATE_STATUS.noUpdate
    },
    clearMerchant(state) {
      state.merchant = {}
    },
    setBackRepairFactories(state, { factories = [] } = {}) {
      state.backRepairFactories = factories.map(
        ({ address = {}, backrepairFactoryId }) => ({
          ...omit(address, 'addressId'),
          backrepairFactoryId,
          returnAddressId: address.addressId,
        }),
      )
    },
    setCarriers(state, { carriers = [] } = {}) {
      state.carriers = carriers.reduce(
        (
          allCarriers,
          { id, shipper_name: name, is_shipper_used: enabled, ...descriptions },
        ) => {
          if (!enabled) {
            return allCarriers
          }

          const description = Object.entries(descriptions).reduce(
            (allDescriptions, [descriptionName, value]) => {
              if (isEmpty(value)) {
                return allDescriptions
              }

              const locale = descriptionName.replace('description_', '')

              return {
                ...allDescriptions,
                [locale]: value,
              }
            },
            {},
          )

          return [
            ...allCarriers,
            {
              id,
              name,
              description,
            },
          ]
        },
        [],
      )
    },
    setCounts(state, { counts, notifyUpdate = false }) {
      const newCounts = {}
      const newCountsStatus = {}
      Object.entries(COUNTS_PROPERTIES).forEach(([context, countProperty]) => {
        if (notifyUpdate) {
          const count = counts[countProperty]
          const previousStatus = state.countsStatus[context]

          // Do not update the status of a count that has already been updated
          // but not yet acknowledged.
          if (
            isNumber(previousStatus) &&
            previousStatus !== COUNTS_UPDATE_STATUS.noUpdate
          ) {
            newCountsStatus[context] = previousStatus
          } else {
            let countUpdateStatus = COUNTS_UPDATE_STATUS.noUpdate

            if (
              state.counts[context] === undefined ||
              count > state.counts[context]
            ) {
              countUpdateStatus = COUNTS_UPDATE_STATUS.added
            } else if (count < state.counts[context]) {
              countUpdateStatus = COUNTS_UPDATE_STATUS.removed
            }

            newCountsStatus[context] = countUpdateStatus
          }
        }

        newCounts[context] = counts[countProperty]
      })

      state.countsStatus = newCountsStatus
      state.counts = newCounts
    },
    setDragging(state, { isDragging }) {
      state.dragging = isDragging
    },
    setMerchant(state, { merchant }) {
      state.merchant = merchant
    },
    setTemplates(state, { templates }) {
      state.templates = templates.reduce((categoriesByLocale, category) => {
        if (isEmpty(category.locale)) {
          return categoriesByLocale
        }

        const { locale } = category
        const categories = categoriesByLocale[locale] || []

        return {
          ...categoriesByLocale,
          [locale]: [...categories, category],
        }
      }, {})
    },
  },
  actions: {
    acknowledge({ commit }, { context }) {
      commit('acknowledge', { context })
    },
    clearMerchant({ commit }) {
      commit('clearMerchant')
    },
    dragging({ commit }) {
      commit('setDragging', { isDragging: true })
    },
    async fetchBackRepairFactories({ commit, dispatch }, { orderlineId }) {
      const { payload: factories } = await dispatch(
        'http/request',
        {
          request: fetchBackRepairFactories,
          pathParams: { orderlineId },
        },
        { root: true },
      )

      commit('setBackRepairFactories', { factories })
    },
    async fetchCarriers({ commit, dispatch }) {
      const {
        payload: { results: carriers },
      } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformCarriers,
          queryParams: { trackable: true },
        },
        { root: true },
      )

      commit('setCarriers', { carriers })
    },
    async fetchCounts({ commit, dispatch }, { notifyUpdate = false } = {}) {
      const { payload: counts } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformCounts,
        },
        { root: true },
      )

      commit('setCounts', { counts, notifyUpdate })
    },
    async fetchMerchantInformation(
      { commit, dispatch },
      { customerRequestId } = {},
    ) {
      const { payload: merchant } = await dispatch(
        'http/request',
        {
          request: !isNumber(customerRequestId)
            ? fetchCustomerRequestPlatformMerchant
            : fetchCustomerRequestPlatformMerchantInformation,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('setMerchant', { merchant })
    },
    async fetchTemplates(
      { commit, dispatch },
      { role = SAV_PLATFORM_ROLES.merchant },
    ) {
      const { payload: templates } = await dispatch(
        'http/request',
        {
          request: fetchCustomerRequestPlatformTemplates,
          pathParams: { role: getApiPathFromRole(role) },
        },
        { root: true },
      )

      commit('setTemplates', { templates })
    },
    stopDragging({ commit }) {
      commit('setDragging', { isDragging: false })
    },
  },
}
