import { removeEmptyValuesInObject } from '@core/helpers'

const formatPrice = (price) => (price ? parseFloat(price, 2).toFixed(2) : price)

export const productModel = ({
  available = true,
  brand,
  category,
  chillpackDuration,
  color = '',
  dealType,
  id,
  insurancePrice,
  insuranceTitle,
  isSwapEnabled,
  list,
  listingId,
  merchantId,
  model,
  name,
  hasReviews,
  numberReviewsDisplayed,
  numberTotalReviews,
  position = 1,
  price,
  provider,
  // number of products selected by the user (funnel)
  quantity,
  // available stock of products
  stock,
  shipper,
  shippingDelay,
  shippingOptions = [],
  shippingPrice,
  hasExpressShipping,
  source,
  splitPayment,
  trustpackDuration,
  uuid,
  variant,
  warrantyDuration,
}) => {
  return removeEmptyValuesInObject({
    product_available: available,
    product_brand: brand,
    product_category: category,
    product_chillpack_months: chillpackDuration,
    product_color: color,
    product_deal_type: dealType,
    product_display_type: source,
    product_displayvendor: provider,
    product_has_review: hasReviews,
    product_id: uuid || (id && String(id)),
    product_insurance_price: insurancePrice,
    product_insurance_title: insuranceTitle,
    product_is_swap_eligible: isSwapEnabled,
    product_legacy_id: id && String(id),
    product_list: list,
    product_listingID: listingId && String(listingId),
    product_model: model,
    product_name: name,
    product_nb_reviews: numberTotalReviews,
    product_paymentmethod: splitPayment,
    product_position: position,
    product_price: formatPrice(price),
    product_quantity: quantity && Number(quantity),
    product_reviews_displayed: numberReviewsDisplayed,
    product_seller_id: merchantId && String(merchantId),
    product_shipper: shipper,
    product_shipping_delay: shippingDelay,
    product_has_shipping_express: hasExpressShipping,
    product_shipping_options: shippingOptions,
    product_shipping_price: formatPrice(shippingPrice),
    product_trustpack_duration: trustpackDuration,
    product_units_in_stock: stock && Number(stock),
    product_variant: variant,
    product_warranty_duration: warrantyDuration,
  })
}

export const productModelCollection = (products = []) => {
  const productsList = Array.isArray(products) ? products : [products]

  return productsList.map((product, index) => ({
    ...productModel({ ...product, position: product.position || index + 1 }),
  }))
}
