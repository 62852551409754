export const PROCESSOUT_SDK_URL = 'https://js.processout.com/processout.js'

export const PROCESSOUT_SDK_ALTERNATE_PAYMENT_METHODS_FILTER =
  'alternative-payment-methods'

export const PROCESSOUT_FIELDS = {
  // While the name field is rendered as a regular RevInputText locally, it is
  // still defined here, as SDK may return errors targeting that field.
  NAME: 'NAME',

  NUMBER: 'NUMBER',
  EXPIRY_DATE: 'EXPIRY_DATE',
  SECURITY_CODE: 'SECURITY_CODE',
}

export const PROCESSOUT_SCHEMES = {
  AMERICAN_EXPRESS: 'american-express',
  ARGENCARD: 'argencard',
  CABAL: 'cabal',
  CARDGUARD: 'cardguard ead bg ils',
  CARTES_BANCAIRES: 'carte bancaire',
  DANKORT: 'dankort',
  DINERS_CLUB: 'diners-club',
  DISCOVER: 'discover',
  ELO: 'elo',
  HIPERCARD: 'hipercard',
  JCB: 'jcb',
  MAESTRO: 'maestro',
  MASTERCARD: 'mastercard',
  NARANJA: 'naranja',
  UATP: 'uatp',
  UNION_PAY: 'union-pay',
  VISA: 'visa',
}
