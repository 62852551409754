import isEmpty from 'lodash/isEmpty'

import {
  landingBreadcrumbFetch,
  landingDetailsFetch,
  landingNavigationSeoFetch,
  sellerDetailsFetch,
} from '@http/endpoints'
import { ROUTES } from '@router'
import {
  PRODUCT_PROVIDERS,
  PRODUCT_SOURCE,
  PRODUCT_SOURCE_TYPE,
} from '@tracking'

import translations from './landing.translations'

export const getDefaultState = () => ({
  id: null,
  breadcrumb: [],
  marketingContent: {},
  hero: {},
  displayHeroAndRates: true,
  tagline: '',
  company: '',
  address: {},
  slug: '',
  type: null,
  ebType: null,
  title: '',
  subtitle: '',
  tags: [],
  seo: {
    title: '',
    description: '',
  },
  urls: {},
  links: {},
  link: {},
  listViewStructure: {},
  landingSeoNavigation: {},
  electronicWaste: {},
  buyingGuide: {},
  isLoading: false,
})

export default {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    saveLanding(state, payload) {
      Object.assign(state, payload)
    },
    setBreadcrumb(state, payload) {
      state.breadcrumb = payload
    },
    setLandingSeoNavigation(state, payload) {
      state.landingSeoNavigation = payload
    },
    reset(state) {
      Object.assign(state, {
        ...getDefaultState(),
      })
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
  },

  getters: {
    isLoading: (state) => {
      return state.isLoading
    },
    landingId: (state) => {
      return state.id
    },

    title: (state) => {
      return state.title
    },

    subtitle: (state) => {
      return state.subtitle
    },

    seoTitle: (state) => {
      return state.seo.title
    },

    seoText: (state) => {
      return state.seo.text
    },

    type: (state) => {
      return state.type
    },

    breadcrumb: (state) => {
      return state.breadcrumb
    },

    landingSeoNavigation: (state) => {
      return state.landingSeoNavigation
    },

    tagline: (state) => {
      return state.tagline
    },

    hero: (state) => {
      return state.hero || {}
    },

    heroTracking: (_, getters) => {
      const {
        id: listingId,
        product_id: productId,
        category,
        name,
        model,
        brand,
        color,
        gradeId,
        price,
        default_warranty: warrantyDuration,
        merchant_id: merchantId,
        recommendation_id: recommendationId,
        link,
      } = getters.hero

      return {
        id: productId,
        name,
        model,
        brand,
        color,
        category,
        variant: gradeId,
        warrantyDuration,
        merchantId,
        price,
        listingId,
        list: PRODUCT_SOURCE_TYPE.LP_HERO,
        source: recommendationId
          ? PRODUCT_SOURCE.RECOMMENDATIONS
          : PRODUCT_SOURCE.SEARCH,
        provider: recommendationId
          ? PRODUCT_PROVIDERS.EARLYBIRD
          : PRODUCT_PROVIDERS.ALGOLIA,
        uuid: link?.params?.uuid,
      }
    },

    slug: (state) => {
      return state.slug
    },

    urls: (state) => {
      return state.urls
    },

    links: (state) => {
      return state.links
    },

    marketingContent: (state) => {
      return state.marketingContent ?? {}
    },

    cardMarketingContent: (state) => {
      return state.marketingContent?.card ?? {}
    },

    listMarketingContent: (state) => {
      return state.marketingContent?.list ?? {}
    },

    shouldDisplayHeroAndRates: (state) => {
      return state.displayHeroAndRates && !isEmpty(state.hero)
    },

    electronicWaste: (state) => {
      return state.electronicWaste
    },

    buyingGuide: (state) => {
      return state.buyingGuide
    },

    tags: (state) => {
      return state.tags || []
    },

    isListView: (state) => {
      return !isEmpty(state.listViewStructure)
    },

    listViewStructure: (state) => {
      return state.listViewStructure
    },

    attributesToRetrieve: (state) => {
      if (isEmpty(state.listViewStructure)) {
        return []
      }

      const { title = [], table = [] } = state.listViewStructure

      return [
        title.map(({ attribute }) => attribute),
        table.map(({ attributes }) =>
          attributes.map(({ attribute }) => attribute),
        ),
      ].flat(Infinity)
    },
  },

  actions: {
    reset({ commit }) {
      commit('reset')
    },
    isLoadingProductLanding({ commit }, value) {
      commit('setIsLoading', value)
    },
    async fetchProductLanding({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingDetailsFetch,
          queryParams: {
            id,
          },
        },
        { root: true },
      )

      commit('saveLanding', { ...payload })
    },

    async fetchLandingBreadcrumb({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingBreadcrumbFetch,
          queryParams: { id },
        },
        { root: true },
      )

      const breadcrumb = [
        { slug: { name: ROUTES.HOME }, title: this.$t(translations.home) },
        ...payload,
      ]

      commit('setBreadcrumb', breadcrumb)
    },
    clearLandingSeoNavigation({ commit }) {
      commit('setLandingSeoNavigation', {})
    },

    async fetchLandingSeoNavigation({ dispatch, commit }, { id }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: landingNavigationSeoFetch,
          queryParams: { id },
        },
        { root: true },
      )

      commit('setLandingSeoNavigation', payload)
    },

    async fetchSellerLanding({ dispatch, commit }, sellerId) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: sellerDetailsFetch,
          pathParams: { id: sellerId },
        },
        { root: true },
      )

      commit('saveLanding', payload)
    },
  },
}
