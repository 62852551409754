import { getPlugin as getTranslationPlugin } from '@i18n/utils'

import {
  PROCESSOUT_ERRORS,
  PROCESSOUT_ERROR_DEFAULT_STRATEGY,
  PROCESSOUT_ERROR_STRATEGIES,
  PROCESSOUT_SDK_ERRORS,
} from '../config/errors'

import translations from './errors.translations'

/**
 * @typedef {Error & Object} ProcessOutError
 * @property {string} code See PROCESSOUT_ERRORS
 * @property {Object} data
 * @property {string} data.origin See PROCESSOUT_ERROR_ORIGINS
 * @property {Object} data.rootError
 * @property {string} data.rootError.message
 * @property {string} data.rootError.code
 * @property {string} data.rootError.stack
 */

/**
 * Create a normalize error, compliant with Error specs.
 * @see {@link https://github.com/BackMarket/api-models/blob/main/models/Problem.yaml}
 * @param {string} code
 * @param {Error & { code: string }} [rootError] Original ProcessOut SDK error, if any
 * @param {Object} [data]
 * @returns {ProcessOutError}
 */
export function processOutError(code, rootError = {}, data = {}) {
  const $t = getTranslationPlugin() || (() => {})
  const reason =
    $t(translations[code], rootError) ||
    rootError.message ||
    rootError.code ||
    code
  const strategy =
    PROCESSOUT_ERROR_STRATEGIES[code] || PROCESSOUT_ERROR_DEFAULT_STRATEGY

  const error = new Error(reason)
  error.code = code
  error.data = {
    isCancel:
      code === PROCESSOUT_ERRORS.SDK[PROCESSOUT_SDK_ERRORS.CUSTOMER_CANCELED],
    rootError: {
      message: rootError.message,
      stack: rootError.stack,
      ...rootError,
    },
    message: strategy.displayMessage
      ? {
          title: $t(translations.errorTitle),
          description: [$t(translations.errorDescription, { reason })],
        }
      : null,
    redirectionLink: strategy.redirect,
    target: strategy.target,
    ...data,
  }

  return error
}
