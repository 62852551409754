
import { mapGetters } from 'vuex'

import MinimalHeader from '@navigation/components/Minimal'

export default {
  components: {
    MinimalHeader,
  },

  // While this component is functionally a "layout", it is not meant to be used
  // in combination of [Nuxt layouts]. Therefore, child pages using this must
  // use an empty [Nuxt layout]. Defining the Nuxt layout here allows not to do
  // so in the child routes.
  // [Nuxt layouts]: https://nuxtjs.org/docs/directory-structure/layouts/
  layout: 'empty',

  head() {
    return {
      htmlAttrs: {
        lang: this.locale,
      },
    }
  },

  computed: {
    ...mapGetters({
      locale: 'config/locale',
    }),
  },
}
