import { CancelToken } from '@http/api'
import {
  getBuybackOffer,
  getEstimationSwapPrice,
  getSwapQuestions,
  postBuybackAnswers,
} from '@http/endpoints'
import { getPlugin as getTranslationPlugin } from '@i18n/utils'

const getDefaultState = () => ({
  questions: [],
  answers: [],
  offer: {},
  estimations: [],
  enabled: true,
  pendingRequest: null,
})

const mapInputTypes = {
  select: 'select',
  radioline: 'radio_line',
}

const formatOptions = (options, type) => {
  return options.map((option) => {
    const title = {}
    if (type === 'radio_line') {
      title.title = option.label
    }

    return {
      value: option.value.toString(),
      label: option.label,
      ...title,
    }
  })
}

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    payload(state) {
      return state.answers.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: item.value,
        }),
        {},
      )
    },
    isLastQuestion(state) {
      return state.questions.some((question) => question.last_question === true)
    },
    parsedQuestions(state) {
      return state.questions.map((question, index) => {
        const type = mapInputTypes[question.input && question.input.type]
        let options

        if (question.answer) {
          options = formatOptions(question.answer.options, type)
        }

        return {
          title: question.title,
          title_short: question.title_short,
          last_question: question.last_question,
          type,
          placeholder: question.input && question.input.placeholder,
          tooltipLabel: question.tooltip && question.tooltip.label,
          tooltipContent: question.tooltip && question.tooltip.content,
          answerKey: question.answer && question.answer.key,
          isAnswered: question.isAnswered,
          scroll: index !== 0,
          options,
        }
      })
    },
    estimations(state) {
      return state.estimations.map((estimation) => ({
        price: estimation.price_with_swap_discount_with_currency,
        id: estimation.listing_id,
        model: estimation.model,
        isOffer: estimation.cart_has_swap_offer,
        label: estimation.cart_has_swap_offer
          ? 'swap_product_page_banner_offer_in_cart'
          : 'swap_product_page_banner_offer',
      }))
    },
    answers(state) {
      return state.answers
    },
    hasNoOffer(state) {
      return state.offer && state.offer.standard_price === null
    },
    offer: ({ offer }) => offer,
    offerPrice: ({ offer }) => Number(offer?.standard_price?.amount),
    offerPriceWithCurrency({ offer }) {
      const $t = getTranslationPlugin()

      return $t.price(offer?.standard_price)
    },
    offerTitle(state) {
      return state.offer && state.offer.title
    },
    isSwapEnabled: ({ enabled }) => enabled,
    pendingRequest: (state) => state.pendingRequest,
  },
  mutations: {
    reset(state) {
      Object.assign(state, {
        ...getDefaultState(),
        enabled: state.enabled,
        estimations: state.estimations,
      })
    },
    saveAnswer(state, answer) {
      state.answers.push(answer)
    },
    saveQuestion(state, question) {
      state.questions.push(question)
    },
    setAnsweredQuestion(state, { index, key, value }) {
      state.questions[index][key] = value
    },
    resetAnswers(state, atIndex) {
      state.answers = state.answers.slice(0, atIndex)
    },
    resetQuestions(state, atIndex) {
      state.questions = state.questions.slice(0, atIndex)
    },
    saveOffer(state, offer) {
      state.offer = offer
    },
    saveEstimations(state, estimations) {
      state.estimations = estimations
    },
    setSwapEnabled(state, isSwapEnabled) {
      state.enabled = isSwapEnabled
    },
    setPendingRequest(state, request) {
      state.pendingRequest = request
    },
  },
  actions: {
    reset({ commit }) {
      commit('reset')
    },
    async saveAnswer({ commit, dispatch, getters }, { key, value }) {
      commit('saveAnswer', { key, value })
      if (getters.isLastQuestion) return
      await dispatch('getQuestions')
    },
    resetAnswers({ commit }, atIndex) {
      commit('resetAnswers', atIndex)
    },
    resetQuestions({ commit }, atIndex) {
      commit('resetQuestions', atIndex)
    },
    setAnsweredQuestion({ getters, commit }, { key }) {
      const index = getters.parsedQuestions.findIndex(
        (item) => item.answerKey === key,
      )
      commit('setAnsweredQuestion', { index, key: 'isAnswered', value: true })
    },
    async getQuestions({ getters, dispatch, commit }) {
      if (getters.pendingRequest) {
        getters.pendingRequest.cancel()
      }

      const cancelToken = CancelToken.source()
      commit('setPendingRequest', cancelToken)

      try {
        const { payload } = await dispatch(
          'http/request',
          {
            request: getSwapQuestions,
            body: getters.payload,
            cancelToken,
          },
          { root: true },
        )

        commit('saveQuestion', { ...payload, isAnswered: false })
      } catch {
        // Fails silently
      } finally {
        commit('setPendingRequest', null)
      }
    },
    async postAnswers({ getters, dispatch, commit }) {
      const {
        status_code: status,
        payload: { sourcingListingId },
      } = await dispatch(
        'http/request',
        {
          request: postBuybackAnswers,
          queryParams: {
            swap: true,
          },
          body: getters.payload,
        },
        {
          root: true,
        },
      )

      if (status === 204) {
        commit('saveOffer', {
          standard_price: null,
        })

        return {}
      }

      if (status === 200) {
        const { payload } = await dispatch(
          'http/request',
          {
            request: getBuybackOffer,
            pathParams: { sourcingListingId },
          },
          { root: true },
        )
        commit('saveOffer', payload)

        return payload
      }

      return {}
    },
    async getSwapEstimation({ commit, dispatch }, listings) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: getEstimationSwapPrice,
          queryParams: {
            listing_id: listings,
          },
        },
        { root: true },
      )
      commit('saveEstimations', payload)
    },
    disableSwap: ({ commit }) => commit('setSwapEnabled', false),
  },
}
