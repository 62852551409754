import payment from './payment'

export default {
  namespaced: true,

  modules: {
    payment,
  },

  state: () => ({
    isLoaded: false,
  }),

  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded
    },
  },

  getters: {
    isLoaded: (state) => state.isLoaded,
  },

  actions: {
    async fetch({ commit, dispatch }) {
      // 1. Fetch payment methods
      await dispatch('payment/fetchMethods')

      commit('setIsLoaded', true)
    },

    disableLoader({ commit }) {
      commit('setIsLoaded', true)
    },
  },
}
