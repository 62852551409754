/* eslint-disable */
import { init as initFirstScript } from './firstScript'
import { init as initSecondScript } from './secondScript'

// adapted from the snippet provided here: https://support.appsflyer.com/hc/en-us/articles/4410472474001#appsflyer-web-sdk-for-smart-banners-only
export function init({ configs }) {
  window.AppsFlyerSdkObject = 'AF'

  if (!window.AF) {
    window.AF = function () {
      window.AF.q = []
      window.AF.q.push(
        [Date.now()].concat(Array.prototype.slice.call(arguments)),
      )
    }
  }

  window.AF.id = window.AF.id || configs
  window.AF.plugins = {}

  initFirstScript()
  initSecondScript()

  window.AF('banners', 'showBanner')
}
