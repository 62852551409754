import { camelizeKeys, decamelizeKeys } from 'humps'

import { dangerouslyGetRuntimeConfig } from '@config/variables'

import API from './api'
import {
  mapToAddress,
  mapToChangeReferenceMarket,
  mapToClientAddress,
  mapToCreateBalanceAdjustment,
  mapToCreateMerchantShippingMethod,
  mapToDiscount,
  mapToInternationalOnboarding,
  mapToKybBank,
  mapToKybOwner,
  mapToKybProfile,
  mapToNewsletterPreferences,
  mapToOperationSubscribe,
  mapToUpdatePayPalActiveMarkets,
} from './transformRequest'
import {
  mapFromAllCategories,
  mapFromBalanceAjustements,
  mapFromBuyingGuides,
  mapFromBuyingGuidesHub,
  mapFromDiscount,
  mapFromGetListings,
  mapFromInternationalOnboarding,
  mapFromKybBank,
  mapFromKybOwner,
  mapFromKybProfile,
  mapFromLandingDetails,
  mapFromNewsletterPreferences,
  mapFromPicsouConfig,
  mapFromUpdateListing,
  mapProductRateFromApi,
  mapProductReviews as mapProductReviewsFromApi,
  mapReviewsListFromApi,
  mapReviewsSummaryFromApi,
} from './transformResponse'

const { TRANSLATIONS_PATH } = dangerouslyGetRuntimeConfig()

export const topSalesFetch = API.get({
  name: 'topSalesFetch',
  path: '/bm/navigation/v2/topsales',
})

export const navigationFetch = API.get({
  name: 'navigationFetch',
  path: '/bm/navigation/v2/sidebar',
})

export const skinnyBannersFetch = API.get({
  name: 'skinnyBannersFetch',
  path: '/bm/branding/skinny-banner',
})

export const recommendationCollection = API.get({
  name: 'recommendationCollection',
  path: '/bm/recommendation/v2/recommendations',
})

export const recommendationItem = API.get({
  name: 'recommendationItem',
  path: '/bm/recommendation/v2/recommendations/:widgetId',
})

export const saveCookiesSettings = API.post({
  name: 'saveCookiesSettings',
  path: '/bm/preferences/user/cookies',
})
/* AUTHENTICATION */

export const register = API.post({
  name: 'register',
  path: '/bm/client/subscription/',
})

export const login = API.post({
  name: 'login',
  path: '/bm/client/login/',
})

export const logout = API.post({
  name: 'logout',
  path: '/bm/client/logout',
})

export const checkUserFromEmail = API.post({
  name: 'checkUserFromEmail',
  path: '/bm/client/check',
})

export const lostPassword = API.post({
  name: 'lostPassword',
  path: '/bm/lost-password',
})

export const resetPassword = API.post({
  name: 'resetPassword',
  path: '/bm/reset-password/:userId?',
})

export const isPasswordTokenValid = API.get({
  name: 'isPasswordTokenValid',
  path: '/bm/reset-password/:userId/check/:token',
})

export const fetchUserData = API.get({
  name: 'fetchUserData',
  path: '/bm/user/auth',
})

export const sendVerificationEmail = API.post({
  name: 'sendVerificationEmail',
  path: '/bm/client/verification-email/send',
})

export const merchantLogin = API.post({
  name: 'merchantLogin',
  path: '/bm/merchants/login',
})

export const sellerRegister = API.post({
  name: 'merchantLogin',
  path: '/bm/merchants/register',
})

/* CRM */
export const getUserCrmId = API.get({
  name: 'getUserCrmId',
  path: '/crm/customers/v1/data/id',
})

/* CMS */

export const getContentFromContentService = API.get({
  name: 'fetchContentFromContentService',
  path: '/bm/content/:pageType/:pageName',
})

export const getCategoryContentFromContentService = API.get({
  name: 'fetchCategoryContentFromContentService',
  path: '/bm/content/:pageType/:pageName/:pageCategory',
})

/* HOMEPAGE */

export const homeFetch = API.get({
  name: 'homeFetch',
  path: '/bm/home',
})

export const homeCarouselFetch = API.get({
  name: 'homeCarouselFetch',
  path: '/bm/home/carousel',
})

export const navSeoFetch = API.get({
  name: 'navSeoFetch',
  path: '/bm/navigation/home',
})

/* SEARCHBAR */

export const searchPopular = API.get({
  name: 'searchPopular',
  path: '/bm/search/v2/popular',
})

/* LANDING */

export const sellerDetailsFetch = API.get({
  name: 'merchantDetailsFetch',
  path: '/bm/merchant/shop/:id',
  transformResponse: [mapFromLandingDetails],
})

export const landingDetailsFetch = API.get({
  name: 'landingDetailsFetch',
  path: '/bm/landing_page',
  transformResponse: [mapFromLandingDetails],
  defaultQueryParams: { includes: 'hero' },
})

export const landingBreadcrumbFetch = API.get({
  name: 'landingBreadcrumbFetch',
  path: '/bm/landing_page/breadcrumb',
})

export const searchConfigurationByScope = API.get({
  name: 'searchConfiguration',
  path: 'bm/search/v2/configuration/:scope',
})

export const searchConfigurationByScopeId = API.get({
  name: 'searchConfiguration',
  path: 'bm/search/v2/configuration/:scope/:id',
})

export const landingNavigationSeoFetch = API.get({
  name: 'landingNavigationSeoFetch',
  path: '/bm/navigation/landing-page',
})

/* PRODUCT */

export const productDetailsV2Fetch = API.get({
  name: 'productDetailsV2Fetch',
  path: '/bm/product/v2/:id',
  defaultQueryParams: { include: 'checks,obsolescence,waterproof,accessories' },
})

export const productGradesFetchV3 = API.get({
  name: 'productGradesFetchV3',
  path: '/bm/product/:id/v3/best_offers',
})

export const productGradesDescriptionFetch = API.get({
  name: 'productGradesFetch',
  path: '/bm/product/:id/grade_descriptions',
})

export const productTechnicalSpecifications = API.get({
  name: 'productTechnicalSpecifications',
  path: '/bm/product/:id/technical_specifications',
})

export const productFrequentlyAskedQuestions = API.get({
  name: 'productFrequentlyAskedQuestions',
  path: '/bm/product/:id/listing/:listingId/faq',
})

export const productWarrantyServices = API.get({
  name: 'productWarrantyServices',
  path: '/bm/product/:id/listing/:listingId/warranty_services',
})

export const fetchSEOTags = API.get({
  name: 'fetchSEOTags',
  path: '/bm/tags',
})

export const productBreadcrumbFetch = API.get({
  name: 'productBreadcrumbFetch',
  path: '/bm/product/:id/breadcrumb',
})

export const productVariantsV2Fetch = API.get({
  name: 'productVariantsV2Fetch',
  path: '/bm/product/:id/v2/variants',
})

export const productAlertCreate = API.post({
  name: 'productAlertCreate',
  path: '/bm/alert/new',
})

export const insuranceOffersFetch = API.get({
  name: 'insuranceOffersFetch',
  path: '/bm/product/warranty/:id',
})

export const productExcludedAccessoriesFetch = API.get({
  name: 'productExcludedAccessoriesFetch',
  path: '/bm/product/:productId/accessory_exclusions',
})

export const productRenewedImpactModalFetch = API.get({
  name: 'productRenewedImpactModalFetch',
  path: '/bm/product/:productId/renewed-impact',
})

export const productGoodestFindsFetch = API.get({
  name: 'productGoodestFindsFetch',
  path: '/bm/product/:productId/goodest-finds',
})

/* BUYING GUIDE LEGACY */

// TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
export const buyingGuideDetailsFetch = API.get({
  name: 'buyingGuideDetailsFetch',
  path: '/bm/branding/buying-guide/pages/:slug',
  transformResponse: [mapFromBuyingGuides, camelizeKeys],
})

// TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
export const buyingGuideHubFetch = API.get({
  name: 'buyingGuideHubFetch',
  path: '/bm/branding/buying-guide/hubs/:id',
  transformResponse: [mapFromBuyingGuidesHub, camelizeKeys],
})

// TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
export const buyingGuideHubFooterLinks = API.get({
  name: 'buyingGuideHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/:id/footer',
  transformResponse: [camelizeKeys],
})

// TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
export const buyingGuideRootHubFooterLinks = API.get({
  name: 'buyingGuideRootHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/footer',
  transformResponse: [camelizeKeys],
})

// TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
export const buyingGuideVote = API.post({
  name: 'buyingGuideVote',
  path: '/bm/branding/buying-guide/pages/:id/:value',
  transformResponse: [camelizeKeys],
})

/* CART */

export const fetchCart = API.get({
  name: 'fetchCart',
  path: '/bm/cart',
})

export const addToCart = API.post({
  name: 'addToCart',
  path: '/bm/cart/add_product',
})

export const updateQuantity = API.post({
  name: 'updateQuantity',
  path: '/bm/api/update_quantity',
})

export const updateOption = API.post({
  name: 'updateOption',
  path: '/bm/cart/update_option',
})

export const updateInsuranceOffer = API.post({
  name: 'updateInsuranceOffer',
  path: '/bm/cart/update_insurance_offer',
})

export const updateCartCollectionPoint = API.post({
  name: 'updateCartCollectionPoint',
  path: '/bm/cart/update-collection-point',
})

export const acceptAgreement = API.post({
  name: 'accept_agreement',
  path: '/bm/cart/accept_agreement',
})

export const fetchCartShippings = API.get({
  name: 'fetchCartShippings',
  path: '/bm/cart/shippings',
})

export const saveCartShippings = API.post({
  name: 'saveCartShippings',
  path: '/bm/cart/shippings',
})

export const updateCollectionPointCustomerDetails = API.post({
  name: 'updateCollectionPointCustomerDetails',
  path: '/bm/cart/update-collection-point-customer-details',
})

export const updateUserInformation = API.post({
  name: 'updateUserInformation',
  path: '/bm/cart/update-user-information',
})

/* CHECKOUT INSURANCE */
export const fetchInsurancePolicy = API.get({
  name: 'fetchInsurancePolicy',
  path: '/bm/checkout/insurance-renewal/:sessionId',
})

/* SWAP */

export const swapCreateOrder = API.post({
  name: 'swapCreateOrder',
  path: '/bm/sourcing/order/new',
})

/* INSTALLMENTS */

export const fetchInstallmentSimulation = API.get({
  name: 'fetchInstallmentSimulation',
  path: '/bm/loan-simulation',
  transformResponse: [camelizeKeys],
})

/* PAYMENT */

export const setClientAddress = API.post({
  name: 'setClientAddress',
  path: '/bm/client/address',
  transformRequest: [mapToClientAddress],
})

export const discountCheck = API.post({
  name: 'discountCheck',
  path: '/bm/promotion/check',
  transformRequest: [mapToDiscount],
  transformResponse: [mapFromDiscount],
})

export const fetchPaymentMethods = API.get({
  name: 'fetchPaymentMethods',
  path: '/payment/payment_methods',
  transformResponse: [camelizeKeys],
})

export const paymentCreate = API.post({
  name: 'paymentCreate',
  path: '/bm/payment/create',
  transformResponse: [camelizeKeys],
})

export const paymentApplePaySession = API.post({
  name: 'paymentApplePaySession',
  path: '/payment/applepay/session',
})

export const paymentConfirmCard = API.post({
  name: 'paymentConfirmCard',
  path: '/payment/confirm/card/:paymentId',
})

export const paymentConfirmHPP = API.post({
  name: 'paymentConfirmHPP',
  path: '/payment/confirm/hpp/:paymentId',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorize = API.post({
  name: 'paymentAuthorize',
  path: '/payment/authorise/:paymentId',
})

export const paymentToken = API.get({
  name: 'paymentToken',
  path: '/payment/get-token',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorizeRecurring = API.post({
  name: 'paymentAuthorizeRecurring',
  path: '/payment/authorise_recurring/:paymentId',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentAuthorizeSepa = API.post({
  name: 'paymentAuthorizeSepa',
  path: '/payment/authorise_sepa/:paymentId',
})

export const updateAchPaymentMethod = API.post({
  name: 'updateAchPaymentMethod',
  path: '/payment/authorise_ach/:paymentId',
})

export const paymentBlankCreate = API.post({
  name: 'paymentBlankCreate',
  path: '/bm/payment/blank/create',
})

// TODO: fix the path of the endpoint to use `authorize` instead of `authorise`.
export const paymentBlankAuthorize = API.post({
  name: 'paymentBlankAuthorize',
  path: '/payment/blank-authorise/:paymentId',
})

export const paymentResult = API.get({
  name: 'paymentResult',
  path: '/payment/payment_result/:paymentId',
})

/* Refer a friend */

export const generatePromoCode = API.post({
  name: 'generatePromoCode',
  path: '/bm/client/referrals/referrer/generate',
})

export const getReferFriendInfos = API.get({
  name: 'getReferFriendInfos',
  path: '/bm/client/referrals/referrer',
})

/* Orders */

export const getOrderlines = API.get({
  name: 'getOrderlines',
  path: '/bm/orders/client/:id',
  defaultQueryParams: { page_size: 6 },
})

export const getOrderlineInfo = API.get({
  name: 'getOrderlineInfo',
  path: '/bm/orders/v1/orderline/:orderlineId',
})

export const getOrderlineWarranties = API.get({
  name: 'getOrderlineWarranties',
  path: '/bm/orders/v1/orderline/:orderlineId/coverage',
})

export const cancelOrder = API.post({
  name: 'cancelOrder',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

export const getCancelReasons = API.get({
  name: 'getCancelReasons',
  path: '/bm/orders/v1/orderline/:orderlineId/cancel',
})

export const getOrderlineDetails = API.get({
  name: 'getOrderlineDetails',
  path: '/bm/orders/v1/orderline/:orderlineId',
})

/* Help Center */

/*
 * FIXME
 * That endpoint targets the Help Center **microservice**,
 * which unfortunately can't be reached on server side.
 * The reason is that Pastrami's request SDK is configured to only target Badoom
 * (cf: https://github.com/BackMarket/front-config/blob/5d8c38a0c7e6ef7e18dc3a7b44241d2cae48bb6d/prod/pastrami-config-values_override.yaml#L15).
 * So, as a temporary fix, we use the a dedicated variable **HELP_CENTER_SERVICE_URL** (set in front-config)
 * for the server API URL and we keep the current client API URL.
 * With this work around, we are able to redirect the request to the proper microservice.
 */

export const getHelpCenterCustomerOrders = API.get({
  name: 'getHelpCenterCustomerOrders',
  path: '/help-center/api/v1/auth/orders',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

export const getHelpCenterCustomerResolutions = API.get({
  name: 'getHelpCenterCustomerResolutions',
  path: '/help-center/api/v1/auth/resolutions',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

export const getHelpCenterFAQCategories = API.get({
  name: 'getHelpCenterFAQCategories',
  path: '/help-center/api/v1/categories',
  apiBaseUrl:
    process.server && process.env.HELP_CENTER_SERVICE_URL
      ? process.env.HELP_CENTER_SERVICE_URL
      : '',
})

export const getHelpCenterFAQArticles = API.get({
  name: 'getHelpCenterFAQArticles',
  path: '/help-center/api/v1/articles/search',
})

/* Insurances */

export const withdrawInsurance = API.post({
  name: 'withdrawInsurance',
  path: '/bm/insurances/v1/policies/:policyId/termination',
})

export const getInsuranceTermination = API.get({
  name: 'getInsuranceTermination',
  path: '/bm/insurances/v2/policies/:policyId/termination',
})

export const terminateInsurance = API.post({
  name: 'getInsuranceTermination',
  path: '/bm/insurances/v2/policies/:policyId/termination',
})

/* Profile */

export const getClientProfile = API.get({
  name: 'getClientProfile',
  path: '/bm/client/profile',
})

export const getClientShippingAddress = API.get({
  name: 'getClientShippingAddress',
  path: '/bm/client/addresses/shipping',
})

export const getClientBillingAddress = API.get({
  name: 'getClientBillingAddress',
  path: '/bm/client/addresses/billing',
})

export const getBills = API.get({
  name: 'getBills',
  path: '/bm/orderline/:orderlineId/insurance_bill/:fileType',
})

export const getClientSsn = API.get({
  name: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

export const setClientSsn = API.put({
  name: 'getClientBankDetails',
  path: '/bm/client/ssn',
})

export const askBill = API.post({
  name: 'askBill',
  path: '/bm/client/:id/orders/askbill',
})

export const getClientIdentityDocuments = API.get({
  name: 'getClientIdentityDocuments',
  path: '/bm/client/identity_documents',
})

export const getClientBankDetails = API.get({
  name: 'getClientBankDetails',
  path: '/bm/client/bank_details',
})

// #LIF-406 Should be removed after newsletter migration to Braze
export const getLegacyNewsletterPreferences = API.get({
  name: 'getLegacyNewsletterPreferences',
  path: '/bm/preference/client/newsletter-preference',
  transformResponse: [mapFromNewsletterPreferences],
})

export const getNewsletterPreferences = API.get({
  name: 'getNewsletterPreferences',
  path: '/crm/preferences/v1/newsletters',
})

export const updateClientInfo = API.put({
  name: 'updateClientInfo',
  path: '/bm/client/profile',
})

export const updateClientPassword = API.put({
  name: 'updateClientPassword',
  path: '/bm/client/update_password',
})

export const setIdentityDocuments = API.post({
  name: 'setIdentityDocuments',
  path: '/bm/client/identity_documents',
})

export const setClientShippingAddress = API.post({
  name: 'setClientShippingAddress',
  path: '/bm/client/addresses/shipping',
})

export const setClientBillingAddress = API.post({
  name: 'setClientBillingAddress',
  path: '/bm/client/addresses/billing',
})

export const deleteIdentityDocuments = API.delete({
  name: 'deleteIdentityDocuments',
  path: '/bm/client/identity_documents',
})

export const setClientSourcingAddress = API.post({
  name: 'setClientSourcingAddress',
  path: '/bm/client/address/sourcing',
  transformRequest: [mapToAddress],
})

export const setBankDetailsDocument = API.put({
  name: 'setBankDetailsDocument',
  path: '/bm/client/bank_details',
})

export const deleteBankDetailsDocument = API.delete({
  name: 'deleteBankDetailsDocument',
  path: '/bm/client/bank_details',
})

// #LIF-406 Should be removed after newsletter migration to Braze
export const setLegacyMailPreferences = API.post({
  name: 'setLegacyMailPreferences',
  path: '/bm/preference/client/newsletter-preference',
  transformRequest: [mapToNewsletterPreferences],
})

export const setNewsletterPreferences = API.patch({
  name: 'setNewsletterPreferences',
  path: '/crm/preferences/v1/newsletters/optin',
})

export const saveIbanBic = API.post({
  name: 'saveIbanBic',
  path: '/bm/client/wallet',
})

/* TRANSLATIONS */

export const fetchTranslationsManifest = API.get({
  name: 'fetchTranslationsManifest',
  apiBaseUrl: TRANSLATIONS_PATH,
  path: '/manifest.json',
  withCsrf: false,
  withCredentials: false,
})

export const fetchTranslationsFiles = API.get({
  name: 'fetchTranslationsFiles',
  apiBaseUrl: TRANSLATIONS_PATH,
  path: '/:localePath',
  headers: {
    'Cache-Control': 'max-age=31536000',
  },
  withCsrf: false,
  withCredentials: false,
})

/* Address autocomplete */

export const getAddressAutocomplete = API.get({
  name: 'GetAddressAutocomplete',
  path: '/bm/shipping/v1/address-autocomplete',
})

export const getAddressDetails = API.get({
  name: 'GetAddressDetails',
  path: '/bm/shipping/v1/address-details',
})

/* Customer Request (SAV) Platform (merchant/admin/backcare side) */
export const fetchCustomerRequestPlatformConversations = API.get({
  name: 'fetchCustomerRequestPlatformConversations',
  path: '/bm/customer_request/:role/claims/:context',
})

export const closeCustomerRequestPlatformConversation = API.post({
  name: 'closeCustomerRequestPlatformConversation',
  path: '/bm/customer_request/:customerRequestId/:role/close',
})

export const fetchCustomerRequestPlatformAllowedActions = API.get({
  name: 'fetchCustomerRequestPlatformAllowedActions',
  path: '/bm/customer_request/:customerRequestId/:role/actions',
})

export const fetchCustomerRequestPlatformRemainingHours = API.get({
  name: 'fetchCustomerRequestPlatformRemainingHours',
  path: '/bm/customer_request/:customerRequestId/remaining_hours',
})

export const openCustomerRequestPlatformConversation = API.post({
  name: 'openCustomerRequestPlatformConversation',
  path: '/bm/customer_request/:customerRequestId/:role/open',
})

export const fetchCustomerRequestPlatformCarriers = API.get({
  name: 'fetchCustomerRequestPlatformCarriers',
  path: '/bm/shippers',
  defaultQueryParams: { trackable: true },
})

export const fetchCustomerRequestPlatformTemplates = API.get({
  name: 'fetchCustomerRequestPlatformTemplates',
  path: '/bm/customer_request/:role/templates',
})

export const refundCustomerRequestPlatformOrder = API.post({
  name: 'refundCustomerRequestPlatformOrder',
  path: '/payment/orderline/:orderlineId/refund',
})

export const returnProductCustomerRequestPlatform = API.post({
  name: 'returnProductCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/product_return',
})

export const returnManualProductCustomerRequestPlatform = API.post({
  name: 'returnManualProductCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/:role/product_returns/:type',
})

export const returnBackCustomerRequestPlatform = API.post({
  name: 'returnBackCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/merchant/product_returns/manual',
  defaultQueryParams: {
    is_return: true,
  },
})

export const fetchPendingProductReturnCustomerRequestPlatform = API.get({
  name: 'fetchPendingProductReturnCustomerRequestPlatform',
  path: '/bm/customer_request/:role/product_returns/pending',
})

export const fetchProductReturnsCustomerRequestPlatform = API.get({
  name: 'fetchProductReturnsCustomerRequestPlatform',
  path: '/bm/customer_request/:customerRequestId/product_returns',
})

/* Customer Request (SAV) Platform (merchant side) */

export const fetchCustomerRequestPlatformMerchantIssues = API.get({
  name: 'fetchCustomerRequestPlatformMerchantIssues',
  path: '/bm/customer_request/merchant/claims/:context/issues',
})

export const fetchCustomerRequestPlatformInvoiceRequests = API.get({
  name: 'fetchCustomerRequestPlatformInvoiceRequests',
  path: '/bm/customer_request/merchant/invoice_requests',
})

export const provideCustomerRequestPlatformInvoice = API.post({
  name: 'provideCustomerRequestPlatformInvoice',
  path: '/bm/customer_request/orders/:orderId/merchant/invoice',
})

export const provideCustomerRequestPlatformInvoiceV2 = API.post({
  name: 'provideCustomerRequestPlatformInvoiceV2',
  path: '/bm/customer_request/orders/:orderId/merchant/invoice-v2',
})

export const fetchCustomerRequestPlatformCounts = API.get({
  name: 'fetchCustomerRequestPlatformCounts',
  path: '/bm/customer_request/merchant/counts',
})

export const fetchCustomerRequestPlatformMerchant = API.get({
  name: 'fetchCustomerRequestPlatformMerchant',
  path: '/bm/customer_request/merchant/information',
})

export const archiveCustomerRequestPlatformProductReturn = API.post({
  name: 'archiveCustomerRequestPlatformProductReturn',
  path: '/bm/customer_request/product_returns/:productReturnId/archive',
})

/* Customer Request (SAV) Platform (admin/backcare side) */

export const fetchCustomerRequestPlatformStaffIssues = API.get({
  name: 'fetchCustomerRequestPlatformStaffIssues',
  path: '/bm/customer_request/:role/issues',
})

export const fetchCustomerRequestPlatformMerchantInformation = API.get({
  name: 'fetchCustomerRequestPlatformMerchantInformation',
  path: '/bm/customer_request/:customerRequestId/information/merchant',
})

export const postCustomerRequestPlatformCustomerMessagesVisibility = API.post({
  name: 'postCustomerRequestPlatformCustomerMessagesVisibility',
  path: '/bm/customer_request/:customerRequestId/:role/actions/toggle-customer-messages-visibility',
})

/* Customer Request (SAV) Discussion (merchant/admin/backcare/client side) */

export const fetchDiscussionCustomerRequestDiscussion = API.get({
  name: 'fetchDiscussionCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/:role/discussion',
})

export const postMessageCustomerRequestDiscussion = API.post({
  name: 'postMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/:role/message',
  // No timeout for this endpoint because of the upload taking a lot of time.
  timeout: 0,
})

export const fetchInformationCustomerRequestDiscussion = API.get({
  name: 'fetchInformationCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/information',
})

export const fetchDetailsCustomerRequestDiscussion = API.get({
  name: 'fetchDetailsCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/information/details',
})

export const fetchActionsCustomerRequestDiscussion = API.get({
  name: 'fetchActionsCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer/actions',
})

export const fetchResolutionFlow = API.get({
  name: 'fetchResolutionFlow',
  path: '/bm/diagnosis/v1/diagnosis',
})

export const getAutoPrepaidLabelShipment = API.post({
  name: 'getAutoPrepaidLabelShipment',
  path: '/bm/customer_request/shipments',
})

export const fetchOrderlineTimeline = API.get({
  name: 'fetchOrderlineTimeline',
  path: '/bm/orderline/:orderlineId/timeline',
})

/* Customer Request Claim Creation (client side) */

export const createCustomerRequestClaim = API.post({
  name: 'createCustomerRequestClaim',
  path: '/bm/customer_request/orderline/:orderlineId/customer_requests',
})

/* Customer Request (SAV) Product Return */

/**
 * @deprecated
 */
export const fetchCustomerRequestProductReturn = API.get({
  name: 'fetchCustomerRequestProductReturn',
  path: '/bm/customer_request/:customerRequestId/customer/product_return',
})

/* Customer Request Claim Summary */

export const fetchCustomerClaimSummary = API.get({
  name: 'fetchCustomerClaimSummary',
  path: '/bm/customer_request/:customerRequestId/claims/summary',
})

/* Customer Request (SAV) Discussion (client side) */

export const rateMessageCustomerRequestDiscussion = API.patch({
  name: 'rateMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/message/:messageId/rate',
})

export const fetchNotificationStatusCustomerRequestDiscussion = API.get({
  name: 'fetchNotificationStatusCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer_notification',
})

export const updateNotificationStatusCustomerRequestDiscussion = API.put({
  name: 'updateNotificationStatusCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer_notification',
})

export const sosCustomerRequestDiscussion = API.post({
  name: 'sosCustomerRequestDiscussion',
  path: '/bm/customer_request/:customerRequestId/customer/care_emergency',
})

/* Customer Request (SAV) Discussion (admin/backcare side) */

export const deleteMessageCustomerRequestDiscussion = API.delete({
  name: 'deleteMessageCustomerRequestDiscussion',
  path: '/bm/customer_request/message/:messageId',
})

export const shareAttachmentsWithSeller = API.post({
  name: 'shareAttachmentsWithSeller',
  path: '/bm/customer_request/:customerRequestId/actions/share-attachments',
})

/* Customer Request (SAV) CMS Templates */
export const fetchCategoriesCustomerRequestTemplates = API.get({
  name: 'fetchCategoriesCustomerRequestTemplates',
  path: '/bm/customer_request/templates/categories',
})

export const createCategoryCustomerRequestTemplates = API.post({
  name: 'createCategoryCustomerRequestTemplates',
  path: '/bm/customer_request/templates/category/create',
})

export const updateCategoryCustomerRequestTemplates = API.put({
  name: 'updateCategoryCustomerRequestTemplates',
  path: '/bm/customer_request/templates/category/:categoryId',
})

export const deleteCategoryCustomerRequestTemplates = API.delete({
  name: 'deleteCategoryCustomerRequestTemplates',
  path: '/bm/customer_request/templates/category/:categoryId',
})

export const fetchTemplatesByCategoriesCustomerRequestTemplates = API.get({
  name: 'fetchTemplatesByCategoriesCustomerRequestTemplates',
  path: '/bm/customer_request/templates/category/:categoryId/templates',
  defaultQueryParams: { pagination: false },
})

export const createTemplateCustomerRequestTemplates = API.post({
  name: 'createTemplateCustomerRequestTemplates',
  path: '/bm/customer_request/templates/template/create',
})

export const deleteTemplateCustomerRequestTemplates = API.delete({
  name: 'deleteTemplateCustomerRequestTemplates',
  path: '/bm/customer_request/templates/template/:templateId',
})

export const updateTemplateCustomerRequestTemplates = API.patch({
  name: 'updateTemplateCustomerRequestTemplates',
  path: '/bm/customer_request/templates/template/:templateId',
})

/* Resolution Engine */

export const fetchResolutionOptions = API.post({
  name: 'fetchResolutionOptions',
  path: '/bm/resolution-engine/v1/resolution-options',
})

export const fetchResolutions = API.get({
  name: 'fetchResolutions',
  path: '/bm/resolution-engine/v1/resolutions',
})

export const createShipment = API.post({
  name: 'createShipment',
  path: '/bm/resolution-engine/v1/shipments',
})

export const createResolution = API.post({
  name: 'createResolution',
  path: '/bm/resolution-engine/v1/resolutions',
})

/* Best reviews */

export const fetchLandingPageShowcaseReviews = API.get({
  name: 'fetchLandingPageShowcaseReviews',
  path: '/bm/reviews/landing_page/showcase_list',
})

/* Rates */

export const fetchBackmarketRateReviews = API.get({
  name: 'fetchBackmarketRateReviews',
  path: '/bm/reviews/rate',
})

export const fetchLandingRateReviews = API.get({
  name: 'fetchLandingRateReviews',
  path: '/bm/reviews/landing_page/rate',
})

export const fetchMerchantRateReviews = API.get({
  name: 'fetchMerchantRateReviews',
  path: '/bm/reviews/merchant/rate',
})

export const fetchMerchantProductRateReviews = API.get({
  name: 'fetchMerchantProductRateReviews',
  path: '/bm/reviews/product/:productId/rate',
  // TODO: Remove case transform when backend ready
  transformResponse: [mapProductRateFromApi],
})

/* Review page */

// Backmarket calls

export const fetchBackmarketReviewsList = API.get({
  name: 'fetchBackmarketReviewsList',
  path: '/bm/reviews/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchBackmarketReviewsSummary = API.get({
  name: 'fetchBackmarketReviewsSummary',
  path: '/bm/reviews/rate_distribution',
  transformResponse: [mapReviewsSummaryFromApi],
})

export const fetchBackmarketReviewsBreadcrumb = API.get({
  name: 'fetchBackmarketReviewsBreadcrumb',
  path: '/bm/reviews/breadcrumb',
})

// Landing calls

export const fetchLandingReviewsList = API.get({
  name: 'fetchLandingReviewsList',
  path: '/bm/reviews/landing_page/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchLandingReviewsSummary = API.get({
  name: 'fetchLandingReviewsSummary',
  path: '/bm/reviews/landing_page/rate_distribution',
  transformResponse: [mapReviewsSummaryFromApi],
})

export const fetchLandingReviewsBreadcrumb = API.get({
  name: 'fetchLandingReviewsBreadcrumb',
  path: '/bm/reviews/landing_page/breadcrumb',
})

export const fetchLandingBuyingGuides = API.get({
  name: 'fetchLandingBuyingGuides',
  path: '/bm/branding/buying-guide/landing-pages/:landingId',
})

// Product calls

export const fetchReviewsListByProductLegacy = API.get({
  name: 'fetchReviewsListByProductLegacy',
  path: '/bm/reviews/v2/product/:productId/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchReviewsListByProduct = API.get({
  name: 'fetchReviewsListByProduct',
  path: '/bm/reviews/v2/product/:productId/list',
})

export const fetchReviewsRateByProduct = API.get({
  name: 'fetchReviewsRateByProduct',
  path: '/bm/reviews/v2/product/:productId/rate',
})

export const fetchReviewsSummaryByProduct = API.get({
  name: 'fetchReviewsSummaryByProduct',
  path: '/bm/reviews/v2/product/:productId/rate_distribution',
})

export const fetchRatingCategoriesByProduct = API.get({
  name: 'fetchRatingCategoriesByProduct',
  path: '/bm/reviews/v2/product/:productId/meta-summary',
})

export const fetchReviewsBreadcrumbByProduct = API.get({
  name: 'fetchReviewsBreadcrumbByProduct',
  path: '/bm/reviews/v2/product/:productId/breadcrumb',
})

// Merchant calls

export const fetchMerchantReviewsList = API.get({
  name: 'fetchMerchantReviewsList',
  path: '/bm/reviews/merchant/list',
  transformResponse: [mapReviewsListFromApi],
})

export const fetchMerchantReviewsSummary = API.get({
  name: 'fetchMerchantReviewsSummary',
  path: '/bm/reviews/merchant/rate_distribution',
  transformResponse: [mapReviewsSummaryFromApi],
})

export const fetchMerchantReviewsBreadcrumb = API.get({
  name: 'fetchMerchantReviewsBreadcrumb',
  path: '/bm/reviews/merchant/breadcrumb',
})

// Category list

export const fetchCategoryReviews = API.get({
  name: 'fetchCategoryReviews',
  path: '/bm/reviews/category-list',
})

/* Review form */

export const fetchProductReviews = API.get({
  name: 'fetchProductReviews',
  path: '/bm/orderline/:productId/review',
  transformResponse: [mapProductReviewsFromApi],
})

export const submitProductReview = API.post({
  name: 'submitProductReview',
  path: '/bm/orderline/:productId/review',
})

/* Buyback */

export const getBuybackOffer = API.get({
  name: 'getBuybackOffer',
  path: '/bm/sourcing/offer/:sourcingListingId',
})

export const getBuybackShipping = API.get({
  name: 'getBuybackShipping',
  path: '/bm/sourcing/shipping_modes/:sourcingListingId',
})

export const getConfirmation = API.get({
  name: 'getConfirmation',
  path: '/bm/sourcing/order/:id/confirmation',
})

export const getCustomerSales = API.get({
  name: 'getCustomerSales',
  path: '/bm/buyback/v1/customer/orders',
})

export const getCustomerSaleDetails = API.get({
  name: 'getCustomerSaleDetails',
  path: '/bm/buyback/v1/customer/orders/:id',
})

export const getCounterOfferDetails = API.get({
  name: 'getCounterOfferDetails',
  path: 'bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})

export const setCounterOfferResponse = API.put({
  name: 'respondToCounterOffer',
  path: 'bm/buyback/v1/customer/orders/:id/counter-offer/pending',
})

export const emailPrepaidLabel = API.post({
  name: 'emailPrepaidLabel',
  path: '/bm/buyback/v1/customer/orders/:id/shipping',
})

export const getCustomerBuybackOrderDetails = API.get({
  name: 'getCustomerBuybackOrderDetails',
  path: '/bm/buyback/v1/refurbisher/orders/:id',
})

export const validateOrderPayment = API.put({
  name: 'setCustomerOrderValidated',
  path: '/bm/buyback/v1/refurbisher/orders/:id/validate-order',
})

/* Merchant */
export const fetchMerchantData = API.get({
  name: 'fetchMerchantData',
  path: '/bm/merchant',
  transformResponse: [camelizeKeys],
})

/* Hyperwallet */
export const getAccessToken = API.get({
  name: 'getAccessToken',
  path: '/bm/merchants/hyperwallet/generate-access-token',
})

export const getKybUrl = API.get({
  name: 'getKybUrl',
  path: '/bm/merchants/hyperwallet/kyb/generate-drop-in-url',
})

export const getTransferMethodsUrl = API.get({
  name: 'getTransferMethodsUrl',
  path: '/bm/merchants/hyperwallet/transfer-methods/generate-drop-in-url',
})

export const submitTransferMethods = API.post({
  name: 'submitTransferMethods',
  path: '/bm/merchants/hyperwallet/transfer-methods/submit-hyperwallet-response',
})

/* Address Book */
export const getAddresses = API.get({
  name: 'getAddresses',
  path: '/bm/merchants/addresses',
})

export const createAddress = API.post({
  name: 'createAddress',
  path: '/bm/merchants/addresses',
})

export const editAddress = API.patch({
  name: 'editAddress',
  path: '/bm/merchants/addresses/:id',
})

export const deleteAddress = API.delete({
  name: 'deleteAddress',
  path: '/bm/merchants/addresses/:id',
})

export const setDefaultAddress = API.post({
  name: 'setDefaultAddress',
  path: '/bm/merchants/addresses/:id/set-default-address',
})

export const getAddressRules = API.get({
  name: 'getAddressRules',
  path: '/bm/merchants/address-rules',
})

export const createAddressRule = API.post({
  name: 'createAddressRule',
  path: '/bm/merchants/address-rules',
})

export const editAddressRule = API.put({
  name: 'editAddressRule',
  path: '/bm/merchants/address-rules/:id',
})

export const deleteAddressRule = API.delete({
  name: 'deleteAddressRule',
  path: '/bm/merchants/address-rules/:id',
})

/* Listings creation */
export const getProductMetadata = API.get({
  name: 'getProductMetadata',
  path: '/bm/merchants/product/:productId/listings/metadata',
})

export const createListing = API.post({
  name: 'createListing',
  path: '/bm/merchants/product/:productId/listings',
})

export const getBackboxInfo = API.get({
  name: 'getBackboxInfo',
  path: '/bm/buybox',
})

/* Listings */
export const getListings = API.get({
  name: 'getListings',
  path: '/bm/catalog/listings',
  transformResponse: [mapFromGetListings],
})

export const updateListing = API.patch({
  name: 'updateListing',
  path: '/bm/catalog/listings/:listingId',
  transformResponse: [mapFromUpdateListing],
})

export const archiveRequest = API.post({
  name: 'archiveRequest',
  path: '/bm/catalog/listings/:listingId/archive',
})

export const publishRequest = API.post({
  name: 'publishRequest',
  path: '/bm/catalog/listings/:listingId/publish',
})

export const csvImportRequest = API.post({
  name: 'csvImportRequest',
  path: '/bm/catalog/listings/batch-upsert-with-products',
})

export const csvUpdateRequest = API.post({
  name: 'csvUpdateRequest',
  path: '/bm/catalog/listings/batch-update',
})

export const csvExportRequest = API.post({
  name: 'csvExportRequest',
  path: '/bm/catalog/listings/export',
})

export const getBuyboxDataRequest = API.get({
  name: 'getBuyboxDataRequest',
  path: '/bm/buybox/listings/:listingId',
})

export const getOnlineConditions = API.get({
  name: 'getOnlineConditions',
  path: '/bm/catalog/listings/:listingId/online-conditions',
})

export const deleteListingComment = API.delete({
  name: 'deleteListingComment',
  path: '/bm/merchants/listings/:listingId/comments/:market',
})

export const getListingComments = API.get({
  name: 'getListingComments',
  path: '/bm/merchants/listings/:listingId/comments',
})

export const addListingComment = API.post({
  name: 'addListingComment',
  path: '/bm/merchants/listings/:listingId/comments/:market',
})

export const deleteListingShipping = API.delete({
  name: 'deleteListingComment',
  path: '/bm/shipping/listings-shipping-methods/:shippingId',
})

export const getListingShippingMethods = API.get({
  name: 'getListingShippingMethods',
  path: '/bm/shipping/listings/:listingId/shippings',
})

export const addListingShipping = API.post({
  name: 'addListingShipping',
  path: '/bm/shipping/listings/:listingId/shippings/:market',
})

/* Sourcing */
export const getSourcingReceived = API.get({
  name: 'getSourcingReceived',
  path: '/bm/sourcing/merchant/orders/received',
})

export const getSourcingPending = API.get({
  name: 'getSourcingPending',
  path: '/bm/sourcing/merchant/orders/pending_reply',
})

export const getSourcingOrders = API.get({
  name: 'getSourcingOrders',
  path: '/bm/sourcing/merchant/orders',
})

export const exportSourcingOrders = API.get({
  name: 'exportSourcingOrders',
  path: '/bm/sourcing/merchant/orders/export',
  responseType: 'blob',
})

export const getSourcingMerchantInfos = API.get({
  name: 'getSourcingMerchantInfos',
  path: '/bm/sourcing/merchant',
})

/* Pricing Tool */
export const changeReferenceMarket = API.post({
  name: 'changeReferenceMarket',
  path: '/bm/merchants/tools/reference-market',
  transformRequest: [mapToChangeReferenceMarket, decamelizeKeys],
  transformResponse: [camelizeKeys],
})

export const getCategoriesRequest = API.get({
  name: 'getCategoriesRequest',
  path: '/bm/category/leaf/light',
  transformResponse: [mapFromAllCategories],
})

export const getMarketPricingRulesRequest = API.get({
  name: 'getMarketPricingRulesRequest',
  path: '/bm/merchants/tools/pricing-rules',
  transformResponse: [camelizeKeys],
})

export const createPricingRule = API.post({
  name: 'createPricingRule',
  path: '/bm/merchants/tools/pricing-rules',
  transformRequest: [decamelizeKeys],
  transformResponse: [camelizeKeys],
})

export const updatePricingRule = API.put({
  name: 'updatePricingRule',
  path: '/bm/merchants/tools/pricing-rules/:ruleId',
  transformRequest: [decamelizeKeys],
  transformResponse: [camelizeKeys],
})

export const deletePricingRule = API.delete({
  name: 'deletePricingRule',
  path: '/bm/merchants/tools/pricing-rules/:ruleId',
})

export const applyPricingRule = API.post({
  name: 'applyPricingRule',
  path: '/bm/merchants/tools/pricing-rules/:ruleId/run-task',
})

export const getMerchantPreferences = API.get({
  name: 'getMerchantPreferences',
  path: '/bm/merchants/tools/merchant-configuration',
})

export const updateMerchantPreferences = API.patch({
  name: 'updateMerchantPreferences',
  path: '/bm/merchants/tools/merchant-configuration',
})

/* Create Shipping Label */
export const createShippingLabel = API.post({
  name: 'createShippingLabel',
  path: '/bm/shipping/labels',
  transformRequest: [decamelizeKeys],
})

export const getBackShipShippers = API.get({
  name: 'getBackShipShippers',
  path: '/bm/shipping/backship/shippers',
})

export const fetchShippingCollectionPoints = API.get({
  name: 'fetchShippingCollectionPoints',
  path: '/bm/shipping/v1/collection-points',
  transformResponse: [
    (collectionPoints) =>
      collectionPoints.map((collectionPoint) => ({
        ...collectionPoint,

        // API returns an unsorted arrays, with not always every days of the week
        // TODO [CK-864] Cleanup once the API is fixed
        openingHours: Array.from({ length: 7 }).map(
          (_, index) =>
            collectionPoint.openingHours.find(
              ({ dayOfTheWeek }) => dayOfTheWeek === index + 1,
            ) || {
              dayOfTheWeek: index + 1,
              timeSlots: [],
            },
        ),
      })),
  ],
})

/* International Onboarding */
export const getPreviousOnboardingInfos = API.get({
  name: 'getPreviousOnboardingInfos',
  path: '/bm/merchants/onboarding',
  transformResponse: [mapFromInternationalOnboarding],
})

export const getNextOnboardingStep = API.post({
  name: 'getNextOnboardingStep',
  path: '/bm/merchants/onboarding',
  transformRequest: [mapToInternationalOnboarding],
  transformResponse: [mapFromInternationalOnboarding],
})

/* Performances */
export const getDashboardAnalytics = API.get({
  name: 'getDashboardAnalytics',
  path: '/bm/dashboard-analytics/embedded',
})

export const getInsightsReports = API.get({
  name: 'getInsightsReports',
  path: '/bm/merchants/insights',
})

/* Options */
export const getMerchantPaymentData = API.get({
  name: 'getMerchantPaymentData',
  path: '/bm/merchants/payment-methods',
})

export const getMerchantPayPalSettings = API.get({
  name: 'getMerchantPayPalSettings',
  path: '/bm/merchants/payment-methods/paypal',
})

export const updatePayPalActiveMarkets = API.patch({
  name: 'updatePayPalActiveMarkets',
  path: '/bm/merchants/payment-methods/paypal',
  transformRequest: [mapToUpdatePayPalActiveMarkets],
})

export const getMerchantShippingMethods = API.get({
  name: 'getMerchantShippingMethods',
  path: '/bm/merchants/shipping-methods',
})

export const getMerchantShippingMethodsMetaData = API.get({
  name: 'getMerchantShippingMethodsMetaData',
  path: '/bm/merchants/shipping-methods/metadata',
})

export const createMerchantShippingMethod = API.post({
  name: 'createMerchantShippingMethod',
  path: '/bm/merchants/shipping-methods/:categoryType',
  transformRequest: [mapToCreateMerchantShippingMethod],
})

export const deleteMerchantShippingMethod = API.delete({
  name: 'deleteMerchantShippingMethod',
  path: '/bm/merchants/shipping-methods/:categoryType/:shippingMethodId',
})

/* KYB */
export const getKybProfile = API.get({
  name: 'getKybProfile',
  path: '/bm/merchant/kyb/profile',
  transformResponse: [mapFromKybProfile],
})

export const updateKybProfile = API.patch({
  name: 'updateKybProfile',
  path: '/bm/merchant/kyb/profile',
  transformRequest: [mapToKybProfile],
})

export const getKybBank = API.get({
  name: 'getKybBank',
  path: '/bm/merchant/kyb/bank_account',
  transformResponse: [mapFromKybBank],
})

export const createKybBank = API.post({
  name: 'createKybBank',
  path: '/bm/merchant/kyb/bank_account',
  transformRequest: [mapToKybBank],
})

export const updateKybBank = API.patch({
  name: 'updateKybBank',
  path: '/bm/merchant/kyb/bank_account',
  transformRequest: [mapToKybBank],
})

export const getKybOwner = API.get({
  name: 'getKybOwner',
  path: '/bm/merchant/kyb/business_owner',
  transformResponse: [mapFromKybOwner],
})

export const createKybOwner = API.post({
  name: 'createKybOwner',
  path: '/bm/merchant/kyb/business_owner',
  transformRequest: [mapToKybOwner],
})

export const updateKybOwner = API.patch({
  name: 'updateKybOwner',
  path: '/bm/merchant/kyb/business_owner',
  transformRequest: [mapToKybOwner],
})

/* SWAP */
export const getEstimationSwapPrice = API.get({
  name: 'getEstimationSwapPrice',
  path: '/bm/sourcing/swap/initial_discount',
})

export const addSwapToCart = API.post({
  name: 'addSwapToCart',
  path: '/bm/cart/swap',
})

export const postBuybackAnswers = API.post({
  name: 'postBuybackAnswers',
  path: '/bm/sourcing/form/answers',
})

export const getSwapQuestions = API.post({
  name: 'getSwapQuestions',
  path: '/bm/sourcing/form/question',
})

export const getBuybackQuestions = API.get({
  name: 'getBuybackQuestions',
  path: 'bm/buyback/form/v3/question',
})

export const deleteSwap = API.delete({
  name: 'deleteSwap',
  path: '/bm/cart/swap',
})

export const operationSubscribe = API.post({
  name: 'operationSubscribe',
  path: '/bm/op/subscribe',
  transformRequest: [mapToOperationSubscribe, decamelizeKeys],
})

/* Catalog API endpoints */

export const getSellerProductsSearch = API.post({
  name: 'getSellerProductsSearch',
  path: '/bm/catalog/bo-seller/products/search',
})

export const getSelletProductDetails = API.get({
  name: 'getSellerProductsSearch',
  path: '/bm/catalog/bo-seller/products/:productId',
})

export const importProducts = API.post({
  name: 'importProducts',
  path: '/bm/catalog/product/import',
})

export const resurrectProducts = API.post({
  name: 'resurrectProducts',
  path: '/bm/catalog/product/resurrect',
})

export const exportProducts = API.post({
  name: 'exportProducts',
  path: '/bm/catalog/product/export',
})

export const importProductImages = API.post({
  name: 'importProductImages',
  path: '/bm/catalog/products/images/batch-create',
})

export const rematchListing = API.post({
  name: 'rematchListing',
  path: '/bm/catalog/listings/:listingId/rematch',
})

export const rematchListings = API.post({
  name: 'rematchListings',
  path: '/bm/catalog/listing/rematch',
})

export const resyncListings = API.post({
  name: 'resyncListings',
  path: '/bm/catalog/listing/resync',
})

export const importVanishingDeals = API.post({
  name: 'importVanishingDeals',
  path: '/bm/catalog/vanishing-deal/import',
})

export const archiveListings = API.post({
  name: 'archiveListings',
  path: '/bm/catalog/listings/archive',
})

export const akeneoSearchProducts = API.get({
  name: 'akeneoSearchProducts',
  path: '/bm/catalog/akeneo/products',
})

export const akeneoSearchProduct = API.get({
  name: 'akeneoSearchProducts',
  path: '/bm/catalog/akeneo/products/:akeneoProductId',
})

export const getAkeneoReferenceEntityRecords = API.get({
  name: 'getAkeneoReferenceEntitiesRecords',
  path: '/bm/catalog/akeneo/reference-entities/:referenceEntityCode/records',
})

/* BackRepair */

export const fetchBackRepairFactories = API.get({
  name: 'fetchBackRepairFactories',
  path: '/bm/repair/v1/orderline/:orderlineId/factories',
})

export const getBackRepairShops = API.post({
  name: 'getBackRepairShops',
  path: '/bm/repair/api/v1/nearby-shops',
})

export const getBackRepairDetails = API.get({
  name: 'getBackRepairDetails',
  path: '/bm/repair/api/v1/repairs/:repairId',
})

/* Product Comparison */

export const fetchRelatedComparisonProducts = API.get({
  name: 'fetchRelatedComparisonProducts',
  path: '/bm/model-comparison/compare/smartphone/:productA/:productB',
})

export const fetchDefaultComparisonProducts = API.get({
  name: 'fetchDefaultComparisonProducts',
  path: '/bm/model-comparison/smartphone/parameters',
})

export const fetchAlgoliaProductsConf = API.get({
  name: 'fetchProductsAttributes',
  path: '/bm/model-comparison/search/smartphone/parameters',
})

/* Admin Tools */
export const fetchTaskAnalyzerSearch = API.get({
  name: 'fetchTaskAnalyzerSearch',
  path: '/bm/taskmanager/tasks',
})

export const fetchTaskAnalyzerActions = API.get({
  name: 'fetchTaskAnalyzerActions',
  path: '/bm/taskmanager/task-actions',
})

export const getAdminListings = API.get({
  name: 'getAdminListings',
  path: '/bm/catalog/listings/admin',
})

export const fetchListingInformation = API.get({
  name: 'fetchListingInformation',
  path: '/bm/catalog/listings/:listingId/admin',
})

export const fetchListingHistory = API.get({
  name: 'fetchListingHistory',
  path: '/bm/catalog/listings/:listingId/history',
})

export const importAkeneoAsset = API.post({
  name: 'importAkeneoAsset',
  path: '/bm/catalog/akeneo/asset-families/:assetFamilyCode/assets',
})

export const getAkeneoAsset = API.get({
  name: 'getAkeneoAsset',
  path: '/bm/catalog/akeneo/asset-families/:assetFamilyCode/assets/:code',
})

export const getSalesListingsAestheticGrades = API.get({
  name: 'getSalesListingsAestheticGrades',
  path: '/bm/catalog/listings/aesthetic-grades',
})

export const getSalesListingsPublicationStates = API.get({
  name: 'getSalesListingsPublicationStates',
  path: '/bm/catalog/listings/publication-states',
})

export const getBuybackListingsGrades = API.get({
  name: 'getBuybackListingsGrades',
  path: '/bm/catalog/buyback/listings/aesthetic-grades',
})

export const getBuybackListingsPublicationStates = API.get({
  name: 'getBuybackListingsPublicationStates',
  path: '/bm/catalog/buyback/listings/publication-states',
})

export const getBuybackCategories = API.get({
  name: 'getBuybackCategories',
  path: '/bm/catalog/buyback/categories',
})

export const getMerchantsAutocomplete = API.get({
  name: 'getMerchantsAutocomplete',
  path: '/bm/merchants/bo-admin/merchants/autocomplete',
})

export const updateBuybackCategories = API.patch({
  name: 'updateBuybackCategories',
  path: '/bm/catalog/buyback/categories/:badoomId',
})

export const getListingsToProductMerchant = API.get({
  name: 'getListingsToProductMerchant',
  path: '/bm/catalog/buyback/bo-admin/listings/to-product-merchant',
})

export const getMerchantProductListings = API.get({
  name: 'getMerchantProductListings',
  path: '/bm/catalog/buyback/bo-admin/merchants/:merchantId/products/:productId/listings',
})

export const patchMerchantProductListing = API.patch({
  name: 'getMerchantProductListings',
  path: '/bm/catalog/buyback/bo-admin/merchants/:merchantId/products/:productId/listings/:listingId',
})

export const rematchCatalogProduct = API.post({
  name: 'rematchCatalogProduct',
  path: '/bm/catalog/products/:productId/rematch',
})

export const generateCatalogProductEan = API.post({
  name: 'generateProductEan',
  path: '/bm/catalog/products/generate-ean',
})

export const getCatalogProduct = API.get({
  name: 'getCatalogProduct',
  path: '/bm/catalog/products/:productId',
})

export const getCatalogProductValidationsProducts = API.get({
  name: 'getCatalogProductValidationsProducts',
  path: '/bm/catalog/product-validations/products',
})

export const fetchCatalogProductsValidationHistory = API.get({
  name: 'fetchCatalogProductsValidationHistory',
  path: '/bm/catalog/product-validations/history',
})

export const fetchCatalogProductValidationRematchSearch = API.post({
  name: 'fetchCatalogProductValidationRematchSearch',
  path: '/bm/catalog/product-validations/rematch/search',
})

export const validateCatalogProduct = API.put({
  name: 'validateCatalogProduct',
  path: '/bm/catalog/product-validations/products/:productId',
})

export const getCatalogCategories = API.get({
  name: 'getCatalogCategories',
  path: '/bm/catalog/categories',
})

export const getCatalogCategoryAttributes = API.get({
  name: 'getCatalogCategoryAttributes',
  path: '/bm/catalog/categories/:categoryId/attributes',
})

export const getAkeneoProductBackboxes = API.get({
  name: 'getAkeneoProductBackboxes',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/backboxes',
})

export const getAkeneoProductListingsCompetition = API.get({
  name: 'getAkeneoProductListingsCompetition',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/competition',
})

export const getAkeneoProductListings = API.get({
  name: 'getAkeneoProductListings',
  path: '/bm/catalog/buyback/bo-admin/products/:akeneoProductId/listings',
})

export const getRestrictedCategories = API.get({
  name: 'getRestrictedCategories',
  path: '/bm/catalog/categories/merchant-restrictions',
})

export const deleteRestrictedCategory = API.delete({
  name: 'deleteRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const postRestrictedCategory = API.post({
  name: 'postRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const putRestrictedCategory = API.put({
  name: 'putRestrictedCategory',
  path: '/bm/catalog/categories/:marketplaceCategoryId/merchant-restrictions',
})

export const fetchCatalogSeller = API.get({
  name: 'fetchCatalogSeller',
  path: '/bm/catalog/buyback/bo-admin/merchants/:sellerId',
})

export const getCatalogSellerRateLimiting = API.get({
  name: 'getCatalogSellerRateLimiting',
  path: '/bm/catalog/bo-admin/sellers/:sellerId/rate-limiting',
})

export const fetchCatalogBuybackSellerProducts = API.get({
  name: 'fetchCatalogBuybackSellerProducts',
  path: '/bm/catalog/buyback/bo-admin/merchants/:sellerId/products',
})

export const getSalesLimitations = API.get({
  name: 'getSalesLimitations',
  path: '/bm/merchants/sales-limitation',
})

export const getSalesLimitationsMetadata = API.get({
  name: 'getSalesLimitationsMetadata',
  path: '/bm/merchants/sales-limitation/metadata',
})

export const createSalesLimitation = API.post({
  name: 'createSaleLimitation',
  path: '/bm/merchants/sales-limitation',
})

export const editSalesLimitation = API.put({
  name: 'editSaleLimitation',
  path: '/bm/merchants/sales-limitation/:salesLimitationId',
})

export const deleteSalesLimitation = API.delete({
  name: 'deleteSaleLimitation',
  path: '/bm/merchants/sales-limitation/:salesLimitationId',
})

export const getBackPricerStatus = API.get({
  name: 'getBackPricerStatus',
  path: '/bm/merchants/backpricer',
})

export const updateBackPricerStatus = API.post({
  name: 'updateBackPricerStatus',
  path: '/bm/merchants/backpricer',
})

export const getStudentDiscount = API.get({
  name: 'getStudentDiscount',
  path: '/bm/branding/student-discount',
})

export const getAdminReviews = API.get({
  name: 'getAdminReviews',
  path: '/bm/bo-admin/reviews',
})

export const updateReviewsPublishStatus = API.patch({
  name: 'updateReviewPublishStatus',
  path: '/bm/bo-admin/reviews/:reviewId',
})

export const getCustomers = API.get({
  name: 'getCustomers',
  path: '/bm/bo-admin/customers',
})

export const getCustomerOrders = API.get({
  name: 'getCustomerOrders',
  path: '/bm/bo-admin/customer/:customerId/orders',
})

export const postCustomerDeleteState = API.post({
  name: 'postCustomerDeleteState',
  path: 'bm/bo-admin/customer/:customerId/delete-state',
})

export const postGoodwillGesture = API.post({
  name: 'postGoodwillGesture',
  path: 'bm/bo-admin/customer/:customerId/goodwill-gesture',
})

export const anonymizeCustomer = API.post({
  name: 'anonymizeCustomer',
  path: 'bm/bo-admin/customer/:customerId/anonymize',
})

export const updateCustomersFraudScore = API.patch({
  name: 'updateCustomersFraudScore',
  path: 'bm/bo-admin/customer/:customerId/fraud-score',
})

export const shadowRefund = API.post({
  name: 'shadowRefund',
  path: '/payment/order/:orderId/shadow-refund',
})

export const getCustomerBuybackOrders = API.get({
  name: 'getCustomerBuybackOrders',
  path: '/bm/buyback/v1/staff/customer/:customerId/orders',
})

export const getPaymentDetails = API.get({
  name: 'getPaymentDetails',
  path: 'payment/:paymentId/details',
})

/* Admin Tools - Landing Pages Bulk Imports */
export const getLpBulkImports = API.get({
  name: 'getLpBulkImports',
  path: '/bm/landing_page/bulk_imports',
})

export const postLpBulkImportFromGsheetsUrl = API.post({
  name: 'postLpBulkImportFromGsheetsUrl',
  path: '/bm/landing_page/bulk_imports',
})

export const postLpBulkImportRollback = API.post({
  name: 'postLpBulkImportRollback',
  path: '/bm/landing_page/bulk_imports/:bulkImportId/rollback',
})

// Merchant Invoices

export const getPastInvoices = API.get({
  name: 'getPastInvoices',
  path: '/finance/merchant/bills',
})

export const getSellerCompensation = API.get({
  name: 'getPastInvoices',
  path: '/finance/merchant/compensations',
})

export const getGoodwillGestures = API.get({
  name: 'getGoodwillGestures',
  path: '/finance/merchant/credit-request',
})

export const getPendingInvoices = API.get({
  name: 'getPendingInvoices',
  path: '/finance/merchant/in-progress-invoices',
})

export const getHomeMessages = API.get({
  name: 'getHomeMessages',
  path: '/bm/merchants/homepage/messages',
})

export const getHomeListingInfos = API.get({
  name: 'getHomeListingInfos',
  path: '/bm/merchants/homepage/listings',
})

export const getHomeSalesMetrics = API.get({
  name: 'getHomeSalesMetrics',
  path: '/bm/merchants/homepage/sales-metrics',
})

export const getHomeUserReviews = API.get({
  name: 'getHomeUserReviews',
  path: '/bm/merchants/homepage/user-reviews',
})

export const getIntegrations = API.get({
  name: 'getIntegrations',
  path: '/bm/merchants/integrations',
})

export const getIntegrationsMetadata = API.get({
  name: 'getIntegrationsMetadata',
  path: '/bm/merchants/integrations/metadata',
})

export const createIntegration = API.post({
  name: 'createIntegration',
  path: '/bm/merchants/integrations',
})

export const getIntegrationAccessToken = API.post({
  name: 'getIntegrationAccessToken',
  path: 'bm/merchants/integrations/:id/token',
})

export const deleteIntegration = API.delete({
  name: 'deleteIntegration',
  path: '/bm/merchants/integrations/:id',
})

export const getSellerRegisterMetadata = API.get({
  name: 'getSellerRegisterMetadata',
  path: '/bm/merchants/register/metadata',
})

/* SELLER Profile Page */

export const getSellerProfileMetadata = API.get({
  name: 'getSellerProfileMetadata',
  path: '/bm/merchants/profile/metadata',
})

export const getSellerProfile = API.get({
  name: 'getSellerProfile',
  path: '/bm/merchants/profile',
})

export const updateSellerProfile = API.put({
  name: 'updateSellerProfile',
  path: '/bm/merchants/profile',
})

export const getContactDetailsMetadata = API.get({
  name: 'getContactDetailsMetadata',
  path: '/bm/merchants/profile/contacts/metadata',
})

export const getProfileDocumentsMetadata = API.get({
  name: 'getSellerProfileDocumentsMetadata',
  path: '/bm/merchants/profile/legal-documents/metadata',
})

export const addSellerProfileLegalDocument = API.post({
  name: 'addSellerProfileLegalDocument',
  path: '/bm/merchants/profile/legal-documents',
})

export const deleteSellerProfileLegalDocument = API.delete({
  name: 'deleteSellerProfileLegalDocument',
  path: '/bm/merchants/profile/legal-documents',
})

export const getSellerProfileLegalDocuments = API.get({
  name: 'getSellerProfileLegalDocuments',
  path: '/bm/merchants/profile/legal-documents',
})

/* Credit Requests */

export const getCreditRequests = API.get({
  name: 'getCreditRequests',
  path: '/finance/credit-requests',
})

export const postCreditRequest = API.post({
  name: 'postCreditRequest',
  path: 'finance/credit-requests/create',
})

export const postApproveCreditRequest = API.post({
  name: 'postApproveCreditRequest',
  path: 'finance/credit-requests/:id/approve',
})

export const postRejectCreditRequest = API.post({
  name: 'postRejectCreditRequest',
  path: 'finance/credit-requests/:id/reject',
})

export const postReverseCreditRequest = API.post({
  name: 'postReverseCreditRequest',
  path: 'finance/credit-requests/:id/reverse',
})

/* Seller Compensations */
export const getSellerCompensations = API.get({
  name: 'getSellerCompensations',
  path: '/finance/compensations/merchant-compensations',
})
export const buildSellerCompensations = API.post({
  name: 'buildSellerCompensations',
  path: '/finance/compensations/build',
})
export const getSellerCompensationsStates = API.get({
  name: 'getSellerCompensationsStates',
  path: '/finance/compensations/states',
})

export const getSearchSeller = API.get({
  name: 'getSearchSeller',
  path: '/finance/search/merchants',
})

/* Reasons */

export const getReasons = API.get({
  name: 'getReasons',
  path: '/finance/reason',
})

// SELLER ORDERS
export const getSellerOrders = API.get({
  name: 'getSellerOrders',
  path: '/bm/merchants/orders',
})

export const getSellerOrder = API.get({
  name: 'getSellerOrder',
  path: '/bm/merchants/orders/:orderId',
})

export const getSellerOrdersMetadata = API.get({
  name: 'getSellerOrdersMetadata',
  path: '/bm/merchants/orders/metadata',
})

export const validateSellerOrderLine = API.put({
  name: 'validateSellerOrderLine',
  path: '/bm/merchants/orderlines/:orderLineId/validate',
})

export const bulkValidateSellerOrderLine = API.put({
  name: 'bulkValidateSellerOrderLine',
  path: '/bm/merchants/orderlines/bulk/validate',
})

export const cancelOrRefundSellerOrderLine = API.put({
  name: 'cancelOrRefundSellerOrderLine',
  path: '/bm/merchants/orderlines/:orderLineId/cancel',
})

export const shipSellerOrder = API.put({
  name: 'shipSellerOrder',
  path: '/bm/merchants/orders/:orderId/ship',
})

export const sendMessageToOrderCustomer = API.post({
  name: 'sendMessageToOrderCustomer',
  path: '/bm/customer_request/orderline/:orderLineId/seller_requests',
})

export const importOrdersTrackingNumbers = API.post({
  name: 'importOrdersTrackingNumbers',
  path: '/bm/merchants/orders/import/tracking-numbers',
})

export const importOrdersImeis = API.post({
  name: 'importOrdersImeis',
  path: '/bm/merchants/orders/import/imei-numbers',
})

export const exportAllOrders = API.post({
  name: 'exportAllOrders',
  path: '/bm/merchants/orders/export',
})

export const exportImeisOrders = API.post({
  name: 'exportImeisOrders',
  path: '/bm/merchants/orders/export/missing-imei-numbers',
})

export const exportPackingSlips = API.post({
  name: 'exportPackingSlips',
  path: '/bm/merchants/orders/export/delivery-slips',
})

export const cancelOrderPickup = API.post({
  name: 'cancelOrderPickup',
  path: '/bm/orders/cancel_pickup/:orderId',
})

export const requestNewPickup = API.post({
  name: 'requestNewPickup',
  path: '/bm/orders/:orderId/new_pickup',
})

export const exportBackshipLabels = API.post({
  name: 'exportBackshipLabels',
  path: '/bm/merchants/orders/export/backship-labels',
})

/* Balance Adjustments */

export const getBalanceAdjustments = API.get({
  name: 'getBalanceAdjustments',
  path: '/finance/picsou/list',
  transformResponse: [mapFromBalanceAjustements],
})

export const createBalanceAdjustment = API.post({
  name: 'createBalanceAdjustment',
  path: '/finance/picsou/create',
  transformRequest: [mapToCreateBalanceAdjustment],
})

export const importBalanceAdjustments = API.post({
  name: 'importBalanceAdjustments',
  path: '/finance/picsou/import',
})

export const getPicsouConfig = API.get({
  name: 'getPicsouConfig',
  path: '/finance/picsou/config',
  transformResponse: [mapFromPicsouConfig],
})

/* Finance - user */

export const getFinanceUserPermissions = API.get({
  name: 'getFinanceUserPermissions',
  path: 'finance/user/permissions',
})

/* CCBM Fee Rules */

export const getCcbmFeeRules = API.get({
  name: 'getCcbmFeeRules',
  path: '/finance/ccbm/fee-rules',
})

export const importCcbmFeeRules = API.post({
  name: 'importCcbmFeeRules',
  path: '/finance/ccbm/fee-rules/import',
})

/* Staff */

export const getStaffUsers = API.get({
  name: 'getStaffUsers',
  path: '/finance/search/staff',
  transformResponse: [camelizeKeys],
})

/* Seller onboarding */

export const getSelfOnboardingBlocks = API.get({
  name: 'getSelfOnboardingBlocks',
  path: '/bm/merchants/self-onboarding',
})

export const getSellerUserList = API.get({
  name: 'getSellerUserList',
  path: '/bm/merchants/users',
})

export const updateSellerUser = API.patch({
  name: 'updateSellerUser',
  path: '/bm/merchants/users/:userId',
})

export const createSellerUser = API.post({
  name: 'createSellerUser',
  path: '/bm/merchants/users',
})
