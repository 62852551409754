import get from 'lodash/get'

import config from '@config/app'
import { COUNTRIES, MARKET_STATUS } from '@config/constants'
import { splitHostname } from '@core/helpers'
import logger from '@logger'

export const APP_COUNTRIES = Object.entries(config)
  .filter(([, { countryStatus }]) => countryStatus !== MARKET_STATUS.CLOSED)
  .map(([country]) => country)

export const ALL_APP_COUNTRIES = Object.keys(config)

export const APP_LOCALES = Object.values(config)
  .filter(({ countryStatus }) => countryStatus !== MARKET_STATUS.CLOSED)
  .map(({ countryCode }) => countryCode)

// The locale is expected in this format:
// primaryLanguage-optionalSubtags-region-optionalSubtags
// We simply make it lowercase to generate URL using a unique format
// A more precise format check will be necessary when we have more complex values with `optionalSubtags`
// https://backmarket.atlassian.net/wiki/spaces/UM/pages/2372993196/URL+updates#The-locale-format
export const getBcp47LocaleFrom = (locale) => locale.toLowerCase()

export const isCountrySupported = (country) => {
  return ALL_APP_COUNTRIES.includes(country)
}

export const getCountryConfig = (country) => {
  const locale = get(config[country], 'countryCode', null)

  if (!locale) {
    logger.error(new Error('[getCountryConfig] Locale not found'), { country })
  }

  return {
    ...config[country],
    country,
    // countryCode is our config is a locale. Let's ease the migration like this.
    locale,
  }
}

/**
 * Retrieves the country code associated to a locale
 * The backend is migrating its endpoints to send the right values for the Market.
 * We have this helper instead in the meantime. (Should only be in transformResponse/Request)
 * @deprecated
 */
export const getCountryCodeFromLocale = (currentLocale) => {
  const market = APP_COUNTRIES.map(getCountryConfig).find(
    ({ countryCode: locale }) => locale === currentLocale,
  )

  return market ? market.country : null
}

/**
 * Retrieves the currency associated to a given country code
 * The backend is migrating its endpoints to send the right values for the Market.
 * We have this helper instead in the meantime. (Should only be in transformResponse/Request)
 * @deprecated
 */
export const getCurrencyFromCountryCode = (countryCode) => {
  return getCountryConfig(countryCode).currency
}

export const getMarketsFromMarketplace = (currentMarketplace) => {
  return APP_COUNTRIES.map(getCountryConfig).filter(
    ({ marketplace }) => marketplace === currentMarketplace,
  )
}

/**
 * Returns the flag from provided country code
 *
 * @param {String} value e.g FR
 * @return {String} flag e.g 🇫🇷
 */
export const flagFromCountry = (value) =>
  value
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))

// TODO: When Doorman will be available on dev, we won't need to maintain this anymore
export const getCountryFromDomainExtension = (extension) => {
  if (!extension) {
    return COUNTRIES.FR
  }

  switch (extension) {
    case 'com':
      return COUNTRIES.US

    case 'co.uk':
      return COUNTRIES.GB

    default: {
      const countryIsoCode = extension.toUpperCase()

      return countryIsoCode in config ? countryIsoCode : COUNTRIES.FR
    }
  }
}

export const getMarketplaceFromCountry = (country) => {
  return getCountryConfig(country).marketplace
}

export const getDefaultLocaleFromCountry = (country) => {
  return getCountryConfig(country).locale
}

// For now there is only one lang per country.
export const getDefaultLangFromCountry = (country) => {
  const [lang] = getDefaultLocaleFromCountry(country).split('-')

  return lang
}

const DEFAULT_DOMAIN = 'backmarket'
export const getCountryDomain = (country) => {
  switch (country) {
    default:
      return DEFAULT_DOMAIN
  }
}

export const getCountryExtension = (country) => {
  switch (country) {
    case COUNTRIES.AT:
      return 'at'

    case COUNTRIES.BE:
      return 'be'

    case COUNTRIES.DE:
      return 'de'

    case COUNTRIES.ES:
      return 'es'

    case COUNTRIES.FI:
      return 'fi'

    case COUNTRIES.FR:
      return 'fr'

    case COUNTRIES.GB:
      return 'co.uk'

    case COUNTRIES.GR:
      return 'gr'

    case COUNTRIES.IE:
      return 'ie'

    case COUNTRIES.IT:
      return 'it'

    case COUNTRIES.NL:
      return 'nl'

    case COUNTRIES.PT:
      return 'pt'

    case COUNTRIES.SK:
      return 'sk'

    case COUNTRIES.SE:
      return 'se'

    case COUNTRIES.US:
      return 'com'

    case COUNTRIES.JP:
      return 'co.jp'

    default:
      return null
  }
}

/**
 * Get the baseURL of the country for the provided hostname
 *
 * Supports the following formats:
 *
 * - local.backmarket.{extension}
 * - preprod.backmarket.{extension}
 * - www.backmarket.{extension}
 * - {IP}
 *
 * @param {Object} parameters
 * @param {String} parameters.hostname
 * @param {String} parameters.country country code like FR, ES etc.
 * @return {Object} { country, baseURL }
 */
export const getMarketBaseURLForCountry = ({ hostname, country }) => {
  const { domain, subdomain, extension } = splitHostname(hostname)

  // local IP management
  if (typeof subdomain === 'undefined') {
    return { country, baseURL: domain }
  }

  const baseURLExtension = getCountryExtension(country) || extension

  return {
    country,
    baseURL: `${subdomain}.${getCountryDomain(country)}.${baseURLExtension}`,
  }
}

export const getMarketBaseURLs = (hostname) => {
  return [
    ...Object.entries(config)
      .filter(([, { countryStatus }]) => countryStatus === MARKET_STATUS.OPEN)
      .map(([country]) => getMarketBaseURLForCountry({ hostname, country })),
    // NO PASTRAMI COUNTRY
    ...[getMarketBaseURLForCountry({ hostname, country: COUNTRIES.JP })],
  ]
}
