
import { RevLink } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

export default {
  name: 'Trustpilot',
  components: {
    RevLink,
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadScript: false,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'config/locale',
      trustmarkConfig: 'config/trustmarkConfig',
    }),
    // TODO: [Clément] Remove this when locales are right in the config.
    formattedLocale() {
      if (!this.locale.includes('-')) {
        return this.locale
      }

      const [lang, country = ''] = this.locale.split('-')

      return `${lang}-${country.toUpperCase()}`
    },
  },
  async mounted() {
    if (isEmpty(window.Trustpilot)) {
      this.loadScript = true
    }

    // Await for all refs to be available.
    await this.$nextTick()

    if (window.Trustpilot && this.$refs.widget) {
      window.Trustpilot.loadFromElement(this.$refs.widget, true)
    }
  },
}
