import difference from 'lodash/difference'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { PROCESSOUT_SDK_ALTERNATE_PAYMENT_METHODS_FILTER } from './config/constants'
import { PROCESSOUT_ERRORS } from './config/errors'
import { findAlternatePaymentMethod } from './helpers/alternate-payment-methods'
import { processOutClient } from './helpers/client'

export const PROCESSOUT_STORE_STEPS = {
  START: 'START',
  LOAD_PENDING: 'LOAD_PENDING',
  LOAD_SUCCESS: 'LOAD_SUCCESS',
  FORM_SETUP_PENDING: 'FORM_SETUP_PENDING',
  FORM_SETUP_SUCCESS: 'FORM_SETUP_SUCCESS',
  TOKENIZE_PENDING: 'TOKENIZE_PENDING',
  TOKENIZE_SUCCESS: 'TOKENIZE_SUCCESS',
  CARD_PAYMENT_PENDING: 'CARD_PAYMENT_PENDING',
  CARD_PAYMENT_SUCCESS: 'CARD_PAYMENT_SUCCESS',
  FETCH_GATEWAYS_PENDING: 'FETCH_GATEWAYS_PENDING',
  FETCH_GATEWAYS_SUCCESS: 'FETCH_GATEWAYS_SUCCESS',
  HANDLE_INVOICE_ACTION_PENDING: 'HANDLE_INVOICE_ACTION_PENDING',
  HANDLE_INVOICE_ACTION_SUCCESS: 'HANDLE_INVOICE_ACTION_SUCCESS',
}

let processOutForm = null

export default {
  namespaced: true,

  state: () => ({
    currentStep: PROCESSOUT_STORE_STEPS.START,
    client: null,
    error: null,
  }),

  mutations: {
    setCurrentStep(state, currentStep) {
      if (!Object.values(PROCESSOUT_STORE_STEPS).includes(currentStep)) {
        throw new Error(`Invalid ProcessOut store step: ${currentStep}`)
      }
      state.currentStep = currentStep
    },

    setClient(state, client) {
      state.client = client
    },

    setForm(state, form) {
      processOutForm = form
    },

    setError(state, error) {
      state.error = error

      if (isEmpty(error)) {
        return
      }

      if (this.$config.IS_DEVELOPMENT) {
        const debugError = get(error, 'data.sdkError', error)
        console.error(debugError.message, debugError.code, debugError.stack)
      }
    },
  },

  getters: {
    client: (state) => state.client,

    form(state, getters) {
      return getters.isFormReady ? processOutForm : null
    },

    error: (state) => state.error,

    isPending: (state) =>
      [
        PROCESSOUT_STORE_STEPS.LOAD_PENDING,
        PROCESSOUT_STORE_STEPS.FORM_SETUP_PENDING,
        PROCESSOUT_STORE_STEPS.TOKENIZE_PENDING,
        PROCESSOUT_STORE_STEPS.CARD_PAYMENT_PENDING,
        PROCESSOUT_STORE_STEPS.FETCH_GATEWAYS_PENDING,
        PROCESSOUT_STORE_STEPS.HANDLE_INVOICE_ACTION_PENDING,
      ].includes(state.currentStep),

    isFormReady: (state) =>
      [
        PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS,
        PROCESSOUT_STORE_STEPS.TOKENIZE_PENDING,
        PROCESSOUT_STORE_STEPS.TOKENIZE_SUCCESS,
        PROCESSOUT_STORE_STEPS.CARD_PAYMENT_PENDING,
      ].includes(state.currentStep),
  },

  actions: {
    async checkCurrentStep({ state, commit }, { action, expected }) {
      if (!expected.includes(state.currentStep)) {
        const error = new Error(
          `Invalid ProcessOut state during ${action}: got ${
            state.currentStep
          }, expected ${expected.join(', ')}`,
        )
        error.code = PROCESSOUT_ERRORS.OWN.UNEXPECTED_STORE_STATE
        error.data = {
          currentStep: state.currentStep,
          action,
          expected,
        }
        commit('setError', error)
        throw error
      }
    },

    async load({ commit, dispatch }, { paymentMethod }) {
      await dispatch('checkCurrentStep', {
        action: 'load',
        expected: [
          PROCESSOUT_STORE_STEPS.START,
          PROCESSOUT_STORE_STEPS.LOAD_SUCCESS,
        ],
      })

      try {
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.LOAD_PENDING)
        commit('setError', null)
        const client = await processOutClient(paymentMethod.config?.publicKey)
        commit('setClient', client)
        commit('setForm', null)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.LOAD_SUCCESS)
      } catch (error) {
        commit('setError', error)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.START)
        throw error
      }
    },

    async setupForm(
      { state, commit, dispatch },
      { container, requireCVC = false, style = {}, paymentMethod },
    ) {
      // Load client if needed
      if (
        state.currentStep === PROCESSOUT_STORE_STEPS.START ||
        // Prevent re-using a previous client with a different public key
        (state.currentStep === PROCESSOUT_STORE_STEPS.LOAD_SUCCESS &&
          state.client.publicKey !== paymentMethod.config.publicKey)
      ) {
        await dispatch('load', { paymentMethod })
      }

      // Destroy previous form if any
      if (state.currentStep === PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS) {
        await dispatch('destroyForm')
      }

      await dispatch('checkCurrentStep', {
        action: 'setupForm',
        expected: [PROCESSOUT_STORE_STEPS.LOAD_SUCCESS],
      })

      try {
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.FORM_SETUP_PENDING)
        commit('setError', null)

        const form = await state.client.setupForm(container, {
          requireCVC,
          style,
        })

        commit('setForm', form)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS)
      } catch (error) {
        commit('setError', error)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.START)
        throw error
      }
    },

    async destroyForm({ state, commit, dispatch }) {
      if (state.currentStep === PROCESSOUT_STORE_STEPS.LOAD_SUCCESS) {
        return
      }

      await dispatch('checkCurrentStep', {
        action: 'destroyForm',
        expected: difference(Object.values(PROCESSOUT_STORE_STEPS), [
          PROCESSOUT_STORE_STEPS.LOAD_SUCCESS,
        ]),
      })

      commit('setForm', null)
      commit('setCurrentStep', PROCESSOUT_STORE_STEPS.LOAD_SUCCESS)
    },

    async tokenize({ state, commit, dispatch }, { name }) {
      await dispatch('checkCurrentStep', {
        action: 'tokenize',
        expected: [PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS],
      })

      try {
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.TOKENIZE_PENDING)
        commit('setError', null)

        const token = await state.client.tokenize(processOutForm, { name })

        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.TOKENIZE_SUCCESS)

        return token
      } catch (error) {
        commit('setError', error)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS)
        throw error
      }
    },

    async makeCardPayment(
      { state, commit, dispatch },
      { invoiceId, token, paymentMethod },
    ) {
      await dispatch('checkCurrentStep', {
        action: 'makeCardPayment',
        expected: [PROCESSOUT_STORE_STEPS.TOKENIZE_SUCCESS],
      })

      try {
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.CARD_PAYMENT_PENDING)

        await state.client.makeCardPayment(invoiceId, token, {
          authorize_only: !paymentMethod.autoCaptureEnabled,
        })

        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.CARD_PAYMENT_SUCCESS)
      } catch (error) {
        commit('setError', error)
        commit('setCurrentStep', PROCESSOUT_STORE_STEPS.FORM_SETUP_SUCCESS)
        throw error
      }

      await dispatch('destroyForm')
    },

    async getAlternatePaymentMethodConfig(
      { state, commit, dispatch },
      { invoiceId, paymentMethod },
    ) {
      // Load client if needed
      if (state.currentStep === PROCESSOUT_STORE_STEPS.START) {
        await dispatch('load', { paymentMethod })
      } else if (state.client?.publicKey !== paymentMethod.config.publicKey) {
        throw new Error(
          `Unexpected ProcessOut client key, expected ${state.client?.publicKey}, got ${paymentMethod.config.publicKey}`,
        )
      }

      await dispatch('checkCurrentStep', {
        action: 'getAlternatePaymentMethodConfig',
        expected: [PROCESSOUT_STORE_STEPS.LOAD_SUCCESS],
      })

      try {
        const alternatePaymentMethods =
          await state.client.fetchGatewayConfigurations(
            invoiceId,
            PROCESSOUT_SDK_ALTERNATE_PAYMENT_METHODS_FILTER,
          )

        return findAlternatePaymentMethod(
          alternatePaymentMethods,
          paymentMethod,
        )
      } catch (error) {
        commit('setError', error)
        throw error
      }
    },

    async handleAlternatePaymentMethodInvoiceAction(
      { state, commit, dispatch },
      payload,
    ) {
      await dispatch('checkCurrentStep', {
        action: 'handleAlternatePaymentMethodInvoiceAction',
        expected: [PROCESSOUT_STORE_STEPS.LOAD_SUCCESS],
      })

      try {
        return await state.client.handleAlternatePaymentMethodInvoiceAction(
          payload.alternatePaymentMethod,
        )
      } catch (error) {
        commit('setError', error)
        throw error
      }
    },
  },
}
