export const ALL_REVIEWS = 'all'
export const MERCHANT_REVIEWS = 'merchant'
export const LANDING_REVIEWS = 'landing_page'
export const PRODUCT_REVIEWS = 'product_page'

export const SEO_TEXT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO = 'reviews-text'
export const REVIEW_COMMENT_DATA_SELECTOR_ATTRIBUTE_FOR_SEO = 'review-comment'

export const REVIEWS_LIST_ID = {
  DESKTOP: 'reviews-list',
  MOBILE: 'reviews-list-mobile',
}

export const TRACKING_ZONE = 'reviews'
