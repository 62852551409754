import { fetchLandingRateReviews } from '@http/endpoints'

export default {
  namespaced: true,
  state() {
    return {
      rate: {},
    }
  },
  getters: {
    get: (state) => state.rate,
  },
  mutations: {
    set: (state, rate) => {
      state.rate = rate
    },
  },
  actions: {
    fetch: async ({ dispatch }, { pk, slug }) => {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchLandingRateReviews,
          queryParams: { pk, slug },
        },
        { root: true },
      )

      dispatch('set', payload)
    },
    set: ({ commit }, data) => commit('set', data),
  },
}
