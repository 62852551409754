import { MARKETPLACES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const buybackRoutes = [
  {
    name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.HOME,
    path: 'sourcing-new/orders',
    component: () => dynamicImport(import('./pages/sourcing')),
    redirect: { name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ACTIVE },
    children: [
      {
        name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ACTIVE,
        path: 'active',
      },
      {
        name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.ALL,
        path: 'all',
      },
      {
        name: ROUTES.BO_MERCHANT.SOURCING.ORDERS.PENDING,
        path: 'pending',
      },
    ],
  },
  {
    name: ROUTES.BO_MERCHANT.BUYBACK.ORDER_DETAILS,
    path: 'buyback/orders/:id',
    component: () =>
      dynamicImport(import('@buyback/refurbisher/orders/pages/OrderDetails')),
  },
].map((route) => ({ ...route, meta: { ...route.meta, isBuyback: true } }))

const pricingToolRoute = [
  {
    name: ROUTES.BO_MERCHANT.PRICING_TOOL,
    path: 'pricing-tool',
    component: () => dynamicImport(import('./pages/pricing-tool')),
  },
]

const hyperwalletRoutes = [
  {
    name: ROUTES.BO_MERCHANT.OPTIONS.KYB,
    path: 'options/payment/kyb',
    component: () => dynamicImport(import('./pages/hyperwallet/kyb')),
  },
  {
    name: ROUTES.BO_MERCHANT.OPTIONS.TRANSFER_METHODS,
    path: 'options/payment/transfer-methods',
    component: () =>
      dynamicImport(import('./pages/hyperwallet/transfer-methods')),
  },
  {
    name: ROUTES.BO_MERCHANT.OPTIONS.TRANSFER_METHODS_CONFIRMATION,
    path: 'options/payment/transfer-methods/confirmation',
    component: () => dynamicImport(import('./pages/hyperwallet/confirmation')),
  },
]

const getOptionsRoutes = (marketplace) => {
  const childrenRoutes = [
    ...insertIf(marketplace === MARKETPLACES.EU, [
      {
        name: ROUTES.BO_MERCHANT.OPTIONS.MARKETS,
        path: 'markets',
        component: () => dynamicImport(import('./pages/options/tabs/markets')),
      },
    ]),
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.ADDRESSES,
      path: 'addresses',
      component: () => dynamicImport(import('./pages/options/tabs/addresses')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.SHIPPING,
      path: 'shipping',
      component: () => dynamicImport(import('./pages/options/tabs/shipping')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.PAYMENT,
      path: 'payment',
      component: () => dynamicImport(import('./pages/options/tabs/payment')),
    },
    ...insertIf(marketplace === MARKETPLACES.EU, [
      {
        name: ROUTES.BO_MERCHANT.OPTIONS.PRICING,
        path: 'pricing',
        component: () => dynamicImport(import('./pages/options/tabs/pricing')),
      },
    ]),
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.BACKPRICER,
      path: 'backpricer',
      component: () => dynamicImport(import('./pages/options/tabs/backpricer')),
    },
    {
      name: ROUTES.BO_MERCHANT.OPTIONS.INTEGRATIONS,
      path: 'integrations',
      component: () =>
        dynamicImport(import('./pages/options/tabs/integrations')),
    },
  ]

  return [
    {
      path: 'options',
      component: () => dynamicImport(import('./pages/options')),
      name: ROUTES.BO_MERCHANT.OPTIONS.HOME,
      redirect: { name: childrenRoutes[0].name },
      children: childrenRoutes,
    },
  ]
}

const routes = [
  {
    name: ROUTES.BO_MERCHANT.OPTIONS.ADDRESS_BOOK,
    path: 'options/address-book',
    component: () => dynamicImport(import('./pages/address-book')),
  },
  {
    name: ROUTES.BO_MERCHANT.SHIPPING.CREATE_LABEL,
    path: 'create-shipping-label',
    component: () => dynamicImport(import('./pages/create-shipping-label')),
  },
  {
    path: 'international-onboarding',
    component: () => dynamicImport(import('./pages/international-onboarding')),
    redirect: {
      name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.MARKETS,
    },
    children: [
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.MARKETS,
        path: 'markets',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/markets'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.SHIPPING,
        path: 'shipping',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/shipping'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.PAYMENT,
        path: 'payment',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/payment'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.PRICING_RULES,
        path: 'pricing',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/pricing-rules'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.REVIEW,
        path: 'review',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/review'),
          ),
      },
      {
        name: ROUTES.BO_MERCHANT.INTERNATIONAL_ONBOARDING.CONFIRMATION,
        path: 'confirmation',
        component: () =>
          dynamicImport(
            import('./pages/international-onboarding/steps/confirmation'),
          ),
      },
    ],
  },
  {
    path: 'listings',
    name: ROUTES.BO_MERCHANT.LISTINGS.ACTIVE,
    component: () => dynamicImport(import('./pages/listings')),
    children: [
      {
        name: ROUTES.BO_MERCHANT.LISTINGS.MISSING_INFOS,
        path: 'missing-infos',
      },
      {
        name: ROUTES.BO_MERCHANT.LISTINGS.IN_VALIDATION,
        path: 'in-validation',
      },
      {
        name: ROUTES.BO_MERCHANT.LISTINGS.ARCHIVED,
        path: 'archived',
      },
    ],
  },
  {
    name: ROUTES.BO_MERCHANT.INSIGHTS,
    path: 'insights',
    component: () => dynamicImport(import('./pages/insights')),
  },
  {
    path: 'listings/create/:productBmId',
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.DETAILS,
    component: () => dynamicImport(import('./pages/listing-creation')),
  },
  {
    path: 'listings/create',
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.SEARCH,
    component: () => dynamicImport(import('./pages/listing-search-products')),
  },
  {
    name: ROUTES.BO_MERCHANT.FEEDBACK,
    path: 'feedback',
    component: () => dynamicImport(import('./pages/feedback')),
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.QUALITY_TEST,
    path: 'onboarding/quality-test',
    component: () => dynamicImport(import('./pages/onboarding/quality-test')),
  },
  {
    name: ROUTES.BO_MERCHANT.INVOICES.HOME,
    path: 'invoices',
    component: () => dynamicImport(import('./pages/invoices')),
    redirect: { name: ROUTES.BO_MERCHANT.INVOICES.PENDING },
    children: [
      {
        name: ROUTES.BO_MERCHANT.INVOICES.PENDING,
        path: 'pending',
        component: () => dynamicImport(import('./pages/invoices/tabs/pending')),
      },
      {
        name: ROUTES.BO_MERCHANT.INVOICES.PAST_INVOICES,
        path: 'past-invoices',
        component: () =>
          dynamicImport(import('./pages/invoices/tabs/past-invoices')),
      },
      {
        name: ROUTES.BO_MERCHANT.INVOICES.GOODWILL_GESTURES,
        path: 'goodwill-gestures',
        component: () =>
          dynamicImport(import('./pages/invoices/tabs/goodwill-gestures')),
      },
      {
        name: ROUTES.BO_MERCHANT.INVOICES.COMPENSATION,
        path: 'seller-compensation',
        component: () =>
          dynamicImport(import('./pages/invoices/tabs/seller-compensation')),
      },
    ],
  },
  {
    name: ROUTES.BO_MERCHANT.HOME,
    path: 'home',
    component: () => dynamicImport(import('./pages/home')),
  },
  {
    path: 'orders',
    component: () => dynamicImport(import('./pages/orders')),
    redirect: { name: ROUTES.BO_MERCHANT.ORDERS.NEW },
    children: [
      {
        name: ROUTES.BO_MERCHANT.ORDERS.NEW,
        path: 'new',
      },
      {
        name: ROUTES.BO_MERCHANT.ORDERS.PENDING,
        path: 'pending',
      },
      {
        name: ROUTES.BO_MERCHANT.ORDERS.ALL,
        path: 'all',
      },
    ],
  },
  {
    name: ROUTES.BO_MERCHANT.PROFILE.ROOT,
    path: 'profile',
    component: () => dynamicImport(import('./pages/profile')),
    redirect: { name: ROUTES.BO_MERCHANT.PROFILE.HOME },
    children: [
      {
        name: ROUTES.BO_MERCHANT.PROFILE.HOME,
        path: 'home',
        component: () => dynamicImport(import('./pages/profile/tabs/home')),
      },
      {
        name: ROUTES.BO_MERCHANT.PROFILE.COMPANY_INFO,
        path: 'company-info',
        component: () =>
          dynamicImport(import('./pages/profile/tabs/company-info')),
      },
      {
        name: ROUTES.BO_MERCHANT.PROFILE.LEGAL_DETAILS,
        path: 'legal-details',
        component: () =>
          dynamicImport(import('./pages/profile/tabs/legal-details')),
      },
      {
        name: ROUTES.BO_MERCHANT.PROFILE.CONTACT_DETAILS,
        path: 'contact-details',
        component: () =>
          dynamicImport(import('./pages/profile/tabs/contact-details')),
      },
      {
        name: ROUTES.BO_MERCHANT.PROFILE.USER_PERMISSIONS,
        path: 'user-permissions',
        component: () =>
          dynamicImport(import('./pages/profile/tabs/user-permissions')),
      },
    ],
  },
]

export default ({ marketplace }) => {
  return {
    requiresSellerAuthentication: [
      {
        path: '/',
        component: () => dynamicImport(import('./layouts/Default.vue')),
        meta: {
          seller: {
            isSellerOnboarding: false,
            required: true,
            redirection: { name: ROUTES.BO_MERCHANT.ONBOARDING.HOME },
          },
        },
        children: [
          ...buybackRoutes,
          ...hyperwalletRoutes,
          ...routes,
          ...getOptionsRoutes(marketplace),
          ...insertIf(marketplace === MARKETPLACES.EU, pricingToolRoute),
        ],
      },
    ],
    other: [],
  }
}
