import { ROUTES } from '@router'

export const SDK_ENDPOINT = 'sdk.fra-01.braze.eu'
export const EVENT_PLATFORM = 'web'
export const SERVICE_WORKER_LOCATION = '/braze-service-worker.js'

const DAYS_TO_WAIT_BETWEEN_PROMPTS = 180
export const MS_TO_WAIT_BETWEEN_PROMPTS =
  1000 * 3600 * 24 * DAYS_TO_WAIT_BETWEEN_PROMPTS

export const MS_TO_WAIT_BEFORE_SHOWING_SOFT_PROMPT = 1000 * 60

export const DO_NOT_LOAD_ROUTES_COLLECTION = [
  ROUTES.ADMIN,
  ROUTES.ADMIN_TOOLS,
  ROUTES.BO_MERCHANT,
  ROUTES.BUYBACK.MERCHANT,
  ROUTES.KYB,
  ROUTES.MERCHANT_INFOS,
  ROUTES.OLD_MERCHANT_ORDERS,
  ROUTES.PDF,
  ROUTES.SAV_PLATFORM,
  ROUTES.SAV_PLATFORM_ADMIN,
  ROUTES.SAV_PLATFORM_BACKCARE,
  ROUTES.SAV_CONVERSATION,
]

export const DO_NOT_DISTURB_ROUTES_COLLECTIONS = [
  ROUTES.BUYBACK,
  ROUTES.CHECKOUT,
  ROUTES.AUTH,
  [ROUTES.PRODUCT.HOME],
]

export const SCROLLING_THRESHOLD_BEFORE_DISMISSAL_IN_PIXELS = 600

export const TRACKING_EVENT_NAME = {
  ACCEPT: 'accept',
  REFUSE: 'refuse',
  CLOSE: 'close',
}

export const TRACKING_ZONE = 'prompt_notification'
