
import { mapGetters } from 'vuex'

import logger from '@logger'

export const POSITION = 'BOTTOM_RIGHT'

export default {
  name: 'GoogleReviews',
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      badgeRendered: false,
      loadScript: false,
      ratingBadgeContainer: undefined,
    }
  },
  computed: {
    ...mapGetters({
      trustmarkConfig: 'config/trustmarkConfig',
    }),
  },
  watch: {
    hidden(isHidden) {
      if (!this.ratingBadgeContainer) {
        return
      }

      this.ratingBadgeContainer.style.opacity = isHidden ? 0 : 1
      this.ratingBadgeContainer.style.pointerEvents = isHidden ? 'none' : 'auto'
    },
  },
  async mounted() {
    const alreadyLoaded = typeof window.renderBadge === 'function'

    window.renderBadge = () => {
      if (this.badgeRendered) {
        return
      }

      this.badgeRendered = true
      this.ratingBadgeContainer = document.createElement('div')
      this.ratingBadgeContainer.className = 'googleReviewBadge'
      document.body.appendChild(this.ratingBadgeContainer)

      window.gapi.load('ratingbadge', () => {
        window.gapi.ratingbadge.render(this.ratingBadgeContainer, {
          merchant_id: this.trustmarkConfig.merchantId,
          position: POSITION,
        })
      })
    }

    if (!alreadyLoaded) {
      this.loadScript = true
    }

    // Await for all refs to be available.
    await this.$nextTick()

    if (window.gapi) {
      window.renderBadge()
    }
  },
  beforeDestroy() {
    if (this.ratingBadgeContainer) {
      try {
        this.ratingBadgeContainer.remove()
        this.badgeRendered = false
      } catch (error) {
        logger.error(error)
      }
    }
  },
}
