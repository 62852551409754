import isEmpty from 'lodash/isEmpty'

import {
  getCurrencyFromCountryCode,
  getMarketsFromMarketplace,
} from '@core/helpers/countries'
import { fetchMerchantData } from '@http/endpoints'

export const getMerchantMarket = (countryCode) => ({
  countryCode,
  currency: getCurrencyFromCountryCode(countryCode),
})

export default {
  namespaced: true,

  state() {
    return {
      activeMarkets: [],
      canOpenNewMarkets: false,
      company: null,
      countryOfOrigin: null,
      email: null,
      featureFlags: {},
      hasOnlyOneMarket: false,
      id: null,
      isAdminUser: false,
      isBackshipEnabled: false,
      isBuybackEnabled: false,
      isImpersonated: false,
      markets: [],
      needToSignNewCgu: false,
      onboardingCompleted: false,
      potentialMarkets: [],
      referenceMarket: null,
      shopLink: null,
      fetched: false,
      vat: {},
    }
  },

  getters: {
    fetched: (state) => state.fetched,
    activeMarkets: (state) => state.activeMarkets.map(getMerchantMarket),
    canOpenNewMarkets: ({ canOpenNewMarkets }) => canOpenNewMarkets,
    company: (state) => state.company,
    countryOfOrigin: (state) => state.countryOfOrigin,
    email: (state) => state.email,
    featureFlags: (state) => state.featureFlags,
    hasOnlyOneMarket: (state) => {
      // to get all markets, we need to add the amount of potentialMarkets and activeMarkets
      // for example, this is a way to share to the store a boolean that help us
      // know if we should display components related to markets
      return state.potentialMarkets.length + state.activeMarkets.length === 1
    },
    id: (state) => state.id,
    isAdminUser: (state) => state.isAdminUser,
    isBackshipEnabled: (state) => state.isBackshipEnabled,
    isBuybackEnabled: (state) => state.isBuybackEnabled,
    isImpersonated: (state) => state.isImpersonated,
    isOffline: ({ activeMarkets }) => isEmpty(activeMarkets),
    marketsOfMarketplace: (state, getters, rootState, rootGetters) =>
      getMarketsFromMarketplace(rootGetters['config/marketplace']).map(
        (market) => ({
          countryCode: market.country,
          currency: getCurrencyFromCountryCode(market.country),
        }),
      ),
    needToSignNewCgu: (state) => state.needToSignNewCgu,
    shopLink: (state) => state.shopLink,
    onboardingCompleted: (state) => state.onboardingCompleted,
    potentialMarkets: (state) => state.potentialMarkets.map(getMerchantMarket),
    referenceMarket: (state) =>
      state.referenceMarket ? getMerchantMarket(state.referenceMarket) : {},
    vat: (state) => state.vat,
  },

  mutations: {
    setPayload(
      state,
      {
        activeMarkets,
        canOpenNewMarkets,
        company,
        countryOfOrigin,
        email,
        featureFlags,
        id,
        isAdminUser,
        isBackshipEnabled,
        isBuybackEnabled,
        isImpersonated,
        needToSignNewCgu,
        onboardingCompleted,
        potentialMarkets,
        referenceMarket,
        shopLink,
        vat,
      },
    ) {
      state.fetched = true
      state.activeMarkets = activeMarkets
      state.canOpenNewMarkets = canOpenNewMarkets
      state.countryOfOrigin = countryOfOrigin
      state.company = company
      state.email = email
      state.featureFlags = featureFlags
      state.id = id
      state.isAdminUser = isAdminUser
      state.isBackshipEnabled = isBackshipEnabled
      state.isBuybackEnabled = isBuybackEnabled
      state.isImpersonated = isImpersonated
      state.potentialMarkets = potentialMarkets
      state.referenceMarket = referenceMarket
      state.needToSignNewCgu = needToSignNewCgu
      state.onboardingCompleted = onboardingCompleted
      state.shopLink = shopLink
      state.vat = vat
    },
  },

  actions: {
    async fetch({ commit, dispatch }) {
      const { payload } = await dispatch(
        'http/request',
        { request: fetchMerchantData },
        { root: true },
      )

      commit('setPayload', payload)
    },
  },
}
