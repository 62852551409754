// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3OdvV5MaVXICa_9RrrVNyc::-ms-clear,._3OdvV5MaVXICa_9RrrVNyc::-ms-reveal{appearance:none;width:0;height:0}._3OdvV5MaVXICa_9RrrVNyc::-webkit-search-cancel-button,._3OdvV5MaVXICa_9RrrVNyc::-webkit-search-decoration,._3OdvV5MaVXICa_9RrrVNyc::-webkit-search-results-button,._3OdvV5MaVXICa_9RrrVNyc::-webkit-search-results-decoration{-webkit-appearance:none;appearance:none}._1aEJg_nSc4QX5troU87Zsy::-moz-placeholder{color:var(--text-primary)}._1aEJg_nSc4QX5troU87Zsy:-ms-input-placeholder{color:var(--text-primary)}._1aEJg_nSc4QX5troU87Zsy::placeholder{color:var(--text-primary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-x": "_3OdvV5MaVXICa_9RrrVNyc",
	"placeholder": "_1aEJg_nSc4QX5troU87Zsy"
};
module.exports = ___CSS_LOADER_EXPORT___;
