
import { RevButtonBase, RevIllustration } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import {
  APPLE_STORE_REDIRECT_URL,
  APPSFLYER_CAMPAIGN,
  APPSFLYER_MEDIA_SOURCE,
  GOOGLE_STORE_REDIRECT_URL,
  STORE,
  STORE_COUNTRIES,
} from './AppStoreButtons.constant'

// Note that before registering new URLs to this configuration, you should
// also download and store associated assets in `app/static/img/social`.

// assets may be found here: https://www.figma.com/file/jC2mtHRgj5YEWRLSQYfKHq/04.-Assets?node-id=1%3A852

export default {
  components: {
    RevButtonBase,
    RevIllustration,
  },

  props: {
    campaign: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      storesBaseUrl: `${this.$config.APPSFLYER_BASE_URL}?pid=${APPSFLYER_MEDIA_SOURCE}&c=${APPSFLYER_CAMPAIGN}`,
    }
  },

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),

    buttons() {
      if (!STORE_COUNTRIES.includes(this.country)) {
        return []
      }

      return [
        {
          key: 'google-play',
          url: this.getStoreUrl(STORE.GOOGLE),
          img: this.$static(`/img/socials/${this.country}/google-play.svg`),
          alt: 'Google Play',
          class: 'inline-block h-[4rem]',
          width: 135,
          height: 40,
        },
        {
          key: 'app-store',
          url: this.getStoreUrl(STORE.APPLE),
          img: this.$static(`/img/socials/${this.country}/apple-store.svg`),
          alt: 'Apple Store',
          class: 'inline-block h-[4rem] ml-2',
          width: 127,
          height: 40,
        },
      ].filter((button) => button.url)
    },

    visible() {
      return !isEmpty(this.buttons)
    },
  },

  methods: {
    getStoreBaseUrl(store) {
      if (store === STORE.APPLE) {
        return `${this.storesBaseUrl}&af_r=${APPLE_STORE_REDIRECT_URL}`
      }

      return `${this.storesBaseUrl}&af_r=${GOOGLE_STORE_REDIRECT_URL}`
    },

    getStoreUrl(store) {
      const storeBaseUrl = this.getStoreBaseUrl(store)

      return `${storeBaseUrl}&af_ad=${this.country.toLowerCase()}&af_adset=${
        this.campaign
      }`
    },
  },
}
