
import { RevButtonBase, RevIllustration } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import { COMPANY_NAME } from '@config/constants'
import { ROUTES } from '@router'

import BmLogo from './BM_logo.svg'

const GIF_SOURCE = '/img/logo_animated_bm.gif'
const CAT_GIF_SOURCE = '/img/catpaign/cat_logo_animated_bm.gif'

export default {
  components: {
    RevButtonBase,
    RevIllustration,
    BmLogo,
  },
  props: {
    // We use this logo on all headers (funnel, minimal, main)
    // We don't want the animated logo everywhere for now
    // so we need to use this props
    hasAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gifSource: GIF_SOURCE,
    }
  },
  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    COMPANY_NAME: () => COMPANY_NAME,
    ROUTES: () => ROUTES,
    isCatCampaignEnabled() {
      return this.$config.FF_CAT_CAMPAIGN_BY_COUNTRY?.includes(this.country)
    },
    getGifSource() {
      return this.isCatCampaignEnabled ? CAT_GIF_SOURCE : GIF_SOURCE
    },
  },
  beforeDestroy() {
    clearTimeout(this.delay)
  },
  methods: {
    // We use this tricky method to reset gif animation on mouse out
    resetGifSource() {
      clearTimeout(this.delay)
      this.delay = setTimeout(() => {
        this.gifSource = `${this.getGifSource}?x=${1 * Math.random()}`
      }, 300)
    },
  },
}
