import { COUNTRIES, MARKETPLACES } from '@config/constants'
import { dynamicImport, insertIf } from '@core/helpers'
import { ROUTES } from '@router'

const refurbishRoute = [
  {
    path: '/refurbish',
    name: ROUTES.PDF.CONSUMER,
    meta: { rollout: false },
  },
]

const warrantyRoutes = [
  {
    path: '/warranty',
    name: ROUTES.PDF.WARRANTY,
    meta: { rollout: false },
  },
  {
    path: '/warranty/previous',
    name: ROUTES.PDF.WARRANTY_PREVIOUS,
    meta: { rollout: false },
  },
]

const buybackRoute = [
  {
    path: '/terms/buyback',
    name: ROUTES.PDF.TERMS_BUYBACK,
    meta: { rollout: false },
  },
]

const onboardingRoute = [
  {
    path: '/terms/merchant/onboarding',
    name: ROUTES.PDF.TERMS_MERCHANT_ONBOARDING,
    meta: { rollout: false },
  },
]

const termsSales = [
  {
    path: '/terms/sale',
    name: ROUTES.PDF.TERMS_SALE,
    meta: { rollout: false },
  },
]

const hasCountryWarranties = (country) =>
  [
    COUNTRIES.DE,
    COUNTRIES.AT,
    COUNTRIES.ES,
    COUNTRIES.US,
    COUNTRIES.NL,
    COUNTRIES.PT,
    COUNTRIES.FR,
    COUNTRIES.BE,
    COUNTRIES.IE,
    COUNTRIES.IT,
    COUNTRIES.GB,
  ].includes(country)

const hasBuybackRoute = (country) =>
  [COUNTRIES.FR, COUNTRIES.DE, COUNTRIES.US].includes(country)

const hasOnboardingRoute = (country) =>
  [COUNTRIES.FR, COUNTRIES.GB, COUNTRIES.US].includes(country)
export default ({ country, marketplace }) => {
  return [
    {
      path: 'legal-mentions',
      name: ROUTES.LEGAL.MENTIONS,
      component: () => dynamicImport(import('./pages/legal-mentions.vue')),
    },
    {
      path: 'get-my-visitor-id',
      name: ROUTES.LEGAL.GET_VISITOR_ID,
      component: () => dynamicImport(import('./pages/get-my-visitor-id.vue')),
    },
    {
      path: 'cookies',
      name: ROUTES.LEGAL.COOKIES,
      component: () => dynamicImport(import('./pages/cookies.vue')),
    },
    {
      path: 'data-protection',
      name: ROUTES.LEGAL.DATA_PROTECTION,
      meta: { rollout: false },
    },
    // [BestPractice] The following `/terms /warranty /refurbish` pages are managed outside from our codebase.
    // They're only static files uploaded to S3 and proxied by Doorman.
    {
      path: 'terms/use',
      name: ROUTES.PDF.TERMS_USE,
      meta: { rollout: false },
    },
    {
      path: 'terms/use/previous',
      name: ROUTES.PDF.TERMS_USE_PREVIOUS,
      meta: { rollout: false },
    },
    {
      path: 'terms/refer-friend',
      name: ROUTES.LEGAL.REFER_FRIEND,
      meta: { rollout: false },
    },
    {
      path: 'terms/cookies',
      name: ROUTES.LEGAL.COOKIES_TERMS,
      meta: { rollout: false },
    },
    ...insertIf(hasCountryWarranties(country), warrantyRoutes),
    ...insertIf(country === COUNTRIES.FR, refurbishRoute),
    ...insertIf(hasBuybackRoute(country), buybackRoute),
    ...insertIf(hasOnboardingRoute(country), onboardingRoute),
    ...insertIf(marketplace === MARKETPLACES.EU, termsSales),
  ]
}
