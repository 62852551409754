
import { RevLink } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'

import AdminMenu from '@navigation/components/AdminMenu.vue'
import BurgerMenuDesktop from '@navigation/components/BurgerMenuDesktop.vue'
import BurgerMenuMobile from '@navigation/components/BurgerMenuMobile.vue'
import CartIconAndBubble from '@navigation/components/CartIconAndBubble.vue'
import Logo from '@navigation/components/Logo'
import UserIconAndSubMenu from '@navigation/components/UserIconAndSubMenu.vue'
import {
  HEADER_TRACKING_NAME,
  HEADER_TRACKING_ZONE,
  NEW_GOOD_DEALS_LINK,
  POSITION_Y_ANIMATION_BEGIN,
  SHIFT_Y_SCROLL_DOWN_HEADER_HIDDEN,
} from '@navigation/constant'
import { ROUTES } from '@router'
import SearchBar from '@search/SearchBar'
import { trackClick } from '@tracking/events'

import translations from './Header.translations'

export default {
  components: {
    AdminMenu,
    SearchBar,
    StoreNav: () => import('@navigation/components/StoreNav'),
    RevLink,
    BurgerMenuDesktop,
    BurgerMenuMobile,
    Logo,
    UserIconAndSubMenu,
    CartIconAndBubble,
  },

  data() {
    return {
      headerVisible: true,
      lastScrollPosition: 0,
      scrollDistanceDown: 0,
    }
  },

  computed: {
    translations: () => translations,
    ROUTES: () => ROUTES,
    HEADER_TRACKING_NAME: () => HEADER_TRACKING_NAME,
    HEADER_TRACKING_ZONE: () => HEADER_TRACKING_ZONE,
    ...mapGetters({
      isNavigationOpened: 'isNavigationOpened',
      topSales: 'countryConfig/sales',
      isBuyBackEnabled: 'config/isBuyBackEnabled',
      isStaff: 'user/isStaff',
      isShadowingClient: 'user/isShadowingClient',
      isShadowingMerchant: 'user/isShadowingMerchant',
      country: 'config/country',
      experiments: 'experiments/getExperiments',
    }),

    buybackLink() {
      return {
        name: ROUTES.CMS.BUYBACK,
        params: {
          pageName: 'home',
        },
      }
    },

    isAdmin() {
      return this.isStaff || this.isShadowingClient || this.isShadowingMerchant
    },

    // TODO: Clean task https://backmarket.atlassian.net/browse/BRO-2079
    shouldUseNewGoodDealsLink() {
      return this.experiments.goodDealsLink === 'goodDealsEventPage'
    },

    // We are gonna keep this legacy logic until the end of the ab-test.
    // We can't easily get rid of the style in the header's admin
    // And it's way easier for the Q/A to keep using data-test during the ab-test (E2E on old header work with data-test)
    // https://backmarket.atlassian.net/browse/GNL-2089
    consolidatedTopSales() {
      return this.topSales
        .filter((sale) => sale.title && !isEmpty(sale.link))
        .map((sale) => ({
          ...sale,
          link: this.shouldReplaceLink(sale),
          style: { color: sale.style.text_color },
          test: `topsales-${sale.title}`,
        }))
    },
  },

  mounted() {
    if (!isEmpty(this.$refs.headerContainer)) {
      this.$refs.headerContainer.style.height = `${this.$refs.header.clientHeight}px`
    }
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.resizeHeader)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resizeHeader)
  },

  methods: {
    // TODO: Clean task https://backmarket.atlassian.net/browse/BRO-2079
    shouldReplaceLink(sale) {
      return this.$config.TOP_SALES_BONS_PLANS_LINK_ID?.includes(sale.id) &&
        this.shouldUseNewGoodDealsLink
        ? NEW_GOOD_DEALS_LINK
        : sale.link
    },

    // We re-use the old method to open the sub navigation
    // It is not a good practice but this was not part of the MVP
    // You can read the spec here
    // https://backmarket.atlassian.net/wiki/spaces/NS/pages/2093088813/P+J+Revamp+header
    openSubMenu() {
      const action = this.isNavigationOpened
        ? 'closeNavigation'
        : 'openNavigation'

      this.$store.dispatch(action)
      this.trackBurgerMenuClick()
    },

    trackBurgerMenuClick() {
      trackClick({
        zone: this.HEADER_TRACKING_NAME.NAVIGATION,
        name: 'all_product',
      })
    },

    trackHorizontalNav(sale, index) {
      this.trackClick(`topsales-${index}-${sale.title}`)
    },

    trackClick(name) {
      trackClick({
        zone: this.HEADER_TRACKING_ZONE,
        name,
      })
    },
    resizeHeader() {
      if (!isEmpty(this.$refs.headerContainer)) {
        this.$refs.headerContainer.style.height = `${this.$refs.header.clientHeight}px`
      }
    },
    handleScroll() {
      window.requestAnimationFrame(() => {
        const { headerContainer, header } = this.$refs
        const headerContainerBottomScroll =
          headerContainer.getBoundingClientRect().bottom
        const headerContainerTopScroll =
          headerContainer.getBoundingClientRect().top
        const currentScroll = window.pageYOffset

        // We make the header sticky 200px after it disappear from the viewport
        // We did that because it make the animation smoother
        if (headerContainerBottomScroll < POSITION_Y_ANIMATION_BEGIN) {
          header.classList.add(this.$style.animated)
        }

        // When we arrive at the top of the header wrapper
        // We remove the sticky class and replace the header on the top of the page
        if (headerContainerTopScroll >= 0) {
          this.headerVisible = true
          header.classList.remove(this.$style.animated)
        }

        const isScrollingUp = this.lastScrollPosition > currentScroll
        const isScrollingDown = this.lastScrollPosition < currentScroll

        // We check if user scrolls to the top
        // And we show the header
        if (isScrollingUp && !this.headerVisible) {
          this.scrollDistanceDown = 0
          header.classList.remove(this.$style.hidden)
          this.headerVisible = true
        }

        // We check if user scrolls to the bottom
        // And we hide the header
        if (isScrollingDown && this.headerVisible) {
          if (this.scrollDistanceDown === 0) {
            this.scrollDistanceDown = currentScroll
          }

          if (
            currentScroll >
            this.scrollDistanceDown + SHIFT_Y_SCROLL_DOWN_HEADER_HIDDEN
          ) {
            header.classList.add(this.$style.hidden)

            // The header is considered always visible when it is not sticky
            if (header.classList.contains(this.$style.animated)) {
              this.headerVisible = false
            }
          }
        }

        this.lastScrollPosition = currentScroll
      })
    },
  },
}
