import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

import generateAuthRoutes from './sub-modules/auth/routes'
import generateOnboardedRoutes from './sub-modules/onboarded/routes'
import generateOnboardingRoutes from './sub-modules/onboarding/routes'

// TODO: Add bo_merchant to the following routes
const badoomRoutesNotNamespaced = [
  {
    name: ROUTES.ADMIN.HOME,
    path: '/admin',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.PAYPAL_CONNECT_ACCOUNT,
    path: '/paypal/merchant_onboard',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.PASSWORD_RESET,
    path: '/password_reset/?m=merchant',
    meta: { rollout: false },
  },
  // Merchant product preview URL
  {
    name: ROUTES.PRODUCT.PREVIEW,
    path: '/preview/:id',
    meta: { rollout: false },
  },
]

const badoomRoutes = [
  {
    name: ROUTES.BO_MERCHANT.ROOT,
    path: '',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.DASHBOARD,
    path: 'dashboard',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.DASHBOARD,
    path: 'sourcing/dashboard',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.ORDER,
    path: 'sourcing/orders/:orderId',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.LISTINGS,
    path: 'sourcing/listings',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.SOURCING.INVOICES,
    path: 'sourcing/invoices',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.PERFORMANCES,
    path: 'performances',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.SEARCH,
    path: 'listings/new',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.CREATE,
    path: 'listings/new/product',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.LISTINGS.CREATE.PRODUCT.DUPLICATE,
    path: 'duplicate_product/:productBmId',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.ONBOARDING.LISTINGS.CREATE.SEARCH,
    path: 'onboarding/listings/new',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.DOCUSIGN_CGU,
    path: 'merchant/cgu/sign',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.QUALITY_CHARTER,
    path: 'merchant_support/charter',
    meta: { rollout: false },
  },
  {
    name: ROUTES.OLD_MERCHANT_ORDERS,
    path: 'order-old',
    meta: { rollout: false },
  },
  {
    name: ROUTES.CLIENTS,
    path: 'admin_tools/clients',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.MAILBOX,
    path: 'mailbox',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.LOGOUT,
    path: 'logout',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.PAYOUT_INFORMATION,
    path: 'kyb/adyens_onboarding_page',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.OPTIONS.COMMENTS,
    path: 'options/comments',
    meta: { rollout: false },
  },
  {
    name: ROUTES.BO_MERCHANT.REVIEWS,
    path: 'reviews',
    meta: { rollout: false },
  },
]

export default ({ marketplace }) => {
  const authRoutes = generateAuthRoutes({ marketplace })
  const onboardedRoutes = generateOnboardedRoutes({ marketplace })
  const onboardingRoutes = generateOnboardingRoutes({ marketplace })

  return [
    {
      path: '/bo_merchant',
      component: () => dynamicImport(import('./layouts/Authenticated.vue')),
      meta: {
        auth: {
          required: true,
          redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
        },
        seller: {
          required: true,
          redirection: { name: ROUTES.BO_MERCHANT.LOGIN },
        },
      },
      children: [
        ...authRoutes.requiresSellerAuthentication,
        ...onboardedRoutes.requiresSellerAuthentication,
        ...onboardingRoutes.requiresSellerAuthentication,
        ...badoomRoutes,
      ],
    },
    {
      path: '/bo_merchant',
      component: () => dynamicImport(import('./layouts/Unauthenticated.vue')),
      children: [
        ...authRoutes.other,
        ...onboardedRoutes.other,
        ...onboardingRoutes.other,
      ],
    },
    ...badoomRoutesNotNamespaced,
  ]
}
