import { sleep } from '@core/helpers'

const retry = async (call, retriesAvailable = 5, interval = 200) => {
  try {
    const response = await call()

    return response
  } catch (error) {
    if (retriesAvailable > 0) {
      await sleep(interval)

      return retry(call, retriesAvailable - 1, interval)
    }
    throw error
  }
}

export default retry
