import isEmpty from 'lodash/isEmpty'

const CANCELLED = 'cancelled'
const REFUND = 'refund'

export const getRefundState = (orderline) => {
  if (
    !orderline.refunded &&
    orderline.history.orderlineId &&
    orderline.history.type
  ) {
    return {
      replaceOrderId: orderline.history.orderlineId,
      type: orderline.history.type,
    }
  }

  const getStep = (steps, matches) =>
    steps.find(({ type }) => matches.includes(type)) || {}

  const { type, date } = getStep(orderline.steps, [
    'refunded_pre_delivery',
    'refunded_post_delivery',
    'cancelled',
  ])

  if (isEmpty(type)) {
    return false
  }

  return {
    date,
    type: type === CANCELLED ? CANCELLED : REFUND,
  }
}
