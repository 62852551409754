import get from 'lodash/get'

import { COUNTRY_KEY, MARKETPLACE_KEY } from '@server/request/constants'
import { getUserContextFromRequest } from '@server/utils'

export const getCountry = (ssrContext) => {
  if (ssrContext) {
    return getUserContextFromRequest(ssrContext.req, 'country')
  }

  return window[COUNTRY_KEY]
}

export const getMarketplace = (ssrContext) => {
  if (ssrContext) {
    return getUserContextFromRequest(ssrContext.req, 'marketplace')
  }

  return window[MARKETPLACE_KEY]
}

export const reloadForRoute = (route) => {
  const isRollout = get(route, 'meta.rollout', true)

  return !isRollout
}
