import omit from 'lodash/omit'

import { MESSAGE_KINDS, SAV_ROLES } from '@sav-common/constants'

const ALLOWED_INVOICE_TYPES = ['application/pdf']

const COUNTS_UPDATE_STATUS = {
  added: 1,
  noUpdate: 0,
  removed: -1,
}

const DEFAULT_EMPTY_FILTERS = {
  countries: [],
  creationDateMax: '',
  creationDateMin: '',
  customer: '',
  issues: [],
  orderId: '',
  productName: '',
  sku: '',
}

const INTRO_MODAL_COOKIE = 'BM_savplatform-missing-return-addresses-alert'
const FALCON_RETURN_MODAL_COOKIE = 'BM_savplatform-falcon_return'
const FALCON_RETURN_LABEL_MODAL_COOKIE = `${FALCON_RETURN_MODAL_COOKIE}_label`

const FALCON_RETURN_OOW_MODAL_COOKIE = `${FALCON_RETURN_MODAL_COOKIE}_oow`
const ASP_BANNER_DISCARDED_COOKIE = 'BM_ASP_banner_discarded'

const MESSAGE_BAR_STATUSES = {
  archivingReturn: 'archivingReturn',
  closing: 'closing',
  opening: 'opening',
  refunding: 'refunding',
  returning: 'returning',
  returningBack: 'returningBack',
  backReturnSent: 'backReturnSent',
  productReturnSent: 'productReturnSent',
  sendingMessage: 'sendingMessage',
  messageSent: 'messageSent',
}

const PRODUCT_RETURN_KINDS = {
  customerToMerchant: 'CUSTOMER_TO_MERCHANT',
  merchantToCustomer: 'MERCHANT_TO_CUSTOMER',
  customerToFactory: 'CUSTOMER_TO_FACTORY',
}

const ONE_WAY_PRODUCT_RETURN_KINDS = [
  PRODUCT_RETURN_KINDS.customerToMerchant,
  PRODUCT_RETURN_KINDS.customerToFactory,
]

const PRODUCT_RETURN_SOURCES = {
  backship: 'TELIAE',
  colissimo: 'COLISSIMO',
  manual: 'MANUAL',
}

const PRODUCT_RETURN_STATES = {
  none: 'NO_PRODUCT_RETURN',
  created: 'PRODUCT_RETURN_CREATED',
  pickedUp: 'PRODUCT_RETURN_PICKED_UP',
  delivered: 'PRODUCT_RETURN_DELIVERED',
  expired: 'PRODUCT_RETURN_EXPIRED',
  returnBackCreated: 'RETURN_OF_PRODUCT_RETURN_CREATED',
  returnBackPickedUp: 'RETURN_OF_PRODUCT_RETURN_PICKED_UP',
  returnBackDelivered: 'RETURN_OF_PRODUCT_RETURN_DELIVERED',
}

const REPAIR_STATUS = {
  created: 'CREATED',
  received: 'RECEIVED',
  deviceLocked: 'DEVICE_LOCKED',
  deviceUnlocked: 'DEVICE_UNLOCKED',
  outOfWarranty: 'OUT_OF_WARRANTY',
  repaired: 'REPAIRED',
  notRepaired: 'NOT_REPAIRED',
  shippedToCustomer: 'SHIPPED_TO_CUSTOMER',
}

const SAV_PLATFORM_CONTEXTS = {
  all: 'all',
  backcare: 'backcare',
  closed: 'closed',
  standaloneConversation: 'standalone-conversation',
  invoiceRequests: 'invoice-requests',
  pending: 'pending',
  returns: 'returns',
  search: 'search',
}
const DEFAULT_CONTEXT = SAV_PLATFORM_CONTEXTS.pending
const CONTEXTS_WITH_ISSUES_LIST = Object.values(
  omit(SAV_PLATFORM_CONTEXTS, [
    'all',
    'invoiceRequests',
    'returns',
    'standaloneConversation',
  ]),
)

const COUNTS_PROPERTIES = {
  [SAV_PLATFORM_CONTEXTS.invoiceRequests]: 'pendingInvoiceRequests',
  [SAV_PLATFORM_CONTEXTS.pending]: 'pendingClaims',
  [SAV_PLATFORM_CONTEXTS.returns]: 'pendingProductReturns',
}

const SAV_PLATFORM_ROLES = omit(SAV_ROLES, SAV_ROLES.client)

const ALLOWED_CONTEXTS = {
  [SAV_PLATFORM_ROLES.merchant]: Object.values(
    omit(SAV_PLATFORM_CONTEXTS, ['invoiceRequests', 'search']),
  ),
  [SAV_PLATFORM_ROLES.admin]: [
    SAV_PLATFORM_CONTEXTS.search,
    SAV_PLATFORM_CONTEXTS.standaloneConversation,
  ],

  [SAV_PLATFORM_ROLES.backcare]: [SAV_PLATFORM_CONTEXTS.standaloneConversation],
}

const URGENCIES = {
  low: 'LOW',
  medium: 'MEDIUM',
  high: 'HIGH',
}

const DEFAULT_FILTERS = {
  [SAV_PLATFORM_CONTEXTS.backcare]: DEFAULT_EMPTY_FILTERS,
  [SAV_PLATFORM_CONTEXTS.closed]: DEFAULT_EMPTY_FILTERS,
  [SAV_PLATFORM_CONTEXTS.invoiceRequests]: {
    countries: [],
    customer: '',
    orderId: '',
    processed: 'false',
  },
  [SAV_PLATFORM_CONTEXTS.pending]: DEFAULT_EMPTY_FILTERS,
  [SAV_PLATFORM_CONTEXTS.returns]: {
    countries: [],
    customer: '',
    orderId: '',
    sku: '',
  },
  [SAV_PLATFORM_CONTEXTS.search]: {
    countries: [],
    customer: '',
    issues: [],
    merchant: '',
    orderId: '',
    source: 'all',
    state: 'all',
  },
  [SAV_PLATFORM_CONTEXTS.all]: {
    orderId: '',
  },
}

const ALLOWED_FILTERS = Object.values(SAV_PLATFORM_CONTEXTS).reduce(
  (allowedFilters, context) => ({
    ...allowedFilters,
    [context]: Object.keys(DEFAULT_FILTERS[context] || {}),
  }),
  {},
)

const RETURN_ADDRESSES_TYPES = {
  merchant: 'merchant',
  backRepair: 'backRepair',
}

const INFORMATIVE_MESSAGE_KINDS = [
  MESSAGE_KINDS.adminToMerchant,
  MESSAGE_KINDS.backcareToMerchant,
]

/**
 * TODO
 * It may be a duplicate of mocks in `modules/dashboard/my-orders/constants.js`
 * We should extract that in a common place.
 */
const INSURANCES_COVERAGES_KINDS = {
  damage: 'DAMAGE',
  extension: 'WARRANTY_EXTENSION',
  theft: 'THEFT',
}

const INSURANCES_DOCUMENTS_TYPES = {
  information: 'IPID',
  recap: 'BACKMARKET_RECAP',
  terms: 'TERMS_AND_CONDITIONS',
}

const INSURANCES_STATES = {
  created: 'CREATED',
  expired: 'EXPIRED',
  subscribed: 'SUBSCRIBED',
  terminated: 'TERMINATED',
}

const WARRANTIES_PAYMENT_FREQUENCIES = {
  monthly: 'MONTHLY',
}

const WARRANTIES_STATUSES = {
  canceled: 'CANCELED',
  inProgress: 'IN_PROGRESS',
  new: 'NEW',
  over: 'OVER',
  pending: 'PENDING',
}

export {
  ALLOWED_CONTEXTS,
  ALLOWED_FILTERS,
  ALLOWED_INVOICE_TYPES,
  CONTEXTS_WITH_ISSUES_LIST,
  COUNTS_PROPERTIES,
  COUNTS_UPDATE_STATUS,
  DEFAULT_CONTEXT,
  DEFAULT_EMPTY_FILTERS,
  DEFAULT_FILTERS,
  FALCON_RETURN_MODAL_COOKIE,
  FALCON_RETURN_LABEL_MODAL_COOKIE,
  FALCON_RETURN_OOW_MODAL_COOKIE,
  INFORMATIVE_MESSAGE_KINDS,
  INSURANCES_COVERAGES_KINDS,
  INSURANCES_DOCUMENTS_TYPES,
  INSURANCES_STATES,
  INTRO_MODAL_COOKIE,
  ASP_BANNER_DISCARDED_COOKIE,
  MESSAGE_BAR_STATUSES,
  ONE_WAY_PRODUCT_RETURN_KINDS,
  PRODUCT_RETURN_KINDS,
  PRODUCT_RETURN_SOURCES,
  PRODUCT_RETURN_STATES,
  REPAIR_STATUS,
  RETURN_ADDRESSES_TYPES,
  SAV_PLATFORM_CONTEXTS,
  SAV_PLATFORM_ROLES,
  URGENCIES,
  WARRANTIES_PAYMENT_FREQUENCIES,
  WARRANTIES_STATUSES,
}
