// TODO: remove store

import isEmpty from 'lodash/isEmpty'

import { isNumber } from '@core/helpers'
import { fetchLandingPageShowcaseReviews } from '@http/endpoints'

const LIMIT_REVIEWS = 1

export default {
  namespaced: true,
  state() {
    return {
      best: {
        reviews: [],
        isPlaceholder: false,
        hide: false,
        isLoading: true,
      },
    }
  },
  getters: {
    get: (state) => state.best,
  },
  mutations: {
    set: (state, reviews) => {
      if (isEmpty(reviews)) {
        state.best.hide = true

        return
      }

      const isPlaceholder = reviews.some(
        (review) => !isNumber(review.reviewOrderLineId),
      )

      state.best.isPlaceholder = isPlaceholder

      if (isPlaceholder) {
        const reviewsWithIds = reviews.map((review, index) => ({
          ...review,
          reviewOrderLineId: index,
        }))

        state.best.reviews = reviewsWithIds

        return
      }

      state.best.reviews = reviews
    },
    hide: (state) => {
      state.best.hide = true
    },
    isLoading: (state, value) => {
      state.best.isLoading = value
    },
  },
  actions: {
    async fetch({ dispatch, commit }, { id: pk }) {
      commit('isLoading', true)
      try {
        const { payload } = await dispatch(
          'http/request',
          {
            request: fetchLandingPageShowcaseReviews,
            queryParams: { pk, limit: LIMIT_REVIEWS },
          },
          { root: true },
        )

        commit('set', payload)
        commit('isLoading', false)
      } catch (error) {
        commit('isLoading', false)
        commit('hide')
      }
    },
  },
}
