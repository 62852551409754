
import { RevButtonBase, RevToast } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import { TOAST_LOGOUT } from '@config/constants/toasts'
import { logout } from '@http/endpoints'
import { ROUTES } from '@router'

import translations from './Logout.translations'

export default {
  components: {
    RevButtonBase,
    RevToast,
  },

  data() {
    return {
      isToastOpened: false,
    }
  },

  computed: {
    ...mapGetters({
      isStaff: 'user/isStaff',
      isShadowingClient: 'user/isShadowingClient',
      isShadowingMerchant: 'user/isShadowingMerchant',
    }),

    TOAST_LOGOUT: () => TOAST_LOGOUT,

    translations: () => translations,

    isAdmin() {
      return this.isStaff || this.isShadowingClient || this.isShadowingMerchant
    },

    buttonProps() {
      // Currently, the only way to be logged out as an admin is to use the Badoom route.
      if (this.isAdmin) {
        return {
          ...this.$attrs,
          to: { name: ROUTES.AUTH.LOGOUT },
        }
      }

      return this.$attrs
    },

    eventListeners() {
      if (this.isAdmin) {
        return this.$listeners
      }

      return {
        ...this.$listeners,
        click: this.logout,
      }
    },
  },

  methods: {
    async logout() {
      this.isToastOpened = false

      try {
        await this.$store.dispatch('http/request', {
          request: logout,
        })

        const { href } = this.$navigation.resolve({
          name: ROUTES.AUTH.REGISTER,
        })

        // External link used in order to trigger a reload
        this.$navigation.push({ type: 'external', href })
      } catch (err) {
        this.isToastOpened = true
      }
    },

    closeToast() {
      this.isToastOpened = false
    },
  },
}
