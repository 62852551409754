import { init } from './vendor/initScript'

export function initAppsflyer({ bannersApiKey }) {
  const params = {
    configs: {
      banners: { key: bannersApiKey },
    },
  }

  init(params)
}
