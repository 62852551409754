import { removeEmptyValuesInObject } from '@core/helpers'

const formatPrice = (price) => (price ? parseFloat(price, 2).toFixed(2) : price)

export const productModel = ({
  brand,
  category,
  model,
  name,
  price,
  image,
  webUrl,
  mobileDeeplink,
} = {}) => {
  return removeEmptyValuesInObject({
    product_brand: brand,
    product_category: category,
    product_model: model,
    product_name: name,
    product_price: formatPrice(price),
    product_image: image,
    product_web_url: webUrl,
    product_mobile_deeplink: mobileDeeplink,
  })
}
