
import { mapGetters } from 'vuex'

import { ROUTES } from '@router'

import GoogleReviews from './components/GoogleReviews'
import TrustedShops from './components/TrustedShops'
import Trustpilot from './components/Trustpilot'

// These are the routes where no badge should be displayed.
export const BLOCKED_ROUTES = [
  ROUTES.CHECKOUT.HELLO,
  ROUTES.CHECKOUT.LOGIN,
  ROUTES.CHECKOUT.SIGNUP,
  ROUTES.CHECKOUT.ADDRESS_CONFIRMATION,
  ROUTES.CHECKOUT.SHIPPING_ADDRESS,
  ROUTES.CHECKOUT.BILLING_ADDRESS,
  ROUTES.CHECKOUT.PAYMENT,
  ROUTES.DASHBOARD.MY_SALES,
]

// Hide the badges 100px above the bottom of the page to not hide the social
// network icons
const LOWER_VISIBILITY_THRESHOLD = 100

export default {
  data() {
    return {
      isAtBottom: false,
    }
  },
  computed: {
    ...mapGetters({
      breakpoint: 'breakpoint',
      useGoogleReviews: 'config/useGoogleReviews',
      useTrustedShops: 'config/useTrustedShops',
      useTrustpilot: 'config/useTrustpilot',
    }),
    isMobile() {
      return this.breakpoint < 2
    },
    display() {
      return (
        this.externalReviewComponent !== null &&
        !BLOCKED_ROUTES.includes(this.$route.name)
      )
    },
    externalReviewComponent() {
      if (!this.isMobile && this.useGoogleReviews) {
        return GoogleReviews
      }

      if (this.useTrustedShops) {
        return TrustedShops
      }

      if (!this.isMobile && this.useTrustpilot) {
        return Trustpilot
      }

      return null
    },
  },
  mounted() {
    if (!this.isMobile) {
      window.addEventListener('scroll', this.checkIsAtBottom)
    }
  },
  beforeDestroy() {
    if (!this.isMobile) {
      window.removeEventListener('scroll', this.checkIsAtBottom)
    }
  },
  methods: {
    checkIsAtBottom() {
      if (this.$route.name !== ROUTES.HOME) {
        this.isAtBottom = false

        return
      }

      window.requestAnimationFrame(() => {
        this.isAtBottom =
          document.documentElement.scrollHeight -
            document.documentElement.scrollTop <=
          document.documentElement.clientHeight + LOWER_VISIBILITY_THRESHOLD
      })
    },
  },
}
