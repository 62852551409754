import isEmpty from 'lodash/isEmpty'

import { dangerouslyGetRuntimeConfig } from '@config/variables'
import {
  APP_COUNTRIES,
  getCountryConfig,
  getMarketBaseURLForCountry,
} from '@core/helpers/countries'
import { createRouter } from '@router/createRouter'

if (process.server) {
  process.routers = process.routers || {}
}

export const application = {
  storage: process.server ? process.routers : {},
  getRouter(country) {
    return this.storage[country]
  },
  saveRouter(country, router) {
    this.storage[country] = router
  },
  hasRouter(country) {
    // Disable the cache for the tests
    return process.env.NODE_ENV !== 'test' && !isEmpty(this.getRouter(country))
  },
}

const getApplicationRouter = ({ baseUrl, country }) => {
  if (application.hasRouter(country)) {
    return application.getRouter(country)
  }

  const $config = dangerouslyGetRuntimeConfig()
  const { marketplace, locale } = getCountryConfig(country)

  const { baseURL } = getMarketBaseURLForCountry({
    country,
    hostname: baseUrl,
  })

  const getRouter = createRouter(null, null, { country, marketplace }, $config)

  const config = { country, locale, marketplace, baseURL }

  application.saveRouter(country, { getRouter, config })

  return { getRouter, config }
}

export const getLink = async ({ link, baseUrl, country }) => {
  if (isEmpty(link)) {
    return null
  }

  const { getRouter, config } = getApplicationRouter({ baseUrl, country })

  // Some countries are not supported by the router
  let countryRouter
  try {
    countryRouter = await getRouter
  } catch {
    return config
  }

  const { fullPath, matched } = countryRouter.matcher.match({
    name: link.name,
    params: { ...link.params, locale: config.locale },
  })

  if (isEmpty(matched)) {
    return null
  }

  return {
    ...config,
    href: `${config.baseURL}${fullPath}`,
  }
}

export const getLinks = async ({
  link,
  baseUrl,
  countries = APP_COUNTRIES,
}) => {
  const links = countries.map((country) => getLink({ link, baseUrl, country }))
  const allLinks = await Promise.all(links)

  return allLinks.filter(Boolean)
}
