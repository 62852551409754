const getDefaultState = () => ({
  page: 1,
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get: (state) => state.page,
  },
  mutations: {
    increment: (state) => {
      state.page += 1
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    increment: ({ commit }) => commit('increment'),
    reset: ({ commit }) => commit('reset'),
  },
}
