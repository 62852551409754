import { fetchCustomerClaimSummary } from '@http/endpoints'

export default {
  namespaced: true,

  state() {
    return {
      summary: {},
    }
  },
  getters: {
    summary(state) {
      return state.summary
    },
  },
  mutations: {
    set(state, { summary }) {
      state.summary = summary
    },
  },
  actions: {
    async fetch({ commit, dispatch }, { customerRequestId }) {
      const { payload: summary } = await dispatch(
        'http/request',
        {
          request: fetchCustomerClaimSummary,
          pathParams: {
            customerRequestId,
          },
        },
        { root: true },
      )

      commit('set', { summary })
    },
  },
}
