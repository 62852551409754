import { splitHostname } from '@core/helpers'

let ravelinInstancePromise

/**
 * @param {Object} $config
 * @returns {Promise<Object>} A promise that resolves to the `ravelinjs` library
 * configured.
 */
async function getRavelinInstance($config) {
  if (process.server) {
    throw new Error('Using Ravelin library from server-side is forbidden')
  }

  if (!ravelinInstancePromise) {
    ravelinInstancePromise = import('ravelinjs/core+track').then(
      ({ default: Ravelin }) => {
        if (!$config.RAVELIN_KEY) {
          throw new Error('Missing RAVELIN_KEY config variable')
        }

        const { domain, extension } = splitHostname(window.location.host)

        const ravelin = new Ravelin({
          key: $config.RAVELIN_KEY,

          // As recommended, setting the cookie domain at as high a level as possible,
          // e.g. mysite.com rather than subdomain.mysite.com
          // Read more: https://developer.ravelin.com/libraries-and-sdks/ravelinjs/v1/#reference
          cookieDomain: `${domain}.${extension}`,
        })

        return ravelin
      },
    )
  }

  return ravelinInstancePromise
}

/**
 * @param {Object} $config
 * @returns {Promise<string>} A promise that resolves to the Ravelin device ID.
 */
export async function ravelinDeviceId($config) {
  return (await getRavelinInstance($config)).core.id()
}
