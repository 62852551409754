import { v4 as uuidv4, validate as validateUUID } from 'uuid'

import { setCookie } from '@core/helpers/cookies'
import { createTTL } from '@core/helpers/date'
import { HEADER_SET_COOKIE } from '@http/headers'
import logger from '@logger'

const createCookieExpirationDate = (expirationDate) =>
  createTTL({ milliseconds: expirationDate }).toUTCString()

export const createCorrelationId = () => uuidv4()

export const getCorrelationId = (cookies, cookie) =>
  cookies[cookie.name] || createCorrelationId()

/**
 *
 * @param {String} visitorId uuid
 * @param {Object} res
 */
export const refreshCorrelationIdCookie = (cookie, value, res = {}) => {
  const expirationDate = createCookieExpirationDate(cookie.expiration)
  let cookieValue = value

  if (process.client) {
    setCookie(cookie.name, cookieValue, { expires: expirationDate })
  } else {
    if (!validateUUID(cookieValue)) {
      cookieValue = createCorrelationId()
      logger.error(new Error(`Wrong header value for ${cookie.name}`), {
        name: cookie.name,
        value,
        newValue: cookieValue,
      })
    }

    // Since the response.setHeader nodeJS method does not merge the already added headers,
    // It is necessary to make sure the other potential headers added are not removed here.
    const existingSetHeaders = res.getHeaders()?.[HEADER_SET_COOKIE] ?? []

    res.setHeader(HEADER_SET_COOKIE, [
      ...existingSetHeaders,
      `${cookie.name}=${cookieValue}; expires=${expirationDate}`,
    ])
  }

  return cookieValue
}
