import merge from 'deepmerge'
import isEmpty from 'lodash/isEmpty'

import { insertIf } from '@core/helpers'

import {
  HEADER_CONTENT_TYPE,
  HEADER_COUNTRY,
  HEADER_CSRF,
  HEADER_LANGUAGE,
  HEADER_MARKETPLACE,
  HEADER_VISITOR_ID,
} from './headers'
import { cloudflareBotManagement } from './hooks/cloudflare-bot-management'
import { ON_ERROR } from './hooks/names'

const urlEncodedForm = (data) => {
  if (isEmpty(data)) return data

  return Object.entries(data)
    .filter(([_, value]) => value !== null && value !== undefined) // eslint-disable-line no-unused-vars
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&')
}

export default {
  namespaced: true,

  actions: {
    request(
      { rootGetters },
      { request, body, cancelToken, encode, ...config },
    ) {
      const userRequestHeaders = rootGetters['config/userRequestHeaders']
      const apiBaseUrl = rootGetters['config/apiBaseUrl']
      const visitorId = rootGetters['config/visitorId']
      const country = rootGetters['config/country']
      const marketplace = rootGetters['config/marketplace']
      const locale = rootGetters['config/locale']
      const csrf = rootGetters['auth/csrf']

      return request({
        hooks: {
          [ON_ERROR]: [cloudflareBotManagement(this.$navigation, this.$config)],
        },
        // Keep the body out of the merge in order to avoid the transformation of FormData into
        // Object. Same goes for the cancelToken from Axios: we want to keep its methods.
        body: encode ? urlEncodedForm(body) : body,
        cancelToken,
        ...merge(config, {
          apiBaseUrl,
          headers: {
            ...insertIf(!isEmpty(userRequestHeaders), userRequestHeaders),
            ...insertIf(!isEmpty(csrf), { [HEADER_CSRF]: csrf }),
            ...insertIf(encode, {
              [HEADER_CONTENT_TYPE]: 'application/x-www-form-urlencoded',
            }),
            [HEADER_MARKETPLACE]: marketplace,
            [HEADER_COUNTRY]: country,
            [HEADER_LANGUAGE]: locale,
            [HEADER_VISITOR_ID]: visitorId,
          },
        }),
      })
    },
  },
}
