import {
  fetchNotificationStatusCustomerRequestDiscussion,
  updateNotificationStatusCustomerRequestDiscussion,
} from '@http/endpoints'

// By default they are enabled.
export const DEFAULT_EMAIL_NOTIFICATION_STATUS = true

export default {
  namespaced: true,

  state() {
    return {
      emailNotificationStatus: DEFAULT_EMAIL_NOTIFICATION_STATUS,
    }
  },
  getters: {
    areEmailNotificationsEnabled(state) {
      return state.emailNotificationStatus
    },
  },
  mutations: {
    setEmailNotificationStatus(state, { emailNotificationStatus }) {
      state.emailNotificationStatus = emailNotificationStatus
    },
  },
  actions: {
    async fetch({ commit, dispatch }, { customerRequestId }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchNotificationStatusCustomerRequestDiscussion,
          pathParams: { customerRequestId },
        },
        { root: true },
      )

      commit('setEmailNotificationStatus', {
        emailNotificationStatus: payload.customerNotification,
      })
    },
    async toggle({ commit, dispatch, getters }, { customerRequestId }) {
      const wereEmailNotificationsEnabled = getters.areEmailNotificationsEnabled
      const areEmailNotificationEnabled = !wereEmailNotificationsEnabled

      await dispatch(
        'http/request',
        {
          request: updateNotificationStatusCustomerRequestDiscussion,
          pathParams: { customerRequestId },
          body: {
            customerNotification: areEmailNotificationEnabled,
          },
        },
        { root: true },
      )

      commit('setEmailNotificationStatus', {
        emailNotificationStatus: areEmailNotificationEnabled,
      })
    },
  },
}
