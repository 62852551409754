// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._307VpKR5mst3w3W-PnPe0y{display:none}@media (min-width:992px){._307VpKR5mst3w3W-PnPe0y{display:block;bottom:0;right:0;float:right;margin:0 .8rem .8rem 0;z-index:2000!important;position:fixed}}._1pg-XYSXBoI4mogiaHUX7J{padding:1.6rem;background-color:#fff;border-radius:.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "_307VpKR5mst3w3W-PnPe0y",
	"widget": "_1pg-XYSXBoI4mogiaHUX7J"
};
module.exports = ___CSS_LOADER_EXPORT___;
