import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export default () => {
  return [
    {
      path: 'about-us',
      name: ROUTES.ABOUT_US,
      component: () => dynamicImport(import('./pages/AboutUs.vue')),
    },
  ]
}
