// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8MskQYAHLiMQ1PTD1-47E{display:none}@media (min-width:992px){._8MskQYAHLiMQ1PTD1-47E{display:block;bottom:0;right:0;float:right;margin:0 .8rem .5rem 0;z-index:2000!important;position:fixed}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "_8MskQYAHLiMQ1PTD1-47E"
};
module.exports = ___CSS_LOADER_EXPORT___;
