import { ROUTES } from '@router'

export const DO_NOT_LOAD_ROUTES_COLLECTION = [
  ROUTES.ADMIN,
  ROUTES.ADMIN_TOOLS,
  ROUTES.BO_MERCHANT,
  ROUTES.BUYBACK.MERCHANT,
  ROUTES.KYB,
  ROUTES.MERCHANT_INFOS,
  ROUTES.OLD_MERCHANT_ORDERS,
  ROUTES.PDF,
  ROUTES.SAV_PLATFORM,
  ROUTES.SAV_PLATFORM_ADMIN,
  ROUTES.SAV_PLATFORM_BACKCARE,
  ROUTES.SAV_CONVERSATION,
]
