import { fetchResolutions } from '@http/endpoints'

export default {
  namespaced: true,

  state() {
    return {
      summary: null,
    }
  },
  getters: {
    summary(state) {
      return state.summary
    },
  },
  mutations: {
    set(state, { summary }) {
      state.summary = summary
    },
  },
  actions: {
    async fetch({ commit, dispatch }, { resolutionId }) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchResolutions,
        },
        { root: true },
      )

      const summary = payload?.resolutions.find(
        (resolution) => resolution.id === resolutionId,
      )

      commit('set', { summary })
    },
  },
}
