import isEmpty from 'lodash/isEmpty'

import {
  refundCustomerRequestPlatformOrder,
  returnBackCustomerRequestPlatform,
  returnManualProductCustomerRequestPlatform,
  returnProductCustomerRequestPlatform,
} from '@http/endpoints'
import { getApiPathFromRole, isPending, isReturns } from '@sav-common/helpers'

import { PRODUCT_RETURN_STATES, SAV_PLATFORM_ROLES } from '../../../constants'

export const RETURN_TYPES = {
  manual: 'manual',
  backRepair: 'backrepair',
  backShip: 'backship',
}

export default {
  namespaced: true,

  actions: {
    async refund(
      { dispatch },
      {
        amountCts,
        context,
        customerRequestId,
        orderlineId,
        partial = false,
        role = SAV_PLATFORM_ROLES.merchant,
      },
    ) {
      await dispatch(
        'http/request',
        {
          request: refundCustomerRequestPlatformOrder,
          pathParams: { orderlineId },
          body: {
            refundAmountCts: amountCts,
          },
        },
        { root: true },
      )

      // Now that the orderline of the customer request is refunded, update the
      // allowed actions for this customer request.
      dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )

      if (isPending(context)) {
        // In the pending context, we must also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      } else if (isReturns(context) && !partial) {
        // On the other hand, fully refunding an order when in the product
        // returns context should remove the product return from the list as
        // it's not pending anymore (as the merchant fully refunded it).
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }
    },
    async returnBack(
      { dispatch },
      {
        address,
        carrier,
        context,
        customerRequestId,
        role = SAV_PLATFORM_ROLES.merchant,
        trackingNumber,
      },
    ) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: returnBackCustomerRequestPlatform,
          pathParams: { customerRequestId },
          body: {
            returnAddressId: address.addressId,
            trackingNumber,
            shipperName: carrier.name,
          },
        },
        { root: true },
      )

      if (!isEmpty(payload.message)) {
        dispatch(
          'customerRequestDiscussion/discussion/addMessage',
          { message: payload.message },
          { root: true },
        )
      }

      // Now that the return of product return is created, update the allowed
      // actions for this customer request.
      dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )

      if (isPending(context)) {
        // In the pending context, we should also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      }

      if (isReturns(context)) {
        // In the product returns context should remove the product return from
        // the list as it's not pending anymore (as the merchant returned the
        // product back to the customer).
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }

      // Only update the status of the product return in the selected
      // conversation when we are not in product returns context as it's
      // unselected anyway in this context.
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnBackCreated,
          },
        },
        { root: true },
      )
    },
    async returnProduct(
      { dispatch },
      {
        address,
        attachments = [],
        carrier,
        context,
        customerRequestId,
        isBackRepairFactory = false,
        message,
        productReturnLabel,
        role = SAV_PLATFORM_ROLES.merchant,
        trackingNumber,
      },
    ) {
      const hasProductReturnLabel = productReturnLabel instanceof File

      let type = RETURN_TYPES.manual
      if (!hasProductReturnLabel) {
        if (isBackRepairFactory) {
          type = RETURN_TYPES.backRepair
        } else {
          type = RETURN_TYPES.backShip
        }
      }

      let body
      if (!hasProductReturnLabel) {
        body = isBackRepairFactory
          ? {
              backrepairFactoryId: address.addressId,
            }
          : {
              returnAddressId: address.addressId,
            }
      } else {
        // TODO [FRONT-586] Use toFormData Axios helper
        body = new FormData()

        body.append('shipmentLabelDocument', productReturnLabel)
        attachments.forEach((attachment) =>
          body.append('attachments', attachment),
        )
        body.append('trackingNumber', trackingNumber)
        body.append('shipperName', carrier.name)
        body.append('message', message)

        if (type === RETURN_TYPES.backRepair) {
          body.append('backrepairFactoryId', address.addressId)
        }
        if (type === RETURN_TYPES.backShip || type === RETURN_TYPES.manual) {
          body.append('returnAddressId', address.addressId)
        }
      }

      if (type === RETURN_TYPES.manual) {
        await dispatch(
          'http/request',
          {
            request: returnManualProductCustomerRequestPlatform,
            pathParams: {
              customerRequestId,
              role: getApiPathFromRole(role),
              type,
            },
            body,
          },
          { root: true },
        )
      } else {
        await dispatch(
          'http/request',
          {
            request: returnProductCustomerRequestPlatform,
            pathParams: {
              customerRequestId,
            },
            body,
          },
          { root: true },
        )
      }

      // Now that the product return is created, update the allowed actions for
      // this customer request.
      dispatch(
        'customerRequestPlatform/discussion/fetchAllowedActions',
        { customerRequestId, role },
        { root: true },
      )

      if (isPending(context)) {
        // In the pending context, we should also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      }

      // Finally update the status of the product return in the selected
      // conversation
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnCreated,
          },
        },
        { root: true },
      )
    },
    async refunded({ dispatch }, { context, customerRequestId, isFullRefund }) {
      if (isPending(context)) {
        // In the pending context, we must also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      } else if (isReturns(context) && isFullRefund) {
        // On the other hand, fully refunding an order when in the product
        // returns context should remove the product return from the list as
        // it's not pending anymore (as the merchant fully refunded it).
        dispatch(
          'customerRequestPlatform/conversations/remove',
          { customerRequestId },
          { root: true },
        )
      }
    },
    async returnLabelGenerated({ dispatch }, { context, customerRequestId }) {
      if (isPending(context)) {
        // In the pending context, we should also update the display of the
        // "Remaining hours" as the merchant made an action on the customer
        // request.
        await dispatch(
          'customerRequestPlatform/discussion/fetchRemainingHours',
          { customerRequestId },
          { root: true },
        )
      }

      // Finally update the status of the product return in the selected
      // conversation
      dispatch(
        'customerRequestDiscussion/information/update',
        {
          customerRequestId,
          updates: {
            productReturnState: PRODUCT_RETURN_STATES.returnCreated,
          },
        },
        { root: true },
      )
    },
  },
}
