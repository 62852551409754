// This dictionnary lists all experiments and their default value, in the form:
//   - Key: Experiment name, as defined in Cloudbees.
//   - Value: Experiment default value.
export const experiments = {
  'experiment.ssAATest': 'noVariation',
  'experiment.ssBBTest': 'noVariation',
  'experiment.originalPriceCrossed': 'noVariation',
  'experiment.ppContentEcoBlock': 'noVariation',
  'experiment.ppContentReassuranceInfoPosition': 'noVariation',
  'experiment.cartRecommendationBlockV2': 'noVariation',
  'experiment.lpHeroReviewsRemoval': 'noVariation',
  'experiment.resultsAlgoliaReranking': 'noVariation',
  'experiment.bestSellerTag': 'noVariation',
  'experiment.deliveryPromiseLabel': 'noVariation',
  'experiment.cartInsuranceUntickMovedDown': 'noVariation',
  'experiment.goodDealsLink': 'noVariation',
  'experiment.insuranceDedicatedStep': 'noVariation',
  'experiment.ppBestOffersAdditionV2': 'noVariation',
  'experiment.splitPaymentWording': 'noVariation',
}
