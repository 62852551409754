import { APP_COUNTRIES } from '@core/helpers/countries'
import { getLink } from '@router/links'

export const generateAlternateLink = async ({ link, baseUrl, country }) => {
  const linkObject = await getLink({ link, baseUrl, country })

  return {
    rel: 'alternate',
    hreflang: linkObject.locale,
    href: linkObject.href,
  }
}

export const generateAlternateLinks = async ({
  link,
  baseUrl,
  countries = APP_COUNTRIES,
}) => {
  return Promise.all(
    countries.map((country) =>
      generateAlternateLink({ link, baseUrl, country }),
    ),
  )
}
