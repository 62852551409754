import {
  createConsoleTransport,
  createDatadogTransport,
  createLogger,
} from '@backmarket/front-logger'

import { dangerouslyGetRuntimeConfig } from '@config/variables'
import { isBrowser, isNode } from '@core/helpers'
import { BUNDLE_ID } from '@http/apiConstants'
import { COUNTRY_KEY, MARKETPLACE_KEY } from '@server/request/constants'

import {
  DD_BROWSER_LOGS_CLIENT_TOKEN,
  DD_SERVICE,
  DD_SITE,
  LOG_LEVELS,
} from './constants'

const {
  DD_BROWSER_LOGS_ENABLED,
  DD_CONTINENT,
  DD_ENV,
  DEV_BROWSER_LOGS_TO_CONSOLE_ENABLED,
  GTM_ENV,
  DD_SERVER_LOGS_ENABLED,
  IS_PRODUCTION,
} = dangerouslyGetRuntimeConfig()

const attributes = {
  bundleId: BUNDLE_ID,
  continent: DD_CONTINENT,
  country: isBrowser() ? window[COUNTRY_KEY] : undefined,
  env: DD_ENV,
  gtm: GTM_ENV,
  marketplace: isBrowser() ? window[MARKETPLACE_KEY] : undefined,
  service: {
    name: DD_SERVICE,
    version: process.env.IMAGE_RELEASE,
    commit: process.env.IMAGE_COMMIT,
  },
}

const isInDebugMode = isBrowser()
  ? window.location.search.includes('debug')
  : false
if (isInDebugMode) {
  window.BM_LOGGER_OVERRIDE_CONSOLE_LEVEL = 0
}

const transports = []
if (
  (isBrowser() && (DEV_BROWSER_LOGS_TO_CONSOLE_ENABLED || isInDebugMode)) ||
  (isNode() && !DD_SERVER_LOGS_ENABLED)
) {
  transports.push(createConsoleTransport())
}

if (
  (isBrowser() && DD_BROWSER_LOGS_ENABLED) ||
  (isNode() && DD_SERVER_LOGS_ENABLED)
) {
  transports.push(
    createDatadogTransport({
      datadogConfiguration: {
        clientToken: DD_BROWSER_LOGS_CLIENT_TOKEN,
        forwardErrorsToLogs: true,
        sampleRate: 100,
        site: DD_SITE,
      },
    }),
  )
}

const logger = createLogger({
  attributes,
  level: LOG_LEVELS.INFO,
  transports,
})

// Do not wrap console/std during test for two reasons:
//  * Avoid to have memory leaks
//  * Ease the usage of console and debugging in tests
// Also, don't wrap the console in local dev mode to be able to simply use
// `console.log` for debugging.
if (IS_PRODUCTION) {
  if (isBrowser()) {
    logger.wrapConsole()
  } else if (isNode()) {
    logger.wrapAll()
  }
}

export default logger
