import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import {
  fetchPaymentMethods,
  navigationFetch,
  skinnyBannersFetch,
  topSalesFetch,
} from '@http/endpoints'
import retry from '@http/retry'

export const SKINNY_BANNERS_DEFAULT = []

export default {
  namespaced: true,

  state() {
    return {
      navigation: [],
      sales: [],
      paymentMethods: [],
      skinnyBanners: SKINNY_BANNERS_DEFAULT,
      isSkinnyBannerDisplayed: true,
    }
  },

  getters: {
    navigation: (state) => state.navigation,
    sales: (state) => state.sales,
    paymentMethods: (state) => state.paymentMethods,
    paymentMethodsIds: (_, getters) =>
      getters.paymentMethods.map((paymentMethod) => paymentMethod.bmCode),
    skinnyBanner: (state) => (page, id) => {
      let banners = state.skinnyBanners.filter(({ locations = [] }) =>
        locations.includes(page),
      )
      if (id) {
        banners = banners.filter(({ id: bannerId }) => bannerId === id)
      }

      const [bannerToDisplay = {}] = banners

      return omit(bannerToDisplay, ['locations', 'id'])
    },
    isSkinnyBannerAvailable: (_, getters) => (page, id) =>
      !isEmpty(getters.skinnyBanner(page, id)),
    isSkinnyBannerDisplayed: (state) => state.isSkinnyBannerDisplayed,
  },

  mutations: {
    setNavigation: (state, navigation) => {
      state.navigation = navigation
    },
    setTopSales: (state, sales) => {
      state.sales = sales
    },
    setPaymentMethods: (state, methods) => {
      state.paymentMethods = methods
    },
    setSkinnyBanners: (state, banners = SKINNY_BANNERS_DEFAULT) => {
      state.skinnyBanners = banners
    },
    closeSkinnyBanner: (state) => {
      state.isSkinnyBannerDisplayed = false
    },
  },
  actions: {
    async fetchNavigation({ commit, dispatch }) {
      try {
        const { payload } = await retry(() =>
          dispatch(
            'http/request',
            { request: navigationFetch },
            { root: true },
          ),
        )

        commit('setNavigation', payload)

        return payload
      } catch {
        return []
      }
    },

    async fetchTopSales({ dispatch, commit }) {
      try {
        const { payload } = await retry(() =>
          dispatch('http/request', { request: topSalesFetch }, { root: true }),
        )

        commit('setTopSales', payload)

        return payload
      } catch {
        return []
      }
    },

    async fetchPaymentMethods({ commit, dispatch, rootGetters }) {
      try {
        const { payload } = await retry(() =>
          dispatch(
            'http/request',
            {
              request: fetchPaymentMethods,
              queryParams: {
                country_code: rootGetters['config/country'].toLowerCase(),
              },
            },
            { root: true },
          ),
        )
        commit('setPaymentMethods', payload.results)

        return payload.results
      } catch {
        return []
      }
    },

    async fetchSkinnyBanners({ commit, dispatch }) {
      try {
        const { payload } = await retry(() =>
          dispatch(
            'http/request',
            { request: skinnyBannersFetch },
            { root: true },
          ),
        )

        commit('setSkinnyBanners', payload)

        return payload
      } catch {
        commit('setSkinnyBanners', SKINNY_BANNERS_DEFAULT)

        return SKINNY_BANNERS_DEFAULT
      }
    },

    closeSkinnyBanner({ commit }) {
      commit('closeSkinnyBanner')
    },
  },
}
