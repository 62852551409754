
import { affirmCheckoutPrice, getAffirmSdk } from '../../methods'
import { AFFIRM_MINIMAL_AMOUNT, AFFIRM_MODAL_SETTINGS } from '../config/affirm'

export default {
  props: {
    name: {
      type: String,
      default: 'AffirmModal',
    },

    /**
     * @typedef {Price}
     */
    basePrice: {
      type: Object,
      required: true,
    },

    /**
     * See PAYMENT_ADVERTISING_TYPES
     */
    variant: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    affirm: null,
    opened: false,
  }),

  computed: {
    settings() {
      return AFFIRM_MODAL_SETTINGS[this.variant] || {}
    },

    amount() {
      return this.settings.requiresAmount
        ? Math.max(affirmCheckoutPrice(this.basePrice), AFFIRM_MINIMAL_AMOUNT)
        : undefined
    },
  },

  watch: {
    basePrice() {
      if (this.affirm) {
        this.affirm.ui.refresh()
      }
    },
  },

  async mounted() {
    await this.prepareModal()
  },

  methods: {
    async prepareModal() {
      // Lazy-load Affirm SDK
      if (!this.affirm) {
        this.affirm = await getAffirmSdk(this.$config)
      }

      this.affirm.events.on('prequal:close', () => {
        this.close()
      })

      this.affirm.ui.ready(async () => {
        this.affirm.ui.refresh()
      })
    },

    open() {
      if (!this.opened) {
        if (this.affirm) {
          this.affirm.ui.refresh()
        }
        this.opened = true
        this.$refs.link.click()
      }
    },

    close() {
      this.opened = false
    },
  },
}
