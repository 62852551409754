import a11yRoutes from '@a11y/routes'
import aboutUsRoutes from '@about-us/routes'
import adminToolsRoutes from '@admin-tools/routes'
import articleRoutes from '@article/routes'
import externalAuthRoutes from '@auth/externalRoutes'
import authRoutes from '@auth/routes'
import buybackRoutes from '@buyback/routes/customer-routes'
import buybackMerchantRoutes from '@buyback/routes/refurbisher-routes'
import buyingGuidesRoutes from '@buying-guide/routes'
import { checkoutDefaultRoutes, checkoutEmptyRoutes } from '@checkout/routes'
import cmsRoutes from '@cms/routes'
import defaultLayout from '@core/layouts/default.vue'
import customerRequestsB2CRoutes from '@customer-requests/B2CRoutes'
import customerRequestsRoutes from '@customer-requests/routes'
import dashboardRoutes from '@dashboard/routes'
import diagnosisRoutes from '@diagnosis/routes'
import helpCenterRoutes from '@help-center/routes'
import homeRoutes from '@home/routes'
import insuranceRoutes from '@insurance/routes'
import landingRoutes from '@landing/routes'
import legalRoutes from '@legal/routes'
import militaryDiscountRoutes from '@military-discount/routes'
import packageTrackingRoutes from '@package-tracking/routes'
import paymentRoutes from '@payment/routes'
import productComparisonRoutes from '@product-comparison/routes'
import productRoutes from '@product/routes'
import repairRoutes from '@repair/routes'
import reviewsRoutes from '@reviews/routes'
import merchantRoutes from '@seller/routes'
import studentDiscountRoutes from '@student-discount/routes'

// Module routers to import

const modulesRoutesBusinessToCustomersDefaultLayout = [
  a11yRoutes,
  buybackRoutes,
  checkoutDefaultRoutes,
  productRoutes,
  reviewsRoutes,
]

// TODO: Move these pages to modulesRoutesBusinessToCustomersDefaultLayout
// if they used the default nuxt layout
const modulesRoutesBusinessToCustomersEmptyLayout = [
  buybackMerchantRoutes,
  aboutUsRoutes,
  articleRoutes,
  authRoutes,
  buyingGuidesRoutes,
  checkoutEmptyRoutes,
  cmsRoutes,
  customerRequestsB2CRoutes,
  dashboardRoutes,
  diagnosisRoutes,
  helpCenterRoutes,
  insuranceRoutes,
  landingRoutes,
  legalRoutes,
  militaryDiscountRoutes,
  packageTrackingRoutes,
  paymentRoutes,
  productComparisonRoutes,
  repairRoutes,

  studentDiscountRoutes,
]

const modulesRoutesBusinessToBusiness = [
  adminToolsRoutes,
  customerRequestsRoutes,
  externalAuthRoutes,
  merchantRoutes,
]

const createRoutes = (routes, context) =>
  routes.flatMap((getModuleRoutes) => getModuleRoutes(context))

const createRoutesWithLocaleDefaultLayout = (routes, context) => {
  return [
    {
      // For now, we only support 1 locale per country
      path: `/:locale(${context.locale})`,
      // Apply the default layout
      component: defaultLayout,
      children: [...createRoutes(routes, context)],
    },
  ]
}
const createRoutesWithLocaleEmptyLayout = (routes, context) => {
  return [
    {
      path: `/:locale(${context.locale})`,
      // Render nothing, these components have their own layout
      component: { render: (h) => h('router-view') },
      children: [...createRoutes(routes, context)],
    },
  ]
}

export const getModulesRoutes = (context) => {
  const routes = [
    // Home has to be initialized separately to remove the trailing slash
    ...homeRoutes(context),
    ...createRoutes(modulesRoutesBusinessToBusiness, context),
  ]

  return [
    ...routes,
    ...createRoutesWithLocaleDefaultLayout(
      modulesRoutesBusinessToCustomersDefaultLayout,
      context,
    ),
    ...createRoutesWithLocaleEmptyLayout(
      modulesRoutesBusinessToCustomersEmptyLayout,
      context,
    ),
  ]
}
