import { isPaymentMethodEnabled, paymentMethodName } from '../../methods'
import { PAYMENT_METHODS_ADVERTISING } from '../config/advertising'
import { PAYMENT_ADVERTISING_TYPES } from '../config/constants'

/**
 * @typedef {import('../../..').PaymentMethod} PaymentMethod
 * @typedef {import('../config/advertising').PaymentAdvertisingCartConfig} PaymentAdvertisingCartConfig
 * @typedef {import('../config/advertising').PaymentAdvertisingLandingConfig} PaymentAdvertisingLandingConfig
 * @typedef {import('../config/advertising').PaymentAdvertisingProductConfig} PaymentAdvertisingProductConfig
 * @typedef {import('../config/advertising').PaymentAdvertisingSearchConfig} PaymentAdvertisingSearchConfig
 */

/**
 * @param {PaymentMethod[]} paymentMethods
 * @param {string} type See PAYMENT_ADVERTISING_TYPES
 * @returns {PaymentMethod | null}
 */
const findAdvertisedMethod = (paymentMethods, type) => {
  // Using for-const-of instead of double `.find`
  // eslint-disable-next-line no-restricted-syntax
  for (const methodId of Object.keys(PAYMENT_METHODS_ADVERTISING)) {
    const method =
      PAYMENT_METHODS_ADVERTISING[methodId][type] &&
      paymentMethods.find(({ bmCode }) => bmCode === methodId)

    if (method) {
      return method
    }
  }

  return null
}

/**
 * @param {PaymentMethod[]} paymentMethods
 * @param {string} type See PAYMENT_ADVERTISING_TYPES
 * @returns {null | ({ variant: string, method: PaymentMethod, methodName: string } & Object)}
 */
function paymentAdvertising(paymentMethods, type) {
  const method = findAdvertisedMethod(paymentMethods, type)

  if (!method) {
    return null
  }

  return {
    variant: type,
    method,
    methodName: paymentMethodName(method.bmCode),
    ...PAYMENT_METHODS_ADVERTISING[method.bmCode][type],
  }
}

/**
 * @return {(PaymentAdvertisingProductConfig & { variant: string, method: PaymentMethod }) | null}
 */
export const cartPaymentAdvertising = (paymentMethods) =>
  paymentAdvertising(paymentMethods, PAYMENT_ADVERTISING_TYPES.CART)

/**
 * @return {(PaymentAdvertisingLandingConfig & { variant: string, method: PaymentMethod }) | null}
 */
export const landingPaymentAdvertising = (paymentMethods) =>
  paymentAdvertising(
    paymentMethods.filter(isPaymentMethodEnabled),
    PAYMENT_ADVERTISING_TYPES.LANDING,
  )

/**
 * @returns {(PaymentAdvertisingSearchConfig & { variant: string, method: PaymentMethod }) | null}
 */
export const searchPaymentAdvertising = (paymentMethods) =>
  paymentAdvertising(
    paymentMethods.filter(isPaymentMethodEnabled),
    PAYMENT_ADVERTISING_TYPES.SEARCH,
  )

/**
 * @returns {boolean}
 */
export const hasSearchPaymentAdvertising = (paymentMethods) =>
  searchPaymentAdvertising(paymentMethods) !== null

/**
 * @param {PaymentMethod[]} paymentMethods
 * @param {string} networkId
 * @returns {{ match: PaymentMethod[], rest: PaymentMethod[] }}
 */
const splitPaymentMethodsByNetworkId = (paymentMethods, networkId) =>
  paymentMethods.reduce(
    ({ match, rest }, paymentMethod) =>
      paymentMethod.networks.includes(networkId)
        ? { match: [...match, paymentMethod], rest }
        : { match, rest: [...rest, paymentMethod] },
    { match: [], rest: [] },
  )

/**
 * @param {PaymentMethod[]} paymentMethods
 * @returns {{
 *   advertised: null | (PaymentAdvertisingProductConfig & { variant: string, methods: PaymentMethod[], networkId: string }),
 *   rest: PaymentMethod[],
 * }}
 */
export function productPaymentAdvertising(paymentMethods) {
  const enabledMethods = paymentMethods.filter(isPaymentMethodEnabled)

  const paymentMethod = findAdvertisedMethod(
    enabledMethods,
    PAYMENT_ADVERTISING_TYPES.PRODUCT,
  )
  const networkId = paymentMethod && paymentMethod.networks[0]

  if (!networkId) {
    return {
      advertised: null,
      rest: enabledMethods,
    }
  }

  const { match, rest } = splitPaymentMethodsByNetworkId(
    enabledMethods,
    networkId,
  )

  return {
    advertised: {
      variant: PAYMENT_ADVERTISING_TYPES.PRODUCT,
      methods: match,
      networkId,
      ...PAYMENT_METHODS_ADVERTISING[paymentMethod.bmCode][
        PAYMENT_ADVERTISING_TYPES.PRODUCT
      ],
    },
    rest,
  }
}
