import get from 'lodash/get'

import { sortByProperty } from '@core/helpers'
import { getPlugin as getTranslationPlugin } from '@i18n/utils'
import logger from '@logger'

import {
  PAYMENT_GROUPS,
  PAYMENT_ICON_PATHS,
  PAYMENT_INTEGRATION_TYPES,
  PAYMENT_METHODS,
  PAYMENT_NETWORKS,
} from '../config/constants'
import {
  PAYMENT_GROUP_CONFIGS,
  PAYMENT_METHOD_CONFIGS,
  PAYMENT_NETWORK_CONFIGS,
} from '../config/methods'

/**
 * @param {import('@payment').PaymentMethod} paymentMethod
 * @returns {boolean} `true` if the payment method is enabled, `false` otherwise.
 */
export function isPaymentMethodEnabled(paymentMethod) {
  return paymentMethod.enabled !== false
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
 * @returns {boolean} `true` if the payment method is one of Klarna's, `false` otherwise.
 *
 * @deprecated Use `payment/advertising` module helpers instead.
 */
export function isKlarna(paymentMethod) {
  return [
    PAYMENT_GROUPS.KLARNA_SLICE_IT,
    PAYMENT_GROUPS.KLARNA_PAY_LATER,
  ].includes(paymentMethod.group)
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
 * @returns {boolean} `true` if the payment method is one of Oney's, `false` otherwise.
 *
 * @deprecated Use `payment/advertising` module helpers instead.
 */
export function isOney(paymentMethod) {
  return paymentMethod.group === PAYMENT_GROUPS.ONEY
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
 * @returns {boolean} `true` if the payment method is debit/credit card, `false` otherwise.
 */
export function isCreditCard(paymentMethod) {
  return paymentMethod.group === PAYMENT_GROUPS.CARD
}

/**
 * @param {string} paymentMethodId
 * @returns {import('../config/methods').PaymentMethodConfig}
 */
function paymentMethodConfigFallback(paymentMethodId) {
  const name = {
    id: `Missing payment method config for "${paymentMethodId}"`,
    defaultMessage: paymentMethodId.toUpperCase(),
  }

  return { name, label: name }
}

/**
 * @param {string} paymentMethodId
 * @returns {import('../config/methods').PaymentMethodConfig}
 */
export function paymentMethodConfig(paymentMethodId) {
  return (
    PAYMENT_METHOD_CONFIGS[paymentMethodId] ||
    paymentMethodConfigFallback(paymentMethodId)
  )
}

/**
 * @param {string} paymentMethodId
 * @returns {string} The Payment Method's name
 */
export function paymentMethodName(paymentMethodId) {
  const $t = getTranslationPlugin()
  const { name, ...config } = paymentMethodConfig(paymentMethodId)

  return $t(name, config)
}

/**
 * @param {string} paymentMethodId
 * @returns {string|null} The Payment Method's icon, if any.
 */
export function paymentMethodIcon(paymentMethodId) {
  if (!Object.values(PAYMENT_METHODS).includes(paymentMethodId)) {
    logger.error(
      new Error('Unknown payment method, please update PAYMENT_METHODS.'),
      { paymentMethodId },
    )

    return null
  }

  return `${PAYMENT_ICON_PATHS.METHODS}/${paymentMethodId}.svg`
}

/**
 * @param {string} paymentMethodId
 * @returns {number}
 */
export function paymentMethodInstallmentCount(paymentMethodId) {
  return paymentMethodConfig(paymentMethodId).installmentCount || 0
}

/**
 * @param {string} paymentGroupId
 * @returns {import('../config/methods').PaymentGroupConfig}
 */
function paymentGroupConfigFallback(paymentGroupId) {
  const providerName = paymentGroupId.toUpperCase()

  return {
    hasCustomIcon: false,
    label: {
      id: `Missing payment group config for "${paymentGroupId}"`,
      defaultMessage: providerName,
    },
    providerName,
  }
}

/**
 * @param {string} paymentGroupId
 * @returns {import('../config/methods').PaymentGroupConfig}
 */
export function paymentGroupConfig(paymentGroupId) {
  return (
    PAYMENT_GROUP_CONFIGS[paymentGroupId] ||
    paymentGroupConfigFallback(paymentGroupId)
  )
}

/**
 * @param {string} paymentNetworkId
 * @returns {string} The Payment Network's label
 */
export function paymentGroupLabel(paymentNetworkId) {
  const $t = getTranslationPlugin()

  return $t(paymentGroupConfig(paymentNetworkId).label)
}

/**
 * @param {import('../config/methods').PaymentMethod} paymentGroupId
 * @returns {string|null} The Payment Group's icon
 */
export function paymentGroupIcon(group) {
  if (!Object.values(PAYMENT_GROUPS).includes(group)) {
    logger.error(
      new Error('Unknown payment group, please update PAYMENT_GROUPS.'),
      {
        paymentGroup: group,
      },
    )

    return null
  }

  return paymentGroupConfig(group).hasCustomIcon
    ? `${PAYMENT_ICON_PATHS.GROUPS}/${group}.svg`
    : null
}

/**
 * @param {string} paymentGroupId
 * @param {string} error
 * @returns {string|null} The Payment Group's errors message, if any.
 */
export function paymentMethodError(paymentGroupId, error) {
  return get(paymentGroupConfig(paymentGroupId), `errorMessages.${error}`, null)
}

/**
 * @param {string} paymentNetworkId
 * @returns {import('../config/methods').PaymentNetworkConfig}
 */
function paymentNetworkConfigFallback(paymentNetworkId) {
  const providerName = paymentNetworkId.toUpperCase()

  return {
    name: {
      id: `Missing payment network config for "${paymentNetworkId}"`,
      defaultMessage: providerName,
    },
    icon: null,
  }
}

/**
 * @param {string} paymentNetworkId
 * @returns {import('../config/methods').PaymentNetworkConfig}
 */
function paymentNetworkConfig(paymentNetworkId) {
  return (
    PAYMENT_NETWORK_CONFIGS[paymentNetworkId] ||
    paymentNetworkConfigFallback(paymentNetworkId)
  )
}

/**
 * @param {string} paymentNetworkId
 * @returns {string} The Payment Network's label
 */
export function paymentNetworkLabel(paymentNetworkId) {
  const $t = getTranslationPlugin()

  return $t(paymentNetworkConfig(paymentNetworkId).name)
}

/**
 * @param {string} paymentNetworkId
 * @returns {string|null} Static path to the Payment Network's icon
 */
export function paymentNetworkIcon(paymentNetworkId) {
  if (!Object.values(PAYMENT_NETWORKS).includes(paymentNetworkId)) {
    logger.error(
      new Error('Unknown payment network, please update PAYMENT_NETWORKS.'),
      { paymentNetworkId },
    )

    return null
  }

  // Re-use payment method icons when a method with the same id exists. This is
  // the case for most payment networks, except: Card ones, `klarna` & `oney`.
  return Object.values(PAYMENT_METHODS).includes(paymentNetworkId)
    ? `${PAYMENT_ICON_PATHS.METHODS}/${paymentNetworkId}.svg`
    : `${PAYMENT_ICON_PATHS.NETWORKS}/${paymentNetworkId}.svg`
}

/**
 * @param {string} paymentMethodId
 * @returns {import('../config/methods').VirtualCardGetter}
 */
export function paymentMethodVirtualCard(paymentMethodId) {
  return paymentMethodConfig(paymentMethodId).virtualCard || null
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod - Payment method.
 * @returns {boolean} `true` if the payment method is a virtual card based one.
 */
export function hasVirtualCard(paymentMethod) {
  return (
    typeof paymentMethodVirtualCard(paymentMethod.bmCode) === 'function' &&
    paymentMethod.integrationType !==
      PAYMENT_INTEGRATION_TYPES.ADYEN_CHECKOUT_API
  )
}

/**
 * @param {import('../config/methods').PaymentGroupConfig[]} paymentGroups
 * @returns {import('../config/methods').PaymentGroupConfig[]} `paymentGroups`,
 * sorted by order of declaration in `PAYMENT_GROUP_CONFIGS`.
 */
export function sortedPaymentGroups(paymentGroups) {
  return sortByProperty(paymentGroups, 'id', Object.keys(PAYMENT_GROUP_CONFIGS))
}

/**
 * @param {import('@payment').PaymentMethod} paymentMethod
 * @returns {Promise<boolean>}
 */
async function isPaymentMethodAvailable(paymentMethod) {
  const isAvailableInBrowser =
    PAYMENT_METHOD_CONFIGS[paymentMethod.bmCode]?.isAvailableInBrowser ||
    (() => true)

  return process.server || isAvailableInBrowser(paymentMethod.config || {})
}

/**
 * Filter a list of payment methods, and retain only those that are available
 * in the user's browser. It relies on `isAvailableInBrowser` from
 * `PAYMENT_METHOD_CONFIGS`, or consider it is always available if not defined.
 * Note that it will return the same list on server-side.
 * @param {import('@payment').PaymentMethod[]} paymentMethods
 * @returns {Promise<import('@payment').PaymentMethod[]>}
 */
export async function filterAvailablePaymentMethods(paymentMethods) {
  const availability = await Promise.all(
    paymentMethods.map(isPaymentMethodAvailable),
  )

  return paymentMethods.filter((_, i) => availability[i])
}
