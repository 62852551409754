
import { RevIllustration, RevLink } from '@backmarket/design-system'

import { COMPANY_NAME } from '@config/constants'
import { tw } from '@core/tailwind'
import { PaymentIcons } from '@payment'

import { TYPES } from './footerConfig'

export default {
  components: {
    RevLink,
    RevIllustration,
    PaymentIcons,
  },
  props: {
    blocks: {
      type: Array,
      required: true,
    },
    socials: {
      type: Object,
      required: true,
    },
  },

  computed: {
    footerLinks() {
      return this.blocks.map(
        ({
          type,
          key,
          url,
          file,
          title,
          name,
          icons,
          style,
          prefetch,
          params,
        }) => {
          const linkName = key ? this.$t(key) : null

          return {
            type,
            icons,
            style,
            prefetch,
            text: linkName || title,
            name: linkName || name,
            hasReload: type !== TYPES.LINK_INTERNAL,
            isAnchor: this.isAnchorLink(type),
            isPaymentNetworks: type === TYPES.PAYMENT_NETWORKS,
            displaySocialNetworks: type === TYPES.SOCIAL_NETWORKS,
            displayIcons: type === TYPES.ICONS,
            target: [TYPES.LINK_EXTERNAL, TYPES.FILE].includes(type)
              ? '_blank'
              : '_self',
            url: this.getUrl({ type, url, file, name, params }),
            trackingName: name,
          }
        },
      )
    },
  },

  methods: {
    isAnchorLink(type) {
      return [
        TYPES.LINK,
        TYPES.LINK_EXTERNAL,
        TYPES.FILE,
        TYPES.LINK_INTERNAL,
      ].includes(type)
    },

    getUrl({ type, url, file, name, params }) {
      if (type === TYPES.LINK_INTERNAL) {
        return { name, params }
      }

      if (url) {
        return url
      }

      if (file) {
        return this.$static(`/pdf/${file}`)
      }

      return ''
    },

    getSocialTitle(socialName) {
      return `${COMPANY_NAME} - ${socialName}`
    },

    getIconStyle(className) {
      const style = {
        iconSmall: tw`first:w-7 mr-3 w-[4rem]`,
        icon: tw`first:ml-0 ml-3 mt-3 w-[6rem]`,
      }

      return style[className]
    },
  },
}
