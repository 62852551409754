// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width:992px){._3TY-FSzwzLyvLsQdDR6CtF:hover ._1N_43KUjb7UEE25jekCh-d{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": "_3TY-FSzwzLyvLsQdDR6CtF",
	"logoAnimated": "_1N_43KUjb7UEE25jekCh-d"
};
module.exports = ___CSS_LOADER_EXPORT___;
