import { isNumber } from '@core/helpers'

import getConfig from '../../../components/Page/store/getConfig'

const getDefaultState = () => ({
  reviews: [],
  isMaxReviewsReached: false,
  isPlaceholder: false,
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get: (state) => state.reviews,
    isPlaceholder: (state) => state.isPlaceholder,
    isMaxReviewsReached: (state) => state.isMaxReviewsReached,
  },
  mutations: {
    set: (state, reviews) => {
      const isPlaceholder = reviews.some((review) => !isNumber(review.reviewId))

      state.isPlaceholder = isPlaceholder

      if (isPlaceholder) {
        const reviewsWithIds = reviews.map((review, index) => ({
          ...review,
          reviewId: index,
        }))

        state.reviews = reviewsWithIds

        return
      }

      state.reviews = reviews
    },
    setMore: (state, reviews) => {
      state.reviews = state.reviews.concat(reviews)
    },
    setIsMaxReviewsReached: (state, value) => {
      state.isMaxReviewsReached = value
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    fetch: async ({ commit, dispatch }, { routeInfo }) => {
      const { pathParams, queryParams, endpoints } = getConfig(routeInfo)

      const request = {
        request: endpoints.list,
        pathParams,
        queryParams: { ...routeInfo.query, ...queryParams },
      }
      const { payload } = await dispatch('http/request', request, {
        root: true,
      })

      commit('set', payload.results)
      commit('setIsMaxReviewsReached', !payload.next)
    },
    fetchMore: async (
      { commit, dispatch, getters, rootGetters },
      { routeInfo },
    ) => {
      if (getters.isMaxReviewsReached) {
        return
      }

      const { 'reviewpage/page/get': page } = rootGetters

      const { pathParams, queryParams, endpoints } = getConfig(routeInfo)

      const request = {
        request: endpoints.list,
        pathParams,
        queryParams: { ...routeInfo.query, page, ...queryParams },
      }
      const { payload } = await dispatch('http/request', request, {
        root: true,
      })

      commit('setMore', payload.results)
      commit('setIsMaxReviewsReached', !payload.next)
    },
    set: ({ commit }, reviews) => commit('set', reviews),
    setMore: ({ commit }, reviews) => commit('setMore', reviews),
    isMaxReviewsReached: ({ commit }, value) =>
      commit('setIsMaxReviewsReached', value),
    reset: ({ commit }) => commit('reset'),
  },
}
