export default {
  payInInstallments: {
    id: 'payment_advertising_pay_in_installments',
    defaultMessage: 'Pay in installments',
  },
  payInInstallmentsCta: {
    id: 'payment_advertising_pay_in_installments_cta',
    defaultMessage: 'Learn more',
  },
  payLaterOrInInstallments: {
    id: 'payment_advertising_pay_later_or_in_installments',
    defaultMessage: 'Pay later or in installments',
  },
  installmentPaymentAvailable: {
    id: 'payment_advertising_installment_payment_available',
    defaultMessage: 'Installment payment available',
  },
  installmentPaymentUnavailable: {
    id: 'payment_advertising_installment_payment_unavailable',
    defaultMessage: 'Installment payment unavailable',
  },
  klarnaSimplyPay: {
    id: 'klarna_product_ad_heading',
    defaultMessage: 'Simply pay with Klarna!',
  },
  klarnaPaySafely: {
    id: 'klarna_product_ad_body',
    defaultMessage:
      'Pay safely & easily with Klarna pay later or in up to 24 flexible monthly installments.',
  },
  asLowAs: {
    id: 'payment_advertising_as_low_as',
    defaultMessage: 'As low as {installment, html}.',
  },
  splitPayment: {
    id: 'payment_advertising_split_payment',
    defaultMessage: '{amount}/mo',
  },
}
