import { COUNTRIES } from '@config/constants'

import { affirmVirtualCard } from '../modules/affirm'

import {
  PAYMENT_GROUPS,
  PAYMENT_METHODS,
  PAYMENT_METHOD_ERRORS,
  PAYMENT_NETWORKS,
} from './constants'
import translations from './methods.translations'

/**
 * @typedef {Object} Translation
 * @property {string} id
 * @property {string} defaultMessage
 */

/**
 * @typedef {(paymentId: string, context: VirtualCardContext, $env: Object) => Promise<import('@payment').AdyenVirtualCardData>} VirtualCardGetter
 *
 * @typedef {Object} VirtualCardContext
 * @property {string} email
 * @property {import('modules/checkout').CheckoutAddress} shippingAddress
 * @property {import('modules/checkout').CheckoutAddress} billingAddress
 * @property {import('modules/checkout').CartItem[]} availableItems
 * @property {Price} tax
 * @property {Price} totalShippingPrice
 * @property {Price} priceAfterDiscount
 * @property {boolean} isDiscountApplied
 * @property {string} discountToken
 * @property {Price} discountDeduction
 */

/**
 * @type {Object<string,PaymentMethodConfig>}
 *
 * @typedef {Object} PaymentMethodConfig
 * @property {Translation} name
 * @property {Translation} [label] Option label in payment page
 * @property {number} [installmentCount]
 * @property {VirtualCardGetter} [virtualCard]
 * @property {(Object config) => Promise<boolean>} [isAvailableInBrowser] A function, that, if
 * present, will be called in the browser, to determine whether the payment method is available.
 */
export const PAYMENT_METHOD_CONFIGS = {
  [PAYMENT_METHODS.AFFIRM]: {
    name: translations.affirmMethodName,
    virtualCard: affirmVirtualCard,
  },
  [PAYMENT_METHODS.APPLE_PAY]: {
    name: translations.applePayMethodName,
    isAvailableInBrowser: (config) => {
      return window.ApplePaySession
        ? window.ApplePaySession.canMakePaymentsWithActiveCard(
            config.merchantIdentifier,
          )
        : Promise.resolve(false)
    },
  },
  [PAYMENT_METHODS.CARD]: {
    name: translations.cardMethodName,
  },
  [PAYMENT_METHODS.EPS]: {
    name: translations.epsMethodName,
  },
  [PAYMENT_METHODS.GIROPAY]: {
    name: translations.giropayMethodName,
  },
  [PAYMENT_METHODS.IDEAL]: {
    name: translations.idealMethodName,
  },
  [PAYMENT_METHODS.KLARNA_PAY_LATER]: {
    name: translations.klarnaPayLaterMethodName,
  },
  [PAYMENT_METHODS.KLARNA_SLICE_IT]: {
    name: translations.klarnaSliceItMethodName,
  },
  [PAYMENT_METHODS.PAYPAL]: {
    name: translations.paypalMethodName,
  },
  [PAYMENT_METHODS.ONEY3X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 3,
  },
  [PAYMENT_METHODS.ONEY4X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 4,
  },
  [PAYMENT_METHODS.ONEY6X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 6,
  },
  [PAYMENT_METHODS.ONEY10X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 10,
  },
  [PAYMENT_METHODS.ONEY12X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 12,
  },
  [PAYMENT_METHODS.SOFORT]: {
    name: translations.sofortMethodName,
  },
  [PAYMENT_METHODS.TRUSTLY]: {
    name: translations.trustlyMethodName,
  },
  [PAYMENT_METHODS.PROCESSOUT_SANDBOX]: {
    name: translations.processoutSandboxMethodName,
  },
}

/**
 * @type {Object<string,PaymentGroupConfig>} Payment group config, indexed by id.
 *
 * @typedef {Object} PaymentGroupLegalNotice
 * @property {Translation} definition
 * @property {{definition: Translation, url: string}} link
 * @property {Object} values
 *
 * @typedef {Object} PaymentGroupConfig
 * @property {Translation} groupConfig.label
 * @property {boolean} groupConfig.hasCustomIcon `true` will expect an icon in
 * (/img/payment/groups/), while `false` will re-use either network icons
 * (/img/payment/networks/), or methods icons (/img/payment/methods/).
 * @property {boolean} [groupConfig.external]
 * @property {Translation[]} groupConfig.messages
 * @property {string} [groupConfig.providerName]
 * @property {Object<string,Translation>} [groupConfig.errorMessages]
 * @property {Object<string,PaymentGroupLegalNotice>} [groupConfig.legalNotices]
 */
export const PAYMENT_GROUP_CONFIGS = {
  [PAYMENT_GROUPS.APPLE_PAY]: {
    label: translations.applePayGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [],
    providerName: 'Apple',
  },

  [PAYMENT_GROUPS.CARD]: {
    label: translations.cardGroupLabel,
    hasCustomIcon: false,
    external: false,
    messages: [],
  },

  [PAYMENT_GROUPS.IDEAL]: {
    label: translations.iDealGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'iDeal',
  },

  [PAYMENT_GROUPS.PAYPAL]: {
    label: translations.paypalGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'PayPal',
    errorMessages: {
      [PAYMENT_METHOD_ERRORS.DISABLED]: translations.paypalErrorDisabled,
      [PAYMENT_METHOD_ERRORS.COUNTRY_DISABLED]:
        translations.paypalErrorCountryDisabled,
      [PAYMENT_METHOD_ERRORS.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.paypalErrorOnlyMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.ONE_MERCHANT_INCOMPATIBLE]:
        translations.paypalErrorOneMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.paypalErrorManyMerchantsIncompatible,
      [PAYMENT_METHOD_ERRORS.PRICE_INCOMPATIBLE]:
        translations.paypalErrorPriceIncompatible,
      [PAYMENT_METHOD_ERRORS.INSURANCE_INCOMPATIBLE]:
        translations.paypalErrorInsuranceIncompatible,
    },
  },

  [PAYMENT_GROUPS.KLARNA_PAY_LATER]: {
    label: translations.klarnaPayLaterGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Klarna',
    errorMessages: {
      [PAYMENT_METHOD_ERRORS.DISABLED]: translations.klarnaErrorDisabled,
      [PAYMENT_METHOD_ERRORS.COUNTRY_DISABLED]:
        translations.klarnaErrorCountryDisabled,
      [PAYMENT_METHOD_ERRORS.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOnlyMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.ONE_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOneMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.klarnaErrorManyMerchantsIncompatible,
      [PAYMENT_METHOD_ERRORS.PRICE_INCOMPATIBLE]:
        translations.klarnaErrorPriceIncompatible,
      [PAYMENT_METHOD_ERRORS.INSURANCE_INCOMPATIBLE]:
        translations.klarnaErrorInsuranceIncompatible,
    },
  },

  [PAYMENT_GROUPS.KLARNA_SLICE_IT]: {
    label: translations.klarnaSliceItGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [
      translations.bodyKlarnaSliceIt,
      translations.bodyRedirection,
      translations.bodySeeYouSoon,
    ],
    providerName: 'Klarna',
    errorMessages: {
      [PAYMENT_METHOD_ERRORS.DISABLED]: translations.klarnaErrorDisabled,
      [PAYMENT_METHOD_ERRORS.COUNTRY_DISABLED]:
        translations.klarnaErrorCountryDisabled,
      [PAYMENT_METHOD_ERRORS.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOnlyMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.ONE_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOneMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.klarnaErrorManyMerchantsIncompatible,
      [PAYMENT_METHOD_ERRORS.PRICE_INCOMPATIBLE]:
        translations.klarnaErrorPriceIncompatible,
      [PAYMENT_METHOD_ERRORS.INSURANCE_INCOMPATIBLE]:
        translations.klarnaErrorInsuranceIncompatible,
    },
  },

  [PAYMENT_GROUPS.AFFIRM]: {
    label: translations.affirmGroupLabel,
    hasCustomIcon: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Affirm',
    errorMessages: {
      [PAYMENT_METHOD_ERRORS.DISABLED]: translations.affirmErrorDisabled,
      [PAYMENT_METHOD_ERRORS.COUNTRY_DISABLED]:
        translations.affirmErrorCountryDisabled,
      [PAYMENT_METHOD_ERRORS.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.affirmErrorOnlyMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.ONE_MERCHANT_INCOMPATIBLE]:
        translations.affirmErrorOneMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.affirmErrorManyMerchantsIncompatible,
      [PAYMENT_METHOD_ERRORS.PRICE_INCOMPATIBLE]:
        translations.affirmErrorPriceIncompatible,
      [PAYMENT_METHOD_ERRORS.INSURANCE_INCOMPATIBLE]:
        translations.affirmErrorInsuranceIncompatible,
    },
  },

  [PAYMENT_GROUPS.ONEY]: {
    label: translations.oneyGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Oney',
    legalNotices: {
      [COUNTRIES.FR]: {
        definition: translations.oneyLegalNotice,
        link: {
          definition: translations.oneyLegalNoticeLink,
          url: 'https://www.orias.fr/',
        },
        values: {
          rate3x: '19,31%',
          rate4x: '19,61%',
        },
      },
      [COUNTRIES.ES]: {
        definition: translations.oneyLegalNotice,
        link: {
          definition: translations.oneyLegalNoticeLink,
          url: 'https://www.oney.es/aviso-legal/informacion-legal-3x4xoney',
        },
        values: {
          rate3x: '43,09%',
          rate4x: '37,33%',
          rate6x: '32,79%',
          rate12x: '28,79%',
        },
      },
    },
    errorMessages: {
      [PAYMENT_METHOD_ERRORS.DISABLED]: translations.oneyErrorDisabled,
      [PAYMENT_METHOD_ERRORS.COUNTRY_DISABLED]:
        translations.oneyErrorCountryDisabled,
      [PAYMENT_METHOD_ERRORS.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.oneyErrorOnlyMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.ONE_MERCHANT_INCOMPATIBLE]:
        translations.oneyErrorOneMerchantIncompatible,
      [PAYMENT_METHOD_ERRORS.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.oneyErrorManyMerchantsIncompatible,
      [PAYMENT_METHOD_ERRORS.PRICE_INCOMPATIBLE]:
        translations.oneyErrorPriceIncompatible,
      [PAYMENT_METHOD_ERRORS.INSURANCE_INCOMPATIBLE]:
        translations.oneyErrorInsuranceIncompatible,
    },
  },

  [PAYMENT_GROUPS.SOFORT]: {
    label: translations.sofortGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Sofort',
  },

  [PAYMENT_GROUPS.GIROPAY]: {
    label: translations.giropayGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Giropay',
  },

  [PAYMENT_GROUPS.EPS]: {
    label: translations.epsGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'EPS',
  },

  [PAYMENT_GROUPS.TRUSTLY]: {
    label: translations.trustlyGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Trustly',
  },

  [PAYMENT_GROUPS.PROCESSOUT_SANDBOX]: {
    label: translations.processoutSandboxGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'ProcessOut',
  },
}

/**
 * @type {Object<string,PaymentNetworkConfig>} Payment network config, indexed by id.
 *
 * @typedef {Object} PaymentNetworkConfig
 * @property {Translation} name
 * @property {import('vue').default} icon
 */
export const PAYMENT_NETWORK_CONFIGS = {
  [PAYMENT_NETWORKS.APPLE_PAY]: {
    name: translations.applePayNetworkName,
  },
  [PAYMENT_NETWORKS.CARTES_BANCAIRES]: {
    name: translations.cartesBancairesNetworkName,
  },
  [PAYMENT_NETWORKS.VISA]: {
    name: translations.visaNetworkName,
  },
  [PAYMENT_NETWORKS.MASTERCARD]: {
    name: translations.mastercardNetworkName,
  },
  [PAYMENT_NETWORKS.AMERICAN_EXPRESS]: {
    name: translations.americanExpressNetworkName,
  },
  [PAYMENT_NETWORKS.DISCOVER]: {
    name: translations.discoverNetworkName,
  },
  [PAYMENT_NETWORKS.BANCONTACT]: {
    name: translations.bancontactNetworkName,
  },
  [PAYMENT_NETWORKS.POSTEPAY]: {
    name: translations.postepayNetworkName,
  },
  [PAYMENT_NETWORKS.PAYPAL]: {
    name: translations.paypalNetworkName,
  },
  [PAYMENT_NETWORKS.AFFIRM]: {
    name: translations.affirmNetworkName,
  },
  [PAYMENT_NETWORKS.EPS]: {
    name: translations.epsNetworkName,
  },
  [PAYMENT_NETWORKS.GIROPAY]: {
    name: translations.giropayNetworkName,
  },
  [PAYMENT_NETWORKS.IDEAL]: {
    name: translations.idealNetworkName,
  },
  [PAYMENT_NETWORKS.KLARNA]: {
    name: translations.klarnaNetworkName,
  },
  [PAYMENT_NETWORKS.ONEY]: {
    name: translations.oneyNetworkName,
  },
  [PAYMENT_NETWORKS.SOFORT]: {
    name: translations.sofortNetworkName,
  },
  [PAYMENT_NETWORKS.TRUSTLY]: {
    name: translations.trustlyNetworkName,
  },
  [PAYMENT_NETWORKS.PROCESSOUT]: {
    name: translations.processoutNetworkName,
  },
}
