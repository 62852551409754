import { insertIf } from '@core/helpers'

import getConfig from '../../getConfig'

const getDefaultState = () => ({
  breadcrumb: [],
})

export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    get: (state) => state.breadcrumb,
  },
  mutations: {
    set: (state, breadcrumb) => {
      state.breadcrumb = breadcrumb
    },
    reset: (state) => {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    fetch: async ({ commit, dispatch }, { routeInfo }) => {
      const { queryParams, pathParams, endpoints } = getConfig(routeInfo)
      const request = {
        request: endpoints.breadcrumb,
        queryParams,
        ...insertIf(pathParams, { pathParams }),
      }
      const { payload } = await dispatch('http/request', request, {
        root: true,
      })

      commit('set', payload)
    },
    set: ({ commit }, breadcrumb) => commit('set', breadcrumb),
    reset: ({ commit }) => commit('reset'),
  },
}
