import { ROUTES } from '@router'

// These routes are referenced here but not handled by Pastrami.
export default () => {
  return [
    {
      path: '/logout',
      name: ROUTES.AUTH.LOGOUT,
      meta: { rollout: false },
    },
    {
      path: '/testchallengepage',
      name: ROUTES.TEST_CHALLENGE,
      meta: { rollout: false },
    },
  ]
}
