
import { RevButtonDefault, RevContainer } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import authenticate from '@auth/util'
import Error4XX from '@core/pages/errors/4XX.vue'
import Error500 from '@core/pages/errors/500.vue'
import { NEW_GOOD_DEALS_LINK } from '@navigation/constant'
import { ROUTES } from '@router'
import { trackError } from '@tracking/events'

import translations from './error.translations'

export default {
  components: {
    RevButtonDefault,
    RevContainer,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  head() {
    const { statusCode, path } = this.error

    return {
      htmlAttrs: {
        lang: this.locale,
      },
      title: statusCode,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${statusCode} | ${path}`,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      locale: 'config/locale',
      experiments: 'experiments/getExperiments',
    }),
    ROUTES: () => ROUTES,
    translations: () => translations,
    is404or410() {
      return this.error.statusCode === 404 || this.error.statusCode === 410
    },
    component() {
      if (this.is404or410) {
        return Error4XX
      }

      return Error500
    },

    // TODO: Clean task https://backmarket.atlassian.net/browse/BRO-2079
    ctaLink() {
      if (this.shouldUseNewGoodDealsLink) {
        return NEW_GOOD_DEALS_LINK
      }

      return { name: ROUTES.HOME }
    },

    // TODO: Clean task https://backmarket.atlassian.net/browse/BRO-2079
    shouldUseNewGoodDealsLink() {
      return this.experiments.goodDealsLink === 'goodDealsEventPage'
    },
  },

  beforeMount() {
    authenticate({
      store: this.$store,
      config: this.$config,
    })
  },

  mounted() {
    trackError({ status: this.error.statusCode, message: this.error.message })
  },
}
