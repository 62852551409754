
import debounce from 'debounce'
import { mapGetters } from 'vuex'

import { searchPopular } from '@http/endpoints'
import SearchParams from '@landing/utils/SearchParams'
import { ROUTES } from '@router'
import { EVENT_TYPE, getDevice } from '@tracking'
import {
  trackFormSubmit,
  trackSearchAnalytics,
  trackSearchTerm,
} from '@tracking/events'

import Combobox from './components/Combobox.vue'

const DEBOUNCE_DELAY = 200

export default {
  components: {
    Combobox,
  },

  props: {
    headerVisible: {
      type: Boolean,
      default: true,
    },
    inputId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      popularSearches: [],
    }
  },

  async fetch() {
    const { payload } = await this.$store.dispatch('http/request', {
      request: searchPopular,
    })

    this.popularSearches = payload
  },

  fetchOnServer: false,

  computed: {
    ...mapGetters({
      results: 'searchBar/results',
      searchQueryID: 'searchBar/queryID',
      visitorId: 'config/visitorId',
      searchIndex: 'searchBar/sortDefaultValue',
    }),
  },

  methods: {
    changeHandler(query) {
      this.fetchResults(query)
    },

    submitHandler({ query, index, item }) {
      trackFormSubmit({
        id: 'header_searchbar',
        name: query,
        payload: {
          index_used: index,
        },
      })

      trackSearchTerm(query)

      if (item) {
        this.goToProductLP({ query, index, item })
      } else if (query) {
        this.goToSearchLP({ query })
      }
    },

    goToSearchLP({ query }) {
      const link = {
        type: 'internal',
        name: ROUTES.LANDING.SEARCH,
        query: { q: query },
      }
      this.$navigation.push(link)
    },

    goToProductLP({ index, item }) {
      if (item.objectID) {
        trackSearchAnalytics({
          type: EVENT_TYPE.CLICK,
          objectId: item.objectID,
          searchQueryID: this.searchQueryID,
          tokenId: this.visitorId,
          // FIXME: Move this offset to trackSearchAnalytics https://github.com/BackMarket/pastrami/blob/b8e4c787b034edd3b645b166b423153ceb23aea5/modules/tracking/events.js#L178
          position: index + 1,
          index: this.searchIndex,
        })
      }

      const link = {
        ...item.link,
        query: { ...item.link.query },
      }
      this.$navigation.push(link)
    },

    fetchResults: debounce(function debouncedFetchResults(query) {
      if (!query) {
        return
      }
      const source = 'search-bar'

      const searchParams = new SearchParams({
        query: {
          q: query,
        },
        context: {
          source,
        },
        tags: [getDevice(), source],
      })

      this.$store.dispatch('searchBar/fetchResults', { searchParams })
    }, DEBOUNCE_DELAY),
  },
}
