import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

// The redirection is done manually on the auth middleware because
// we don't want the staff to be redirected to the client login page
const commonRedirectionConfig = {
  auth: { required: true, redirection: { name: ROUTES.ADMIN_TOOLS.HOME } },
  staff: { required: true },
}

const routes = [
  /**
   * Tools
   */
  {
    name: ROUTES.ADMIN_TOOLS.TOOLS.TASK_ANALYZER,
    path: 'tools/task_analyzer',
    component: () =>
      dynamicImport(import('@admin-tools/modules/tools/pages/task-analyzer')),
    meta: commonRedirectionConfig,
  },
  /**
   * Catalog
   */
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.TOOLS.BULK_ACTIONS,
    path: 'catalog/tools/bulk_actions',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/tools/bulk-actions'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.PRODUCTS.LIST,
    path: 'catalog/buyback/products',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/buyback/products/search'),
      ),
  },
  {
    path: 'catalog/buyback/products/:akeneoProductId',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/buyback/products/single'),
      ),
    redirect: { name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.PRODUCTS.ITEM.INDEX },
    children: [
      {
        name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.PRODUCTS.ITEM.INDEX,
        path: 'details',
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/buyback/products/single/children/ProductDetails'
            ),
          ),
      },
      {
        path: 'backboxes',
        name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.PRODUCTS.ITEM.BACKBOXES,
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/buyback/products/single/children/Backboxes'
            ),
          ),
      },
      {
        path: 'listings',
        name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.PRODUCTS.ITEM.LISTINGS,
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/buyback/products/single/children/Listings'
            ),
          ),
      },
    ],
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.TOOLS.AKENEO.IMAGES.UPLOAD,
    path: 'catalog/tools/akeneo/images/upload',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/tools/akeneo/images/upload'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.CATEGORIES.LIST,
    path: 'catalog/buyback/categories',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/buyback/categories'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.MERCHANT.PRODUCT.LISTINGS,
    path: 'catalog/buyback/merchants/:merchantId/products/:productId',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/catalog/pages/buyback/merchant/product/listings'
        ),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.BUYBACK.LISTINGS.SEARCH,
    path: 'catalog/buyback/listings/search',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/buyback/search'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.HISTORY,
    path: 'catalog/validations/products/history',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/catalog/pages/validations/products/history'
        ),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.LIST,
    path: 'catalog/validations/products',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/validations/products/list'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.REMATCH.ITEM,
    path: 'catalog/validations/rematch/products/:productId',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/catalog/pages/validations/products/rematch/search'
        ),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.LISTINGS.REMATCH,
    path: 'catalog/validations/rematch/listings/:productId/:productIdToCompare',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/catalog/pages/validations/listings/rematch'
        ),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.CATEGORIES.RESTRICTED.LIST,
    path: 'catalog/sales/categories/restricted',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/catalog/pages/sales/categories/restricted'
        ),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIST,
    path: 'catalog/sales/listings',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/sales/listings/search'),
      ),
  },
  {
    path: 'catalog/sales/listings/:listingId/lifecycle',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/sales/listings/lifecycle'),
      ),
    redirect: {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
    },
    children: [
      {
        path: 'marketized-attributes',
        name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.INDEX,
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/sales/listings/lifecycle/children/MarketizedAttributes'
            ),
          ),
      },
      {
        path: 'online-conditions',
        name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE
          .ONLINE_CONDITIONS,
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/sales/listings/lifecycle/children/OnlineConditions'
            ),
          ),
      },
      {
        path: 'history',
        name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.LISTINGS.LIFECYCLE.HISTORY,
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/catalog/pages/sales/listings/lifecycle/children/History'
            ),
          ),
      },
    ],
  },
  {
    name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.PRODUCTS.UPDATE,
    path: 'catalog/sales/product/update/:productId',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/catalog/pages/sales/product/update'),
      ),
  },
  /**
   * Customers
   */
  {
    name: ROUTES.ADMIN_TOOLS.CUSTOMERS.INDEX,
    path: 'customers',
    component: () => dynamicImport(import('@admin-tools/modules/customers')),
  },

  /** Sales Limitations */
  {
    name: ROUTES.ADMIN_TOOLS.SALES.LIMITATIONS,
    path: 'sales-limitations',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/sales/pages/sales-limitations'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.SALES.RATINGS_REVIEWS,
    path: 'ratings-reviews',
    component: () =>
      dynamicImport(import('@admin-tools/modules/sales/pages/ratings-reviews')),
  },
  /** Sellers */
  {
    path: 'sellers/:sellerId',
    component: () =>
      dynamicImport(import('@admin-tools/modules/sellers/pages/single')),
    redirect: {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.BUYBACK_LISTINGS,
    },
    children: [
      {
        name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.BUYBACK_LISTINGS,
        path: 'buyback-listings',
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/sellers/pages/single/children/BuybackListings'
            ),
          ),
      },
      {
        name: ROUTES.ADMIN_TOOLS.CATALOG.SELLERS.ITEM.RATE_LIMITING,
        path: 'rate-limiting',
        component: () =>
          dynamicImport(
            import(
              '@admin-tools/modules/sellers/pages/single/children/RateLimiting'
            ),
          ),
      },
    ],
  },

  /**
   * Insurances
   */
  {
    name: ROUTES.ADMIN_TOOLS.INSURANCES.CANCEL,
    path: 'insurances/:policyId/cancel',
    component: () =>
      dynamicImport(
        import(
          '@admin-tools/modules/insurances/pages/PolicyCancellationPage.vue'
        ),
      ),
    meta: commonRedirectionConfig,
  },
  {
    name: ROUTES.ADMIN_TOOLS.INSURANCES.STATUS,
    path: 'insurances/:policyId/status',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/insurances/pages/PolicyStatusPage.vue'),
      ),
    meta: commonRedirectionConfig,
  },
  /**
   * Landing Pages Management
   */
  {
    name: ROUTES.ADMIN_TOOLS.LANDING_PAGES.BULK_IMPORTS,
    path: 'landing-pages/bulk-imports',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/landing-pages/pages/bulk-imports'),
      ),
  },
  /**
   * Finance
   */
  {
    name: ROUTES.ADMIN_TOOLS.FINANCE.PICSOU,
    path: 'finance/picsou',
    component: () =>
      dynamicImport(import('@admin-tools/modules/finance/pages/picsou')),
  },
  {
    name: ROUTES.ADMIN_TOOLS.FINANCE.SELLER_COMPENSATIONS,
    path: 'finance/seller-compensations',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/finance/pages/sellerCompensations'),
      ),
  },
  {
    name: ROUTES.ADMIN_TOOLS.FINANCE.CREDIT_REQUESTS,
    path: 'finance/credit-requests',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/finance/pages/creditRequests'),
      ),
  },
  // TODO: The following is a redirect from '/bo_admin/credit-requests' to '/bo_admin/finance/credit-requests'.
  //       It should be deleted at some point.
  {
    name: ROUTES.ADMIN_TOOLS.FINANCE.CREDIT_REQUESTS_REDIRECT,
    path: 'credit-requests',
    component: () =>
      dynamicImport(
        import('@admin-tools/modules/finance/pages/creditRequests'),
      ),
    redirect: {
      name: ROUTES.ADMIN_TOOLS.FINANCE.CREDIT_REQUESTS,
    },
  },
  {
    name: ROUTES.ADMIN_TOOLS.FINANCE.CCBM_FEE_RULES,
    path: 'finance/ccbm-fee-rules',
    component: () =>
      dynamicImport(import('@admin-tools/modules/finance/pages/ccbmFeeRules')),
  },
]

export default () => {
  return [
    {
      path: '/bo_admin',
      component: () =>
        dynamicImport(import('./shared/components/layouts/Base.vue')),
      meta: commonRedirectionConfig,
      children: routes,
    },
    /**
     * Legacy routes
     */
    {
      name: ROUTES.ADMIN_TOOLS.HOME,
      path: '/bo_merchant/admin_tools/dashboard',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.SALES.PRODUCTS.LIST,
      path: '/bo_merchant/admin_tools/choose_product_to_duplicate',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CATALOG.VALIDATION.PRODUCTS.REMATCH.LIST,
      path: '/bo_merchant/admin_tools/validate_products',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.ORDERS,
      path: '/bo_merchant/admin_tools/orders',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.TRANSACTIONS.HISTORY,
      path: '/bo_merchant/admin_tools/bank',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CUSTOMERS.ACCOUNT,
      path: '/bo_merchant/admin_tools/clients',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SALES.REFUNDS,
      path: '/bo_merchant/admin_tools/refunds',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.CUSTOMERS.PERSONAL_DATA,
      path: '/bm/bo-admin/customer/:customerId/personal-data',
      meta: { rollout: false },
    },
    {
      name: ROUTES.ADMIN_TOOLS.SOURCING.ORDERS,
      path: '/bo_merchant/admin_tools/sourcing/orders/:orderId',
      meta: { rollout: false },
    },
  ]
}
