const CUSTOMER_REQUESTS_SOURCES = {
  web: 'WEBSITE',
  mobile: 'MOBILE_APP',
}

const CUSTOMER_REQUESTS_STATES = {
  closed: 'CLOSED',
  open: 'OPEN',
}

const INFORMATIVE_MESSAGE_ONBOARDING_COOKIE =
  'BM_savplatform-informative-message-onboarding-shown'

const MESSAGE_KINDS = {
  adminToAll: 'ADMIN_TO_ALL',
  adminToCustomer: 'ADMIN_TO_CLIENT',
  adminToMerchant: 'ADMIN_TO_MERCHANT',
  merchantToAll: 'MERCHANT_TO_ALL',
  merchantToBackcare: 'MERCHANT_TO_BACKCARE',
  merchantToAdmin: 'MERCHANT_TO_ADMIN',
  backcareToAll: 'BACKCARE_TO_ALL',
  backcareToMerchant: 'BACKCARE_TO_MERCHANT',
  backcareToCustomer: 'BACKCARE_TO_CUSTOMER',
  customerToAll: 'CLIENT_TO_ALL',
  auto: 'AUTO',
}

const MAX_ATTACHMENTS_SIZE = 25000000

const ORDERLINE_STATES = {
  paid: 'PAID',
  cancelled: 'CANCELLED',
  validated: 'VALIDATED',
  shipped: 'SHIPPED',
  refundedPreDelivery: 'REFUNDED_PRE_DELIVERY',
  refundedPostDelivery: 'REFUNDED_POST_DELIVERY',
}

const SAV_ROLES = {
  client: 'client',
  merchant: 'merchant',
  backcare: 'backcare',
  admin: 'admin',
}

const VISIBILITY_ROLES = {
  customer: 'CUSTOMER',
  seller: 'SELLER',
  agent: 'AGENT',
  staff: 'STAFF',
}

export {
  CUSTOMER_REQUESTS_SOURCES,
  CUSTOMER_REQUESTS_STATES,
  INFORMATIVE_MESSAGE_ONBOARDING_COOKIE,
  MESSAGE_KINDS,
  MAX_ATTACHMENTS_SIZE,
  ORDERLINE_STATES,
  SAV_ROLES,
  VISIBILITY_ROLES,
}
