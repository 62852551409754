import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { fetchPaymentMethods } from '@http/endpoints'
import {
  PAYMENT_METHODS,
  isPaymentMethodEnabled,
  productPaymentAdvertising,
} from '@payment'

export default {
  namespaced: true,

  /**
   * @typedef {Object} State
   * @property {import('@payment').PaymentMethod[]} methods
   * @property {import('../modules/common').PaymentConfig} config
   * @property {import('@Payment'.PaymentMethod) | null} selectedMethod
   *
   * @returns {State}
   */
  state: () => ({
    methods: [],
    config: {
      adyenEncryption: { key: '', time: '' },
      signifydFingerprint: null,
    },
    selectedMethod: null,
  }),

  mutations: {
    /**
     * @param {State} state
     * @param {import('@payment').PaymentMethod[]} methods
     */
    setMethods(state, methods) {
      state.methods = methods
    },

    /**
     * @param {State} state
     * @param {import('../modules/common').PaymentConfig} config
     */
    setConfig(state, config) {
      state.config = config
    },

    /**
     * @param {State} state
     * @param {import('@payment').PaymentMethod} method
     */
    setSelectedMethod(state, method) {
      state.selectedMethod = method
    },
  },

  getters: {
    /**
     * @param {State} state
     * @returns {import('@payment').PaymentMethod[]}
     */
    methods: (state) => state.methods,

    /**
     * @param {State} state
     * @param {Object} getters
     */
    enabledMethods: (state, { methods }) =>
      methods.filter(isPaymentMethodEnabled),

    /**
     * @param {State} state
     * @param {Object} getters
     * @param {Object} rootGetters
     */
    advertisedMethodsId: (state, { methods }) =>
      get(productPaymentAdvertising(methods).advertised, 'methods', [])
        .map((method) => method.bmCode)
        .join('_') || null,

    /**
     * @param {State} state
     */
    selectedMethod: (state) => state.selectedMethod,

    /**
     * @param {State} state
     * @returns {import('../modules/common').PaymentConfig}
     */
    config: (state) => state.config,
  },

  actions: {
    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {import('../modules/common').PaymentConfig & Object} payload
     */
    async set({ commit }, { adyenEncryption, signifydFingerprint }) {
      commit('setConfig', { adyenEncryption, signifydFingerprint })
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {string[]} payload.cartItemsIds
     * @param {Price} payload.cartPrice
     */
    async fetchMethods(
      { commit, dispatch, rootGetters },
      { cartItemsIds, cartPrice },
    ) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: fetchPaymentMethods,
          queryParams: {
            country_code: rootGetters['config/country'],
            listings: cartItemsIds.join(','),
            bag_price: cartPrice.amount,
          },
        },
        { root: true },
      )

      // TODO [PAYIN-1798] Remove once Apple Pay is fully rolled out
      const methods =
        isEmpty(this.$config) ||
        this.$config.FF_APPLE_PAY_ENABLED_LISTINGS === '*'
          ? payload.results
          : payload.results.filter(
              (paymentMethod) =>
                paymentMethod.bmCode !== PAYMENT_METHODS.APPLE_PAY ||
                cartItemsIds.every((itemId) =>
                  this.$config.FF_APPLE_PAY_ENABLED_LISTINGS.split(
                    /\s*,\s*/,
                  ).includes(itemId),
                ),
            )

      commit('setMethods', methods)
    },

    /**
     * @param {import('vuex').ActionContext<State, Object>} context
     * @param {Object} payload
     * @param {import('@payment').PaymentMethod | null} payload.paymentMethod
     */
    setSelectedMethod({ commit }, { paymentMethod }) {
      commit('setSelectedMethod', paymentMethod)
    },
  },
}
