import isEmpty from 'lodash/isEmpty'

import {
  applyPricingRule,
  changeReferenceMarket,
  createPricingRule,
  deletePricingRule,
  getCategoriesRequest,
  getMarketPricingRulesRequest,
  getMerchantPreferences,
  updateMerchantPreferences,
  updatePricingRule,
} from '@http/endpoints'

export default {
  namespaced: true,
  state: () => ({
    merchantPreferences: {},
    categories: [],
    pricingRules: [],
  }),
  getters: {
    categories: (state) => state.categories,
    merchantPreferences: (state) => state.merchantPreferences,
    pricingRules: (state) => state.pricingRules,
    getPricingRulesForMarket: (state) => (market) =>
      state.pricingRules.filter((rule) => rule.targetMarket === market),
  },
  mutations: {
    setCategories(state, categories) {
      state.categories = categories
    },
    setMerchantPreferences(state, merchantPreferences) {
      state.merchantPreferences = merchantPreferences
    },
    setPricingRules(state, pricingRules) {
      state.pricingRules = pricingRules
    },
    editPricingRule(state, { id, ...payload }) {
      state.pricingRules = state.pricingRules.map((rule) => {
        if (rule.id === id) {
          return {
            ...rule,
            ...payload,
          }
        }

        return rule
      })
    },
    addPricingRule(state, payload) {
      state.pricingRules = [...state.pricingRules, payload]
    },
    deletePricingRule(state, id) {
      state.pricingRules = state.pricingRules.filter((rule) => rule.id !== id)
    },
  },
  actions: {
    async getCategories({ commit, dispatch }) {
      const { payload: categories } = await dispatch(
        'http/request',
        {
          request: getCategoriesRequest,
          queryParams: { my_categories: true },
        },
        { root: true },
      )
      commit('setCategories', categories)
    },
    async changeReferenceMarket(
      { commit, dispatch },
      { oldReferenceMarket, newReferenceMarket },
    ) {
      // Since changing the reference market delete's all pricing rules
      // don't do anything if the merchant selects the same reference market than before
      if (oldReferenceMarket !== newReferenceMarket) {
        const { payload } = await dispatch(
          'http/request',
          {
            request: changeReferenceMarket,
            body: {
              referenceMarket: newReferenceMarket,
            },
          },
          { root: true },
        )

        if (payload.referenceMarket === newReferenceMarket) {
          commit('setPricingRules', [])
          // Re-fetch the reference market
          await dispatch('seller/fetch', {}, { root: true })
        }
      }
    },
    // PRICING RULES
    async getPricingRules({ commit, dispatch }) {
      const {
        payload: { results },
      } = await dispatch(
        'http/request',
        { request: getMarketPricingRulesRequest },
        { root: true },
      )

      if (results) {
        commit('setPricingRules', results)
      }
    },
    async createRule({ getters, commit, dispatch }, localPayload) {
      const { payload } = await dispatch(
        'http/request',
        {
          request: createPricingRule,
          body: localPayload,
        },
        { root: true },
      )

      if (!payload.id && !localPayload.targetMarket) {
        return null
      }

      const { targetMarket } = localPayload
      // If there are no rules yet, it means the merchant is creating its first rule
      // In this case and ONLY in this case, we want to auto-enable the daily price update for him
      // It implies that the daily price update shouldn't be auto-enabled if the merchant explicitely disabled it
      const rulesOfMarket = getters.getPricingRulesForMarket(targetMarket)

      if (isEmpty(rulesOfMarket)) {
        await dispatch('setDailyPriceUpdate', {
          countryCode: targetMarket,
          status: true,
        })
      }

      commit('addPricingRule', payload)

      return payload.id
    },
    async createRuleAndApply({ dispatch }, localPayload) {
      const id = await dispatch('createRule', localPayload)
      await dispatch('applyRule', { id })
    },
    async editRule({ commit, dispatch }, { rule, ...localPayload }) {
      if (rule.category !== localPayload.category) {
        // The edition of a category requires to delete the rule and create a brand new one
        // @see https://www.notion.so/Discussion-Pricing-rule-Scope-and-ID-change-bdbf90c15b6747fb93591b286ab858fa
        await dispatch('deleteRule', { id: rule.id })
        await dispatch('createRule', localPayload)
      } else {
        const { payload } = await dispatch(
          'http/request',
          {
            request: updatePricingRule,
            pathParams: { ruleId: rule.id },
            body: localPayload,
          },
          { root: true },
        )

        commit('editPricingRule', payload)
      }
    },
    async applyRule({ commit, dispatch }, { id }) {
      await dispatch(
        'http/request',
        {
          request: applyPricingRule,
          pathParams: { ruleId: id },
        },
        { root: true },
      )

      commit('editPricingRule', {
        id,
        lastTaskCreationDate: new Date().toISOString(),
      })
    },
    async deleteRule({ commit, dispatch }, { id }) {
      await dispatch(
        'http/request',
        {
          request: deletePricingRule,
          pathParams: { ruleId: id },
        },
        { root: true },
      )

      commit('deletePricingRule', id)
    },
    // MERCHANT PREFERENCES
    async getMerchantPreferences({ commit, dispatch }) {
      const { payload: merchantPreferences } = await dispatch(
        'http/request',
        { request: getMerchantPreferences },
        { root: true },
      )

      commit('setMerchantPreferences', merchantPreferences)
    },
    async setDailyPriceUpdate(
      { getters, commit, dispatch },
      { countryCode, status },
    ) {
      const marketPreferences = getters.merchantPreferences.markets?.find(
        ({ market }) => {
          return market === countryCode
        },
      )

      if (!marketPreferences) {
        return
      }

      const { payload: updatedMerchantPreferences } = await dispatch(
        'http/request',
        {
          request: updateMerchantPreferences,
          body: {
            markets: [
              ...getters.merchantPreferences.markets.filter(
                ({ market }) => market !== countryCode,
              ),
              {
                market: countryCode,
                automatic_updates_enabled: status,
              },
            ],
          },
        },
        { root: true },
      )

      commit('setMerchantPreferences', updatedMerchantPreferences)
    },
  },
}
