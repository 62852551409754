import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

// TODO [ACQ-265] Needed during Buying Guides migration to Contentful.
const routesToNewArticleLayout = [
  {
    path: 'c/:category/:pageName',
    name: ROUTES.CMS.ARTICLE,
    component: () => dynamicImport(import('@article/Article.vue')),
  },
  {
    path: 'c/:category/:slug',
    name: ROUTES.BUYING_GUIDE.SELF,
    component: () => dynamicImport(import('@article/Article.vue')),
  },
]

const routesToLegacyBuyingGuideLayout = [
  {
    path: 'c/:category/:slug',
    name: ROUTES.BUYING_GUIDE.SELF,
    component: () =>
      dynamicImport(import('./pages/BuyingGuide/LayoutBuyingGuide.vue')),
  },
]

export default ({ config, country }) => {
  // TODO [ACQ-265] Needed during Buying Guides migration to Contentful.
  const isNewArticleLayoutOpenedInCountry =
    config.FF_ARTICLE_PAGE_BY_COUNTRY?.includes(country)

  const routesToArticle = isNewArticleLayoutOpenedInCountry
    ? routesToNewArticleLayout
    : routesToLegacyBuyingGuideLayout

  return [
    {
      path: 'c',
      name: ROUTES.BUYING_GUIDE.ROOT_HUB,
      component: () => dynamicImport(import('./pages/Hub/LayoutRootHub.vue')),
    },
    {
      path: 'c/hub/:unifiedName',
      name: ROUTES.BUYING_GUIDE.HUB,
      component: () => dynamicImport(import('./pages/Hub/LayoutHub.vue')),
    },
    ...routesToArticle,
  ]
}
