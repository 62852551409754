import { ALL_APP_COUNTRIES } from '@core/helpers/countries'
import { createTTL, isPastDate } from '@core/helpers/date'

const NAV_CACHE_DURATION_IN_MINUTES = 15
// Use process instead of global since for some reason, global is not keeping the aggregated data through the requests.
if (!process.countryCache) {
  process.countryCache = ALL_APP_COUNTRIES.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        nav: [],
        topSales: [],
        paymentMethods: [],
        skinnyBanners: [],
      },
    }),
    {},
  )
}

const isCountryCacheAvailable = (country) =>
  // False if the country is not rolled out.
  process.countryCache[country].ttl &&
  !isPastDate(process.countryCache[country].ttl)

export default async ({ store }) => {
  const country = store.getters['config/country']
  if (process.server && process.countryCache[country]) {
    if (isCountryCacheAvailable(country)) {
      const { topSales, paymentMethods, skinnyBanners } =
        process.countryCache[country]

      store.commit('countryConfig/setTopSales', topSales)
      store.commit('countryConfig/setPaymentMethods', paymentMethods)
      store.commit('countryConfig/setSkinnyBanners', skinnyBanners)
    } else {
      process.countryCache[country].ttl = createTTL({
        minutes: NAV_CACHE_DURATION_IN_MINUTES,
      })

      const [topSales, paymentMethods, skinnyBanners] = await Promise.all([
        store.dispatch('countryConfig/fetchTopSales'),
        store.dispatch('countryConfig/fetchPaymentMethods'),
        store.dispatch('countryConfig/fetchSkinnyBanners'),
      ])

      process.countryCache[country] = {
        // Keep the stored values like ttl
        ...process.countryCache[country],
        topSales,
        paymentMethods,
        skinnyBanners,
      }
    }
  }
}
