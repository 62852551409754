import { dynamicImport } from '@core/helpers'
import { ROUTES } from '@router'

export const checkoutEmptyRoutes = () => [
  {
    path: 'f2',
    component: () =>
      dynamicImport(import('./modules/layout/pages/CheckoutPage.vue')),
    children: [
      {
        path: 'cart',
        name: ROUTES.CHECKOUT.CART,
        component: () =>
          dynamicImport(import('./modules/legacy/pages/cart.vue')),
      },
      {
        path: 'coverage',
        name: ROUTES.CHECKOUT.INSURANCE,
        component: () =>
          dynamicImport(
            import('./modules/insurance/pages/InsuranceStepPage.vue'),
          ),
      },
      {
        path: 'hello',
        name: ROUTES.CHECKOUT.HELLO,
        component: () =>
          dynamicImport(
            import('./modules/account/pages/CheckoutHelloPage.vue'),
          ),
        meta: {
          guest: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
          },
        },
      },
      {
        path: 'login',
        name: ROUTES.CHECKOUT.LOGIN,
        component: () =>
          dynamicImport(
            import('./modules/account/pages/CheckoutLoginPage.vue'),
          ),
        meta: {
          guest: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
          },
        },
      },
      {
        path: 'signup',
        name: ROUTES.CHECKOUT.SIGNUP,
        component: () =>
          dynamicImport(
            import('./modules/account/pages/CheckoutSignupPage.vue'),
          ),
        meta: {
          guest: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION },
          },
        },
      },
      {
        path: 'shipping/confirmation-address',
        name: ROUTES.CHECKOUT.ADDRESS_CONFIRMATION,
        component: () =>
          dynamicImport(
            import(
              './modules/shipping/pages/CheckoutAddressConfirmationPage.vue'
            ),
          ),
        meta: {
          auth: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.HELLO },
          },
          showShippingAlerts: true,
        },
      },
      {
        path: 'shipping/shipping-address',
        name: ROUTES.CHECKOUT.SHIPPING_ADDRESS,
        component: () =>
          dynamicImport(
            import('./modules/shipping/pages/CheckoutShippingPage.vue'),
          ),
        meta: {
          auth: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.HELLO },
          },
          showShippingAlerts: true,
        },
      },
      {
        path: 'shipping/billing-address',
        name: ROUTES.CHECKOUT.BILLING_ADDRESS,
        component: () =>
          dynamicImport(
            import('./modules/shipping/pages/CheckoutBillingPage.vue'),
          ),
        meta: {
          auth: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.HELLO },
          },
        },
      },
      {
        path: 'payment',
        name: ROUTES.CHECKOUT.PAYMENT,
        component: () =>
          dynamicImport(
            import('./modules/payment/pages/CheckoutPaymentPage.vue'),
          ),
        meta: {
          auth: {
            required: true,
            redirection: { name: ROUTES.CHECKOUT.HELLO },
          },
          showShippingAlerts: true,
        },
      },
    ],
  },
]

export const checkoutDefaultRoutes = () => [
  // Note: payment result page does not share the same layout with the rest of
  // the checkout funnel pages, so it does not have CheckoutPage as parent
  // route component.
  {
    path: 'f2/payment-result/:paymentId?',
    name: ROUTES.CHECKOUT.PAYMENT_RESULT,
    component: () =>
      dynamicImport(
        import('./modules/result/pages/CheckoutPaymentResultPage.vue'),
      ),
    meta: {
      auth: {
        required: true,
      },
    },
  },
]
